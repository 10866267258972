@<template>
    <div class="order-detail__container w100">
        <div class="order-detail__header f aic mb2">
            <div class="desktop h3 pr1 border-right">
                Đơn hàng
            </div>
            <div class="desktop p3 pr1 pl1 gray">
                Đơn hàng
            </div>
            <div class="desktop icon icon--next"></div>
            <div class="h5 pr1 pl1 text-red-linear">
                Chi tiết đơn hàng
            </div>
        </div>
        
        <div class="order-detail__body f fw jcb ais w100">

            <!-- body-left -->
            <div class="body-left">

                <!-- left__status -->
                <div class="body-left__status mb1 pt1 pb1 pl1 pr1 border-radius bg-white-1">
                    <div
                        v-if="dataOrder.status_order" 
                        class="f jcb aic pb1 border-bottom">
                        <div class="h5 gray">Trạng thái đơn hàng</div>
                        <div 
                            v-if="dataOrder.status_order.id == 1"
                            class="card-item__status h6 pt025 pb025 pl05 pr05 border-radius orange bg-yellow">
                                {{ dataOrder.status_order.title_status_order }}
                        </div>
                        <div 
                            v-if="dataOrder.status_order.id == 2"
                            class="card-item__status h6 pt025 pb025 pl05 pr05 border-radius green bg-green">
                                {{ dataOrder.status_order.title_status_order }}
                        </div>
                        <div 
                            v-if="dataOrder.status_order.id == 3"
                            class="card-item__status h6 pt025 pb025 pl05 pr05 border-radius red bg-red">
                                {{ dataOrder.status_order.title_status_order }}
                        </div>
                    </div>
                    <div v-if="dataOrder.status_delivery" class="pt1">
                        <div 
                            class="mb1 h5 gray">Trạng thái vận kho</div>
                            <div 
                                @click="handleStatus"
                                class="relative f jcb aic mb1 pt1 pb1 pl1 pr1 border-radius border">
                                <div 
                                class="p4 cursor"
                                v-if="dataOrder.status_delivery.id == 1">
                                Cần chuẩn bị hàng
                            </div>
                            <div 
                                class="p4 cursor"
                                v-if="dataOrder.status_delivery.id == 2">
                                Đang chờ vận chuyển tới lấy
                            </div>
                            <div 
                                class="relative f jcb aic cursor w100"
                                v-if="dataOrder.status_delivery.id == 3">
                                <div class="p4">Đã giao hàng cho vận chuyển</div>
                                <div class="info-support__icon icon icon--warning-blue"></div>
                                <div class="info-support__container absolute pt1 pb1 pl1 pr1 border-radius bg-white-1">
                                    <div class="p3">Bạn đã hoàn tất vận kho. Trong trường hợp giao hàng không thành công, hệ thống sẽ tự động cập nhật trạng thái "Đang chờ vận chuyển trả hàng".</div>
                                </div>
                                <div class="info-support__svg absolute">
                                    <svg width="26" height="23" viewBox="0 0 26 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.4641 21C14.9245 23.6667 11.0755 23.6667 9.5359 21L0.875645 5.99999C-0.663956 3.33333 1.26055 -2.59991e-06 4.33975 -2.33071e-06L21.6603 -8.16506e-07C24.7395 -5.47314e-07 26.664 3.33333 25.1244 6L16.4641 21Z" fill="white"/>
                                    </svg>
                                </div>
                            </div>
                            <div 
                                class="cursor"
                                v-if="dataOrder.status_delivery.id == 4">
                                Đang chờ vận chuyển trả hàng
                            </div>
                            <div 
                                class="cursor"
                                v-if="dataOrder.status_delivery.id == 5">
                                Đã nhận lại hàng về kho
                            </div>
                            <div 
                                v-if="dataOrder.status_delivery.id != 3"
                                class="icon icon--arrow"></div>
                            <div class="select-status__list absolute pl1 pr1 border-radius border bg-white-1">
                                <div 
                                    v-for="(item, index) in deliverystatus"
                                    :key="index">
                                    <div 
                                        @click="handleDeliveryStatus(item.id)"
                                        class="select-status__item upcase border-bottom pt1 pb1 p4 cursor black"
                                        v-if="dataOrder.status_delivery.id + 1 == item.id">
                                            {{ item.title_delivery }}
                                    </div>
                                    <!-- <div 
                                        v-else
                                        class="select-status__item-disable upcase border-bottom pt1 pb1 p4 cursor gray">
                                            {{ item.title_delivery }}
                                    </div> -->
                                </div>
                            </div>
                        </div>
                        <div 
                            v-if="dataOrder.status_delivery.id == 1"
                            class="f jcc aic pt05 pb05 border-radius white bg-red-2 cursor">
                            <div class="icon icon--bell-white"></div>
                            <div class="ml025">
                                <div class="p6">Báo cáo kho không đủ sản phẩm</div>
                            </div>
                        </div>
                        <div 
                            v-if="dataOrder.status_delivery.id != 1"
                            class="history-status">
                            <div 
                                @click="handleHistoryTicket"
                                class="p4 mb05 blue cursor"> Lịch sử phiếu xuất/nhập hàng đã tạo </div>
                            <!-- <div class="p4 blue cursor"> Lịch sử kiện hàng đã tạo </div> -->
                        </div>
                    </div>
                </div>

                <!-- left__id -->
                <div class="body-left__id mb1 pt1 pb1 pl1 pr1 border-radius bg-white-1">
                    <div class="mb1 h5 gray">Mã đơn hàng</div>
                    <div class="f jcb aic mb1 pt1 pb1 pl1 pr1 border-radius border">
                        <div class="code-number h5 blue">#{{ dataOrder.order_key }} </div>
                        <div 
                            class="icon icon--copy"
                            @click="handleCopyCode"></div>
                    </div>
                </div>

                <!-- left__info -->
                <div 
                    v-if="dataOrder.customer"
                    class="body-left__info pt1 pb1 pl1 pr1 mb1 border-radius bg-white-1">
                    <div class="h5 gray mb1">Thông tin người mua</div>
                    <div class="mb1">
                        <div class="h4 mb05"> {{ dataOrder.customer.full_name }} </div>
                        <div class="f aic mb05">
                            <div class="icon icon--phone mr05"></div>
                            <div class="p2 ml05"> {{ dataOrder.customer.phone }} </div>
                        </div>
                        <div 
                            v-if="dataOrder.customer.email == ''"
                            class="f aic">
                            <div class="icon icon--email mr05"></div>
                            <div class="p2 ml05"> Chưa có thông tin </div>
                        </div>
                        <div 
                            v-else
                            class="f aic">
                            <div class="icon icon--email mr05"></div>
                            <div class="p2 ml05"> {{ dataOrder.customer.email }} </div>
                        </div>
                    </div>
                    <div 
                        @click="handleInfoCustomer"
                        class="p4 blue cursor">Chi tiết thông tin</div>
                </div>
            </div>

            <!-- body-center -->
            <div class="body-center pt1 pb1 pl1 pr1 mb1 border-radius bg-white-1">
                <div class="body-center__header f jcb aic mb15">
                    <div class="h5">Thông tin thanh toán</div>
                    <div class="h5 text-red-linear">Thanh toán COD</div>
                </div>
                <div class="body-center__content">
                    <div class="desktop w100">
                        <div class="body-center__content-key f jcb aic w100 p2 gray">
                            <div class="large-info">Sản phẩm</div>
                            <div class="small-info">Số lượng</div>
                            <div class="small-info">Đơn giá</div>
                            <div class="small-info">Thành tiền</div>
                        </div>
                    </div>
                    <div class="body-center__content-item mb1">
                        <ListProductView :dataSubOrder="dataOrder.id"></ListProductView>
                    </div>
                    <div class="body-center__content-total gray">
                        <div 
                            v-if="dataOrder.total_price"
                            class="f jcb aic mb05">
                            <div class="p3">Tổng tiền hàng</div>
                            <div class="p4"> {{ formatMoney(dataOrder.total_price) }} </div>
                        </div>
                        <div class="f jcb aic mb05">
                            <div class="p3">Phí vận chuyển</div>
                            <div class="p4">35.000VND</div>
                        </div>
                        <div class="f jcb aic">
                            <div class="p3">Tổng đơn hàng</div>
                            <div class="p4 text-red-linear">{{totalOrderPrice()}}</div>
                        </div>
                    </div>
                </div>
                <div class="body-center__total">

                </div>
            </div>

            <!-- body-right -->
            <div class="body-right pt1 pb1 pl1 pr1 border-radius bg-white-1">
                <div class="h5 mb1">Thông tin vận chuyển</div>
                <div class="">
                    <div class="f jcb aic mb05">
                        <div class="p3 gray">Đơn vị vận chuyển</div>
                        <div class="h6">BZI Express VN</div>
                    </div>
                    <div class="f jcb aic">
                        <div class="p3 gray">Mã vận chuyển</div>
                        <div class="h6">#SKU123456789</div>
                    </div>
                    <LogOrder
                        :data='data'></LogOrder>
                </div>
            </div>
        </div>

        <HistoryTicketOrder 
            :orderId='dataOrder.id'
            @closePopup='closePopup'
            v-if="statusHistory"/>

        <InfoCustomerView :dataCustomer="dataOrder.customer"></InfoCustomerView>
        <PopupNotiView 
            :data='data'
            :dataOrder='dataOrder'
            :changeStatus='changeStatus'
            :shipper='dataOrder.shipper'
            :dataSubOrder='dataOrder.sub_order'
            @getData='getData'></PopupNotiView>
    </div>
</template>

<script>
import LogOrder from '@/components/OrderDetailView/LogOrderView/LogOrder.vue'
import ListProductView from '@/components/OrderDetailView/ListProductView/ListProductView.vue'
import PopupNotiView from '@/components/OrderDetailView/PopupNotiView/PopupNotiView.vue'
import InfoCustomerView from '@/components/OrderDetailView/InfoCustomerView/InfoCustomerView.vue'
import HistoryTicketOrder from '@/components/OrderDetailView/HistoryTicketOrder/HistoryTicketOrder.vue'

import { HTTP } from '../../http-default'

import firebase from "@/firebaseInit";
import "firebase/firestore";
export default {
    components : {
        ListProductView,
        PopupNotiView,
        InfoCustomerView,
        LogOrder,
        HistoryTicketOrder
    },

    data () {
        return {
            dataOrder : {},
            deliverystatus: [],
            changeStatus: 1,
            data : [],
            statusHistory : false,
        }
    },

    methods: {
        // formatMoney
        formatMoney(value) {
            value = value.toLocaleString('it-IT', {style : 'currency', currency : 'VND'})
            return value
        },

        totalOrderPrice() {
            var total = this.dataOrder.total_price + 35000
            return this.formatMoney(total)
        },

        handleCopyCode() {
            var copyText = document.getElementsByClassName('code-number')[0].innerText
            var TempText = document.createElement("input");
            TempText.value = copyText;
            document.body.appendChild(TempText);
            TempText.select();
            document.execCommand("copy"); 
            document.body.removeChild(TempText);
            alert("Copied the text: " + TempText.value);
        },
        handleStatus () {
            if (this.dataOrder.status_delivery.id !== 3) {
                var select = document.getElementsByClassName('select-status__list')[0]
                select.classList.toggle('active')
            }
        },

        closePopup () {
            this.statusHistory = false
        },

        handleHistoryTicket () {
            this.statusHistory = true
        },

        handleInfoCustomer () {
            var popup = document.getElementsByClassName('popup-info')[0]
            popup.classList.toggle('active-popup')
        },
        handleDeliveryStatus (value) {
            this.changeStatus = value
            var popup = document.getElementsByClassName('popup-noti')[0]
            popup.classList.toggle('active-popup')
            var select = document.getElementsByClassName('select-status__list')[0]
            select.classList.toggle('active')
        },
        async getData () {
            HTTP.get('v1/api/order/' + this.$route.params.id + '/')
            .then((res) => {
                this.dataOrder = res.data
                this.dataOrder.customer.note = this.dataOrder.note
                // eslint-disable-next-line @typescript-eslint/no-this-alias
                let path = this

                var ref = firebase.database().ref("/status_order_warehouse/" + parseInt(this.dataOrder.order_key))
                path.data = []
                ref.on("child_added", function(childSnapshot) {
                    path.data.push(childSnapshot.val())
                    path.data.reverse() 
                })

            })

            HTTP.get('v1/api/deliverystatus/')
            .then((res) => {
                this.deliverystatus = res.data.results
            })
        }
    },

    created () {
        this.getData()
    }
}
</script>

<style scoped lang="scss">
    @import 'OrderDetail.scss';
</style>