<template>
  <div id="app" class="relative h100vh">
    <NotiOrderView></NotiOrderView>
    <router-view />
  </div>
</template>

<script>
import NotiOrderView from '@/components/NotiOrderView/NotiOrderView.vue'
export default {
  components : {
    NotiOrderView
  }
}
</script>

<style lang="scss">
@import '../global/index';

#app {
  background-image: url("/src/assets/ImageimgBackground.jpg");
  background-position: center;
  background-size: cover;
  overflow-x: hidden;
}

.popup {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(5px);
}

@media (max-width: $xxs) {
  .popup {
    padding: 0 1em !important;
  }
}

::-webkit-scrollbar {
    background-color: #fff;
    width: 16px;
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
    background-color: #fff;
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #fff;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
    display:none;
}
@import "/global/index";
</style>
