@<template>
    <div class="nav__containter mobile w100">
        <div class="sidebar f aic w100 pt05 pb05 pl1 pr1 bg-white-1">
            <div 
                v-for="(item, index) in dataSideBar"
                :key="index">
                <router-link 
                    v-if="item.name === 'stock'"
                    :to="{ name: 'menu-stock-view', params: { sidebaritem : 'quan-ly-stock' , menuitem : 'quan-ly-stock' } }">
                    <div v-if="item.name != 'account' && item.name != 'organization' && item.name != 'package' && item.name != 'consignment'" >
                        <div
                            class="sidebar__item f fdc aic jcc bg-select"
                            v-if="item.path == '/' + $route.params.sidebaritem">
                            <div class="icon" :class="item.icon + '-1'"></div>
                            <div class="sidebar__item-title p5 gray text-select upcase"> {{ item.props.titlecontent }} </div>
                        </div>
                        <div
                            class="sidebar__item f fdc aic jcc"
                            v-else>
                            <div class="icon" :class="item.icon + '-' + 0"></div>
                            <div class="sidebar__item-title p5 gray upcase"> {{ item.props.titlecontent }} </div>
                        </div>
                    </div>
                </router-link>
                <router-link 
                    v-else
                    :to="item.path">
                    <div v-if="item.name != 'account' && item.name != 'organization' && item.name != 'package' && item.name != 'consignment'">
                        <div
                            class="sidebar__item f fdc aic jcc bg-select"
                            v-if="item.path == '/' + $route.params.sidebaritem">
                            <div class="icon" :class="item.icon + '-1'"></div>
                            <div class="sidebar__item-title p5 gray text-select upcase"> {{ item.props.titlecontent }} </div>
                        </div>
                        <div
                            class="sidebar__item f fdc aic jcc"
                            v-else>
                            <div class="icon" :class="item.icon + '-' + 0"></div>
                            <div class="sidebar__item-title p5 gray upcase"> {{ item.props.titlecontent }} </div>
                        </div>
                    </div>
                </router-link>
            </div>
            <div v-if="isMobile()">
                <router-link to="/connect-device">
                    <div>
                        <div
                            v-if="$route.params.sidebaritem === 'connect-device'"
                            class="sidebar__item f fdc aic jcc bg-select">
                            <div class="icon icon--bell" ></div>
                            <div class="sidebar__item-title p5 text-red-linear upcase"> Kết nối </div>
                        </div>
                        <div
                            v-else
                            class="sidebar__item f fdc aic jcc bg-select">
                            <div class="icon icon--bell" ></div>
                            <div class="sidebar__item-title p5 gray upcase"> Kết nối </div>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props : {
        dataSideBar : {
            type : Array,
            default() {
                return []
            }
        }
    },

    data() {
        return {
            fullWidth : 0
        }
    },

    methods : {
        isMobile () {
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        }
    },

    // mounted () {
    //     var item = document.getElementsByClassName('sidebar__item')
    //     item.forEach(element => {
    //         element.style.width = this.fullWidth/4
    //     });
    // },

    created () {
        this.fullWidth = window.screen.width;
        
        // item.forEach(element => {
        //     element.style.width = this.fullWidth/4
        // });
    }
}
</script>

<style scoped lang="scss">
    @import 'Nav.scss';
</style>