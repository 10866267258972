<template>
  <div>
      <div class="auth f aic jcc w100 h100vh">
        <div class="login relative f fdc jcb pt2 pb1 pl1 pr1 border-radius bg-white-1">
            <div>
                <div class="logo logo-bzi-small">
                </div>
                <div class="title h3 w100 mb1">Đăng nhập</div>
                <form v-on:submit.prevent="login">
                    <div class="f fdc mb1">
                        <label for="">Số điện thoại</label>
                        <input 
                            value=""
                            v-model="username"  
                            type="text" 
                            placeholder="Nhập số điện thoại vào đây">
                    </div>
                    <div class="relative f fdc mb1">
                        <div @click="handleViewPassWord" class="icon__input absolute">
                            <div class="absolute icon icon--blind"></div>
                        </div>
                        <div @click="handleViewPassWord" class="icon__input absolute hide">
                            <div class="absolute icon icon--eye"></div>
                        </div>
                        <label for="">Mật khẩu</label>
                        <input 
                            value=""
                            v-model="password"  
                            :type="type" 
                            placeholder="Nhập mật khẩu vào đây">
                    </div>
                    <div class="f jcb aic">
                        <div class="f aic">
                            <input type="checkbox" name="" id="">
                            <p class="ml05 h7 gray">Nhớ tài khoản</p>
                        </div>
                        <div class="cursor" @click="handleForgot">
                            <p class="h6 gray">Quên mật khẩu</p>
                        </div>
                    </div>
                    <button class="w100 h5 mt2 pt075 pb075 align-c border-radius bg-red-1 white">Đăng nhập</button>
                </form>
            </div>
            <div class="w100 align-c mt15">
                <span class="gray p3">Chưa có tài khoản? </span>
                <span @click="handleRegister" class="cursor blue h6">Đăng ký ngay</span>
            </div>
        </div>

        <RegisterView></RegisterView>

        <div class="forgot-password relative f fdc pb1 border-radius bg-white-1">
                <div class="align-c pl1 pr1 pt075 pb075 border-bottom">
                    <div @click="handleBack" class="f aic align-l cursor">
                        <div class="icon-back icon--arrow-1"></div>
                        <div class="h5">Quay lại</div>
                    </div>
                </div>
                <div class="pl1 pr1 pt2">
                    <div class="step relative f jcb aic mb2">
                        <div class="step-line"></div>
                        <div class="f fdc aic" style="height: 100px">
                            <div class="step-circle step-control f jcc aic p3 gray mb05 border bg-white-1">1</div>
                            <div class="step-title step-style p3 gray">Xác thực <br> thông tin</div>
                        </div>
                        <div class="f fdc aic" style="height: 100px">
                            <div class="step-circle f jcc aic p3 gray mb05 border bg-white-1">2</div>
                            <div class="step-title p3 gray">Tạo mật khẩu <br> mới</div>
                        </div>
                        <div class="f fdc aic" style="height: 100px">
                            <div class="step-circle f jcc aic p3 gray mb05 border bg-white-1">3</div>
                            <div class="step-title p3 gray">Hoàn thành</div>
                        </div>
                    </div>
                    <form class="auth-info" action="">
                        <div class="f fdc mb1">
                            <label class="h7 mb05" for="">Số điện thoại</label>
                            <input class="h6" type="text" placeholder="Nhập số điện thoại của bạn">
                        </div>
                        <div class="f fdc mb2">
                            <label class="h7 mb05" for="">Mã OTP</label>
                            <div class="code-confirm w100 f jcb aic mb05">
                                <input type="number" id="input-1" maxlength="1" 
                                    v-model="input1"
                                    v-on:input="handlePaste('input-1')"
                                    v-on:keyup="handleInputFocus('input-1','input-2')">
                                <input type="number" id="input-2" maxlength="1" 
                                    v-model="input2"
                                    v-on:keyup="handleInputFocus('input-2','input-3')">
                                <input type="number" id="input-3" maxlength="1" 
                                    v-model="input3"
                                    v-on:keyup="handleInputFocus('input-3','input-4')">
                                <input type="number" id="input-4" maxlength="1" 
                                    v-model="input4"
                                    v-on:keyup="handleInputFocus('input-4','input-5')">
                                <input type="number" id="input-5" maxlength="1" 
                                    v-model="input5"
                                    v-on:keyup="handleInputFocus('input-5','input-6')">
                                <input type="number" id="input-6" maxlength="1"
                                    v-model="input6">
                            </div>
                        </div>
                        <button class="w100 h5 pt075 pb075 align-c border-radius bg-red-1 white" @click="handleToNewPassword">Xác nhận mã OTP</button>
                    </form>
                    <form class="new-password hide" action="">
                        <div class="f fdc mb1">
                            <label class="h7 mb05" for="">Mật khẩu mới</label>
                            <input class="h6" type="text" placeholder="Nhập mật khẩu mới của bạn">
                        </div>
                        <div class="f fdc mb2">
                            <label class="h7 mb05" for="">Xác nhận mật khẩu mới của bản</label>
                            <input class="h6" type="password" placeholder="Nhập mật khẩu vào đây">
                        </div>
                        <button class="w100 h5 pt075 pb075 align-c border-radius bg-red-1 white" @click="handleToComplate">Tạo mật khẩu mới</button>
                    </form>
                    <div class="complate hide">
                        <div class="f fdc aic">
                            <img class="mb1" src="" alt="">
                            <h2 class="mb2 align-c gray">Quý khách đã tạo mật khẩu<br>mới thành công! </h2>
                            <button class="w100 h5 pt075 pb075 align-c border-radius bg-red-1 white">Xác nhận mã OTP</button>
                        </div> 
                    </div>
                </div>
        </div>

        <div class="hide">
            <svg width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path id="close" d="M5.78516 4.75L8.73828 1.82422C8.90234 1.66016 8.90234 1.35938 8.73828 1.19531L8.05469 0.511719C7.89062 0.347656 7.58984 0.347656 7.42578 0.511719L4.5 3.46484L1.54688 0.511719C1.38281 0.347656 1.08203 0.347656 0.917969 0.511719L0.234375 1.19531C0.0703125 1.35938 0.0703125 1.66016 0.234375 1.82422L3.1875 4.75L0.234375 7.70312C0.0703125 7.86719 0.0703125 8.16797 0.234375 8.33203L0.917969 9.01562C1.08203 9.17969 1.38281 9.17969 1.54688 9.01562L4.5 6.0625L7.42578 9.01562C7.58984 9.17969 7.89062 9.17969 8.05469 9.01562L8.73828 8.33203C8.90234 8.16797 8.90234 7.86719 8.73828 7.70312L5.78516 4.75Z" fill="#757575"/>
            </svg>
            <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path id="back" d="M1.13672 4.53125L5.15625 0.484375C5.32031 0.320312 5.47526 0.320312 5.62109 0.484375L6.16797 1.00391C6.3138 1.16797 6.3138 1.32292 6.16797 1.46875L2.91406 4.75L6.16797 8.03125C6.3138 8.17708 6.3138 8.33203 6.16797 8.49609L5.62109 9.01562C5.47526 9.17969 5.32031 9.17969 5.15625 9.01562L1.13672 4.96875C0.990885 4.82292 0.990885 4.67708 1.13672 4.53125Z" fill="#2577C9"/>
            </svg>

        </div>
    </div>
  </div>
</template>

<script>
import RegisterView from '@/views/LoginView/RegisterView/RegisterView.vue'
import { HTTP } from '@/http-default'
import firebase from "@/firebaseInit";
import axios from "axios"
export default {
    components: {
        RegisterView
    },
    data() {
        return {
            username : "",
            password : "",
            type: 'password',
            input1 : '',
            input2 : '',
            input3 : '',
            input4 : '',
            input5 : '',
            input6 : ''
        }
    },
    watch: {
        'password' : function() {
            var iconBlind = document.getElementsByClassName('icon__input')[0]
            if(this.password.length == 0) {
                iconBlind.classList.add('hide')
            } else {
                iconBlind.classList.remove('hide')
            }
        }
    },
    methods: {
        handleClose() {
            document.querySelector('.auth').classList.remove('active');
            this.handleLogin();
            var forgot = document.querySelector('.forgot-password');
            forgot.classList.remove('active')
            this.handleBack();
        },
        handleRegister(event) {
            event.preventDefault()
            var login = document.querySelector('.login');
            login.classList.add('visible');
            var register = document.querySelector('.register');
            register.classList.add('active')
        },
        handleLogin() {
            var login = document.querySelector('.login');
            login.classList.remove('visible');
            var register = document.querySelector('.register');
            register.classList.remove('active');
        },
        handleForgot() {
            var login = document.querySelector('.login');
            login.classList.add('visible');
            var register = document.querySelector('.forgot-password');
            register.classList.add('active')
        },
        handleBack() {
            document.querySelector('.auth').classList.remove('active');
            this.handleLogin();
            var forgot = document.querySelector('.forgot-password');
            forgot.classList.remove('active')
            var login = document.querySelector('.login');
            login.classList.remove('visible');
            var register = document.querySelector('.forgot-password');
            register.classList.remove('active');
            var hide = document.querySelector('.new-password');
            hide.classList.add('hide')
            var hide1 = document.querySelector('.complate');
            hide1.classList.add('hide');
            var visible = document.querySelector('.auth-info');
            visible.classList.remove('hide');
            var circle1 = document.querySelectorAll('.step-circle')[1];
            circle1.classList.remove('step-control')
            var title1 = document.querySelectorAll('.step-title')[1];
            title1.classList.remove('step-style')
            var circle2 = document.querySelectorAll('.step-circle')[2];
            circle2.classList.remove('step-control')
            var title2 = document.querySelectorAll('.step-title')[2];
            title2.classList.remove('step-style')
            var circle = document.querySelectorAll('.step-circle')[0];
            circle.classList.add('step-control')
            var title = document.querySelectorAll('.step-title')[0];
            title.classList.add('step-style')
        },
        handleToNewPassword(evt) {
            evt.preventDefault()
            var hide = document.querySelector('.auth-info');
            hide.classList.add('hide')
            var visible = document.querySelector('.new-password');
            visible.classList.remove('hide')
            var circle1 = document.querySelectorAll('.step-circle')[0];
            circle1.classList.remove('step-control')
            var title1 = document.querySelectorAll('.step-title')[0];
            title1.classList.remove('step-style')
            var circle = document.querySelectorAll('.step-circle')[1];
            circle.classList.add('step-control')
            var title = document.querySelectorAll('.step-title')[1];
            title.classList.add('step-style')
        },
        handleToComplate(evt) {
            evt.preventDefault()
            var hide = document.querySelector('.new-password');
            hide.classList.add('hide')
            var visible = document.querySelector('.complate');
            visible.classList.remove('hide');
            var circle1 = document.querySelectorAll('.step-circle')[1];
            circle1.classList.remove('step-control')
            var title1 = document.querySelectorAll('.step-title')[1];
            title1.classList.remove('step-style')
            var circle = document.querySelectorAll('.step-circle')[2];
            circle.classList.add('step-control')
            var title = document.querySelectorAll('.step-title')[2];
            title.classList.add('step-style')
        },
        handleViewPassWord() {
            var iconEye = document.getElementsByClassName('icon__input')[1]
            iconEye.classList.toggle('active')
            var iconBlind = document.getElementsByClassName('icon__input')[0]
            iconBlind.classList.toggle('visible')
            var view = document.getElementsByClassName('visible');
            if(view.length > 0) {
                this.type = 'text'
                this.$emit('typePassUpdate', this.type)
            } else {
                this.type = 'password'
                this.$emit('typePassUpdate', this.type)
            }
        },
        async login() {
            var dataUser = JSON.stringify({
                "username": this.username,
                "password": this.password,
            });

            const axiosConfig = {
                headers : {'Content-Type': 'application/json'} 
            }

            const email = '0912002523a@gmail.com';
            const password = '1234567';

            firebase.auth().signInWithEmailAndPassword(email, password)

            axios.post('https://api.nmeco.xyz/login/', dataUser, axiosConfig)
            .then( (response) => {
                if (response.data.token) {
                    var user = response.data['token']
                    var sessionId = response.data['sessionId']
                    localStorage.setItem('user', user)
                    this.$cookies.set("user",user);
                    this.$cookies.set("sessionId",sessionId);
                    this.username = ""
                    this.password = ""

                    var config = {
                        headers : {
                            'Content-Type': 'application/json',
                            'Authorization': 'Token '+ user
                        } 
                    }

                    axios.get('https://api.nmeco.xyz/v1/api/systemsetting/', config)
                    .then((res) => {
                        var setting = res.data.results[0].router.data.toLowerCase()
                        var results = setting.replaceAll("'", '"')
                        localStorage.setItem("systemSetting",results);
                        this.$router.go({ path: "/" })
                    })
                    
                }

            })
        },

        handleInputFocus (first,last) {
            document.getElementById(last).focus()
        },

        handlePaste (value) {
            var paste = document.getElementById(value).value
            for (let i = 0; i < paste.length; i++) {
                this.input1 = paste[0]
                this.input2 = paste[1]
                this.input3 = paste[2]
                this.input4 = paste[3]
                this.input5 = paste[4]
                this.input6 = paste[5]
            }
        },
    },
}
</script>

<style scoped lang="scss">
@import 'Login.scss';
</style>