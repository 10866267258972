@<template>
    <div class="product-scan">
        <div 
            v-for="(item, index) in productImport"
            :key="index">
            <div class="product-item__container pt1 pb1 pl1 pr1 mb1 border-radius bg-white-1">
                <div class="f jcb aic mb1">
                    <div class="large-info f aic">
                        <div class="mr05">
                            <img :src="item.image_product" alt="">
                        </div>
                        <div class="f fdc jcb">
                            <div class="mb025 h6"> {{ item.title_product }} </div>
                            <div class="p4 gray"> Hàng có sẵn :{{item.quantity_in_stock}} </div>
                        </div>
                    </div>
                    <div    
                        @click="handleDeleteProduct(item.id_firebase.idScan)"
                        class="icon icon--bin"></div>
                </div>
                <div class="product-item__info mb1">
                    <div class="f jcb aic mb025">
                        <div class="p4 gray">Mã sản phẩm</div>
                        <div class="h6 blue"> #{{item.id}} </div>
                    </div>
                    <div class="f jcb aic mb025">
                        <div class="p4 gray">Nhà cung cấp</div>
                        <div class="p4"> The A Corp </div>
                    </div>  
                </div>
                <div class="product-item__create-consignment mb1 pt1 pb1 border-bottom border-top">
                    <div class="h6 mb05 gray">Thiết lập thông tin nhập hàng</div>
                    <div
                        v-if="listConsignment.length == 0" 
                        class="p6 mb05 gray-5">Chưa có lô hàng nào được thiết lập</div>
                    <div class="product-item__list-consignment mt05 mb05">
                        <div 
                            v-for="(value, index) in listConsignment"
                            :key="index">
                            <div v-if="value.product == item.id">
                                <div class="pt1 pb1 pl1 pr1 mb075 bg-gray-1 border-radius">
                                    <div class="f jcb aic mb05">
                                        <div>
                                            <div class="h6"> {{ value.title_consignment }} </div>
                                            <div class="p4 blue"> {{ value.consignment_key }} </div>
                                        </div>
                                        <div>
                                            <div
                                                @click="handleEditConsignment(value.consignment_key)"
                                                class="icon icon--edit"></div>
                                            <div 
                                                @click="handleDeleteConsignment(value.consignment_key)"
                                                class="ml025 icon icon--bin"></div>
                                        </div>
                                    </div>
                                    <div class="mt1 pt1 border-top">
                                        <div class="f jcb aic mb05">
                                            <div class="p3">Số lượng sản phẩm</div>
                                            <div class="p4"> {{value.quantity_in_consignment}} đơn vị</div>
                                        </div>
                                        <div class="f jcb aic">
                                            <div class="p3"> Tổng giá trị </div>
                                            <div class="p4 text-red-linear"> {{ formatMoney(value.total_price) }} </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="f jcc aic pt05 pb05 border-blue border-radius cursor">
                        <div class="icon icon--add-circle"></div>
                        <div 
                            @click="handleConsignment(item.id)"
                            class="p4 ml025 blue">Thêm lô hàng</div>
                    </div>
                </div>
                <div class="product-item__total">
                    <div class="f jcb aic mb025">
                        <div class="p3 gray">Tổng số lượng nhập</div>
                        <div 
                            v-if="item.total_quantity_consignment"
                            class="p4">{{ watchTotalQuantity(item.id) }} đơn vị </div>
                        <div 
                            v-else
                            class="p4">0 đơn vị</div>
                    </div>
                    <div class="f jcb aic">
                        <div class="p3 gray">Tổng giá trị nhập</div>
                        <div
                            v-if="item.total_price_consignment"
                            class="p4 text-red-linear"> {{ watchTotalPrice(item.id) }}</div>
                        <div 
                            v-else
                            class="p4 text-red-linear">Chưa có dữ liệu</div>
                    </div>
                </div>
            </div>
        </div>

        <EditConsignmentView
            :idConsignmentEdit='idConsignmentEdit'
            :listConsignment='listConsignment'
            @updateEditConsignment='updateEditConsignment'></EditConsignmentView>

        <DeleteConsignmentView
            :idConsignmentDelete='idConsignmentDelete'
            :listConsignment='listConsignment'></DeleteConsignmentView>
    </div>
</template>

<script>
import DeleteConsignmentView from '@/components/ImportTicketView/CreatePackageView/ProductScanView/DeleteConsignmentView/DeleteConsignmentView.vue'
import EditConsignmentView from '@/components/ImportTicketView/CreatePackageView/ProductScanView/EditConsignmentView/EditConsignmentView.vue'
import { HTTP } from '@/http-default'

// firebase
import firebase from "@/firebaseInit";
import "firebase/firestore";
const db = firebase.firestore();

export default {
    components : {
        DeleteConsignmentView,
        EditConsignmentView
    },
    
    props : {
        dataSubOrder : {
            type : Array,
            default () {
                return []
            }
        },
        listConsignment : {
            type : Array,
            default () {
                return []
            }
        },
        account : {
            type : Object,
            default () {
                return {}
            }
        }
    },

    data () {
        return {
            idConsignmentDelete : '',
            idConsignmentEdit: '',
            listProduct : [],
            productImport : [],
            subOrder : [],
            subOrderSelect : [],
            categoryproduct : [],
            productScan : [],
        }
    },

    watch : {
        'productScan' : function () {
            this.productImport = []

            for (let k = 0; k < this.listProduct.length; k++) {
                for (let m = 0; m < this.productScan.length; m++) {
                    if (this.productScan[m].value == this.listProduct[k].key_product) {
                        this.listProduct[k].id_firebase = this.productScan[m]
                        this.listProduct[k].price_product = this.listProduct[k].price_product.toLocaleString('it-IT', {style : 'currency', currency : 'VND'});
                        this.productImport.push(this.listProduct[k])
                    }
                }
            }

            this.$emit('updateProductScan', this.productImport)
        },

        'account' : function () {
            this.readProductScan ()
        },

        'listConsignment' : function () {
            this.updateEditConsignment()
        },
        watchTotalQuantity : function () {
            this.watchTotalQuantity()
        }
    },

    methods : {

        // theo doi so luong
        watchTotalQuantity : function (productId) {
            var totalQuantity = 0
            this.listConsignment.forEach(item => {
                if (item.product === productId) {
                    totalQuantity = totalQuantity + parseInt(item.quantity_in_consignment)
                }
            })
        
            return totalQuantity
        },

        // theo doi gia nhap
        watchTotalPrice : function (productId) {
           var totalPrice = 0
            this.listConsignment.forEach(item => {
                if (item.product === productId) {
                    totalPrice = totalPrice + parseInt(item.total_price)
                }
            })
            return this.formatMoney(totalPrice)
        },

        updateEditConsignment () {
            this.productImport.forEach(item => {
                item.total_quantity_consignment = 0
                item.total_price_consignment = 0
                this.listConsignment.forEach(value => {
                    if (value.product == item.id) {
                        // item.total_price_consignment = parseInt(item.total_price_consignment.replace(/\./g,''))
                        item.total_quantity_consignment = item.total_quantity_consignment + parseInt(value.quantity_in_consignment)
                        item.total_price_consignment = item.total_price_consignment + parseInt(value.total_price)
                        // item.total_price_consignment = item.total_price_consignment.toLocaleString('it-IT', {style : 'currency', currency : 'VND'});
                        console.log(item.total_quantity_consignment, "so luong");
                        console.log(item.total_price_consignment, "tong tien");
                        this.$emit('updateProductScan', this.productImport)
                    }
                })
            })
        },

        formatMoney(value) {
            value = value.toLocaleString('it-IT', {style : 'currency', currency : 'VND'})
            return value
        },

        async readProductScan() {
            if (this.account.key_doc_firebase) {
                var a = []
                let b = this.account.key_doc_firebase
                db.collection("product-item")
                .where('id', '==', b) 
                .onSnapshot(function(querySnapshot) {
    
                    querySnapshot.docChanges().forEach(function(change) {
                        const data = change.doc.data()
                        data.idScan = change.doc.id
                        a.push(data);
                    });
                });
    
                this.productScan = a
                // this.$emit('updateProductScan', this.productScan)
            }
        },

        handleDeleteProduct (id) {
            db.collection("product-item").doc(id).delete()
            .then(() => {
                this.readProductScan()
            })
        },

        handleDeleteConsignment (value) {
            this.idConsignmentDelete = value
            var popup = document.getElementsByClassName('popup-confirm-delete')[0]
            popup.classList.toggle('f')
        },

        handleEditConsignment(value) {
            this.idConsignmentEdit = value
            var popup = document.getElementsByClassName('popup__edit-consignment')[0]
            popup.classList.toggle('f')
        },

        handleConsignment (value) {
            this.$emit('updateIdProduct', value)
            
            var consignment = document.getElementsByClassName('create-consignment__container')[0]
            consignment.classList.toggle('active')

            var createPackage = document.getElementsByClassName('create-package__container')[0]
            createPackage.classList.toggle('hide')
        },

        async getData () {

            HTTP.get('/v1/api/suborder/')
            .then((res) => {
                this.subOrder = res.data.results
                for (let i = 0; i < this.subOrder.length; i++) {
                    for (let n = 0; n < this.productScan.length; n++) {
                        if (this.productScan[n].value == this.subOrder[i].id) {
                            // this.listProduct[i].price_product = this.listProduct[i].price_product.toLocaleString('it-IT', {style : 'currency', currency : 'VND'});
                            this.subOrderSelect.push(this.subOrder[i])
                        }
                    }
                }
            })
            
            HTTP.get('/v1/api/product/')
            .then((res) => {
                this.listProduct = res.data
                // for (let k = 0; k < this.listProduct.length; k++) {
                //     for (let m = 0; m < this.subOrderSelect.length; m++) {
                //         if (this.productScan[m].value == this.listProduct[k].id) {
                //             this.listProduct[k].price_product = this.listProduct[k].price_product.toLocaleString('it-IT', {style : 'currency', currency : 'VND'});
                //             this.productImport.push(this.listProduct[k])
                //         }
                //     }
                // }
            })

            HTTP.get('v1/api/categoryproduct/')
            .then((res) => {
                this.categoryproduct = res.data.results
            })
        }
    },

    created () {
        this.getData()
        this.readProductScan()
    }
}
</script>

<style scoped lang='scss'>
    @import 'ProductScan.scss';
</style>