<template>
    <div class="rating-star">
        <star-rating 
            :increment="0.5"
            :star-size="40"
            :border-width="8" 
            border-color="#FFCC00" 
            active-color="#FFCC00"
            :rounded-corners="true" 
            inactive-color="#fff"
            :star-points="[108, 0.0, 141, 70, 218, 78.3, 162, 131, 175, 205, 108, 170, 41.2, 205, 55, 131, 1, 78, 75, 68]"
            :show-rating="false"
            @rating-selected="setRating"
        ></star-rating>
    </div>
</template>
 
<script>
// import StarRating from 'vue-star-rating'
import StarRating from '../../../node_modules/vue-star-rating/src/star-rating.vue'

export default {
    components: {
        StarRating,
    },
    data() {
        return {
            rating: "No Rating Selected",
            currentRating: "No Rating",
            currentSelectedRating: "No Current Rating",
            boundRating: 3,
        }
    },
    methods: {
        setRating: function(rating) {
            this.rating = "You have Selected: " + rating + " stars";
            this.$emit('updateRating', rating)
        },
        showCurrentRating: function(rating) {
            this.currentRating = (rating === 0) ? this.currentSelectedRating : "Click to select " + rating + " stars"
        },
        setCurrentSelectedRating: function(rating) {
            this.currentSelectedRating = "You have Selected: " + rating + " stars";
        }
    },
}
</script>

<style scoped lang="scss">
 
</style>
