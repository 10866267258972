@<template>
    <div 
        v-if="$route.params.sidebaritem === 'quan-ly-order'"
        class="card-order__contaienr w100 align-c">

        <!-- demo when loading api order -->
        <div 
            v-if="cardDemo"
            class="card-demo border-radius mb1 bg-white-1">
            <div class="card-demo__header f jcb aic pt05 pb05 pl1 pr1 border-bottom">
                <div class="card-demo__header-item"></div>
                <div class="card-demo__header-item"></div>
            </div>
            <div class="card-demo__body cursor pt1 pb1 pl1 pr1">
                <div class="f jcb aic mb1 ">
                    <div class="card-demo__header-item"></div>
                    <div class="card-demo__body-item"></div>
                </div>
                <div class="f jcb aic mb1 ">
                    <div class="card-demo__body-item"></div>
                    <div class="card-demo__header-item"></div>
                </div>
                <div class="f jcb aic mb1 ">
                    <div class="card-demo__header-item"></div>
                    <div class="card-demo__body-item"></div>
                </div>
            </div>
            <div class="card-demo__total f jcb aic pt1 pb1 pr1 pl1 bg-gray-2">
                <div class="card-demo__total-item"></div>
                <div class="card-demo__total-item"></div>
            </div>
        </div>
        <div 
            v-if="cardDemo"
            class="card-demo border-radius mb1 bg-white-1">
            <div class="card-demo__header f jcb aic pt05 pb05 pl1 pr1 border-bottom">
                <div class="card-demo__header-item"></div>
                <div class="card-demo__header-item"></div>
            </div>
            <div class="card-demo__body cursor pt1 pb1 pl1 pr1">
                <div class="f jcb aic mb1 ">
                    <div class="card-demo__header-item"></div>
                    <div class="card-demo__body-item"></div>
                </div>
                <div class="f jcb aic mb1 ">
                    <div class="card-demo__body-item"></div>
                    <div class="card-demo__header-item"></div>
                </div>
                <div class="f jcb aic mb1 ">
                    <div class="card-demo__header-item"></div>
                    <div class="card-demo__body-item"></div>
                </div>
            </div>
            <div class="card-demo__total f jcb aic pt1 pb1 pr1 pl1 bg-gray-2">
                <div class="card-demo__total-item"></div>
                <div class="card-demo__total-item"></div>
            </div>
        </div>
        <div 
            v-if="cardDemo"
            class="card-demo border-radius mb1 bg-white-1">
            <div class="card-demo__header f jcb aic pt05 pb05 pl1 pr1 border-bottom">
                <div class="card-demo__header-item"></div>
                <div class="card-demo__header-item"></div>
            </div>
            <div class="card-demo__body cursor pt1 pb1 pl1 pr1">
                <div class="f jcb aic mb1 ">
                    <div class="card-demo__header-item"></div>
                    <div class="card-demo__body-item"></div>
                </div>
                <div class="f jcb aic mb1 ">
                    <div class="card-demo__body-item"></div>
                    <div class="card-demo__header-item"></div>
                </div>
                <div class="f jcb aic mb1 ">
                    <div class="card-demo__header-item"></div>
                    <div class="card-demo__body-item"></div>
                </div>
            </div>
            <div class="card-demo__total f jcb aic pt1 pb1 pr1 pl1 bg-gray-2">
                <div class="card-demo__total-item"></div>
                <div class="card-demo__total-item"></div>
            </div>
        </div>

        <!-- data -->
        <div v-if="valueFilter == 'Tất cả' && dataItemFilter.length === 0 && filterKey === ''">
            <div 
                class="card-item border-radius mb1 bg-white-1" 
                v-for="(item, index) in dataItem" :key="index">
                <div
                    v-for="(value, key) in propStatusDelivery"
                    :key="key">
                    <div v-if="item.status_delivery.id == value.id && value.checker == true">
                        <router-link :to="{ name : 'detail-view', params : { id : item.id} }">
                            <div class="card-item__header f jcb aic pt05 pb05 pl1 pr1 border-bottom">
                                <h5 class="blue"> #{{ item.order_key }}</h5>
                                <div 
                                    v-if="item.status_order.title_status_order == 'Đang xử lý'"
                                    class="card-item__status h6 pt025 pb025 pl05 pr05 border-radius orange bg-yellow">
                                        {{ item.status_order.title_status_order }}
                                </div>
                                <div 
                                    v-if="item.status_order.title_status_order == 'Thành công'"
                                    class="card-item__status h6 pt025 pb025 pl05 pr05 border-radius green bg-green">
                                        {{ item.status_order.title_status_order }}
                                </div>
                                <div 
                                    v-if="item.status_order.title_status_order == 'Đã hủy'"
                                    class="card-item__status h6 pt025 pb025 pl05 pr05 border-radius red bg-red">
                                        {{ item.status_order.title_status_order }}
                                </div>
                            </div>
                            <div
                                v-if="item.customer != null"
                                class="card-item__body cursor pt1 pb1 pl1 pr1">
                                <div class="f jcb aic mb05">
                                    <div class="gray p4">Tên người mua</div>
                                    <div class="p4">{{item.customer.full_name}}</div>
                                </div>
                                <div class="f jcb aic mb05">
                                    <div class="gray p4">Số điện thoại</div>
                                    <div class="p4">{{item.customer.phone}}</div>
                                </div>
                                <div class="f jcb aic mb1">
                                    <div class="gray p4">Địa chỉ</div>
                                    <div class="p4">{{item.customer.address}}</div>
                                </div>
                                <div class="f jcb aic pt1 border-top">
                                    <div class="desktop align-s gray p4">Trạng thái vận kho</div>
                                    <div 
                                        class="h6 status-order orange"
                                        v-if="item.status_delivery.id == 1">
                                        Cần chuẩn bị hàng
                                    </div>
                                    <div 
                                        class="h6 status-order orange"
                                        v-if="item.status_delivery.id == 2">
                                        Đang chờ vận chuyển tới lấy
                                    </div>
                                    <div 
                                        class="h6 status-order orange"
                                        v-if="item.status_delivery.id == 3">
                                        Đã giao hàng cho vận chuyển
                                    </div>
                                    <div 
                                        class="h6 status-order orange"
                                        v-if="item.status_delivery.id == 4">
                                        Đang chờ vận chuyển trả hàng
                                    </div>
                                    <div 
                                        class="h6 status-order orange"
                                        v-if="item.status_delivery.id == 5">
                                        Đã nhận lại hàng về kho
                                    </div>
                                </div>
                            </div>
                            <div class="card-item__total f jcb aic pt1 pb1 pr1 pl1 bg-gray-2">
                                <div class="f fdc">
                                    <div class="h6 mb05">Tổng sản phẩm</div>
                                    <div class="h4 text-red-linear"> {{item.total_quantity}} Sản phẩm </div>
                                </div>
                                <div class="h5 pt075 pb075 pl1 pr1 border border-radius bg-white-1">
                                    Xem chi tiết
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>

        <div v-else-if="valueFilter != 'Tất cả' && dataItemFilter.length === 0 && filterKey === ''">
            <div 
                class="card-item border-radius mb1 bg-white-1" 
                v-for="(item, index) in dataItem" :key="index">
                <div v-for="(value, key) in propStatusDelivery"
                    :key="key">
                    <div v-if="item.status_delivery.id == value.id && value.checker == true">
                        <div v-if="item.status_order.title_status_order == valueFilter">
                            <router-link :to="{ name : 'detail-view', params : { id : item.id} }">
                                <div class="card-item__header f jcb aic pt05 pb05 pl1 pr1 border-bottom">
                                    <h5 class="blue"> #{{ item.order_key }}</h5>
                                    <div 
                                        v-if="item.status_order.title_status_order == 'Đang xử lý'"
                                        class="card-item__status h6 pt025 pb025 pl05 pr05 border-radius orange bg-yellow">
                                            {{ item.status_order.title_status_order }}
                                    </div>
                                    <div 
                                        v-if="item.status_order.title_status_order == 'Thành công'"
                                        class="card-item__status h6 pt025 pb025 pl05 pr05 border-radius green bg-green">
                                            {{ item.status_order.title_status_order }}
                                    </div>
                                    <div 
                                        v-if="item.status_order.title_status_order == 'Đã hủy'"
                                        class="card-item__status h6 pt025 pb025 pl05 pr05 border-radius red bg-red">
                                            {{ item.status_order.title_status_order }}
                                    </div>
                                </div>
                                <div class="card-item__body cursor pt1 pb1 pl1 pr1">
                                    <div class="f jcb aic mb05">
                                        <div class="gray p4">Tên người mua</div>
                                        <div class="p4">{{item.customer.full_name}}</div>
                                    </div>
                                    <div class="f jcb aic mb05">
                                        <div class="gray p4">Số điện thoại</div>
                                        <div class="p4">{{item.customer.phone}}</div>
                                    </div>
                                    <div class="f jcb aic mb1">
                                        <div class="gray p4">Địa chỉ</div>
                                        <div class="p4">{{item.customer.address}}</div>
                                    </div>
                                    <div class="f jcb aic pt1 border-top">
                                        <div class="desktop gray p4">Trạng thái vận kho</div>
                                        <div 
                                            class="h6 status-order orange"
                                            v-if="item.status_delivery.id == 1">
                                            Cần chuẩn bị hàng
                                        </div>
                                        <div 
                                            class="h6 status-order orange"
                                            v-if="item.status_delivery.id == 2">
                                            Đang chờ vận chuyển tới lấy
                                        </div>
                                        <div 
                                            class="h6 status-order orange"
                                            v-if="item.status_delivery.id == 3">
                                            Đã giao hàng cho vận chuyển
                                        </div>
                                        <div 
                                            class="h6 status-order orange"
                                            v-if="item.status_delivery.id == 4">
                                            Đang chờ vận chuyển trả hàng
                                        </div>
                                        <div 
                                            class="h6 status-order orange"
                                            v-if="item.status_delivery.id == 5">
                                            Đã nhận lại hàng về kho
                                        </div>
                                    </div>
                                </div>
                                <div class="card-item__total f jcb aic pt1 pb1 pr1 pl1 bg-gray-2">
                                    <div class="f fdc">
                                        <div class="h6 mb05">Tổng sản phẩm</div>
                                        <div class="h4 text-red-linear"> {{item.total_quantity}} Sản phẩm </div>
                                    </div>
                                    <div class="h5 pt075 pb075 pl1 pr1 border border-radius bg-white-1">
                                        Xem chi tiết
                                    </div>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-else-if="valueFilter == 'Tất cả' && dataItemFilter.length !== 0 && filterKey !== ''">
            <div 
                class="card-item border-radius mb1 bg-white-1" 
                v-for="(item, index) in dataItemFilter" :key="index">
                <div
                    v-for="(value, key) in propStatusDelivery"
                    :key="key">
                    <div v-if="item.status_delivery.id == value.id && value.checker == true">
                        <router-link :to="{ name : 'detail-view', params : { id : item.id} }">
                            <div class="card-item__header f jcb aic pt05 pb05 pl1 pr1 border-bottom">
                                <h5 class="blue"> #{{ item.order_key }}</h5>
                                <div 
                                    v-if="item.status_order.title_status_order == 'Đang xử lý'"
                                    class="card-item__status h6 pt025 pb025 pl05 pr05 border-radius orange bg-yellow">
                                        {{ item.status_order.title_status_order }}
                                </div>
                                <div 
                                    v-if="item.status_order.title_status_order == 'Thành công'"
                                    class="card-item__status h6 pt025 pb025 pl05 pr05 border-radius green bg-green">
                                        {{ item.status_order.title_status_order }}
                                </div>
                                <div 
                                    v-if="item.status_order.title_status_order == 'Đã hủy'"
                                    class="card-item__status h6 pt025 pb025 pl05 pr05 border-radius red bg-red">
                                        {{ item.status_order.title_status_order }}
                                </div>
                            </div>
                            <div
                                v-if="item.customer != null"
                                class="card-item__body cursor pt1 pb1 pl1 pr1">
                                <div class="f jcb aic mb05">
                                    <div class="gray p4">Tên người mua</div>
                                    <div class="p4">{{item.customer.full_name}}</div>
                                </div>
                                <div class="f jcb aic mb05">
                                    <div class="gray p4">Số điện thoại</div>
                                    <div class="p4">{{item.customer.phone}}</div>
                                </div>
                                <div class="f jcb aic mb1">
                                    <div class="gray p4">Địa chỉ</div>
                                    <div class="p4">{{item.customer.address}}</div>
                                </div>
                                <div class="f jcb aic pt1 border-top">
                                    <div class="desktop align-s gray p4">Trạng thái vận kho</div>
                                    <div 
                                        class="h6 status-order orange"
                                        v-if="item.status_delivery.id == 1">
                                        Cần chuẩn bị hàng
                                    </div>
                                    <div 
                                        class="h6 status-order orange"
                                        v-if="item.status_delivery.id == 2">
                                        Đang chờ vận chuyển tới lấy
                                    </div>
                                    <div 
                                        class="h6 status-order orange"
                                        v-if="item.status_delivery.id == 3">
                                        Đã giao hàng cho vận chuyển
                                    </div>
                                    <div 
                                        class="h6 status-order orange"
                                        v-if="item.status_delivery.id == 4">
                                        Đang chờ vận chuyển trả hàng
                                    </div>
                                    <div 
                                        class="h6 status-order orange"
                                        v-if="item.status_delivery.id == 5">
                                        Đã nhận lại hàng về kho
                                    </div>
                                </div>
                            </div>
                            <div class="card-item__total f jcb aic pt1 pb1 pr1 pl1 bg-gray-2">
                                <div class="f fdc">
                                    <div class="h6 mb05">Tổng sản phẩm</div>
                                    <div class="h4 text-red-linear"> {{item.total_quantity}} Sản phẩm </div>
                                </div>
                                <div class="h5 pt075 pb075 pl1 pr1 border border-radius bg-white-1">
                                    Xem chi tiết
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>

        <div v-else-if="valueFilter != 'Tất cả' && dataItemFilter.length !== 0 && filterKey !== ''">
            <div 
                class="card-item border-radius mb1 bg-white-1" 
                v-for="(item, index) in dataItemFilter" :key="index">
                <div v-for="(value, key) in propStatusDelivery"
                    :key="key">
                    <div v-if="item.status_delivery.id == value.id && value.checker == true">
                        <div v-if="item.status_order.title_status_order == valueFilter">
                            <router-link :to="{ name : 'detail-view', params : { id : item.id} }">
                                <div class="card-item__header f jcb aic pt05 pb05 pl1 pr1 border-bottom">
                                    <h5 class="blue"> #{{ item.order_key }}</h5>
                                    <div 
                                        v-if="item.status_order.title_status_order == 'Đang xử lý'"
                                        class="card-item__status h6 pt025 pb025 pl05 pr05 border-radius orange bg-yellow">
                                            {{ item.status_order.title_status_order }}
                                    </div>
                                    <div 
                                        v-if="item.status_order.title_status_order == 'Thành công'"
                                        class="card-item__status h6 pt025 pb025 pl05 pr05 border-radius green bg-green">
                                            {{ item.status_order.title_status_order }}
                                    </div>
                                    <div 
                                        v-if="item.status_order.title_status_order == 'Đã hủy'"
                                        class="card-item__status h6 pt025 pb025 pl05 pr05 border-radius red bg-red">
                                            {{ item.status_order.title_status_order }}
                                    </div>
                                </div>
                                <div class="card-item__body cursor pt1 pb1 pl1 pr1">
                                    <div class="f jcb aic mb05">
                                        <div class="gray p4">Tên người mua</div>
                                        <div class="p4">{{item.customer.full_name}}</div>
                                    </div>
                                    <div class="f jcb aic mb05">
                                        <div class="gray p4">Số điện thoại</div>
                                        <div class="p4">{{item.customer.phone}}</div>
                                    </div>
                                    <div class="f jcb aic mb1">
                                        <div class="gray p4">Địa chỉ</div>
                                        <div class="p4">{{item.customer.address}}</div>
                                    </div>
                                    <div class="f jcb aic pt1 border-top">
                                        <div class="desktop gray p4">Trạng thái vận kho</div>
                                        <div 
                                            class="h6 status-order orange"
                                            v-if="item.status_delivery.id == 1">
                                            Cần chuẩn bị hàng
                                        </div>
                                        <div 
                                            class="h6 status-order orange"
                                            v-if="item.status_delivery.id == 2">
                                            Đang chờ vận chuyển tới lấy
                                        </div>
                                        <div 
                                            class="h6 status-order orange"
                                            v-if="item.status_delivery.id == 3">
                                            Đã giao hàng cho vận chuyển
                                        </div>
                                        <div 
                                            class="h6 status-order orange"
                                            v-if="item.status_delivery.id == 4">
                                            Đang chờ vận chuyển trả hàng
                                        </div>
                                        <div 
                                            class="h6 status-order orange"
                                            v-if="item.status_delivery.id == 5">
                                            Đã nhận lại hàng về kho
                                        </div>
                                    </div>
                                </div>
                                <div class="card-item__total f jcb aic pt1 pb1 pr1 pl1 bg-gray-2">
                                    <div class="f fdc">
                                        <div class="h6 mb05">Tổng sản phẩm</div>
                                        <div class="h4 text-red-linear"> {{item.total_quantity}} Sản phẩm </div>
                                    </div>
                                    <div class="h5 pt075 pb075 pl1 pr1 border border-radius bg-white-1">
                                        Xem chi tiết
                                    </div>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import firebase from "@/firebaseInit";
import "firebase/firestore";
import { HTTP } from '../../../http-default'

export default {
    data () {
        return {
            dataItem : [],
            stock: [],
            cardDemo: true,
            dataOrderLength : 0
        }
    },

    props : {
        valueFilter : String,
        filterKey : {
            type: String,
            default () {
                return ''
            }
        },
        propStatusDelivery : {
            type : Array,
            default() {
                return []
            }
        },
        dataItemFilter : {
            type: Array,
            default() {
                return []
            }
        }
    },

    watch : {
        'propStatusDelivery' : {
            handler: function() {
                this.getData()
            },
            deep: true
        },
        'dataOrderLength' : function () {
            if (this.dataOrderLength > this.dataItem.length) {
                this.getData()
            }
        }
    },

    methods : {

        getData () {
            HTTP.get('v1/api/order/')
            .then((res) => {
                this.dataItem = res.data.results
                this.cardDemo = false
            })
        },

        getNewOrder () {
            HTTP.get('v1/api/stock/')
            .then((res) => {
                this.stock = res.data.results
                // eslint-disable-next-line @typescript-eslint/no-this-alias
                let path = this
                var ref = firebase.database().ref("/order_warehouse/" + this.stock[0].id)
                ref.on("child_added", function() {
                    path.dataOrderLength++
                })
            })
        }
    },
    mounted () {
        this.getData()
        this.getNewOrder()
    }
}
</script>

<style scoped lang="scss">
    @import 'CardOrder.scss';
</style>