<template>
  <div class="desktop" @click="handleReload">
    <div class="per-view-button-back fixed f aic jcc mt4" style="left: 45%; bottom: 50px">
      <router-link to="/">
        <button 
          class="border-radius h6 pt1 pb1 pr1 pl1 bg-white-1 blue-1" 
          style="box-shadow: 0px 0px 16px rgba(88, 92, 162, 0.24);">
          <img src="../assets/home.png" alt="">
          Màn hình chính
        </button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    handleReload() {
      location.reload()
    },
  }
}
</script>

<style>

</style>