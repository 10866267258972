import { render, staticRenderFns } from "./CreatePackageView.vue?vue&type=template&id=f6b91d40&scoped=true&"
import script from "./CreatePackageView.vue?vue&type=script&lang=js&"
export * from "./CreatePackageView.vue?vue&type=script&lang=js&"
import style0 from "./CreatePackageView.vue?vue&type=style&index=0&id=f6b91d40&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f6b91d40",
  null
  
)

export default component.exports