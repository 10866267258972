<template>
  <div class="home">
    <HeaderHomeView 
      :organization="organization"/>
    <!-- Mobile -->
    <div
      v-if="isMobile()"  
      class="swiper-container">
      <Flickity 
        ref="flickity" 
        :options="flickityOptions">
        <div class="carousel-cell">
          <Body />
        </div>
        <div class="carousel-cell">
          <ListApp 
            :dataSystem="dataShow"/> 
        </div>
        <div class="carousel-cell">
          <TabApp 
            :dataSystem="dataShow"/> 
        </div> 
      </Flickity>
    </div>

    <!-- Desktop -->
    <div
      v-else 
      class="swiper-container">
      <Flickity 
        ref="flickity" 
        :options="flickityOptions">
        <div class="carousel-cell">
          <Body />
          <ListApp 
            :dataSystem="dataShow"/> 
        </div>
        <div class="carousel-cell">
          <TabApp 
            :dataSystem="dataShow"/> 
        </div>
      </Flickity>
    </div>

    <NavBar class="desktop" :App="data"/> 
  </div>
</template>

<script>
import { Vue } from 'vue-property-decorator';
import HeaderHomeView from '@/components/HeaderHomeView/HeaderHomeView.vue';
import Body from '@/components/BodyView/Body.vue';
import ListApp from '@/components/ListAppView/ListApp.vue';
import NavBar from '@/components/NavBar.vue';
import TabApp from '@/components/TabApp.vue';
import { HTTP } from '../../http-default';
import Flickity from '../../../node_modules/vue-flickity/src/flickity.vue';
// import required modules

export default Vue.extend({
  components: {
    HeaderHomeView,
    Body,
    ListApp,
    NavBar,
    TabApp,
    Flickity
  },
  data() {
    return {
      data : [],
      organization : [],
      dataShow : [],
      flickityOptions: {
        initialIndex: 3,
        prevNextButtons: false,
        pageDots: true,
        wrapAround: true
      }
    }
  },
  methods: {
    // mobile
    isMobile () {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },

    getData() {
      var systemSetting = localStorage.getItem("systemSetting")
      this.data = JSON.parse(systemSetting)
      for(let i = 0; i < this.data.length; i++) {
        if(this.data[i]['levelmenu'] == 1) {
          this.dataShow.push(this.data[i])
        }
      }
      HTTP.get('v1/api/organization/')
        .then ((res) => {
          this.organization = res.data
        });
    },
  },
  mounted() {
    this.getData();
    this.isMobile ();
  }
})
</script>
<style lang="scss">
  @import 'Home.scss';
</style>
