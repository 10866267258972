@<template>
    <div class="popup popup-create-ticket jcc aic">

        <!-- create export ticket -->
        <div
            v-if="actionStatus" 
            class="popup-create-ticket__container f fdc aic pt15 pb15 pl1 pr1 border-radius bg-white-1">
            <div class="icon--noti mb1"></div>
            <div class="h3 text-red-linear mb05">Thông báo</div>
            <div class="p2 mb15 gray">Xác nhận tạo phiếu xuất hàng bán?</div>
            <div class="w100 f jcb aic">
                <div 
                    @click="handleCloseTicket"
                    class="w50 h5 pt1 pb1 border border-radius align-c cursor">Quay lại</div>
                <div 
                    @click="handleCreateTicket"
                    class="w50 h5 pt1 pb1 ml1 border-radius align-c cursor bg-red-1 white">Xác nhận</div>
            </div>
        </div>

        <!-- cancel create ticket -->
        <div
            v-if="!actionStatus" 
            class="popup-create-ticket__container f fdc aic pt15 pb15 pl1 pr1 border-radius bg-white-1">
            <div class="icon--noti mb1"></div>
            <div class="h3 text-red-linear mb05">Thông báo</div>
            <div class="p2 mb15 gray">Xác nhận huỷ bỏ thao tác Tạo phiếu?</div>
            <div class="w100 f jcb aic">
                <div 
                    @click="handleCloseTicket"
                    class="w50 h5 pt1 pb1 border border-radius align-c cursor">Quay lại</div>
                <div 
                    @click="handleCancelTicket"
                    class="w50 h5 pt1 pb1 ml1 border-radius align-c cursor bg-red-1 white">Xác nhận</div>
            </div>
        </div>

        <!-- create ticket success-->
        <div class="popup-success__container hide fdc aic pt15 pb15 pl1 pr1 border-radius bg-white-1">
            <div class="icon--noti-success mb1"></div>
            <div class="h3 text-red-linear mb05">Thông báo</div>
            <div class="p2 mb15 gray">Tạo phiếu xuất hàng bán thành công! <br> Trạng thái đơn hàng đã được cập nhật.</div>
            <div class="w100 f jcb aic">
                <div 
                    @click="handleDetailTicket"
                    class="w50 h5 pt1 pb1 border border-radius align-c cursor">Xem chi tiết phiếu</div>
                <router-link :to="{ name : 'detail-view' }" 
                    class="w50 h5 pt1 pb1 ml1 border-radius align-c cursor bg-red-1 white">Về trang đơn hàng
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { HTTP } from '@/http-default'
import firebase from "@/firebaseInit";
import "firebase/firestore";
export default {
    props : {
        dataSubOrder : {
            type : Array,
            default() {
                return []
            }
        },
        dataOrder : {
            type: Object,
            default () {
                return {}
            }
        },
        packageKeyParent : String,
        actionStatus : Boolean,
        note_ticket : String,
        title_export_ticket : String,
        export_reason : String,
        ticket_package : Object
    },

    data () {
        return {
            idTicket : 0,
            data : []
        }
    },

    methods : {
        handleCloseTicket () {
            var popup = document.getElementsByClassName('popup-create-ticket')[0]
            popup.classList.toggle('active-popup')
        },

        handleCancelTicket () {
            this.$router.go(-1)
        },

        handleDetailTicket () {
            this.$router.push({name: 'detail-ticket-view' , params : { 
                filter : "phiếu xuất hàng bán", 
                id : this.idTicket, 
                typeTicket : 'exportticket',
                menuitem:"quan-ly-exportticket",
                sidebaritem:"quan-ly-stock"
            }})
        },

        handleCreateTicket () {
            const token = localStorage.getItem('user')
            const axiosConfig = {
                headers : {
                    'Content-Type': 'application/json',
                    'Authorization': 'Token '+ token,
                }
            }

            const dataTicket = JSON.stringify({
                "title_export_ticket": this.title_export_ticket,
                "key_ticket": Math.floor(Math.random() * 9999999999),
                "note_ticket": this.note_ticket,
                "total_fund": 1,
                "export_reason": this.export_reason,
                "ticket_type": 1,
                "ticket_status": 5,
                "organization": "2",
                "stock": "1",
                "order": this.$route.params.id,
                "user_create": "2",
                "package": String(this.ticket_package.id)
            });

            axios.post('https://api.nmeco.xyz/v1/api/exportticket/', dataTicket, axiosConfig)
            .then((res) => {
                this.idTicket = res.data.id
                this.handleTranferOrder()
                const deliverystatus = JSON.stringify({
                    "status_delivery" : 2,
                    "sub_order": this.dataSubOrder
                })

                axios.put('https://api.nmeco.xyz/v1/api/order/' + this.$route.params.id + '/', deliverystatus, axiosConfig)
                .then(() => {
                    this.handleAddStatusFirebase()
                    var popupCreate = document.getElementsByClassName('popup-create-ticket__container')[0]
                    popupCreate.classList.add('hide')
                    popupCreate.classList.remove('f')

                    var popup = document.getElementsByClassName('popup-success__container')[0]
                    popup.classList.add('f')
                })
            })
        },

        getRealTime() {
            var today = new Date();
            var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
            var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
            var dateTime = date+'T'+time+'Z';
            return dateTime
        },

        handleAddStatusFirebase () {
            this.data.push({
                created_at: this.getRealTime(),
                title_logorder: "Kho (Tạo kiện hàng thành công)"
            })
            var statusFirebase = firebase.database().ref("/status_order_warehouse/" + parseInt(this.dataOrder.order_key))
            statusFirebase.set(this.data)
        },

        handleTranferOrder () {
            var dataPackageKey = JSON.stringify({
                "package_key" : parseInt(this.packageKeyParent)
            })
            HTTP.post('transfer/', dataPackageKey)
            .then((res) => {
                var dataPackage = res.data
                dataPackage["key"] = "order"

                dataPackage.order.shipper = 1

                var data = JSON.stringify(dataPackage)
                var config = {
                    headers : {
                        'Content-Type': 'application/json',
                    }
                }
                axios.post('https://api.nmeco.xyz/contact/send/', data, config)
            })
        }
    },

    mounted () {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        let path = this
        var ref = firebase.database().ref("/status_order_warehouse/" + parseInt(this.dataOrder.order_key))
        ref.on("child_added", function(childSnapshot) {
            path.data.push(childSnapshot.val())
            path.data.reverse() 
        })
    }
}
</script>

<style scoped lang='scss'>
    @import 'PopupCreateTicket.scss';
</style>