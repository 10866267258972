@<template>
    <div class="noti-list__container active pt1 pb1 pl1 pr1 border-radius bg-white-1">
        <div class="noti-list__header w100 f jcb aic mb05">
            <div class="h6">Thông báo ({{data.length}})</div>
            <div 
                @click="handleSeenAll"
                class=" h6 blue cursor">Đánh dấu Đã đọc tất cả</div>
        </div>
        <div
            v-for="(item, index) in data"
            :key="index" 
            class="noti-list__body">
            <div 
                class="noti-item pt1 mt1 f jcb aic border-top">
                <div class="noti-item__img f jcc aic bg-green-1">
                    <div class="icon--order-noti"></div>
                </div>
                <div class="noti-item__title w80 ml1 mr05">
                    <div class="noti-item__title-header f jcb aic mb05">
                        <div class="h6">Thông báo đơn hàng!</div>
                        <div
                            @click='handleDetailOrder(item.order_key)'
                            class="h6 blue cursor">Xem chi tiết</div>
                    </div>
                    <div class="noti-item__title-content p3 mb025">
                        Mã đơn hàng: <span class="h6 blue"> {{item.order_key}} </span>
                    </div>
                    <div class="noti-item__title-time p3 gray">
                        Cập nhật từ BZI | 22/02/2022 09:00AM
                    </div>
                </div>
                <div 
                    v-if="!item.seen"
                    class="noti-item__status border-radius"></div>
            </div>
        </div>
    </div>
</template>

<script>
import firebase from "@/firebaseInit";
import "firebase/firestore";
import { HTTP } from '@/http-default';

export default {
    data () {
        return {
            data : [],
            dataOrder : [],
            stock: []
        }
    },
    
    methods : {

        handleSeenAll () {
            for (let i = 0; i < this.data.length; i++) {
                var ref = firebase.database().ref("/order_warehouse/" + this.stock[0].id + '/' + i)
                ref.child('seen').set(true)
                this.data[i].seen = true
            }
        },

        handleDetailOrder(value) {
            var idOrder = this.dataOrder.filter(item => item.order_key == value)
            var listNoti = document.getElementsByClassName('noti-list__container')[0]
            listNoti.classList.toggle('active')
            this.$router.push({ name : 'detail-view', params : { id : idOrder[0].id} })
        },

        async getNoti () {

            // eslint-disable-next-line @typescript-eslint/no-this-alias
            let path = this
            var ref = firebase.database().ref("/order_warehouse/" + this.stock[0].id)
            ref.on("child_added", function(childSnapshot) {
                path.data.push(childSnapshot.val())
                path.data.reverse()
            })
        },

        getData () {
            HTTP.get('v1/api/stock/')
            .then((res) => {
                this.stock = res.data.results
                this.getNoti ()
            })

            HTTP.get('v1/api/order/')
            .then((res) => {
                this.dataOrder = res.data.results
            })
        }
    },

    mounted () {
        this.getData()
    }
}
</script>

<style scoped lang='scss'>
    @import 'ListNoti.scss';
</style>