


























































































import { Component, Prop, Vue } from 'vue-property-decorator';
import MyChart from '@/components/MyChartView/MyChart.vue'

@Component({
  components: {
    MyChart,
  },
})
export default class Body extends Vue {
  @Prop() private msg!: string;
}
