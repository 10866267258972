@<template>
    <div class="popup aic jcc popup-create-package">
        <div 
            v-if="this.$route.params.typeTicket == 'exportticket'"
            class="info-package__container pt1 pb1 pl1 pr1 border-radius bg-gray-1">
            <div class="confirm-product__body f">
                <div class="confirm-product__body-left">
                    <div class="h5 mb1 text-red-linear">Xác nhận thông tin sản phẩm</div>
                    <div class="list-product">
                        <div 
                            v-for="(item, index) in productImport"
                            :key="index">
                            <div 
                                v-if="item.id == idProduct"
                                @click="handleSelect(item.id)"
                                class="product-item pt1 pb1 pl1 pr1 mb05 border-radius cursor border-red bg-white-1">
                                <div>
                                    <div class="f aic">
                                        <div class="mr05">
                                            <img :src="item.image_product" alt="">
                                        </div>
                                        <div class="f fdc jcb">
                                            <div class="mb025 h6"> {{item.title_product}} </div>
                                            <div class="p4 gray"> Hàng có sẵn: {{item.id_product_sub}} </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div 
                                v-else
                                @click="handleSelect(item.id)"
                                class="product-item pt1 pb1 pl1 pr1 mb05 border-radius cursor bg-white-1">
                                <div>
                                    <div class="f aic">
                                        <div class="mr05">
                                            <img :src="item.image_product" alt="">
                                        </div>
                                        <div class="f fdc jcb">
                                            <div class="mb025 h6"> {{item.title_product}} </div>
                                            <div class="p4 gray"> Hàng có sẵn: {{item.id_product_sub}} </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="confirm-product__body-right ml1">
                    <div class="h5 mb1 gray">Chi tiết thông tin sản phẩm</div>
                    <div 
                        v-for="(item, index) in productImport"
                        :key="index">
                        <div 
                            v-if="item.id == idProduct"
                            class="product-item__container pt1 pb1 pl1 pr1 mb1 border-radius bg-white-1">
                            <div>
                                <div class="f aic mb05">
                                    <div class="mr05">
                                        <img :src="item.image_product" alt="">
                                    </div>
                                    <div class="f fdc jcb">
                                        <div class="mb025 h6"> {{item.title_product}} </div>
                                        <div class="p4 gray"> Hàng có sẵn: {{item.id_product_sub}} </div>
                                    </div>
                                </div>
                            </div>
                            <div class="product-item__info mb1 border-bottom">
                                <div class="f jcb aic mb025">
                                    <div class="p4 gray">Mã sản phẩm</div>
                                    <div class="h6 blue"> #{{item.id}} </div>
                                </div>
                                <div class="f jcb aic mb025">
                                    <div class="p4 gray">Mã lô hàng</div>
                                    <div class="h6 blue"> #{{item.id}} </div>
                                </div>
                                <div class="f jcb aic mb025">
                                    <div class="p4 gray">Vị trí lô</div>
                                    <div class="p4"> Khu A- Kệ B- Tầng C- Hàng D </div>
                                </div>
                                <div class="f jcb aic mb025">
                                    <div class="p4 gray">Nhà cung cấp</div>
                                    <div class="p4"> The A Corp </div>
                                </div>
                                <div class="f jcb aic mb025">
                                    <div class="p4 gray">Ngày sản xuất</div>
                                    <div class="p4"> dd/mm/yy </div>
                                </div>
                                <div class="f jcb aic mb025">
                                    <div class="p4 gray">Hạn sử dụng</div>
                                    <div class="p4"> dd/mm/yy </div>
                                </div>
                                <div class="f jcb aic mb025">
                                    <div class="p4 gray">Đơn giá</div>
                                    <div class="p4 text-red-linear"> {{formatMoney(item.price_product)}} </div>
                                </div>
                            </div>
                            <div class="product-item__quantity f jcb aic">
                                <div class="p4">Số lượng thực xuất</div>
                                <div class="h6"> {{item.total_quantity}} </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="confirm-product__action w100 f aic jcb pt1 pb1 pl1 pr1 border-top bg-gray-1">
                <div class="p4 w50">
                    Tổng giá trị kiện hàng: <span class="h6 text-red-linear"> {{totalPrice}} </span>
                </div>
                <div 
                    @click="handlePackage"
                    class="confirm-product__btn h5 align-c border border-radius pt1 pb1 cursor">Hủy bỏ</div>
            </div>
        </div>

        <div 
            v-if="this.$route.params.typeTicket == 'importticket'"
            class="info-package__container pt1 pb1 pl1 pr1 border-radius bg-gray-1">
            <div class="confirm-product__body f">
                <div class="confirm-product__body-left">
                    <div class="h5 mb1 text-red-linear">Xác nhận thông tin sản phẩm</div>
                    <div class="list-product">
                        <div 
                            v-for="(item, index) in listConsignment"
                            :key="index">
                            <div 
                                v-if="item.consignment_key == idProduct"
                                @click="handleSelect(item.consignment_key)"
                                class="product-item pt1 pb1 pl1 pr1 mb05 border-radius cursor border-red bg-white-1">
                                <div>
                                    <div class="f aic">
                                        <div class="mr05">
                                            <img :src="item.product.image_product" alt="">
                                        </div>
                                        <div class="f fdc jcb">
                                            <div class="mb025 h6"> {{item.product.title_product}} </div>
                                            <div class="p4 gray"> Hàng có sẵn: {{item.product.id_product_sub}} </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div 
                                v-else
                                @click="handleSelect(item.consignment_key)"
                                class="product-item pt1 pb1 pl1 pr1 mb05 border-radius cursor bg-white-1">
                                <div>
                                    <div class="f aic">
                                        <div class="mr05">
                                            <img :src="item.product.image_product" alt="">
                                        </div>
                                        <div class="f fdc jcb">
                                            <div class="mb025 h6"> {{item.product.title_product}} </div>
                                            <div class="p4 gray"> Hàng có sẵn: {{item.product.id_product_sub}} </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="confirm-product__body-right ml1">
                    <div class="h5 mb1 gray">Chi tiết thông tin sản phẩm</div>
                    <div 
                        v-for="(item, index) in listConsignment"
                        :key="index">
                        <div 
                            v-if="item.consignment_key == idProduct"
                            class="product-item__container pt1 pb1 pl1 pr1 mb1 border-radius bg-white-1">
                            <div>
                                <div class="f aic mb05">
                                    <div class="mr05">
                                        <img :src="item.product.image_product" alt="">
                                    </div>
                                    <div class="f fdc jcb">
                                        <div class="mb025 h6"> {{item.product.title_product}} </div>
                                        <div class="p4 gray"> Hàng có sẵn: {{item.product.id_product_sub}} </div>
                                    </div>
                                </div>
                            </div>
                            <div class="product-item__info mb1 border-bottom">
                                <div class="f jcb aic mb025">
                                    <div class="p4 gray">Mã sản phẩm</div>
                                    <div class="h6 blue"> #{{item.product.id}} </div>
                                </div>
                                <div class="f jcb aic mb025">
                                    <div class="p4 gray">Mã lô hàng</div>
                                    <div class="h6 blue"> #{{item.consignment_key}} </div>
                                </div>
                                <div class="f jcb aic mb025">
                                    <div class="p4 gray">Vị trí lô</div>
                                    <div class="p4"> {{item.position}} </div>
                                </div>
                                <div class="f jcb aic mb025">
                                    <div class="p4 gray">Nhà cung cấp</div>
                                    <div class="p4"> The A Corp </div>
                                </div>
                                <div class="f jcb aic mb025">
                                    <div class="p4 gray">Ngày sản xuất</div>
                                    <div class="p4"> {{formatTime(item.manufacturing_date)}} </div>
                                </div>
                                <div class="f jcb aic mb025">
                                    <div class="p4 gray">Hạn sử dụng</div>
                                    <div class="p4"> {{formatTime(item.expiry_date)}} </div>
                                </div>
                                <div class="f jcb aic mb025">
                                    <div class="p4 gray">Đơn giá</div>
                                    <div class="p4 text-red-linear"> {{formatMoney(item.price_product)}} </div>
                                </div>
                            </div>
                            <div class="product-item__quantity f jcb aic">
                                <div class="p4">Số lượng thực nhập</div>
                                <div class="h6"> {{item.quantity_in_consignment}} </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="confirm-product__action w100 f aic jcb pt1 pb1 pl1 pr1 border-top bg-gray-1">
                <div class="p4 w50">
                    Tổng giá trị kiện hàng: <span class="h6 text-red-linear"> {{totalPrice}} </span>
                </div>
                <div 
                    @click="handlePackage"
                    class="confirm-product__btn h5 align-c border border-radius pt1 pb1 cursor">Hủy bỏ</div>
            </div>
        </div>
    </div>
</template>

<script>
import { HTTP } from '@/http-default'
export default {
    props : {
        listProduct : {
            type: Array,
            default() {
                return []
            } 
        },
        listConsignment : {
            type: Array,
            default() {
                return []
            }
        },
        listPackage : {
            type: Array,
            default() {
                return []
            }
        },
        totalPrice : String
        
    },

    data () {
        return {
            idProduct : 1,
            productImport : [],
            categoryproduct : [],
        }
    },

    watch : {
        'listPackage' : function () {
            this.getData()
        },
        
        'listProduct' : function () {
            this.getData()
        }
    },

    methods : {
        // formatMoney
        formatMoney(value) {
            value = value.toLocaleString('it-IT', {style : 'currency', currency : 'VND'})
            return value
        },

        // formatTime
        formatTime(value) {
            value = value.split('T')
            return value[0]
        },

        handleSelect (id) {
            this.idProduct = id
        },

        handlePackage () {
            var popup = document.getElementsByClassName('popup-create-package')[0]
            popup.classList.toggle('active-popup')
        },

        getData () {
                if (this.$route.params.typeTicket == 'importticket') {
                    this.productImport = []
                    this.listConsignment.forEach(item => {
                        this.listProduct.forEach(value => {
                            if (item.product == value.id) {
                                item.product = value
                                this.productImport.push(item)
                            }
                        })
                    })

                    this.idProduct = this.listConsignment[0].consignment_key
                }

                else if (this.$route.params.typeTicket == 'exportticket') {
                    this.productImport = []
                    this.listPackage.forEach(item => {
                        this.listProduct.forEach(value => {
                            if (item.product == value.id) {
                                value.total_quantity = item.total_quantity
                                value.total_price = item.total_price
                                this.productImport.push(value)
                            }
                        })
                    }) 

                    this.idProduct = this.productImport[0].id
                }

                HTTP.get('v1/api/categoryproduct/')
                .then((res) => {
                    this.categoryproduct = res.data.results
                })
        }
    },

    created () {
        setTimeout(() => {
            this.getData()
        }, 5000)
    }
}
</script>

<style scoped lang='scss'>
    @import 'ProductPackage.scss';
</style>