@<template>
    <div class="popup popup-histoty-ticket f jcc aic">
        <div class="histoty-ticket__container pt1 pb1 pl1 pr1 border-radius bg-white-1">
            <div class="history-ticket__header f jcb aic mb1">
                <div class="h5 text-red-linear">
                    Lịch sử tạo phiếu xuất / nhập hàng
                </div>
                <div 
                    @click="handleClose"
                    class="icon icon--close"></div>
            </div>

            <div class="history-ticket__body">
                <div class="history-ticket__body-title aic mb1">
                    <div class="align-c p2 gray">STT</div>
                    <div class="p2 gray">Tên phiếu</div>
                    <div class="p2 gray align-c">Loại phiếu</div>
                </div>
                <div 
                    v-for="(item, index) in dataTicket"
                    :key="index">
                    <div class="history-ticket__item pt1 mb1 border-top aic">
                        <div class="align-c">{{index + 1}}</div>
                        <div>
                            <div class="p3 mb025">{{item.title_export_ticket}}</div>
                            <div class="p3 blue mb025">#{{item.key_ticket}}</div>
                            <div class="p3 gray">{{formatTime(item.time_created_ticket)}}</div>
                        </div>
                        <div class="p3 align-c">{{formatData(item.ticket_type)}}</div>
                        <router-link 
                            :to="{ name: 'detail-ticket-view' , params : { sidebaritem: 'quan-ly-stock', menuitem: 'quan-ly-exportticket' , filter: 'phiếu xuất hàng bán' , id : item.id, typeTicket : 'exportticket'} }"
                            class="align-r">
                            <div class="p4 blue cursor">
                                Xem chi tiết
                            </div>
                        </router-link >
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { HTTP } from '@/http-default'
export default {

    props : {
        orderId : {
            type: Number,
            default() {
                return 0
            }
        }
    },

    data () {
        return {
            dataTicket : [],
            typeTicket : []
        }
    },

    methods : {
        formatTime (value) {
            var time = value.split('T')
            return time[0]
        },

        formatData (value) {
            var type = ''
            this.typeTicket.forEach(element => {
                if (element.id === value) {
                    type = element.title_ticket_type
                }
            });
            return type
        },

        handleClose () {
            this.$emit('closePopup')
        },

        async getData () {
            await HTTP.get('v1/api/exportticket/')
            .then((res) => {
                var data = res.data.results
                this.dataTicket = data.filter(item => item.order === this.orderId)
            })

            await HTTP.get('v1/api/exporttickettype/')
            .then((res) => {
                this.typeTicket = res.data.results
            })
        }
    },

    created () {
        this.getData()
    }
}
</script>

<style scoped lang="scss">
    @import 'HistoryTicketOrder.scss';
</style>