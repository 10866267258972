<template>
  <div class="w100 mt15 f fw jcb" >
    <div 
      v-if="!isMobile()"
      class=" mb2 border-radius align-c" 
        style="width: 31%; height: 288px;">
      <div 
        class="f border-radius"
        style="background-color: rgba(20, 20, 20, 0.4); backdrop-filter: blur(20px); height: 288px;">
        <div 
          v-for="(item, index) in a" 
          :key="index" 
          class="f fw pt1 pb1 pr1 pl1" 
          style="width: calc(100% + 5em)">
          <div>
            <router-link :to="item.path">
              <div v-bind:class="item.icon" class="mb05"></div>
              <p class="p-small white align-c" style="width: 64px">{{item.titlemenu}}</p>
            </router-link>
          </div>
        </div>
      </div>
      <div class="mt05 h5 white">
        Quản lý nhân sự
      </div>
    </div>
    <div class="tab-app__container mb2 border-radius align-c" 
        style="width: 31%; height: 288px;">
      <div 
        class="f fw border-radius"
        style="background-color: rgba(20, 20, 20, 0.4); backdrop-filter: blur(20px); height: 288px;">
        <div 
          v-for="(item, index) in dataSystem" 
          :key="index">
          <div 
            v-if="item.titlemenu != 'quản lý tổ chức' && item.titlemenu != 'quản lý lô hàng' && item.titlemenu != 'quản lý kiện hàng'"
            class="align-c f pt1 pb1 pr1 pl1" style="width: calc(100%)">
            <router-link :to="item.path">
              <div v-bind:class="item.icon" class="mb05"></div>
              <p class="p-small white align-c" style="width: 64px">{{item.titlemenu}}</p>
            </router-link>
          </div>
        </div>
      </div>
      <div class="mt05 h5 white">
        Quản lý công việc
      </div>
    </div>
    <div 
      v-if="!isMobile()"
      class=" mb2 border-radius align-c" 
        style="width: 31%; height: 288px;">
      <div 
        class="f border-radius"
        style="background-color: rgba(20, 20, 20, 0.4); backdrop-filter: blur(20px); height: 288px;">
        <div 
          v-for="(item, index) in appOrganization" 
          :key="index" 
          class="f fw pt1 pb1 pr1 pl1" 
          style="width: calc(100% + 5em)">
          <div>
            <router-link :to="item.path">
              <div v-bind:class="item.icon" class="mb05"></div>
              <p class="p-small white align-c" style="width: 64px">{{item.titlemenu}}</p>
            </router-link>
          </div>
        </div>
      </div>
      <div class="mt05 h5 white">
        Quản lý dự án
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from '../http-default';

export default {
  data() {
    return {
      data : [],
      appCustomer: [],
      appAccount: [],
      appOrganization: []
    }
  },

  props : {
    dataSystem : {
      type : Array,
      default() {
        return []
      }
    }
  },

  methods: {

    isMobile () {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    // getData() {
    //   HTTP.get('v1/api/systemsetting/')
    //   .then((res) => {
    //     const setting = res.data.results[0].router.data.toLowerCase()
    //     const results = setting.replaceAll("'", '"')
    //     this.data = JSON.parse(results)
    //     for(let i = 0; i < this.data.length; i++) {
    //       if (this.data[i]['subparent'] == 'customer') {
    //         this.appCustomer.push(this.data[i])
    //       }
    //       if (this.data[i].subparent == 'account') {
    //         this.appAccount.push(this.data[i])
    //       }
    //       if (this.data[i].subparent == 'organization') {
    //         this.appOrganization.push(this.data[i])
    //       }
    //     }
    //   })
    // },
  },
  // created() {
  //   this.getData()
  // }
}
</script>

<style lang="scss" scoped>
@import '../../global/base/variables';
p {
    width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 20px;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}
@media (max-width: $xxs) {
  .tab-app__container {
    width: 100% !important;
  }
}
</style>