@<template>
    <div class="w100">
        <StockView v-if="this.$route.params.sidebaritem == 'quan-ly-stock'"></StockView>
        <OrderView v-if="this.$route.params.sidebaritem == 'quan-ly-order'"></OrderView>
        <ProductView v-if="this.$route.params.sidebaritem == 'quan-ly-product'"></ProductView>
        <ConnectionView v-if="this.$route.params.sidebaritem == 'connect-device'"></ConnectionView>
    </div>
</template>

<script>
import StockView from '@/components/StockView/StockView.vue'
import OrderView from '@/components/OrderView/OrderView.vue'
import ProductView from '@/components/ProductView/ProductView.vue'
import ConnectionView from '@/components/ConnectionView/ConnectionView.vue'
export default {
    components : {
        StockView,
        OrderView,
        ProductView,
        ConnectionView
    }
}
</script>

<style>

</style>