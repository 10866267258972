@<template>
    <div class="content__list">
            <div class="content__list-search f fw jcb aic mb2">
                <div class="text-input--search relative w50">
                    <div class="desktop icon--search text-input__icon"></div>
                    <input 
                        v-if="$route.params.menuitem == 'quan-ly-stock'"
                        class="text-input__el w80"
                        type="text" 
                        placeholder="Nhập sản phẩm bạn muốn tìm">
                    <input 
                        v-else-if="$route.params.menuitem == 'quan-ly-importticket'"
                        class="text-input__el w80"
                        type="text" 
                        @keyup="handleFilter"
                        placeholder="Nhập mã phiếu nhập hàng">
                    <input 
                        v-else-if="$route.params.menuitem == 'quan-ly-exportticket'"
                        class="text-input__el w80"
                        type="text" 
                        @keyup="handleFilter"
                        placeholder="Nhập mã phiếu xuất hàng">
                    <input 
                        v-else
                        class="text-input__el w80"
                        type="text" 
                        placeholder="Tìm kiếm">
                </div>
                <div 
                  v-if="this.$route.params.menuitem != 'quan-ly-stock'"
                  class="time-picker f jce aic w50">
                    <div class="time-start f aic mr1">
                        <pre class="p4 mr05 gray">Từ ngày</pre>
                        <input v-model="timeStart" class="p4" type="date">
                    </div>
                    <div class="time-end f aic">
                        <pre class="p4 mr05 gray">Đến ngày</pre>
                        <input v-model="timeEnd" class="p4" type="date">
                    </div>
                </div>
            </div>

            <!-- content__list-define -->
            <div 
              v-if="this.$route.params.menuitem == 'quan-ly-stock'"
              class="content__list-define pt1 pb1 pl1 pr1 mb15 border-radius bg-white-1">
                <div class="content__list-define__header f jcb aic mb05">
                    <div class="f aic">
                        <div class="icon icon--light"></div>
                        <div class="h6 ml05 blue">Định nghĩa:</div>
                    </div>
                    <div class="p4 blue">Đã hiểu</div>
                </div>
                <div class="content__list-define__body p3">
                    Cho biết số lượng hàng đang có/đang xử lí trong kho của bạn ở thời điểm hiện tại. Báo cáo giúp bạn biết hàng còn ít hay nhiều, để ra quyết định đề xuất nhập thêm hàng. Số lượng hàng ở đây có thể khác với số tồn của từng sản phẩm trong mục Sản phẩm (chỉ bao gồm lượng hàng sẵn sàng bán và không bao gồm phần hàng đang được xử lí).
                </div>
            </div>
 
            <!-- content__list-header -->
            <div 
                v-if="this.$route.params.menuitem == 'quan-ly-stock'"
                class="content__list-header f jcb aic mb15">
                <div>
                    <div class="h5 mb05">Sản phẩm (69)</div>
                    <div class="p2 gray">Lần cập nhật dữ liệu cuối: hh:mm dd/mm/yy </div>
                </div>
                <router-link 
                    :to="{ name: 'importticket-view' }"
                    class="content__list-header__btn f aic border-radius bg-red-1 white">
                    <div class="icon icon--download"></div>
                    <div class="ml05"> Xuất báo cáo</div>
                </router-link>
            </div>

            <div 
                v-else
                class="content__list-action f jcb aic w100 mb1">
                <div class="action-title h4">
                    Danh sách {{ this.$route.params.filter }}
                </div>
                <div 
                    v-if="this.$route.params.filter == 'phiếu nhập hàng thông thường'"
                    class="button f aic pt075 pb075 pl1 pr1 border-radius bg-red-1 white cursor">
                    <div class="icon icon--btn-importticket mr05"></div>
                    <div 
                        @click="handleCreateImportTicket"
                        class="h5">Tạo phiếu nhập hàng</div>
                </div>
            </div>

            <div 
                class="content__list-filter f aic"
                v-if="this.$route.params.filter == 'phiếu nhập hàng thông thường'">
                <div    
                    v-if="filterTicket != 100"
                    @click="handleFilterTicket(100)" 
                    class="button pt025 pb025 pl05 pr05 mr05 border-radius bg-red cursor">
                    <div class="h6 text-red-linear"> Tất cả </div>
                </div>
                <div    
                    v-if="filterTicket == 100"
                    @click="handleFilterTicket(100)" 
                    class="button pt025 pb025 pl05 pr05 mr05 border-radius bg-red-1 cursor">
                    <div class="h6 white"> Tất cả </div>
                </div>
                <div 
                    v-for="(item, index) in dataType"
                    :key="index">
                    <div 
                        v-if="filterTicket != item.id 
                            && item.title_ticket_status != 'Thất bại'
                            && item.title_ticket_status != 'Thành công'
                            && item.title_ticket_status != 'Đang xử lý'"
                        class="button pt025 pb025 pl05 pr05 mr05 border-radius bg-red cursor">
                        <div 
                            @click="handleFilterTicket(item.id)"
                            class="h6 red"> {{ item.title_ticket_status }}
                        </div>
                    </div>
                    <div 
                        v-if="filterTicket == item.id
                        && item.title_ticket_status != 'Thất bại'
                        && item.title_ticket_status != 'Thành công'
                        && item.title_ticket_status != 'Đang xử lý'"
                        class="button pt025 pb025 pl05 pr05 mr05 border-radius bg-red-1 cursor">
                        <div 
                            @click="handleFilterTicket(item.id)"
                            class="h6 white"> {{ item.title_ticket_status }}
                        </div>
                    </div>
                </div>
            </div>

            <!-- content__list-body-stock -->
            <div 
                v-if="this.$route.params.menuitem == 'quan-ly-stock'"
                >
              <div class="content-list pt15 pb15">
                <div class="content-list__header f aic mb15 pr15 pl15">
                    <div class="f jcb aic w100 p4 mr2">
                        <div class="large-info">Sản phẩm</div>
                        <div class="small-info">Tổng số hàng <br> kho sở hữu (1)</div>
                        <div class="small-info">Hàng có sẵn (2)</div>
                        <div class="large-info-1 align-c">
                            <div class="mb05">Đang xử lý</div>
                            <div class="f aic gray">
                                <div class="small-info-1">Đang giao cho <br> khách (3)</div>
                                <div class="small-info-1">Đang lưu kho <br> tạm thời</div>
                                <div class="small-info-1">Luân chuyển & <br> xử lý khác(5)</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content-list__body f fw aic">
                  <div class="w100" v-for="(item, index) in dataShowSelect" :key="index">
                    <!-- <router-link :to="{ name: 'detail', params: { id: item.id} }" class="w100"> -->
                      <div class="item-person relative f aic w100 border-radius mb1 pr15 pl15 pt1 pb1 bg-white-1">
                        <div class="f jcb aic w100">
                            <div class="large-info f aic">
                                <div class="mr05">
                                    <img src="../../../assets/imgPerson.png" alt="">
                                </div>
                                <div>
                                    <div class="mb025"> {{ item.title_product }} </div>
                                    <div class="mb025 gray">#{{ item.key_product }} </div>
                                    <div><span class="text-red-linear"> {{ item.price_product }} </span> / cái</div>
                                </div>
                            </div>
                            <div class="small-info"> {{ item.quantity_own }} </div>
                            <div class="small-info"> {{ item.quantity_in_stock }} </div>
                            <div class="large-info-1 f jcb aic">
                                <div class="small-info-1"> {{ item.quantity_deliver }} </div>
                                <div class="small-info-1"> {{ item.quantity_pending }} </div>
                                <div class="small-info-1"> {{ item.quantity_other }} </div>
                            </div>
                            <div 
                                class="icon icon--dots relative"
                                @click="handleUpdate">
                            </div>
                        </div>
                            <div class="absolute poup-update p4 pl1 pr1 border-radius bg-white-1">
                              <div class="pt1 pb1 border-radius cursor gray">Cập nhật số lượng hàng có sẵn</div>
                              <div class="pt1 pb1 border-top cursor gray" >Xem lịch sử cập nhật</div>
                            </div>
                      </div>
                    <!-- </router-link> -->
                  </div>
                </div>
              </div>
            </div>

            <!-- content__list-body-exportticket -->
            <div v-if="this.$route.params.menuitem == 'quan-ly-exportticket'">
                <div class="content-list pt15 pb15">
                    <div class="content-list__header desktop aic mb15 pr15 pl15">
                        <div 
                            class="f aic w100 gray">
                            <div class="p3">
                                STT
                            </div>
                            <div class="small-info p3">
                               Tên phiếu
                            </div>
                            <div class="small-info p3">
                               Mã phiếu xuất hàng
                            </div>
                            <div class="small-info p3">
                               Thời điểm tạo phiếu
                            </div>
                            <div class="small-info p3">
                               Đơn hàng
                            </div>
                            <div class="small-info p3">
                               Trạng thái phiếu
                            </div>
                            <div class="small-info p3">
                               Thao tác
                            </div>
                        </div>
                    </div>
                    <div class="content-list__body f fw aic">
                        <div 
                            class="w100"
                            v-for="(item,index) in dataShowSelect"
                            :key="index">
                            <div class="content-ticket__item f aic w100 border-radius mb1 pr15 pl15 pt1 pb1 bg-white-1">
                                <div class="mr1 ml05"> {{index + 1}}.  </div>
                                <div class="content-ticket__title small-info p4 align-s">
                                    {{ item.title_export_ticket }}
                                </div>
                                <div class="small-info p4 blue">
                                    {{ item.key_ticket }}
                                </div>
                                <div class="small-info p4 gray">
                                    {{ item.time_created_ticket[1] }} - {{ item.time_created_ticket[0] }}
                                </div>
                                <div class="small-info p4 cursor blue">
                                    Xem đơn hàng
                                </div>
                                <div class="small-info align-c">
                                    <div 
                                        v-for="(value, key) in dataType"
                                        :key="key">
                                        <div
                                            v-if="item.ticket_status == value.id" 
                                            class="f jcc aic w100 mb1">
                                            <div 
                                                v-if="value.title_ticket_status == 'Thành công'"
                                                class="pt05 pb05 pl1 pr1 h6 border-radius green bg-green">
                                                    {{ value.title_ticket_status }}
                                            </div>
                                            <div 
                                                class="pt05 pb05 pl1 pr1 h6 border-radius orange bg-yellow"
                                                v-if="value.title_ticket_status == 'Đang xử lý'">
                                                    {{ value.title_ticket_status }}
                                            </div>
                                            <div 
                                                class="pt05 pb05 pl1 pr1 h6 border-radius red bg-red"
                                                v-if="value.title_ticket_status == 'Thất bại'">
                                                    {{ value.title_ticket_status }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <router-link 
                                    :to="{ name: 'detail-ticket-view' , params : { id : item.id, typeTicket : 'exportticket'} }"
                                    class="small-info p2 cursor blue">
                                    Xem chi tiết
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- content__list-body-importticket -->
            <div v-if="this.$route.params.menuitem == 'quan-ly-importticket'">
                <div v-if="filterTicket ==  100">
                    <div class="content-list pt15 pb15">
                        <div class="content-list__header desktop aic mb15 pr15 pl15">
                            <div 
                                class="f aic w100 gray">
                                <div class="p2">
                                    STT
                                </div>
                                <div class="small-info p2 qcont">
                                Tên phiếu nhập
                                </div>
                                <div class="small-info p2 qcont">
                                Người tạo phiếu
                                </div>
                                <div class="small-info p2 qcont">
                                Thời điểm tạo phiếu
                                </div>
                                <div class="small-info p2 qcont">
                                Trạng thái phiếu
                                </div>
                                <div class="small-info p2 qcont">
                                Thao tác
                                </div>
                            </div>
                        </div>

                        <!-- desktop -->
                        <div class="content-list__body desktop fw aic">
                            <div 
                                class="w100"
                                v-for="(item,index) in dataShowSelect"
                                :key="index">
                                <div class="item-person f aic w100 border-radius mb1 pr15 pl15 pt1 pb1 bg-white-1">
                                    <div class="mr1 ml05"> {{index + 1}}. </div>
                                    <div class="small-info align-s pl05">
                                        <div class="p2 mb025">{{ item.title_import_ticket }}</div>
                                        <div class="p2 blue"> #{{ item.key_ticket }}</div>
                                    </div>
                                    <div class="small-info p4 blue">
                                        <div>
                                            <div
                                                class="p2 blue">
                                                {{ dataUser.full_name }}
                                            </div>
                                        </div>
                                    </div>
                                    <div 
                                        v-if="item.time_created_ticket"
                                        class="small-info p2 gray">
                                        {{ item.time_created_ticket[1] }} - {{ item.time_created_ticket[0] }}
                                    </div>
                                    <div class="small-info h6 f jcc aic">
                                        <div 
                                            class="pt05 pb05 w80 border-radius bg-green green"
                                            v-if="item.ticket_status == 1">
                                            Đã tạo
                                        </div>
                                        <div 
                                            class="pt05 pb05 w80 border-radius bg-gray-1 gray"
                                            v-else-if="item.ticket_status == 2">
                                            Lưu nháp
                                        </div>
                                    </div>
                                    <router-link 
                                        :to="{ name: 'detail-ticket-view' , params : { id : item.id, typeTicket : 'importticket'} }"
                                        class="small-info p2 cursor blue">
                                        Xem chi tiết
                                    </router-link>
                                </div>
                            </div>
                        </div>

                        <!-- mobile -->
                        <div class="content-list__body mobile fw aic">
                            <div 
                                class="w100"
                                v-for="(item,index) in dataShowSelect"
                                :key="index">
                                <div class="item-person w100 border-radius mb1 pr15 pl15 pt1 pb1 bg-white-1">
                                    <div class="f jcb aic">
                                        <div class="p2 mb025">{{ item.title_import_ticket }}</div>
                                        <div class="h6 cursor blue">
                                            Xem chi tiết
                                        </div>
                                    </div>
                                    <div class="p4 mb1 blue"> #{{ item.key_ticket }}</div>
                                    <div class="h6 f jcs mb1">
                                        <div 
                                            class="pt05 pb05 pl1 pr1 border-radius bg-yellow orange"
                                            v-if="item.ticket_status == 1">
                                            Đang xử lý
                                        </div>
                                        <div 
                                            class="pt05 pb05 pl1 pr1 border-radius bg-green green"
                                            v-else-if="item.ticket_status == 2">
                                            Đã tạo
                                        </div>
                                        <div 
                                            class="pt05 pb05 pl1 pr1 border-radius bg-red red"
                                            v-if="item.ticket_status == 3">
                                            Thất bại
                                        </div>
                                    </div>
                                    <div class="f jcb aic mb05">
                                        <div class="p3 gray">Người khởi tạo</div>
                                        <div
                                            class="h6 blue">
                                            {{ dataUser.full_name }}
                                        </div>
                                    </div>
                                    <div 
                                        v-if="item.time_created_ticket"
                                        class="f jcb aic">
                                        <div class="p3 gray">Thời gian khởi tạo</div>
                                        <div
                                            class="h6">
                                            {{ item.time_created_ticket[1] }} - {{ item.time_created_ticket[0] }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-else>
                    <div class="content-list pt15 pb15">
                        <div class="content-list__header desktop aic mb15 pr15 pl15">
                            <div 
                                class="f aic w100 gray">
                                <div class="p2">
                                    STT
                                </div>
                                <div class="small-info p2 qcont">
                                Tên phiếu nhập
                                </div>
                                <div class="small-info p2 qcont">
                                Người tạo phiếu
                                </div>
                                <div class="small-info p2 qcont">
                                Thời điểm tạo phiếu
                                </div>
                                <div class="small-info p2 qcont">
                                Trạng thái phiếu
                                </div>
                                <div class="small-info p2 qcont">
                                Thao tác
                                </div>
                            </div>
                        </div>

                        <!-- desktop -->
                        <div class="content-list__body desktop fw aic">
                            <div 
                                class="w100"
                                v-for="(item,index) in dataShowSelect"
                                :key="index">
                                <div v-if="item.ticket_status == filterTicket">
                                    <div class="item-person f aic w100 border-radius mb1 pr15 pl15 pt1 pb1 bg-white-1">
                                        <div class="mr1 ml05"> {{index + 1}}. </div>
                                        <div class="small-info align-s pl05">
                                            <div class="p2 mb025">{{ item.title_import_ticket }}</div>
                                            <div class="p2 blue"> #{{ item.key_ticket }}</div>
                                        </div>
                                        <div class="small-info p4 blue">
                                            <div>
                                                <div
                                                    class="p2 blue">
                                                    {{ dataUser.full_name }}
                                                </div>
                                            </div>
                                        </div>
                                        <div 
                                            v-if="item.time_created_ticket"
                                            class="small-info p2 gray">
                                            {{ item.time_created_ticket[1] }} - {{ item.time_created_ticket[0] }}
                                        </div>
                                        <div class="small-info h6 f jcc aic">
                                            <div 
                                                class="pt05 pb05 w80 border-radius bg-green green"
                                                v-if="item.ticket_status == 1">
                                                Đã tạo
                                            </div>
                                            <div 
                                                class="pt05 pb05 w80 border-radius bg-gray-1 gray"
                                                v-else-if="item.ticket_status == 2">
                                                Lưu nháp
                                            </div>
                                        </div>
                                        <router-link 
                                            :to="{ name: 'detail-ticket-view' , params : { id : item.id, typeTicket : 'import-ticket'} }"
                                            class="small-info p2 cursor blue">
                                            Xem chi tiết
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- mobile -->
                        <div class="content-list__body mobile fw aic">
                            <div 
                                class="w100"
                                v-for="(item,index) in dataShowSelect"
                                :key="index">
                                <div v-if="item.ticket_status == filterTicket">
                                    <div class="item-person w100 border-radius mb1 pr15 pl15 pt1 pb1 bg-white-1">
                                        <div class="f jcb aic">
                                            <div class="p2 mb025">{{ item.title_import_ticket }}</div>
                                            <div class="h6 cursor blue">
                                                Xem chi tiết
                                            </div>
                                        </div>
                                        <div class="p4 mb1 blue"> #{{ item.key_ticket }}</div>
                                        <div class="h6 f jcs mb1">
                                            <div 
                                                class="pt05 pb05 pl1 pr1 border-radius bg-yellow orange"
                                                v-if="item.ticket_status == 1">
                                                Đang xử lý
                                            </div>
                                            <div 
                                                class="pt05 pb05 pl1 pr1 border-radius bg-green green"
                                                v-else-if="item.ticket_status == 2">
                                                Đã tạo
                                            </div>
                                            <div 
                                                class="pt05 pb05 pl1 pr1 border-radius bg-red red"
                                                v-if="item.ticket_status == 3">
                                                Thất bại
                                            </div>
                                        </div>
                                        <div class="f jcb aic mb05">
                                            <div class="p3 gray">Người khởi tạo</div>
                                            <div
                                                class="h6 blue">
                                                {{ dataUser.full_name }}
                                            </div>
                                        </div>
                                        <div 
                                            v-if="item.time_created_ticket"
                                            class="f jcb aic">
                                            <div class="p3 gray">Thời gian khởi tạo</div>
                                            <div
                                                class="h6">
                                                {{ item.time_created_ticket[1] }} - {{ item.time_created_ticket[0] }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <PaginationView
                @updateNumberPagination='updateNumberPagination'
                :totalPage='totalPage'></PaginationView>

            <LoadingView></LoadingView>
        </div>
</template>
 
<script>
import PaginationView from '@/components/PaginationView/PaginationView.vue'
import LoadingView from '@/components/LoadingView/LoadingView.vue'
// firebase import
import firebase from "@/firebaseInit";
import "firebase/firestore";
const db = firebase.firestore();

import { HTTP } from '../../../http-default'
import axios from 'axios'
export default {

    components : {
        PaginationView,
        LoadingView
    },

    props: {
        data1 : {
            type : Array,
            default() {
                return []
            }
        },
        keyFilter : {
            type : Array,
            default() {
                return []
            }
        }
    },
    data () {
        return {
            dataKey: ['Sản phẩm', 'Mã sản phẩm', 'Đơn vị tính', 'Đơn giá', 'Tồn kho'],
            data: [],
            dataShow: [],
            dataShowSelect: [],
            dataTicket: [],
            dataType: [],
            dataUser: {},
            listDevice : [],
            filterTicket : 100,
            wid: '',
            margin: '',
            // filter time
            timeStart : '',
            timeEnd: '',

            // pagination data prop
            totalPage : 0,
            numberSelect : 1,

            // data Item filter
            dataItemFilter: []
        }
    },
    watch: {
        '$route' : function async () {
            this.totalPage = 0,
            // this.numberSelect = 1,
            this.dataShowSelect = [],
            this.getData()
        },

        'numberSelect' : function () {
            this.panigationData()
        },

        'filterTicket' : function () {
            this.dataShowSelect = []
            var dataFilter = []
            this.numberSelect = 1

            if (this.filterTicket == 100) {
                for (let i = (this.numberSelect - 1)*5 ; i <  ((this.numberSelect - 1)*5) + 5; i++) {
                    this.dataShowSelect.push(this.dataShow[i])
                }

                this.totalPage = (this.dataShow.length - (this.dataShow.length)%5)/5 + 1
            }

            else {
                this.dataShow.forEach(item => {
                    if (item.ticket_status == this.filterTicket) {
                        dataFilter.push(item)
                    }
                })

                this.totalPage = (dataFilter.length - (dataFilter.length)%5)/5 + 1

                if (this.numberSelect == this.totalPage) {
                    for (let i = (this.numberSelect - 1)*5 ; i <  ((this.numberSelect - 1)*5) + ((dataFilter.length)%5); i++) {
                        this.dataShowSelect.push(dataFilter[i])
                    }
                } else {
                    for (let i = (this.numberSelect - 1)*5 ; i <  ((this.numberSelect - 1)*5) + 5; i++) {
                        this.dataShowSelect.push(dataFilter[i])
                    }
                }
            }
        },

        timeStart : function () {
            this.handleFilterTime()
        },

        timeEnd : function () {
            this.handleFilterTime()
        },

        watchDataShow : function () {
            this.totalPage = parseInt((this.dataShow.length - (this.dataShow.length % 5))/5 + 1)
            this.panigationData()
        }

    },

    computed : {
        watchDataShow () {
            return this.dataShow.length
        },
    },

    methods: {

        // filter time
        handleFilterTime() {
            var timeStart = this.timeStart.split('-')
            var timeEnd = this.timeEnd.split('-')
            var data = []

            for (let i = 0; i < 3; i++) {
                if (this.timeStart !== '') {
                    timeStart[i] = parseInt(timeStart[i])
                } 
                if (this.timeEnd !== '') {
                    timeEnd[i] = parseInt(timeEnd[i])
                }
            }

            console.log(timeStart, timeEnd);

            this.dataTicket.forEach(item => {
                var a = item.created_at
                var yearCreate = parseInt(a[0] + a[1] + a[2] + a[3])
                var monthCreate = parseInt(a[5] + a[6])
                var dayCreate = parseInt(a[8] + a[9])

                console.log(yearCreate, monthCreate, dayCreate)
                if (this.timeStart === '' && this.timeEnd !== '') {
                    if (yearCreate === timeEnd[0]) {
                        if (monthCreate === timeEnd[1]) {
                            if (dayCreate <= timeEnd[2]) {
                                data.push(item)
                            }
                        } else if (monthCreate < timeEnd[1]) {
                            data.push(item)
                        }
                    } else if (yearCreate < timeEnd[0] ) {
                        data.push(item)
                    }
                } else if (this.timeEnd === '' && this.timeStart !== '') {
                    if (yearCreate === timeStart[0]) {
                        if (monthCreate === timeStart[1]) {
                            if (dayCreate >= timeStart[2]) {
                                data.push(item)
                            }
                        } else if (monthCreate > timeStart[1]) {
                            data.push(item)
                        }
                    } else if (yearCreate > timeStart[0] ) {
                        data.push(item)
                    }
                } else if (this.timeStart !== '' && this.timeEnd !== '') {
                    var dataEnd = []
                    if (yearCreate === timeEnd[0]) {
                        if (monthCreate === timeEnd[1]) {
                            if (dayCreate <= timeEnd[2]) {
                                dataEnd.push(item)
                            }
                        } else if (monthCreate < timeEnd[1]) {
                            dataEnd.push(item)
                        }
                    } else if (yearCreate < timeEnd[0] ) {
                        dataEnd.push(item)
                    }

                    var dataStart = []
                    if (yearCreate === timeStart[0]) {
                        if (monthCreate === timeStart[1]) {
                            if (dayCreate >= timeStart[2]) {
                                dataStart.push(item)
                            }
                        } else if (monthCreate > timeStart[1]) {
                            dataStart.push(item)
                        }
                    } else if (yearCreate > timeStart[0] ) {
                        dataStart.push(item)
                    }

                    dataEnd.forEach(e => {
                        let status = false
                        dataStart.forEach(s => {
                            if (e.id === s.id) {
                                status = true
                            }
                        })
                        if (status) {
                            data.push(e)
                        }
                    })
                } 
                // if (this.timeStart === '' && this.timeEnd === '') {
                //     if (item['ticket_type'] == this.keyFilter[1]) {
                //         data.push(item)
                        
                //     }
                // }
            })

            this.dataShow = data
        },

        // filter keyword
        handleFilter(event) {
            var input = event.target
            var filter = input.value.toUpperCase();
            this.timeStart = ''
            this.timeEnd = ''
            // this.filterKey = filter;
            this.dataItemFilter = [];
            this.dataShow = []
            // this.personnelWait = [];
            if(!filter) {
                for(let n = 0; n < this.dataTicket.length; n++) {
                    // var time = this.dataTicket[n]['time_created_ticket'].split('T')
                    // time[1] = time[1].split('.')[0]
                    // this.dataTicket[n]['time_created_ticket'] = time
                    if (this.dataTicket[n]['ticket_type'] == this.keyFilter[1]) {
                        this.dataShow.push(this.dataTicket[n])
                        
                    }
                }
            }
            else {
                for (let i = 0; i < this.dataTicket.length; i++) {
                    var key = this.dataTicket[i].key_ticket;
                    var txtValue = key
                    if (txtValue.toUpperCase().indexOf(filter) > -1 && this.dataTicket[i]['ticket_type'] == this.keyFilter[1]) {
                        this.dataItemFilter.push(this.dataTicket[i])
                    }
                }
                this.dataShow = this.dataItemFilter
                // for(let i = 0; i < this.personnelWait.length ; i++) {
                //     this.personnelActive.push(this.personnelWait[i]);
                // }
            }
        },

        panigationData () {
            this.dataShowSelect = []
            if (this.$route.params.filter == 'phiếu nhập hàng thông thường') {
                if (this.filterTicket == 100) {
                    if (this.numberSelect == this.totalPage) {
                        for (let i = (this.numberSelect - 1)*5 ; i <  ((this.numberSelect - 1)*5) + ((this.dataShow.length)%5); i++) {
                            this.dataShowSelect.push(this.dataShow[i])
                        }
                    } else {
                        for (let i = (this.numberSelect - 1)*5 ; i <  ((this.numberSelect - 1)*5) + 5; i++) {
                            this.dataShowSelect.push(this.dataShow[i])
                        }
                    }
                }
                
                else {
                    var dataFilter = []
                    this.dataShow.forEach(item => {
                        if (item.ticket_status == this.filterTicket) {
                            dataFilter.push(item)
                        }
                    })

                    this.totalPage = (dataFilter.length - (dataFilter.length)%5)/5 + 1

                    if (this.numberSelect == this.totalPage) {
                        for (let i = (this.numberSelect - 1)*5 ; i <  ((this.numberSelect - 1)*5) + ((dataFilter.length)%5); i++) {
                            this.dataShowSelect.push(dataFilter[i])
                        }
                    } else {
                        for (let i = (this.numberSelect - 1)*5 ; i <  ((this.numberSelect - 1)*5) + 5; i++) {
                            this.dataShowSelect.push(dataFilter[i])
                        }
                    }
                }
            } else {
                if (this.numberSelect == this.totalPage) {
                    for (let i = (this.numberSelect - 1)*5 ; i <  ((this.numberSelect - 1)*5) + ((this.dataShow.length)%5); i++) {
                        this.dataShowSelect.push(this.dataShow[i])
                    }
                } else {
                    for (let i = (this.numberSelect - 1)*5 ; i <  ((this.numberSelect - 1)*5) + 5; i++) {
                        this.dataShowSelect.push(this.dataShow[i])
                    }
                }
            }
        },

        // update Number filter seclect
        updateNumberPagination (value) {
            this.numberSelect = value
        },

        // filter ticket
        handleFilterTicket (value) {
            this.filterTicket = parseInt(value)
        },

        handleUpdate (event) {
            var button = event.target.parentElement.parentElement
            var poup = button.getElementsByClassName('poup-update')[0]
            var clearActive = document.getElementsByClassName('active-popup')
            if (clearActive.length != 0 && clearActive[0] != poup) {
                clearActive[0].classList.remove('active-popup')
            }
            poup.classList.toggle('active-popup')
        },

        // firebase-update
        handleCreateImportTicket () {
            var popup = document.getElementsByClassName('popup-loading')[0]
            popup.classList.toggle('f')
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            let path = this
            db.collection('product-scan').add({
                type : 'Product-ticket-scan'
                })
                .then(function(res) {
                    const token = localStorage.getItem('user')
                    const axiosConfig = {
                        headers : {
                            'Content-Type': 'application/json',
                            'Authorization': 'Token '+ token,
                        }
                    }

                    const dataDoc = JSON.stringify({
                        "key_doc_firebase": res.id,
                    });

                    axios.put('https://api.nmeco.xyz/v1/api/account/' + path.dataUser.id, dataDoc, axiosConfig)
                    .then(() => {
                        HTTP.get('getdevice/')
                        .then((res) => {
                            
                            
                            var listDevice = []

                            res.data.device.forEach(item => {
                                listDevice.push(item.sessionId)
                            })

                            var data = JSON.stringify({
                                "list_session" : listDevice
                            })

                            HTTP.put('disconnect/', data)
                            .then(() => {
                                path.$router.push({name: 'importticket-view'})
                            })

                        })
                    })
                })
        },

        async getData () {
            if (this.$route.params.menuitem != 'quan-ly-stock') {
                for (let i = 0; i < this.data1.length; i++) {
                    if (this.data1[i].path == '/' + this.$route.params.menuitem && this.data1[i].path != '/quan-ly-stock') {
                        this.data = this.data1[i]
                        HTTP.get(this.data1[i].props.urlapi)
                        .then((res) => {
                            this.dataShow = []
                            this.dataTicket = res.data.results

                            for(let n = 0; n < this.dataTicket.length; n++) {
                                var time = this.dataTicket[n]['time_created_ticket'].split('T')
                                time[1] = time[1].split('.')[0]
                                this.dataTicket[n]['time_created_ticket'] = time
                                if (this.dataTicket[n]['ticket_type'] == this.keyFilter[1]) {
                                    this.dataShow.push(this.dataTicket[n])
                                    
                                }
                            }

                            this.numberSelect = 1,

                            this.totalPage = parseInt((this.dataShow.length - (this.dataShow.length % 5))/5 + 1)
                            if (this.numberSelect == this.totalPage) {
                                for (let i = (this.numberSelect - 1)*5 ; i <  ((this.numberSelect - 1)*5) + ((this.dataShow.length)%5); i++) {
                                    this.dataShowSelect.push(this.dataShow[i])
                                }
                            } else {
                                for (let i = (this.numberSelect - 1)*5 ; i <  ((this.numberSelect - 1)*5) + 5; i++) {
                                    this.dataShowSelect.push(this.dataShow[i])
                                }
                            }
                        })
                        // eslint-disable-next-line vue/no-mutating-props
                    }
                }
            }
            else {
                HTTP.get('v1/api/product/')
                .then((res) => {
                    this.dataShow = res.data
                    for (let i = 0; i < this.dataShow.length; i++) {
                        this.dataShow[i].price_product = this.dataShow[i].price_product.toLocaleString('it-IT', {style : 'currency', currency : 'VND'});
                    }

                    this.numberSelect = 1,

                    this.totalPage = parseInt((this.dataShow.length - (this.dataShow.length % 5))/5 + 1)
                    if (this.numberSelect == this.totalPage) {
                        for (let i = (this.numberSelect - 1)*5 ; i <  ((this.numberSelect - 1)*5) + ((this.dataShow.length)%5); i++) {
                            this.dataShowSelect.push(this.dataShow[i])
                        }
                    } else {
                        for (let i = (this.numberSelect - 1)*5 ; i <  ((this.numberSelect - 1)*5) + 5; i++) {
                            this.dataShowSelect.push(this.dataShow[i])
                        }
                    }
                })
            }
        },

        getDataUser () {
            HTTP.get('v1/api/account/')
            .then((res) => {
                this.dataUser = res.data[0]
            })

            HTTP.get('v1/api/ticketstatus/')
            .then((res) => {
                this.dataType = res.data.results
            })
        }
    },
    mounted() {
        this.getData()
        this.getDataUser()
    },

}
</script>

<style scoped lang="scss">
    @import 'StockBody.scss';
</style>