@<template>
    <div class="list-product__container">
        <div 
            v-if="productDemo"
            class="product-demo w100 f jcb aic p4 pt1 pb1 border-bottom">
            <div class="large-info f aic">
                <div class="product-demo__item product-demo__img"></div>
                <div class="f fdc ml05" style="width: 65%">
                    <div class="product-demo__item product-demo__title mb05"></div>
                    <div class="product-demo__item product-demo__key"></div>
                </div>
            </div>
            <div class="product-demo__item product-demo__quantity"></div>
            <div class="product-demo__item product-demo__price"></div>
            <div class="product-demo__item product-demo__total"></div>
        </div>
        <div 
            v-if="productDemo"
            class="product-demo w100 f jcb aic p4 pt1 pb1 border-bottom">
            <div class="large-info f aic">
                <div class="product-demo__item product-demo__img"></div>
                <div class="f fdc ml05" style="width: 65%">
                    <div class="product-demo__item product-demo__title mb05"></div>
                    <div class="product-demo__item product-demo__key"></div>
                </div>
            </div>
            <div class="product-demo__item product-demo__quantity"></div>
            <div class="product-demo__item product-demo__price"></div>
            <div class="product-demo__item product-demo__total"></div>
        </div>
        <div 
            v-if="productDemo"
            class="product-demo w100 f jcb aic p4 pt1 pb1 border-bottom">
            <div class="large-info f aic">
                <div class="product-demo__item product-demo__img"></div>
                <div class="f fdc ml05" style="width: 65%">
                    <div class="product-demo__item product-demo__title mb05"></div>
                    <div class="product-demo__item product-demo__key"></div>
                </div>
            </div>
            <div class="product-demo__item product-demo__quantity"></div>
            <div class="product-demo__item product-demo__price"></div>
            <div class="product-demo__item product-demo__total"></div>
        </div>
        <div 
            v-for="(item, index) in subOrderSelect"
            :key="index">
            <div class="product-item w100 f fdc p4 pt1 pb1 border-bottom">
                <div>
                    <div>
                        <div 
                            v-if="item.product"
                            class="desktop w100 aic">
                            <div class="large-info f aic">
                                <div class="mr05">
                                    <img :src="item.product.image_product" class="border-radius">
                                </div>
                                <div class="f fdc jcb p4">
                                    <div class="mb025"> {{item.product.title_product}} </div>
                                    <div class="gray"> #{{item.product.id_product_sub}} </div>
                                </div>
                            </div>
                            <div class="small-info gray">
                                {{ item.quantity_order }}
                            </div>
                            <div class="small-info gray">
                                {{ formatMoney(item.product.price_product) }}
                            </div>
                            <div class="small-info h6">
                                {{ totalPriceProduct(item.product.price_product, item.quantity_order) }}
                            </div>
                        </div>

                        <div 
                            v-if="item.product"
                            class="mobile w100 aic">
                            <div class="w100 f aic mb1"> 
                                <div class="mr05">
                                    <img :src="item.product.image_product" class="border-radius">
                                </div>
                                <div class="f fdc jcb">
                                    <div class="mb025 h6"> {{item.product.title_product}} </div>
                                    <div class="p4 gray"> #{{item.product.id_product_sub}} </div>
                                </div>
                            </div>
                            <div class="f jcb aic mb05">
                                <div class="p4 gray">Số lượng</div>
                                <div class="h6">{{ item.quantity_order }}</div>
                            </div>
                            <div class="f jcb aic mb05">
                                <div class="p4 gray">Đơn giá</div>
                                <div class="h6">{{ formatMoney(item.product.price_product) }}</div>
                            </div>
                            <div class="f jcb aic mb05">
                                <div class="p4 gray">Thành tiền</div>
                                <div class="h6">{{ formatMoney(item.product.price_product*item.quantity_order) }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { HTTP } from '../../../http-default'

export default {
    props : {
        dataSubOrder : {
            type : Number
        }
    },

    data () {
        return {
            subOrder : [],
            subOrderSelect : [],
            productDemo : true
        }
    },

    methods : {
        formatMoney(value) {
            value = value.toLocaleString('it-IT', {style : 'currency', currency : 'VND'})
            return value
        },

        totalPriceProduct(price, quantity) {
            var total = parseInt(price) * parseInt(quantity)
            return this.formatMoney(total)
        },

        getData () {
            HTTP.get('/v1/api/suborder/')
            .then((res) => {
                this.subOrder = res.data.results
                this.productDemo = false
                for (let i = 0; i < this.subOrder.length; i++) {
                    if (this.subOrder[i].order) {
                        if (this.dataSubOrder == this.subOrder[i].order.id) {
                            // this.listProduct[i].price_product = this.listProduct[i].price_product.toLocaleString('it-IT', {style : 'currency', currency : 'VND'});
                            this.subOrderSelect.push(this.subOrder[i])
                        }
                    }
                }
            })
        }
    },

    created () {
        this.getData()
    }
}
</script>

<style scoped lang='scss'>
    @import 'ListProduct.scss';
</style>