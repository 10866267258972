@<template>
    <div class="desktop sidebar__container">
        <div class="sidebar pr1 pt15">
            <div 
                v-for="(item, index) in dataSideBar"
                :key="index">
                <router-link 
                    v-if="item.name === 'stock'"
                    :to="{ name: 'menu-stock-view', params: { sidebaritem : 'quan-ly-stock' , menuitem : 'quan-ly-stock' } }">
                    <div v-if="item.name != 'account' && item.name != 'organization' && item.name != 'package' && item.name != 'consignment'" >
                        <div
                            class="sidebar__item f aic pl2 pt075 pb075 bg-select"
                            v-if="item.path == '/' + $route.params.sidebaritem">
                            <div class="icon mr15" :class="item.icon + '-1'"></div>
                            <div class="sidebar__item-title p5 gray text-select upcase"> {{ item.props.titlecontent }} </div>
                        </div>
                        <div
                            class="sidebar__item f aic pl2 pt075 pb075"
                            v-else>
                            <div class="icon mr15" :class="item.icon + '-' + 0"></div>
                            <div class="sidebar__item-title p5 gray upcase"> {{ item.props.titlecontent }} </div>
                        </div>
                    </div>
                </router-link>
                <router-link 
                    v-else
                    :to="item.path">
                    <div v-if="item.name != 'account' && item.name != 'organization' && item.name != 'package' && item.name != 'consignment'" >
                        <div
                            class="sidebar__item f aic pl2 pt075 pb075 bg-select"
                            v-if="item.path == '/' + $route.params.sidebaritem">
                            <div class="icon mr15" :class="item.icon + '-1'"></div>
                            <div class="sidebar__item-title p5 gray text-select upcase"> {{ item.props.titlecontent }} </div>
                        </div>
                        <div
                            class="sidebar__item f aic pl2 pt075 pb075"
                            v-else>
                            <div class="icon mr15" :class="item.icon + '-' + 0"></div>
                            <div class="sidebar__item-title p5 gray upcase"> {{ item.props.titlecontent }} </div>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props : {
        dataSideBar : {
            type : Array,
            default() {
                return []
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    @import "SideBar.scss";
</style>