@<template>
    <div class="popup aic jcc popup-create-package">

        <!-- Order havent package -->
        <div 
            v-if="dataOrder.package == null"
            class="create-package__container border-radius bg-gray-1">
            <div class="create-package__body f">
                <div 
                     v-if="!isMobile()"
                    class="create-package__body-left pt1 pb1 pl1 pr1 bg-white-1">
                    <div class="body-left__header h5 text-red-linear mb1">
                        Thông tin sản phẩm
                    </div>
                    <div class="f jcb aic mb05">
                        <div class="h6">Thiết bị đã đồng bộ</div>
                        <div 
                            @click="handleTutorial"
                            class="p4 blue cursor">Hướng dẫn</div>
                    </div>
                    
                    <div class="list-device">
                        <!-- No-data-device -->
                        <div    
                            v-if="listDevice.length == 0"
                            class="body-left__body">
                                <div class="p4 mb1 gray">Chưa có dữ liệu</div>
                                <div class="f ais  pt1 pb1 pl1 pr1 border-radius border-yellow bg-yellow">
                                    <div class="icon icon--warning"></div>
                                    <div class="p4 ml1 orange">Bạn cần đồng bộ tài khoản của bạn với ít nhất 01 thiết bị di động (với camera sẵn sàng hoạt động) để tiến hành quét mã</div>
                                </div> 
                        </div>
                        <!-- Have-data-device -->
                        <div 
                            v-else
                            class="body-left__body">
                            <div 
                                v-for="(item, index) in listDevice"
                                :key="index">
                                <div v-if="item.connect != null && item.sessionId != sessionMyDevice">
                                    <div v-if="item.connect.response">
                                        <div class="p6 mb05 green">Đang kết nối</div>
                                        <div class="device-connection">
                                            <div>
                                                <div 
                                                    class="device-item f jcb aic pt1 pb1 pl1 pr1 mb05 w100 border-green border-radius bg-gray-1">
                                                    <div class="p4">{{ item.name }} - <span class="gray">abcxyz</span></div>
                                                    <div 
                                                        @click="handleDisconnection(item.sessionId)"
                                                        class="p6 red-1 cursor">Hủy bỏ
                                                    </div>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="p6 mb05 blue">Đang hoạt động</div>
                                <div class="disconnect-device">
                                    <div 
                                        v-for="(item, index) in listDevice"
                                        :key="index">
                                        <div 
                                            v-if="item.sessionId != sessionMyDevice && item.connect == null"
                                            class="device-item f jcb aic pt1 pb1 pl1 pr1 mb05 w100 border-radius bg-gray-1">
                                            <div class="p4">{{ item.name }} - <span class="gray">abcxyz</span></div>
                                            <div 
                                                @click="handleConnectionDevice(item.sessionId)"
                                                class="p6 blue cursor">Sử dụng
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="create-package__body-right pt1 pb1 pl1 pr1">
                    <div class="body-right__header f jcb aic mb1">
                        <div class="h5 gray">Sản phẩm quét được</div>
                        <div 
                            v-if="!isMobile()"
                            class="p4"> {{ this.productScan.length }} sản phẩm</div>
                        <div 
                            v-if="isMobile()"
                            @click="handleOpenCamera()"
                            class="cemera-scan f aic">
                            <div class="icon--camera"></div>
                            <div class="p6 gray ml05">Quét mã</div>
                        </div>
                    </div>
                    <div class="create-package__product">
                        <ProductScanView 
                            @updateError='updateError'
                            @updateQuantity='updateQuantity'
                            @updateProductScan='updateProductScan'
                            :account='account'
                            :dataOrder='dataOrder'
                            :subOrderSelect="subOrderSelect"></ProductScanView>
                    </div>
                </div>
            </div>
            <div class="create-package__footer w100">
                <div v-if="errorProduct" class="w100 align-c pt05 pb05 bg-red">
                    <div class="text-red-linear p6">Bạn cần loại bỏ những sản phẩm không thuộc đơn hàng để tiếp tục</div>
                </div>
                <div class="create-package__action w100 f aic jce pt1 pb1 pl1 pr1 border-top bg-gray-1">
                    <div 
                        class="h5 align-c border border-radius pt1 pb1 mr1 cursor"
                        @click="handlePackage">Hủy bỏ</div>
                    <div
                        v-if="productScan.length == 0 || errorProduct == true" 
                        class="h5 align-c border-radius pt1 pb1 cursor bg-red white">
                            Tiếp tục
                    </div>
                    <div
                        v-else
                        @click="handleConfirmProduct"
                        class="h5 align-c border-radius pt1 pb1 cursor bg-red-1 white">
                            Tiếp tục
                    </div>
                </div>
            </div>
        </div>

        <!-- Order have package -->
        <div 
            v-else
            class="info-package__container pt1 pb1 pl1 pr1 border-radius bg-gray-1">
            <div class="confirm-product__body f">
                <div class="confirm-product__body-left">
                    <div class="h5 mb1 text-red-linear">Xem thông tin sản phẩm</div>
                    <div class="list-product">
                        <div 
                            v-for="(item, index) in productImport"
                            :key="index">
                            <div 
                                v-if="item.id == idProduct"
                                @click="handleSelect(item.id)"
                                class="product-item pt1 pb1 pl1 pr1 mb05 border-radius cursor border-red bg-white-1">
                                <div>
                                    <div class="f aic">
                                        <div class="mr05">
                                            <img :src="item.image_product" alt="">
                                        </div>
                                        <div class="f fdc jcb">
                                            <div class="mb025 h6"> {{item.title_product}} </div>
                                            <div class="p4 gray"> Hàng có sẵn: {{item.quantity_in_stock}} </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div 
                                v-else
                                @click="handleSelect(item.id)"
                                class="product-item pt1 pb1 pl1 pr1 mb05 border-radius cursor bg-white-1">
                                <div>
                                    <div class="f aic">
                                        <div class="mr05">
                                            <img :src="item.image_product" alt="">
                                        </div>
                                        <div class="f fdc jcb">
                                            <div class="mb025 h6"> {{item.title_product}} </div>
                                            <div class="p4 gray"> Hàng có sẵn: {{item.quantity_in_stock}} </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="confirm-product__body-right ml1">
                    <div class="h5 mb1 gray">Chi tiết thông tin sản phẩm</div>
                    <div 
                        v-for="(item, index) in productImport"
                        :key="index">
                        <div 
                            v-if="item.id == idProduct"
                            class="product-item__container pt1 pb1 pl1 pr1 mb1 border-radius bg-white-1">
                            <div>
                                <div class="f aic mb05">
                                    <div class="mr05">
                                        <img :src="item.image_product" alt="">
                                    </div>
                                    <div class="f fdc jcb">
                                        <div class="mb025 h6 text-red-linear"> {{item.title_product}} </div>
                                        <div class="p4 gray"> Hàng có sẵn: {{item.quantity_in_stock}} </div>
                                    </div>
                                </div>
                            </div>
                            <div class="product-item__info mb1 border-bottom">
                                <div class="f jcb aic mb025">
                                    <div class="p4 gray">Mã sản phẩm</div>
                                    <div class="h6 blue"> #{{item.id}} </div>
                                </div>
                                <div class="f jcb aic mb025">
                                    <div class="p4 gray">Mã lô hàng</div>
                                    <div class="h6 blue"> #{{item.id}} </div>
                                </div>
                                <div class="f jcb aic mb025">
                                    <div class="p4 gray">Vị trí lô</div>
                                    <div class="p4"> Khu A- Kệ B- Tầng C- Hàng D </div>
                                </div>
                                <div class="f jcb aic mb025">
                                    <div class="p4 gray">Nhà cung cấp</div>
                                    <div class="p4"> The A Corp </div>
                                </div>
                                <div class="f jcb aic mb025">
                                    <div class="p4 gray">Ngày sản xuất</div>
                                    <div class="p4"> dd/mm/yy </div>
                                </div>
                                <div class="f jcb aic mb025">
                                    <div class="p4 gray">Hạn sử dụng</div>
                                    <div class="p4"> dd/mm/yy </div>
                                </div>
                                <div class="f jcb aic mb025">
                                    <div class="p4 gray">Đơn giá</div>
                                    <div class="p4 text-red-linear"> {{formatMoney(item.price_product)}} </div>
                                </div>
                            </div>
                            <div class="product-item__quantity f jcb aic">
                                <div class="p4">Số lượng thực xuất</div>
                                <div class="h6"> {{item.total_quantity}} </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="confirm-product__action w100 f aic jcb pt1 pb1 pl1 pr1 border-top bg-gray-1">
                <div class="p4 w50">
                    Tổng giá trị kiện hàng: <span class="h6 text-red-linear"> {{total_price}} </span>
                </div>
                <div 
                    @click="handlePackage"
                    class="confirm-product__btn h5 align-c border border-radius pt1 pb1 cursor">Hủy bỏ</div>
            </div>
        </div>

        <QRcodeScanView 
            @handleClose='handleClose'
            v-if="cameraScan"/>

        <TutorialConnectView></TutorialConnectView>

        <ConfirmProductView 
            :productImport='TestData'
            :productScan='productScan'
            :dataOrder='dataOrder'
            :title_export_ticket='title_export_ticket'
            :note_ticket="note_ticket"
            @updateData='updateData'
            @updatePackageParent='updatePackageParent'
            @updateDataTest='updateDataTest'
            ></ConfirmProductView>
    </div>  
</template>

<script>
import ProductScanView from '@/components/ExportticketView/CreatePackageView/ProductScanView/ProductScanView.vue'
import ConfirmProductView from '@/components/ExportticketView/CreatePackageView/ConfirmProductView/ConfirmProductView.vue'
import TutorialConnectView from '@/components/ImportTicketView/CreatePackageView/TutorialConnectView/TutorialConnectView.vue'
import QRcodeScanView from '@/components/QRcodeScanView/QRcodeScanView.vue'

import { HTTP } from '@/http-default'
import axios from 'axios'

export default {
    components : {
        ProductScanView,
        ConfirmProductView,
        TutorialConnectView,
        QRcodeScanView
    },

    props : {
        // dataOrder : {
        //     type : Object,
        //     default () {
        //         return {}
        //     }
        // },
        account : {
            type : Object,
            default () {
                return {}
            }
        },
        note_ticket : String,
        title_export_ticket : String
    },

    data() {
        return {
            idProduct : 1,
            errorProduct : false,
            cameraScan : false,
            dataOrder: {},
            // data
            listProduct : [],
            listPackage: [],
            selectPackage : [],
            packageOrder : [],
            productOrder : [],
            productScan : [],
            NewSubPackage : [],
            subOrder : [],
            subOrderSelect : [],
            sessionMyDevice : '',

            productImport : [],
            packageImport: [],

            // device
            listDevice : [],

            // price total package
            total_price : 0,

            // test data
            TestData : []
        }
    },
  
    methods: {
        // formatMoney
        formatMoney(value) {
            value = value.toLocaleString('it-IT', {style : 'currency', currency : 'VND'})
            return value
        },

        handleOpenCamera () {
            this.cameraScan = true
            var container = document.getElementsByClassName('create-package__container')[0]
            container.classList.toggle('hide')
        },

        handleClose () {
            this.cameraScan = false
            var container = document.getElementsByClassName('create-package__container')[0]
            container.classList.toggle('hide')
        },

        isMobile () {
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        },

        // update key package Parent
        updatePackageParent (value) {
            this.$emit('updatePackageKey', value)
        },

        // update Error product scan
        updateError (value) {
            this.errorProduct = value
        },

        // updateData
        updateData () {
            this.idProduct = this.productImport[0].id
            this.productImport = [],
            this.selectPackage = [],
            this.subOrderSelect=[]

            this.getData()
            this.$emit('getDataAll')
        },

        // update product scan
        updateProductScan (value) {
            this.productScan = value
        },

        // update quantity 
        updateQuantity (value) {
            this.productImport = value
        },

        handleSelect (id) {
            this.idProduct = id
        },

        handleConnectionDevice (sessionId) {
            const token = localStorage.getItem('user')
            const axiosConfig = {
                headers : {
                    'Content-Type': 'application/json',
                    'Authorization': 'Token '+ token,
                },
                credentials: 'include'
                
            }
            const dataDevice = JSON.stringify({
                "sessionId_req" : this.$cookies.get('sessionId'),
                "sessionId_res" : sessionId
            });

            
            axios.post('https://api.nmeco.xyz/getdevice/', dataDevice, axiosConfig, {withCredentials: true})
        },

        handleDisconnection (sessionId) {
            var sessionid = []
            sessionid.push(sessionId)
            var data = JSON.stringify({
                "list_session" : sessionid
            })
            HTTP.put('disconnect/', data)
            // .then(() => {
            //     setInterval(this.getData(), 5000)
            // })
        },

        handlePackage () {
            var popup = document.getElementsByClassName('popup-create-package')[0]
            popup.classList.toggle('active-popup')
        },

        handleTutorial () {
            var tutorial = document.getElementsByClassName('tutorial-connect__container')[0]
            tutorial.classList.toggle('active')
            var popup = document.getElementsByClassName('create-package__container')[0]
            popup.classList.toggle('hide')
        },

        // update data Test
        updateDataTest () {
            this.TestData = []
        },

        handleConfirmProduct () {
            this.TestData = this.productImport
            var createPackage = document.getElementsByClassName('create-package__container')[0]
            createPackage.classList.toggle('hide')
            var confirmProduct = document.getElementsByClassName('confirm-product__container')[0]
            confirmProduct.classList.toggle('active')
        },

        async getData () {
            this.sessionMyDevice = this.$cookies.get('sessionId')
            HTTP.get('/v1/api/suborder/')
            .then((res) => {
                this.subOrder = res.data.results
                for (let i = 0; i < this.subOrder.length; i++) {
                    if (this.subOrder[i].order != null) {
                        if (this.dataOrder.id == this.subOrder[i].order.id) {
                            // this.listProduct[i].price_product = this.listProduct[i].price_product.toLocaleString('it-IT', {style : 'currency', currency : 'VND'});
                            this.subOrderSelect.push(this.subOrder[i])
                        }
                    }
                }
            })

            HTTP.get('v1/api/order/' + this.$route.params.id + '/')
            .then((res) => {
                this.dataOrder = res.data
                if (this.dataOrder.package != null) {
                    this.dataOrder.package.total_price = this.dataOrder.package.total_price.toLocaleString('it-IT', {style : 'currency', currency : 'VND'});
                    HTTP.get('v1/api/package/') 
                    .then((res) => {
                        this.listPackage = res.data
                        for (let i = 0; i < this.listPackage.length; i++) {
                            if (this.listPackage[i].package_parent == this.dataOrder.package.id)
                                this.selectPackage.push(this.listPackage[i])
                        }

                        HTTP.get('v1/api/product/')
                        .then((res) => {
                            this.productImport =[]
                            this.listProduct = res.data
                            for (let k = 0; k < this.listProduct.length; k++) {
                                this.selectPackage.forEach(item => {
                                    if (item.product == this.listProduct[k].id) {
                                        // this.listProduct[k].price_product = this.listProduct[k].price_product.toLocaleString('it-IT', {style : 'currency', currency : 'VND'});
                                        this.listProduct[k].total_price = item.total_price
                                        this.listProduct[k].total_quantity = item.total_quantity
                                        this.productImport.push(this.listProduct[k]) 
                                    }
                                })
                            }

                            this.$emit('updateProductImport', this.productImport)
                            this.idProduct = this.productImport[0].id
                            this.productImport.forEach(item => {
                                this.total_price = this.total_price + item.total_price
                            })

                            this.total_price =this.total_price.toLocaleString('it-IT', {style : 'currency', currency : 'VND'});
                        })
                    })
                }
            })
        },

        getDataDevice () {
            setInterval(() => {
                HTTP.get('getdevice/')
                .then((res) => {
                    this.listDevice = res.data.device
                    this.listDevice.forEach(item => {
                        var nameDevice = item.name.split('(')
                        var b = nameDevice[1].split(')')
                        item.name = b[0]
                    })

                })
            }, 2000)
        }
    },

    created () { 
        this.getData();
        this.getDataDevice ()
    } 
}
</script>

<style scoped lang="scss">
    @import 'CreatePackage.scss';
</style>