@<template>
    <div class="product-item">


        <!-- status = 'đã tạo phiếu' -->
        <div v-if="$route.params.typeTicket == 'import-ticket'">
            <div 
                v-for="(item, index) in listConsignment"
                :key="index">
                <div 
                    v-for="(value, key) in listProduct"
                    :key="key">
                    <div 
                        v-if="item.product == value.id"
                        class="product-item__container pt1 pb1 pl1 pr1 mb1 border-radius bg-gray-3">
                        <div>
                            <div class="large-info f aic mb1">
                                <div class="mr05">
                                    <img class="border-radius" :src="value.image_product" alt="">
                                </div>
                                <div class="f fdc jcb">
                                    <div class="mb025 h6 text-red-linear"> {{value.title_product}} </div>
                                    <div class="p4 gray"> #{{value.id_product_sub}} </div>
                                </div>
                            </div>
                        </div>

                        <div >
                            <div class="large-info f aic mb1 w100">
                                <div class="product-item__info w100">
                                    <div class="f jcb aic mb025">
                                        <div class="p4 gray">Mã sản phẩm</div>
                                        <div class="h6 blue"> #{{ value.id_product_sub }} </div>
                                    </div>
                                    <div class="f jcb aic mb025">
                                        <div class="p4 gray">Nhà cung cấp</div>
                                        <div class="p4"> {{ value.organization }} </div>
                                    </div>
                                    <div class="f jcb aic w100">
                                        <div class="p4 gray">Danh mục sản phẩm</div>
                                        <div 
                                            v-for="(category, y) in categoryproduct"
                                            :key="y"> 
                                                <div 
                                                    v-if="category.id == value.category[0]"
                                                    class="p4">
                                                    {{ category.category_name }}
                                                </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                        <div class="product-item__quantity f jcb aic w100">
                            <div>
                                <div class="f jcb aic">
                                    <div class="w50 mr05">
                                        <label class="p4" for="">Đơn giá nhập</label>
                                        <input class="w100 mt05" type="text" v-model="item.price_product">
                                    </div>
                                    <div class="w50">
                                        <label class="p4" for="">Số lượng nhập</label>
                                        <input class="w100 mt05" type="text" v-model="item.quantity_in_consignment">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- status = 'tạo nháp' và tạo phiếu mới -->
        <div v-else>
            <div 
                v-for="(item, index) in productImport"
                :key="index">
                <div
                    v-if="productImport.length != 0" 
                    class="product-item__container pt1 pb1 pl1 pr1 mb1 border-radius bg-gray-3">
                    <div>
                        <div class="large-info f aic mb1">
                            <div class="mr05">
                                <img class="border-radius" :src="item.image_product" alt="">
                            </div>
                            <div class="f fdc jcb">
                                <div class="mb025 h6 text-red-linear"> {{item.title_product}} </div>
                                <div class="p4 gray"> #{{item.id_product_sub}} </div>
                            </div>
                        </div>
                    </div>

                    <div >
                        <div class="large-info f aic mb025 w100">
                            <div class="product-item__info w100">
                                <div class="f jcb aic mb025">
                                    <div class="p4 gray">Mã sản phẩm</div>
                                    <div class="h6 blue"> #{{ item.id }} </div>
                                </div>
                                <div class="f jcb aic mb025">
                                    <div class="p4 gray">Nhà cung cấp</div>
                                    <div class="p4"> {{ item.organization }} </div>
                                </div>
                                <div
                                    v-for="(category, y) in categoryproduct" 
                                    :key="y + 2"
                                    class="w100">
                                    <div 
                                        class="f jcb aic w100"
                                        v-if="category.id == item.category[0]">
                                        <div class="p4 gray">Danh mục sản phẩm</div>
                                        <div> 
                                            {{ category.category_name }}  
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                
                    <div class="product-item__quantity f jcb aic w100">
                        <div class="w100">
                            <div
                                class="f jcb aic w100 mb025">
                                <div class="p4 gray">Đơn giá nhập</div>
                                <div class="h6">{{ formatMoney(item.total_price_consignment/item.total_quantity_consignment) }}</div>
                            </div>
                            <div
                                class="f jcb aic">
                                <div class="p4 gray">Số lượng nhập</div>
                                <div class="h6">{{ item.total_quantity_consignment }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { HTTP } from '../../../http-default'

export default {
    props : {
        productImport: {
            type : Array,
            default () {
                return []
            }
        },
        listConsignment: {
            type : Array,
            default () {
                return []
            }
        },
        listProduct: {
            type : Array,
            default () {
                return [] 
            }
        }
    },

    data () {
        return {
            productOrder : [],
            subOrder : [],
            subOrderSelect : [],
            categoryproduct : []
        }
    },

    methods : {
        formatMoney(value) {
            value = value.toLocaleString('it-IT', {style : 'currency', currency : 'VND'})
            return value
        },

        getData () {
            HTTP.get('v1/api/categoryproduct/')
            .then((res) => {
                this.categoryproduct = res.data.results
            })
        }
    },

    created () {
        this.getData()
    }
}
</script>

<style lang='scss' scoped>
    @import 'ProductItem.scss';
</style>