@<template>
    <div class="popup popup-specifications jcc aic">
        <div class="package-specifications__contaienr pt1 pb1 pl1 pr1 border-radius bg-white-1">
            <div class="specifications__header mb1">
                <div class="h5 mb05 text-red-linear">Thông số kiện hàng</div>
                <div class="p6 gray">Vui lòng hoàn thiện thông tin dưới đây</div>
            </div>
            <div class="specifications__form mb15">
                <div class="specifications__form-weith mb1">
                    <div class="h5 gray mb05">Khối lượng <span class="text-red-linear">*</span></div>
                    <label class="mb05">Cân nặng</label>
                    <div class="f jcb aic">
                        <input      
                            v-model="dataInput.weight"
                            class="w50"
                            type="number" 
                            placeholder="Nhập cân nặng">
                        <div class="relative f fdc w50 ml05">
                            <div 
                                @click="handleListType"
                                class="f jcb aic pt05 pb05 pl075 pr075 border border-radius-s w100">
                                <div class="p3"> {{this.weight_measure}}</div>
                                <div class="border-left">
                                    <div class="icon icon--arrow"></div>
                                </div>
                            </div>
                            <div class="type-date__contaienr absolute hide fdc w100 border border-radius-s bg-white-1 pb05">
                                <div 
                                    v-for="(item, index) in weightmeasure"
                                    :key="index">
                                    <div 
                                        v-if="item != weight_measure"
                                        class="type-date__item pt05 pb05 pl15 pr15 p3 cursor"
                                        @click="handleSelectType(item)">
                                        {{ item }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="specifications__form-size mb1">
                    <div class="h5 gray mb05">Kích thước</div>
                    <div class="size-input f jcb aic">
                        <div class="f fdc">
                            <label class="mb05">Chiều dài</label>
                            <input 
                                v-model="dataInput.height"
                                type="text" 
                                placeholder="Nhập chiều dài">
                        </div>
                        <div class="f fdc">
                            <label class="mb05 ml05">Chiều rộng</label>
                            <input 
                                v-model="dataInput.width"
                                type="text" 
                                placeholder="Nhập chiều rộng">
                        </div>
                        <div class="f fdc">
                            <label class="mb05 ml05">Chiều cao</label>
                            <input 
                                v-model="dataInput.length"
                                type="text" 
                                placeholder="Nhập chiều cao">
                        </div>
                    </div>
                </div>

                <div class="specifications__form-img mb1">
                    <div class="h5 gray mb05">Ảnh kiện hàng</div>
                    <div>
                        <div class="w100 f mt05 aic pt1 pb1 pl1 pr1 border-radius bg-white-1" style="border: 1px dashed #D9DDE8;">
                            <div
                                @click="uploadImg" 
                                class="h7 pt05 pb05 pr1 pl1 mr1 border-radius border bg-blue-1 cursor">
                                <input 
                                    class="hide"
                                    type="file" 
                                    accept="image/*" 
                                    ref="file"
                                    @change="uploadImage($event)" 
                                    id="file-input">
                                <i class="white">Tải ảnh lên</i>
                            </div>

                            <div>
                                <div class="h6">Bạn có thể thêm nhiều ảnh cùng lúc (tối đa: 10 ảnh)</div>
                                <div class="p4 gray">Dung lượng ảnh tối đa: 2MB</div>
                            </div>
                        </div>
                    </div>
                    <div id="listImgUpload" class="mt05">

                    </div>
                </div>

                <div class="specifications__form-note f fdc">
                    <label class="h5 gray mb05">Ghi chú</label>
                    <input 
                        v-model="dataInput.note"
                        type="text" 
                        placeholder="Nhập ghi chú">
                </div>
            </div>

            <div class="specifications__action f jce aic">
                <div
                    @click="handleClose" 
                    class="pt05 mr1 pb05 pl15 pr15 h5 border-radius border cursor">Hủy bỏ</div>
                <div
                    @click="handleSpecifications"
                    class="pt05 pb05 pl15 pr15 h5 border-radius white bg-red-1 cursor">Hoàn thành</div>
            </div>
        </div>
    </div>
</template>

<script>
import { HTTP } from '@/http-default'
export default {
    props : {
        dataOrder: {
            type: Object,
            default() {
                return {}
            }
        }
    },

    data () {
        return {
            weightmeasure : ['Kg'],
            weight_measure: 'Kg',
            dataInput : {
                weight: 0,
                length: 0,
                height: 0,
                width: 0,
                weight_measure: 1,
                note : ''
            },
            avatar : []
        }
    },

    methods : {
        uploadImg () {
            var input = document.getElementById('file-input')
            input.click()
        },

        uploadImage(event) {
            this.avatar.push(event.target.files[0])
            var url = URL.createObjectURL(event.target.files[0]);
            var list = document.getElementById('listImgUpload');
            list.innerHTML+="<img style=\"width: 64px; height: 64px\" class=\"output border-radius mr05\" src=\"" + url + "\" alt=\"img\" >";
            // this.dataInput.avatar.push(event.target.files[0])
        },


        handleListType () {
            var container = document.getElementsByClassName('type-date__contaienr')[0]
            container.classList.toggle('hide')
        },

        handleClose () {
            var popup = document.getElementsByClassName('popup-specifications')[0]
            popup.classList.toggle('f')
        },

        updateData () {
            this.$emit('getDataAll')
        },

        handleSpecifications () {
            // var data = JSON.stringify({
            //     "weight": parseInt(this.dataInput.weight),
            //     "length": parseInt(this.dataInput.length),
            //     "height": parseInt(this.dataInput.height),
            //     "width": parseInt(this.dataInput.width),
            //     "note": this.dataInput.note,
            // })
            var data = new FormData()
            data.append('weight', parseInt(this.dataInput.weight))
            data.append('length', parseInt(this.dataInput.length))
            data.append('height', parseInt(this.dataInput.height))
            data.append('width', parseInt(this.dataInput.width))
            data.append('note', this.dataInput.note)
            data.append('image', this.avatar[0])
            HTTP.put('v1/api/package/' + this.dataOrder.package.id + '/', data)
            .then(() => {
                this.handleClose()
                this.updateData()
            })
        },

        handleSelectType (value) {
            this.typeDateInput = value
            if (value == 'Kg') {
                this.dataInput.time_measure = 1
            }
            if (value == 'Tuần') {
                this.dataInput.time_measure = 2
            }
            if (value == 'Tháng') {
                this.dataInput.time_measure = 3
            }
            if (value == 'Năm') {
                this.dataInput.time_measure = 4
            }
            this.handleListType()
        },

        getData () {
            // HTTP.get('v1/api/weightmeasure/')
            // .then((res) => {
            //     this.weightmeasure = res.data.results
            // })
        }
    },

    created () {
        this.getData()
    }
}
</script>

<style scoped lang='scss'>
    @import 'PackageSpecifications.scss';
</style>