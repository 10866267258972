@<template>
    <div class="popup popup-create-ticket jcc aic">
        <!-- step1 -->
        <div class="popup-create-ticket__container f fdc aic pt15 pb15 pl1 pr1 border-radius bg-white-1">
            <div class="icon--noti mb1"></div>
            <div class="h3 text-red-linear mb05">Thông báo</div>
            <div
                v-if="statusAction == 'create'" 
                class="p2 mb15 gray">Xác nhận tạo phiếu nhập hàng bán?</div>
            <div
                v-if="statusAction == 'save'" 
                class="p2 mb15 gray">Xác nhận lưu nháp phiếu nhập hàng?</div>
            <div
                v-if="statusAction == 'delete'" 
                class="p2 mb15 gray">Xác nhận hủy bỏ tạo phiếu?</div>
            <div class="w100 f jcb aic">
                <div 
                    @click="handleCloseTicket"
                    class="w50 h5 pt1 pb1 border border-radius align-c cursor">Quay lại</div>
                <div 
                    v-if="statusAction == 'create'" 
                    @click="handleCreateTicket"
                    class="w50 h5 pt1 pb1 ml1 border-radius align-c cursor bg-red-1 white">Xác nhận</div>
                <div 
                    v-if="statusAction == 'save'"
                    @click="handleSaveTicket"
                    class="w50 h5 pt1 pb1 ml1 border-radius align-c cursor bg-red-1 white">Xác nhận</div>
                <router-link
                    :to="{ name: 'filter-ticket', params: { menuitem: 'quan-ly-importticket', filter: 'phiếu nhập hàng thông thường' } }" 
                    v-if="statusAction == 'delete'"
                    class="w50 h5 pt1 pb1 ml1 border-radius align-c cursor bg-red-1 white">Xác nhận</router-link>
            </div>
        </div>

        <!-- step2 -->
        <div class="popup-success-ticket__container hide fdc aic pt15 pb15 pl1 pr1 border-radius bg-white-1">
            <div class="icon--noti-success mb1"></div>
            <div class="h3 text-red-linear mb05">Thông báo</div>
            <div
                v-if="statusAction == 'create'" 
                class="p2 mb15 gray">Bạn đã tạo phiếu nhập hàng thành công!</div>
            <div
                v-if="statusAction == 'save'" 
                class="p2 mb15 gray">Lưu nháp phiếu nhập hàng thành công!</div>
            <div class="w100 f jcb aic">
                <div 
                    @click="handleCloseTicket"
                    class="w50 h5 pt1 pb1 border border-radius align-c cursor">Quay lại</div>
                <div 
                    @click="handleCreateNewTicket"
                    class="w50 h5 pt1 pb1 ml1 border-radius align-c cursor bg-red-1 white">Tạo phiếu mới</div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { HTTP } from '@/http-default'

export default {
    props : {
        dataSubOrder : {
            type : Array,
            default() {
                return []
            }
        },

        listConsignment : {
            type : Array,
            default() {
                return []
            }
        },
        account : {
            type : Object,
            default() {
                return {}
            }
        },
        key_ticket: Number,

        statusAction : String,
        note_ticket : String,
        title_import_ticket : String,
        original_documents : String,
        shipperName: String,
        shipperPhone: String,
        shipperNote: String,
    },

    data () {
        return {
            dataTicket : {
                title_import_ticket: 'Phiếu nhập BZI',
                note_ticket: '',
                original_documents: '',
                ticket_type: 1,
                ticket_status: 1,
                organization: 1,
                stock: 1,
                user_create: 2,
                consignment: 2
            }
        }
    },
 
    methods : {
        handleCloseTicket () {
            var popup = document.getElementsByClassName('popup-create-ticket')[0]
            popup.classList.toggle('active-popup')
        },

        handleCreateNewTicket () {
            HTTP.get('getdevice/')
            .then((res) => {   
                     
                var listDevice = []

                res.data.device.forEach(item => {
                    listDevice.push(item.sessionId)
                })

                var data = JSON.stringify({
                    "list_session" : listDevice
                })

                HTTP.put('disconnect/', data)
                .then(() => {
                    this.$router.push({name: 'importticket-view'})
                })

            })
            location.reload()
            
        },

        handleShowPopup2 () {
            var popupCreate = document.getElementsByClassName('popup-create-ticket__container')[0]
            popupCreate.classList.toggle('hide')
            popupCreate.classList.toggle('f')

            var popupSuccess = document.getElementsByClassName('popup-success-ticket__container')[0]
            popupSuccess.classList.toggle('hide')
            popupSuccess.classList.toggle('f')
        },

        async handleCreateTicket () {
            const token = localStorage.getItem('user')
            const axiosConfig = {
                headers : {
                    'Content-Type': 'application/json',
                    'Authorization': 'Token '+ token,
                }
            }

            const consignment = JSON.stringify({
                "title_consignment":  Math.floor(Math.random() * 9999999999).toString(),
                "consignment_key": Math.floor(Math.random() * 9999999999).toString(),
                "quantity_in_consignment": 12,
                "user_create": 2,
                "time_measure": 1
            });

            axios.post('https://api.nmeco.xyz/v1/api/consignment/', consignment, axiosConfig)
            .then((res) => {
                var child_consignment = []
                var id = res.data.id
                for (let i = 0; i < this.listConsignment.length; i++) {
                    // eslint-disable-next-line vue/no-mutating-props
                    this.listConsignment[i].consignment_parent = id

                    for (const item in this.listConsignment[i]) {
                        if (this.listConsignment[i][item] == '') {
                            delete this.listConsignment[i][item]
                        }
                    }

                    const consignmentSub = JSON.stringify(this.listConsignment[i])


                    axios.post('https://api.nmeco.xyz/v1/api/consignment/', consignmentSub, axiosConfig)
                    .then((res) => {
                        child_consignment.push(res.data.id)
                        return child_consignment
                    })
                }

                const data = JSON.stringify({
                    "child_consignment" : child_consignment
                })

                axios.put('https://api.nmeco.xyz/v1/api/consignment/' + id + '/', data, axiosConfig)
                .then ((res) => {

                    const infoShipper = JSON.stringify({
                        "name" : this.shipperName,
                        "phone" : this.shipperPhone,
                        "note" : this.shipperNote
                    })

                    const dataTicket = JSON.stringify({
                        "title_import_ticket": this.title_import_ticket,
                        "key_ticket": this.key_ticket,
                        "note_ticket": this.note_ticket,
                        "original_documents": this.original_documents,
                        "shipper": infoShipper,
                        "ticket_type": 3,
                        "ticket_status": 1,
                        "organization": 1,
                        "stock": 1,
                        "user_create": this.account.user,
                        "consignment": id
                    })

                    axios.post('https://api.nmeco.xyz/v1/api/importticket/', dataTicket, axiosConfig)
                    this.handleShowPopup2 ()
                })
            })
        },

        async handleSaveTicket () {
            const token = localStorage.getItem('user')
            const axiosConfig = {
                headers : {
                    'Content-Type': 'application/json',
                    'Authorization': 'Token '+ token,
                }
            }

            const consignment = JSON.stringify({
                "title_consignment": "kho BZI tổng",
                "consignment_key": "123456789",
                "note": "",
                "quantity_in_consignment": 12,
                "user_create": 2,
                "product": 1,
                "time_measure": 1
            });

            axios.post('https://api.nmeco.xyz/v1/api/consignment/', consignment, axiosConfig)
            .then((res) => {
                var child_consignment = []
                var id = res.data.id
                for (let i = 0; i < this.listConsignment.length; i++) {
                    // eslint-disable-next-line vue/no-mutating-props
                    this.listConsignment[i].consignment_parent = id
                    const consignmentSub = JSON.stringify(this.listConsignment[i])

                    axios.post('https://api.nmeco.xyz/v1/api/consignment/', consignmentSub, axiosConfig)
                    .then((res) => {
                        child_consignment.push(res.data.id)
                        return child_consignment
                    })
                }

                const data = JSON.stringify({
                    "child_consignment" : child_consignment
                })

                axios.put('https://api.nmeco.xyz/v1/api/consignment/' + id + '/', data, axiosConfig)
                .then ((res) => {

                    const dataTicket = JSON.stringify({
                        "title_import_ticket": this.title_import_ticket,
                        "key_ticket": this.key_ticket,
                        "note_ticket": this.note_ticket,
                        "original_documents": this.original_documents,
                        "ticket_type": 3,
                        "ticket_status": 2,
                        "organization": 1,
                        "stock": 1,
                        "user_create": 2,
                        "consignment": id
                    })

                    axios.post('https://api.nmeco.xyz/v1/api/importticket/', dataTicket, axiosConfig)

                    this.handleShowPopup2()
                })
            })
        },
    }
}
</script>

<style scoped lang='scss'>
    @import 'PopupCreateTicket.scss';
</style>