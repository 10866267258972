@<template>
    <div class="pagination__container f jce aic">
        <div 
            @click="handleBackPagination('back')" 
            v-if="typeButton == 'back'"
            class="pagination__action-back bg-red-1 cursor">
            <div class="icon--arrow-white"></div>
        </div>
        <div 
            @click="handleBackPagination('back')" 
            v-else
            class="pagination__action-back bg-red cursor">
            <div class="icon--arrow-white"></div>
        </div>
        <div class="pagination-number f aic">
            <div
                v-for="(item, index) in numberPage"
                :key="index" 
                class="pagination-number__item p4 cursor"
                @click="handleSelectNumberPagination(item)">
                <div 
                    v-if="item == numberSelect"
                    class="text-red-linear">
                    {{ item }}
                </div>  
                <div 
                    v-else
                    class="gray">
                    {{ item }}
                </div>                                       
            </div>
        </div>
        <div
            @click="handleNextPagination('next')" 
            v-if="typeButton == 'next'"
            class="pagination__action-next bg-red-1 cursor">
            <div class="icon--arrow-white"></div>
        </div>
        <div
            @click="handleNextPagination('next')" 
            v-else
            class="pagination__action-next bg-red cursor">
            <div class="icon--arrow-white"></div>
        </div>
    </div>
</template>

<script>
export default {
    props : {
        totalPage : Number,
        updateNumberPagination : Function
    },

    data() {
        return {
            numberPage : [],
            numberSelect : 1,
            typeButton : 'next'
        }
    },

    watch : {
        'totalPage' : function () {
            this.numberPage = []
            for (let index = 0; index < this.totalPage; index++) {
                this.numberPage.push(index + 1)
            }
        },
    },

    methods : {
        handleSelectNumberPagination (value) {
            this.numberSelect = value
            this.$emit('updateNumberPagination', value)
        },

        handleNextPagination (value) {
            this.typeButton = value
            if (this.numberSelect < this.numberPage.length) {
                this.numberSelect = this.numberSelect + 1
                this.$emit('updateNumberPagination', this.numberSelect)
            }
        },

        handleBackPagination (value) {
            this.typeButton = value
            if (this.numberSelect != 1) {
                this.numberSelect = this.numberSelect - 1
                this.$emit('updateNumberPagination', this.numberSelect)
            }
        }
    }

    
}
</script>

<style scoped lang='scss'>
    @import 'Pagination.scss';
</style>