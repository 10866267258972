@<template>
    <div class="tutorial-connect__container pt1 pb1 pl1 pr1 border-radius bg-white-1">
        <div class="tutorial-connect__header f jcb aic mb05">
            <div class="h5 text-red-linear">Hướng dẫn đồng bộ thiết bị</div>
            <div 
                @click="handleTutorial"
                class="icon icon--close"></div>
        </div>
        <div class="tutorial-connect__body p4 mb1 gray">Vui lòng sử dụng thiết bị di động của bạn và làm theo hướng dẫn dưới đây</div>
        <div class="pt1 pb1 pl1 pr1 mb1 border border-radius">
            <div class="tutorial-connect__step">
                <div class="tutorial-connect__step-1">
                    <div class="h6 mb05">Bước 1</div>
                    <div class="p3 mb05">Truy cập <a class="blue" href="">địa chỉ trang đăng ký</a> hoặc quét mã QR dưới đây (sử dụng camera thường)</div>
                    <div class="f jcc aic">
                        <div class="pt025 pb025 pl025 pr025 border-radius border">
                            <img src="../../../../assets/qr-code-bzi.png" alt="">
                        </div>
                    </div>
                    <div class="h6 mb05">Bước 2</div>
                    <div class="p3 mb1">Đăng nhập vào hệ thống, sử dụng thông tin chính tài khoản hiện tại của bạn</div>
                    <div class="p4 gray">
                        Sau khi bạn hoàn thành các bước trên, hệ thống sẽ tự động đồng bộ giữa các thiết bị của bạn.
                    </div>
                </div>
            </div>
        </div>

        <div class="f ais pt1 pb1 pl1 pr1 border-radius border-yellow bg-yellow">
            <div class="icon icon--warning"></div>
            <div class="p4 ml1 orange">Vui lòng kiểm tra mục <span class="h6">Đề xuất kết nối</span> để tiến hành quét mã.</div>
        </div>
    </div>
</template>

<script>
export default {
    methods : {
        handleTutorial () {
            var tutorial = document.getElementsByClassName('tutorial-connect__container')[0]
            tutorial.classList.toggle('active')
            var popup = document.getElementsByClassName('create-package__container')[0]
            popup.classList.toggle('hide')
        },
    }
}
</script>

<style scoped lang='scss'>
    @import 'TutorialConnect.scss';
</style>