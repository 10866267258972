@<template>
    <div class="stock__container w100">
        <div class="body">
          <div 
            v-if="this.$route.params.filter"
            class="body__header f aic mb2">
            <span class="desktop h3 pr1">Kho & Hàng tồn </span>
            <span 
              v-for="(item,index) in dataMenu"
              :key="index">
              <div 
                class="desktop pl1 border-left"
                v-if="item.path == '/' + $route.params.menuitem">
                <div class="upcase p4 gray">{{ item.props.titlecontent }}</div>
              </div>  
            </span>
            <span class="desktop icon icon--next"></span>
            <span class="upcase h5 pl1 text-red-linear"> {{ $route.params.filter }} </span>
          </div>
          <div
            v-else 
            class="body__header f aic mb2">
            <span class="h3 pr1">Kho & Hàng tồn</span>
            <span 
              v-for="(item,index) in dataMenu"
              :key="index">
              <div 
                class="h5 pl1 border-left text-red-linear"
                v-if="item.path == '/' + $route.params.menuitem">
                {{ item.props.titlecontent }}
              </div>  
            </span>
            <span></span>
          </div>
          <div class="body__content f">
            <MenuView 
              :dataMenu="dataMenu"
              @updateFilter='updateFilter'></MenuView>
            <router-view  
              :data1="data"
              :keyFilter.sync="keyFilter"></router-view>
          </div>
        </div>
    </div>
</template>

<script>
import MenuView from '@/components/MenuView/MenuView.vue'
import { HTTP } from '../../http-default'

export default {
    components: {
        MenuView
    },
    data() {
    return {
      personnelActive: [],
      data: [],
      dataSideBar: [],
      dataMenu: [],
      keyFilter: []
    }
  },
  watch: {
    
  },
  methods: {
    updateFilter(value) {
      this.keyFilter = value
    },

    handleFilter() {
      var input = document.getElementById("myInput");
      var filter = input.value.toUpperCase();
      this.personnelActive = [];
      this.personnelWait = [];
      if(!filter) {
        for(let i = 0; i < 5; i++) {
        this.personnelActive.push(this.dataShow[i]);
      }
      }
      else {
        for (let i = 0; i < this.dataShow.length; i++) {
            var perName = this.dataShow[i].name;
            var txtValue = perName;
            if (txtValue.toUpperCase().indexOf(filter) > -1) {
                this.personnelWait.push(this.dataShow[i])
            }
        }
        for(let i = 0; i < this.personnelWait.length ; i++) {
          this.personnelActive.push(this.personnelWait[i]);
        }
      }
    },
    handleMorePer(event) {
      var tickactive = document.getElementsByClassName('active');
      if(tickactive.length == 1) {
        tickactive[0].classList.remove('active')
      }
      var button = event.target;
      var per = button;
      var active = event.target;
      active.classList.add('active');
      var number = per.innerText;
      this.personnelActive = [];
      for(let i = (number * 5 -5); i < (number * 5); i++) {
        var birthday = this.dataShow[i]['birthday'].split('T')
        this.dataShow[i]['birthday'] = birthday[0]
        this.personnelActive.push(this.dataShow[i]);
      }
    },
    handleAdd(event) {
      event.preventDefault()
      var add = document.getElementsByClassName('add-customer')[0]
      add.classList.toggle('add')
    },
    getData() {
      var systemSetting = localStorage.getItem("systemSetting")
      this.data = JSON.parse(systemSetting)
      for (let i = 0; i < this.data.length; i++) {
        if (this.data[i].levelmenu == 1) {
          this.dataSideBar.push(this.data[i])
        }
        if (this.data[i].subparent == 'ticket' || this.data[i].subparent == 'stock') {
          this.dataMenu.push(this.data[i])
        }
      }
    },
    dateFormat (classes, date) {
        if (!classes.disabled) {
          classes.disabled = date.getTime() < new Date()
        }
        return classes
      }

  },
  created() {
    this.getData()
  }
}
</script>

<style scoped lang="scss">
    @import 'Stock.scss';
</style>