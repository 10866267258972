@<template>
    <div class="import-ticket__container w100">
        <div class="import-ticket__header f aic mb2">
            <div class="desktop h3 pr1 border-right">
                Kho & hàng tồn
            </div>
            <div class="desktop p3 pr025 pl1 gray">
                Phiếu nhập hàng
            </div>
            <div class="desktop icon icon--next"></div>
            <div class="desktop p3 pr025 pl025 gray">
                Phiếu nhập hàng thông thường
            </div>
            <div class="desktop icon icon--next"></div>
            <div class="h5 pr025 pl025 text-red-linear">
               Tạo phiếu nhập hàng thông thường
            </div>
        </div>

        <div class="import-ticket__body f fw jcb ais w100">

            <!-- body-left -->
            <div class="body-left">
                <div class="body-left__id-order mb1 pt1 pb1 pl1 pr1 border-radius bg-white-1">
                    <div class="mb1 h5 gray">Mã phiếu</div>
                    <div class="f jcb aic mb1 pt1 pb1 pl1 pr1 border-radius border">
                        <div class="code-number h5 blue">  # {{formDataTicket.key_ticket}} </div>
                        <div 
                            class="icon icon--copy"
                            @click="handleCopyCode"></div>
                    </div>
                </div>
            </div>

            <!-- body-center -->
            <div class="body-center pt1 pb1 pl1 pr1 mb1 border-radius bg-white-1">
                <div class="ticket-header h5 mb1">
                    Thiết lập thông tin phiếu
                </div>
                <div 
                    v-if="data.props"
                    class="ticket-form">
                    <div 
                        v-for="(item, key) in data.props.listfieldaddnew"
                        :key="key">
                                <div
                                    v-if="key == 'title_import_ticket' || key == 'original_documents'" 
                                    class="f fdc mb1">
                                    <label class="upcase mb05 p4" for=""> {{item.text_lable}} <span class="text-red-linear">*</span></label>
                                    <input 
                                        v-if="key == 'title_import_ticket'"
                                        class="pt075 pb075 pl075 pr075"
                                        :type="item.type_input" 
                                        :placeholder="'Nhập ' + item.text_lable"
                                        v-model='title_import_ticket'>
                                    <input 
                                        v-else-if="key == 'original_documents'"
                                        class="pt075 pb075 pl075 pr075"
                                        :type="item.type_input" 
                                        :placeholder="'Nhập ' + item.text_lable"
                                        v-model='original_documents'>
                                    <!-- <textarea 
                                        v-else-if="key == 'note_ticket'"
                                        class="pt075 pb075 pl075 pr075"
                                        cols="30" rows="10"
                                        :type="item.type_input" 
                                        :placeholder="'Nhập ' + item.text_lable"
                                        v-model='note_ticket'> </textarea> -->
                                </div>
                    </div>
                    <div class="mb1">
                        <label class="upcase mb05 p4" for=""> Ghi chú phiếu xuất </label>
                        <textarea 
                            class="w100 p3 pt075 pb075 pl075 pr075 mt025 border-radius-s bg-white-1"
                            placeholder="Nhập ghi chú"
                            cols="30" 
                            rows="10"
                            v-model='note_ticket'></textarea>
                    </div>

                    <div>
                        <div 
                            class="form-item pt1 pb1 pr1 pl1 mb1 border-radius bg-gray-3">
                            <div 
                                @click="handleOpenDetail"
                                class="form-item__label f aic jcb">
                                <div 
                                    class="h6 gray">Thông tin người giao hàng</div>
                                <div 
                                    class="icon--arrow arrow-rotage"></div>
                            </div>
                            <div class="form-item__detail border-top mt1 pt1">
                                <div class="f jcb aic w100 mb1">
                                    <div class="w50 mr1">
                                        <div class="mb05 p4">Tên người giao hàng</div>
                                        <input 
                                            v-model="shipperName"
                                            type="text"
                                            class="w100 p3 pt075 pb075 pl075 pr075 bg-white-1"
                                            placeholder="Nhập tên người giao hàng">
                                    </div>
                                    <div class="w50">
                                        <div class="mb05 p4">Số điện thoại</div>
                                        <input 
                                            v-model="shipperPhone"
                                            type="text"
                                            class="w100 p3 pt075 pb075 pl075 pr075 bg-white-1"
                                            placeholder="Nhập số điện thoại">
                                    </div>
                                </div>
                                <div class="w100 mb1">
                                    <div class="mb05 p4">Ghi chú</div>
                                    <input 
                                        v-model="shipperNote"
                                        type="text"
                                        class="w100 p3 pt075 pb075 pl075 pr075 bg-white-1"
                                        placeholder="Nhập ghi chú">
                                </div>
                                <div class="w100">
                                    <div class="p3 mb05">Ảnh người giao hàng</div>
                                    <div class="w100 f mt05 aic pt1 pb1 pl1 pr1 border-radius bg-white-1" style="border: 1px dashed #D9DDE8;">
                                        <pre class="h5 pt05 pb05 pr1 pl1 mr1 border-radius bg-blue-1 white cursor" style="border: 1px solid #D9DDE8;">Tải ảnh lên</pre>
                                        <div>
                                            <div class="h6">Bạn có thể thêm nhiều ảnh cùng lúc (tối đa: 10 ảnh)</div>
                                            <div class="p4 gray">Dung lượng ảnh tối đa: 2MB</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div 
                            class="form-item pt1 pb1 pr1 pl1 mb1 border-radius bg-gray-3">
                            <div 
                                @click="handleOpenDetail"
                                class="form-item__label f aic jcb">
                                <div 
                                    class="h6 gray">Thông tin thủ kho</div>
                                <div 
                                    class="icon--arrow arrow-rotage"></div>
                            </div>
                            <div class="form-item__detail border-top mt1 pt1">
                                <div class="w100 mb1">
                                    <div class="f jcb aic mb05">
                                        <div class="p4">Tên thủ kho</div>
                                        <div class="p4">Hoàng Văn Chiến</div>
                                    </div>
                                    <div class="f jcb aic">
                                        <div class="p4">Số điện thoại</div>
                                        <div>0912002322</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div class="form-item pt1 pb1 pr1 pl1 border-radius bg-gray-3 gray">
                            <div 
                                @click="handleOpenDetail"
                                class="form-item__label f aic jcb">
                                <div 
                                    class="h6 gray">Thông tin kiện hàng</div>
                                <div 
                                    class="icon--arrow arrow-rotage"></div>
                            </div>
                            <div class="form-item__detail border-top mt1 pt1">
                                <div v-if="packageOrder.length != 0" class="p4 mb1">
                                    Mã kiện: <span class="blue"> #{{packageOrder[0].id}} </span>
                                </div>

                                <div v-else class="p4 mb1">
                                    Mã kiện: <span class="blue"> # </span>
                                </div>

                                <div 
                                    v-if="listConsignment.length == 0"
                                    class="pt1 pb1 pl1 pr1 mb05 border-radius bg-white-1 cursor"
                                    @click="handlePackage">
                                    <div class="f jcb aic">
                                        <div class="h6 gray">Thiết lập thông tin sản phẩm</div>
                                        <div class="p6 pt05 pb05 pl05 pr05 border-radius bg-yellow orange">Chưa thiết lập</div>
                                    </div>
                                </div>
                                <div 
                                    v-else
                                    class="pt1 pb1 pl1 pr1 mb05 border-radius bg-white-1 cursor"
                                    @click="handlePackage">
                                    <div class="f jcb aic mb05">
                                        <div class="h6 gray">Thông tin sản phẩm</div>
                                        <div class="p6 pt05 pb05 pl05 pr05 border-radius bg-green green">Đã thiết lập</div>
                                    </div>
                                    <div class="f jcb aic p6 mb025">
                                        <div>Tổng sản phẩm trong kiện</div>
                                        <div> {{this.totalQuantity}} sản phẩm</div>
                                    </div>
                                    <div class="f jcb aic p6">
                                        <div>Tổng giá trị kiện hàng</div>
                                        <div> {{ formatMoney(totalPrice) }} </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- body-right -->
            <div class="body-right border-radius">
                <div class="body-right__product pt1 pb1 pl1 pr1 border-radius bg-white-1">
                    <div class="body-right__header h5 mb05">
                        Thông tin sản phẩm nhập kho 
                    </div>
                    <div
                        v-if="productImport.length == 0" 
                        class="body-right__quantity-total mb1 p3 gray">
                        Hệ thống chưa có dữ liệu
                    </div>
                    <div class="body-right__quantity-total mb1 p4"
                        v-else>
                        <span class="p3 gray">Đã nhập:</span> {{this.totalQuantity}} sản phẩm
                    </div>
                    <div class="body-right__product-list">
                        <ProductItemView :productImport="productImport"></ProductItemView>
                    </div>
                </div>

                <div class="body-right__action border-radius mt1 pt1 pl1 pr1 bg-white-1">
                    <div class="f jcb aic pb1 border-bottom">
                        <div class="h5">Tổng tiền hàng nhập</div>
                        <div class="h5 text-red-linear">{{ formatMoney(totalPrice) }}</div>
                    </div>
                    <div class="w100 f jcb aic pt15 pb15">
                        <div 
                            @click="handleSaveTicket('save')"
                            class="border border-radius h5 pt1 pb1 w50 align-c mr05 cursor">Lưu Nháp</div>
                        <div
                            @click="handleCancelTicket('delete')" 
                            class="border border-radius h5 pt1 pb1 w50 align-c mr05 bg-white-1 cursor">Hủy bỏ</div>
                        <div 
                            @click="handleCreateTicket('create')"
                            v-if="listConsignment.length != 0 
                                && title_import_ticket.length != 0
                                && original_documents.length != 0"
                            class="border-radius h5 pt1 pb1 w50 align-c cursor bg-red-1 white">Tạo phiếu</div>
                        <div 
                            v-else
                            class="border-radius h5 pt1 pb1 w50 align-c bg-red white">Tạo phiếu</div>
                    </div>
                </div>
            </div>
        </div>

        <CreatePackageView 
            :account='account'
            :dataSubOrder="dataOrder.sub_order"
            @updateListConsignment='updateListConsignment'
            @updateProductTicket='updateProductTicket'
            @updateTotalPrice='updateTotalPrice'>
        </CreatePackageView>

        <PopupQRView></PopupQRView>
        <PopupCreateTicketView
            :key_ticket="formDataTicket.key_ticket"
            :shipperName="shipperName"
            :shipperPhone="shipperPhone"
            :shipperNote="shipperNote"
            :account='account'
            :statusAction="statusAction"
            :dataSubOrder="dataOrder.sub_order"
            :title_import_ticket='title_import_ticket'
            :note_ticket="note_ticket"
            :original_documents="original_documents"
            :listConsignment="listConsignment">
        </PopupCreateTicketView>
    </div>
</template>

<script>
import CreatePackageView from '@/components/ImportTicketView/CreatePackageView/CreatePackageView'
import ProductItemView from '@/components/ImportTicketView/ProductItemView/ProductItemView'
import PopupQRView from '@/components/ImportTicketView/PopupNotiQRView/PopupQRView'
import PopupCreateTicketView from '@/components/ImportTicketView/PopupCreateTicket/PopupCreateTicketView'
import { HTTP } from '../../http-default'
// import axios from 'axios'

// import firebase from "@/firebaseInit";
// import "firebase/firestore";
// const db = firebase.firestore();
export default {
    components : {
        CreatePackageView,
        ProductItemView,
        PopupQRView,
        PopupCreateTicketView,
    },
    data () {
        return {
            dataPackage : [],
            dataOrder : [],
            data : [
                {
                    props : [
                        {
                            listfieldaddnew : []
                        }
                    ]
                }
            ],

            // action with ticket
            statusAction : '',


            packageOrder : [],
            packageScan : [],
            title_import_ticket : '',
            note_ticket : '',
            original_documents : '',

            listProduct : [],

            // Account
            account : {},

            // consignment
            listConsignment : [],
            // product Scan
            listProductScan : [],
            productImport : [],
            totalPrice : 0,
            totalQuantity : 0,

            // form input 
            formDataTicket : {
                title_import_ticket: '',
                key_ticket: '',
                created_at: null,
                customer: '',
                shipper: '',
                stocker: '',
                time_created_ticket: null,
                time_updated_ticket: null,
                time_finished_ticket: null,
                note_ticket: '',
                time_import: null,
                total_fund: null,
                original_documents: '',
                ticket_type: null,
                ticket_status: null,
                organization: null,
                stock: null,
                order: null,
                user_create: null,
                package: null
            },

            // shipper info
            shipperName : '',
            shipperPhone: '',
            shipperNote: ''
        }
    },


    methods: {
        randomKey () {
            var number = Math.floor(Math.random() * 1000000000)
            this.formDataTicket.key_ticket = number
        },
        
        // formatMoney
        formatMoney(value) {
            value = value.toLocaleString('it-IT', {style : 'currency', currency : 'VND'})
            return value
        },

        // handle update consignment
        updateListConsignment (value) {
            this.listConsignment = value

            this.handleTotalQuantity()
        },

        // updateTotalPrice
        updateTotalPrice (value) {
            this.totalPrice = value
        },
 
        // update product scan
        updateProductTicket (value) {
            this.productImport = value
        },
        
        handleCopyCode () {
            var copyText = document.getElementsByClassName('code-number')[0].innerText
            var TempText = document.createElement("input");
            TempText.value = copyText;
            document.body.appendChild(TempText);
            TempText.select();
            document.execCommand("copy");
            document.body.removeChild(TempText);
            alert("Copied the text: " + TempText.value);
        },

        handleTotalQuantity () {
            var total = 0
            this.listConsignment.forEach(item => {
                total = total + parseInt(item.quantity_in_consignment)
            })
            this.totalQuantity = total
        },

        handleOpenDetail (event) {
            var button = event.target.parentElement.parentElement;
            var popup = button.getElementsByClassName('form-item__detail')[0]
            var arrown = button.getElementsByClassName('icon--arrow')[0]
            popup.classList.toggle('active')
            arrown.classList.toggle('arrow-rotage')
        },

        // create package
        handlePackage () {
            HTTP.get('v1/api/account/')
            .then ((res) => {
                this.account = res.data[0]
            })

            var popup = document.getElementsByClassName('popup-create-package')[0]
            popup.classList.toggle('active-popup')
        },

        // create QR package
        handleQR () {

            var popup = document.getElementsByClassName('popup-QR')[0]
            popup.classList.toggle('active-popup')
        },

        // create ticket
        handleCreateTicket (value) {
            this.statusAction = value
            var popup = document.getElementsByClassName('popup-create-ticket')[0]
            popup.classList.toggle('active-popup')
        },

        // save ticket
        handleSaveTicket (value) {
            this.statusAction = value
            var popup = document.getElementsByClassName('popup-create-ticket')[0]
            popup.classList.toggle('active-popup')
        },

        // delete ticket
        handleCancelTicket (value) {
            this.statusAction = value
            var popup = document.getElementsByClassName('popup-create-ticket')[0]
            popup.classList.toggle('active-popup')
        },

        // get data API
        getData () {
            var systemSetting = localStorage.getItem("systemSetting")
            this.data = JSON.parse(systemSetting)
            for (let i = 0; i < this.data.length; i++) {
                if (this.data[i].name == "importticket") {
                    this.data = this.data[i]
                    break
                }
            }

            // HTTP.get('v1/api/order/' + this.$route.params.id + '/')
            // .then((res) => {
            //     this.dataOrder = res.data
            //     const setting = this.dataOrder.customer.toLowerCase()
            //     const results = setting.replaceAll("'", '"')
            //     this.dataOrder.customer = JSON.parse(results)
            // })

            HTTP.get('v1/api/package/')
            .then((res) => {
                this.dataPackage = res.data
                for (let i = 0; i < this.dataPackage.length; i++) {
                    if (this.dataPackage[i].id === this.dataOrder.package) {
                        this.packageOrder = this.dataPackage[i]
                        break
                    }
                }
            })

            HTTP.get('/v1/api/product/')
            .then((res) => {
                this.listProduct = res.data
            })

            HTTP.get('v1/api/account/')
            .then ((res) => {
                this.account = res.data[0]
            })
        }
    },

    created () {
        this.getData();
        this.randomKey() 
    }
}
</script>

<style scoped lang='scss'>
    @import 'ImportTicket.scss';
</style>