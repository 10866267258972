@<template>
    <div class="popup popup-info jcc aic">
        <div class="info-customer__container pt1 pb1 pl1 pr1 border-radius bg-white-1">
            <div class="info-customer__header f jcb aic mb1">
                <div class="info-customer__header-title text-red-linear h5">
                    Thông tin người mua
                </div>
                <div 
                    @click="handleInfoCustomer"
                    class="icon icon--close"></div>
            </div>
            <div class="info-customer__body">
                <div class="f jcb aic mb1">
                    <div class="p3 gray">Tên người mua</div>
                    <div class="p3"> {{ dataCustomer.full_name }} </div>
                </div>
                <div class="f jcb aic mb1">
                    <div class="p3 gray">Số điện thoại</div>
                    <div class="p3"> {{ dataCustomer.phone }} </div>
                </div>

                <!-- email customer -->
                <div 
                    v-if="dataCustomer.email == ''"
                    class="f jcb aic mb1">
                    <div class="p3 gray">Email</div>
                    <div class="p3 gray"> Chưa có dữ liệu </div>
                </div>
                <div
                    v-else 
                    class="f jcb aic mb1">
                    <div class="p3 gray">Email</div>
                    <div class="p3"> {{ dataCustomer.email }} </div>
                </div>
                <div class="f jcb aic mb1">
                    <div class="p3 gray">Địa chỉ</div>
                    <div class="p3"> {{dataCustomer.address}} </div>
                </div>
                <div 
                    v-if="dataCustomer.note"
                    class="note-customer">
                    <div class="p3 mb05 gray">Ghi chú</div>
                    <div 
                        class="w100 p3" >
                        {{dataCustomer.note}}
                    </div>
                </div>
                <div 
                    v-else
                    class="note-customer w100 f jcb aic">
                    <div class="p3 mb05 gray">Ghi chú</div>
                    <div 
                        class="p3 gray" >
                        Chưa có dữ liệu
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props : {
        dataCustomer : {
            type : Object,
            default () {
                return {}
            }
        }
    },

    methods : {
        handleInfoCustomer () {
            var popup = document.getElementsByClassName('popup-info')[0]
            popup.classList.toggle('active-popup')
        },
    }
}
</script>

<style scoped lang='scss'>
    @import 'InfoCustomer.scss';
</style>