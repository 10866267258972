@<template>
    <div class="popup popup-view-consignment jcc aic">
        <div
            v-for="(item, index) in listConsignment"
            :key="index">
            <div 
                v-if="item.consignment_key == keySelect"
                class="view-consignment__contaienr border-radius bg-white-1">
                <div class="view-consignment__header pt1 pl1 pr1 h5 text-red-linear">
                    Xem lô hàng
                </div>
                <div class="view-consignment__body w100 f fdc aic pt1 pb1 pl1 pr1">
                    <div class="f jcb aic w100">
                        <div class="p2">Tên lô hàng</div>
                        <div class="h6"> {{item.title_consignment}} </div>
                    </div>
                    <div class="f jcb aic w100">
                        <div class="p2">Mã lô hàng của bạn</div>
                        <div class="h6"> {{item.consignment_key}} </div>
                    </div>
                    <div class="f jcb aic w100">
                        <div class="p2">Số lượng sản phẩm</div>
                        <div class="h6"> {{item.quantity_in_consignment}} </div>
                    </div>
                    <div class="f jcb aic w100">
                        <div class="p2">Đơn giá nhập</div>
                        <div class="h6 text-red-linear"> {{formatMoney(item.price_product)}} </div>
                    </div>
                    <div class="f jcb aic w100">
                        <div class="p2">Ngưỡng cảnh báo số lượng</div>
                        <div
                            v-if="item.warning_quantity_percent == ''" 
                            class="h6"> Chưa có dữ liệu </div>
                        <div
                            v-else
                            class="h6"> {{item.warning_quantity_percent}}% </div>
                    </div>
                    <div class="f jcb aic w100">
                        <div class="p2">Ngày sản xuất</div>
                        <div
                            v-if="item.manufacturing_date == ''" 
                            class="h6"> Chưa có dữ liệu </div>
                        <div
                            v-else
                            class="h6"> {{item.manufacturing_date}} </div>
                    </div>
                    <div class="f jcb aic w100">
                        <div class="p2">Ngày hết hạn</div>
                        <div
                            v-if="item.expiry_date == ''" 
                            class="h6"> Chưa có dữ liệu </div>
                        <div
                            v-else
                            class="h6"> {{item.expiry_date}} </div>
                    </div>
                    <div class="f jcb aic w100">
                        <div class="p2">Cảnh báo HSD</div>
                        <div
                            v-if="item.warning_date == ''" 
                            class="h6"> Chưa có dữ liệu </div>
                        <div
                            v-else
                            class="h6"> {{item.warning_date}} </div>
                    </div>
                    <div class="f jcb aic w100">
                        <div class="p2">Vị trí</div>
                        <div
                            v-if="item.position == ''" 
                            class="h6"> Chưa có dữ liệu </div>
                        <div
                            v-else
                            class="h6"> {{item.position}} </div>
                    </div>
                </div>
                <div class="view-consignment__action f jcb aic pt1 pb1 pl1 pr1 border-top bg-gray-1">
                    <div class="f fdc">
                        <div class="p4">Tổng giá trị lô hàng:</div>
                        <div
                            class="h6 text-red-linear"> {{formatMoney(item.total_price)}} </div>
                    </div>
                    <div
                        @click="handleClose" 
                        class="view-consignment__btn w50 pt1 pb1 border border-radius align-c cursor bg-white-1">
                        Đóng 
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props : {
        listConsignment : {
            type : Array,
            default() {
                return []
            }
        },

        keySelect : String,
    },

    methods : {
        formatMoney(value) {
            value = parseInt(value)
            value = value.toLocaleString('it-IT', {style : 'currency', currency : 'VND'})
            return value
        },

        handleClose () {
            var popup = document.getElementsByClassName('popup-view-consignment')
            popup.forEach(element => {
                element.classList.remove('f')
            });
        }
    }
}
</script>

<style scoped lang='scss'>
    @import 'ViewConsignment.scss';
</style>