@<template>
    <div class="popup popup-noti jcc aic">

        <!-- status-2 -->
        <div 
            v-if="changeStatus == 2"
            class="popup-noti__container align-c pt15 pb15 pl1 pr1 border-radius bg-white-1">
            <div class="w100 f jcc aic mb1">
                <div class="icon--noti"></div>
            </div>
            <div class="mb05 text-red-linear h3">Thông báo</div>
            <div class="p2 gray mb15">Bạn cần tạo phiếu xuất hàng bán trước khi cập nhật trạng thái này</div>
            <div class="f jcb aic w100 h5">
                <div 
                    @click="handleDeliveryStatus"
                    class="w50 mr1 pt1 pb1 border-radius align-c border cursor">
                    Quay lại
                </div>
                <div
                    @click="handleCreateImportTicket"
                    class="button w50 pt1 pb1 border-radius align-c bg-red-1 white cursor">
                    <div>
                        Tạo phiếu
                    </div>
                </div>
            </div>
        </div>

        <!-- status-3 -->
        <div 
            v-else-if="changeStatus == 3"
            class="popup-noti__container  align-c pt15 pb15 pl1 pr1 border-radius bg-white-1">
            <div class="w100 f jcc aic mb1">
                <div class="icon--noti"></div>
            </div>
            <div class="mb05 text-red-linear h3">Thông báo</div>
            <div class="p2 gray mb1">Xác nhận đã đưa hàng cho bên vận chuyển?</div>
            <div class="info-drive f fdc jcs ais pt1 pb1 pl1 pr1 mb15  border-radius bg-gray-1">
                <div class="info-drive__header w100 f jcb aic mb1">
                    <div class="h6">
                        Thông tin tài xế
                    </div>
                    <div 
                        v-if="reviewShipper.length != 0"
                        class="p4 green">
                        Đã đánh giá
                    </div>
                </div>
                <div class="info-drive__body f aic mb1">
                    <div class="mr05">
                        <img src="../../../assets/imgUser.png" alt="">
                    </div>
                    <div class="f fdc jcb ais">
                        <div class="p4 mb025"> {{this.shipper.name}} </div>
                        <div class="p4 gray"> {{this.shipper.phone}} </div>
                    </div>
                </div>
                <div 
                    @click="handleReviewShipper"
                    class="info-drive__review align-c w100 pt025 pb025 border-radius p4 cursor blue bg-white-1">
                    Đánh giá tài xế
                </div>
            </div>
            <div class="f jcb aic w100 h5">
                <div 
                    @click="handleConfirmCancel"
                    class="w50 mr1 pt1 pb1 border-radius align-c border cursor">
                    Quay lại
                </div>
                <div
                    v-if="reviewShipper.length == 0"
                    class="w50 pt1 pb1 border-radius align-c bg-red white cursor">
                    <div>
                        Xác nhận
                    </div>
                </div>
                <div
                    v-else
                    @click="handleChangeStatus"
                    class="w50 pt1 pb1 border-radius align-c bg-red-1 white cursor">
                    <div>
                        Xác nhận
                    </div>
                </div>
            </div>
        </div>

        <!-- review shipper -->
        <div class="popup-review hide pt15 pb15 pl1 pr1 pb1 border-radius bg-white-1">
            <div class="popup-review__title h6 gray mb1">
                Đánh giá tài xế
            </div>
            <div class="popup-review__star mb1">
                <div class="mb05"> 
                    <div class="p4">Điểm đánh giá</div>
                </div>
                <div class="f jcc aic mt15">
                    <RatingStarView @updateRating='updateRating'></RatingStarView>
                </div>
            </div>
            <div class="popup-review__comment w100 mb15">
                <div class="p4 mb05">Nhận xét</div>
                <textarea cols="30" rows="10" class="w100 p4 pt05 pb05 pl075 pr075" placeholder="Thêm nhận xét"></textarea>
            </div>
            
            <div class="popup-review__action w100 f jcb aic h5">
                <div 
                    @click="handleCancelReviewShipper"
                    class="w50 mr1 pt1 pb1 border-radius align-c border cursor">
                    Quay lại
                </div>
                <div
                    @click="handleReviewShipper"
                    class="w50 pt1 pb1 border-radius align-c bg-red-1 white cursor">
                    <div>
                        Xác nhận
                    </div>
                </div>
            </div>
        </div>

        <!-- confirm cancel -->
        <div class="popup__confirm-cancel hide align-c fdc aic bg-white-1 border-radius pt15 pb15 pl1 pr1">
            <div class="f jcc aic">
                <div class="icon--noti mb1"></div>
            </div>
            <div class="h3 text-red-linear mb05">Thông báo</div>
            <div class="p2 gray align-c mb05">Xác nhận hủy bỏ đánh giá tài xế?</div>
            <div class="f jcb aic w100">
                <div 
                    @click="handleConfirmCancel"
                    class="pt1 pb1 h5 mr1 w50 align-c border border-radius cursor">Quay lại</div>
                <div 
                    @click="handleConfirm"
                    class="pt1 pb1 h5 w50 align-c border-radius cursor bg-red-1 white">Xác nhận</div>
            </div>
        </div>

    </div>
</template> 

<script>
import RatingStarView from '@/components/RatingStarView/RatingStarView.vue'

import firebase from "@/firebaseInit";
import "firebase/firestore";
const db = firebase.firestore();

import { HTTP } from '@/http-default'
import axios from 'axios'

export default {
    components : {
        RatingStarView
    },

    props : {
        changeStatus : {
            type : Number
        },
        shipper : {
            type : Object,
            default() {
                return {}
            }
        },
        dataSubOrder : {
            type : Array,
            default() {
                return []
            }
        },
        dataOrder : {
            type : Object,
            default() {
                return {}
            }
        },
        data : {
            type : Array,
            default() {
                return []
            }
        },
    },

    data () {
        return {
            reviewShipper: [],
            dataUser: [],
            ratingStar: 0,
        }
    },

    methods : {
        updateRating (value) {
            this.reviewShipper = []
            this.ratingStar = value
            this.reviewShipper.push(value)
        },

        handleCancelReviewShipper () {
            this.reviewShipper = []
            this.handleReviewShipper ()
        },

        handleConfirmCancel () {
            var close = document.getElementsByClassName('popup__confirm-cancel')[0]
            close.classList.toggle('hide')

            var popup = document.getElementsByClassName('popup-noti__container')[0]
            popup.classList.toggle('hide')
        },

        handleConfirm () {
            this.reviewShipper = []

            var popup = document.getElementsByClassName('popup-noti__container')[0]
            popup.classList.toggle('hide')

            var close = document.getElementsByClassName('popup__confirm-cancel')[0]
            close.classList.toggle('hide')

            var popup1 = document.getElementsByClassName('popup-noti')[0]
            popup1.classList.toggle('active-popup')

            var select = document.getElementsByClassName('select-status__list')[0]
            select.classList.toggle('active')
        },

        handleReviewShipper () {
            var popup = document.getElementsByClassName('popup-noti__container')[0]
            popup.classList.toggle('hide')

            var review = document.getElementsByClassName('popup-review')[0]
            review.classList.toggle('hide')
        },

        handleDeliveryStatus () {
            var select = document.getElementsByClassName('select-status__list')[0]
            select.classList.toggle('active')
            var popup = document.getElementsByClassName('popup-noti')[0]
            popup.classList.toggle('active-popup')
        },

        getRealTime() {
            var today = new Date();
            var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
            var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
            var dateTime = date+'T'+time+'Z';
            return dateTime
        },

        handleAddStatusFirebase () {
            var data = [...this.data]
            data.push({
                    created_at: this.getRealTime(),
                    title_logorder: "Kho (Đã giao kiện hàng cho tài xế)"
                })
            var statusFirebase = firebase.database().ref("/status_order_warehouse/" + parseInt(this.dataOrder.order_key))
            statusFirebase.set(data)
        },

        handleChangeStatus () {
            const token = localStorage.getItem('user')
            const axiosConfig = {
                headers : {
                    'Content-Type': 'application/json',
                    'Authorization': 'Token '+ token,
                }
            }

            const deliverystatus = JSON.stringify({
                "status_delivery" : 3,
                "sub_order": this.dataSubOrder
            })

            axios.put('https://api.nmeco.xyz/v1/api/order/' + this.$route.params.id + '/', deliverystatus, axiosConfig)
            .then(() => {
                var popup = document.getElementsByClassName('popup-noti')[0]
                popup.classList.toggle('active-popup')
                var select = document.getElementsByClassName('select-status__list')[0]
                select.classList.toggle('active')
                if (this.changeStatus === 3) {
                    this.handleAddStatusFirebase()
                }
                this.$emit('getData')
            })
        },

        handleCreateImportTicket () {
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            let path = this
            
            db.collection('product-scan').add({
                type : 'Product-ticketexport-scan'
            })
            .then(function(res) {
                const token = localStorage.getItem('user')
                const axiosConfig = {
                    headers : {
                        'Content-Type': 'application/json',
                        'Authorization': 'Token '+ token,
                    }
                }

                const dataDoc = JSON.stringify({
                    "key_doc_firebase": res.id,
                });

                axios.put('https://api.nmeco.xyz/v1/api/account/' + path.dataUser.id , dataDoc, axiosConfig)
                .then(() => {
                    HTTP.get('getdevice/')
                    .then((res) => {
                            
                        var listDevice = []

                        res.data.device.forEach(item => {
                            listDevice.push(item.sessionId)
                        })

                        var data = JSON.stringify({
                            "list_session" : listDevice
                        })

                        HTTP.put('disconnect/', data)
                        .then(() => {
                            path.$router.push({ name: 'exportticket-view' })
                        })

                    })
                })
            })
        },

        getDataUser () {
            HTTP.get('v1/api/account/')
            .then((res) => {
                this.dataUser = res.data[0]
            })
        }
    },

    created () {
        this.getDataUser()
    }
}
</script>

<style scoped lang='scss'>
    @import 'PopupNoti.scss';
</style>