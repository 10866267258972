@<template>
    <div class="popup popup__confirm-code jcc aic">
        <div class="confirm-code__contaienr align-c pt15 pb15 pl1 pr1 border-radius bg-white-1">
            <div class="w100 f jcc aic">
                <div class="icon--noti"></div>
            </div>
            <div class="h3 text-red-linear mt1 mb05">Thông báo</div>
            <div class="mb1">Vui lòng nhập mã OTP đã gửi về địa chỉ email: <span class="h6"> {{email}} </span> </div>
            <div class="code-confirm w100 f jcb aic mb05">
                <input type="number" id="input-1" maxlength="1" 
                    v-model="input1"
                    v-on:input="handlePaste('input-1')"
                    v-on:keyup="handleInputFocus('input-1','input-2')">
                <input type="number" id="input-2" maxlength="1" 
                    v-model="input2"
                    v-on:keyup="handleInputFocus('input-2','input-3')">
                <input type="number" id="input-3" maxlength="1" 
                    v-model="input3"
                    v-on:keyup="handleInputFocus('input-3','input-4')">
                <input type="number" id="input-4" maxlength="1" 
                    v-model="input4"
                    v-on:keyup="handleInputFocus('input-4','input-5')">
                <input type="number" id="input-5" maxlength="1" 
                    v-model="input5"
                    v-on:keyup="handleInputFocus('input-5','input-6')">
                <input type="number" id="input-6" maxlength="1"
                    v-model="input6">
            </div>
            <div class="w100 align-e h6 mb15 blue cursor">Gửi lại mã xác nhận</div>
            <div class="w100 f aic jcb">
                <div
                    @click="handleBack" 
                    class="w50 pt1 pb1 h6 align-c border-radius border cursor">
                    Quay lại
                </div>
                <div 
                    @click="handleActive"
                    class="w50 pt1 pb1 ml1 h6 align-c border-radius bg-red-1 white cursor">
                    Hoàn thành
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    components : {
        
    },

    props : {
        'email' : {
            type : String
        },
        'idRegister' : {
            type : Number
        }
    },

    data () {
        return {
            input1 : '',
            input2 : '',
            input3 : '',
            input4 : '',
            input5 : '',
            input6 : '',
        }
    },

    methods : {
        handleBack () {
            var popup = document.getElementsByClassName('popup__confirm-code')[0]
            popup.classList.toggle('f')
        },

        handleInputFocus (first,last) {
            document.getElementById(last).focus()
        },

        handlePaste (value) {
            var paste = document.getElementById(value).value
            for (let i = 0; i < paste.length; i++) {
                this.input1 = paste[0]
                this.input2 = paste[1]
                this.input3 = paste[2]
                this.input4 = paste[3]
                this.input5 = paste[4]
                this.input6 = paste[5]
            }
        },

        handleActive () {
            var code = this.input1 + this.input2 + this.input3 + this.input4 + this.input5+ this.input6
            var codeActive = JSON.stringify({
                "reset_code" : code
            })
            
            const axiosConfig = {
                headers : {'Content-Type': 'application/json'} 
            }

            axios.post('https://api.nmeco.xyz/activeuser/' + this.idRegister, codeActive, axiosConfig)
            .then(() => {
                this.handleBack()
                var login = document.querySelector('.login');
                login.classList.remove('visible');
                var register = document.querySelector('.register');
                register.classList.remove('active');

            })

        }
    },
}
</script>

<style scoped lang='scss'>
    @import 'ConfirmCode.scss';
</style>