
@<template>
    <div class="popup popup-photo-QR jcc aic">

        <!-- Noti create QR -->
        <div class="photo-QR__container f fdc aic pt15 pb15 pl1 pr1 border-radius bg-white-1">
            <div class="icon--noti mb1"></div>
            <div class="h3 text-red-linear mb05">Thông báo</div>
            <div class="p2 gray align-c mb15">Máy + tên máy in đang thực hiện in mã QR</div>
            <div class="f jcb aic w100">
                <div 
                    @click="handleClose"
                    class="pt1 pb1 h5 mr1 w50 align-c border border-radius cursor">Đóng</div>
                <div 
                    @click="handlePrintQRcode"
                    class="pt1 pb1 h5 w50 align-c border-radius cursor bg-red-1 white">Chọn máy khác</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props : {
        dataOrder : {
            type : Object,
            default() {
                return {}
            }
        }
    },
    methods : {
        handleClose () {

            var popup = document.getElementsByClassName('popup-photo-QR')[0]
            popup.classList.toggle('active-popup')
        },

        handlePrintQRcode () {
            var win = window.open('about:blank', "_new");
            win.document.open();
            win.document.write([
                '<html>',
                '   <head>',
                '   </head>',
                '   <body onload="window.print()" onafterprint="window.close()">',
                '       <img src="' + this.dataOrder.package.qrcode + '"/>',
                '   </body>',
                '</html>'
            ].join(''));
        }
    }
}
</script>

<style>

</style>