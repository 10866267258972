@<template>
    <div id="noti__container" class="noti__container absolute border-radius bg-green">
        <div class="noti__header green pt05 pb025 pl075 pr075 h5">
            Bạn có đơn hàng mới
        </div>
        <div class="noti__body p4 pb05 pl075 pr075">
            Mã đơn hàng: <span class="h6 blue">#{{ this.idData.order_key }}</span>
        </div>
        <div class="noti_time"></div>     
    </div>
</template>

<script>

import firebase from "@/firebaseInit";
import "firebase/firestore";
import { HTTP } from '@/http-default';
import router from '@/router';

export default {
    
    data() {
        return {
            count : 0,
            data : [],
            stock: [],
            idData : ''
        } 
    }, 

    methods : {

        async getNoti () {

            // eslint-disable-next-line @typescript-eslint/no-this-alias
            let path = this
            var ref = firebase.database().ref("/order_warehouse/" + this.stock[0].id)
            ref.on("child_added", function(childSnapshot) {
                if (path.count == 0) {
                    path.data.push(childSnapshot.val())
                } else {
                    path.idData = childSnapshot.val()
                    var noti = document.getElementById('noti__container')
                    noti.classList.add('active')

                    var setTime = document.getElementsByClassName('noti_time')[0]
                    setTime.classList.add('setTime')

                    setTimeout(() => {
                        noti.classList.remove('active')
                        noti.classList.add('close')
                    }, 4000)

                    setTimeout(() => {
                        noti.classList.remove('close')
                        setTime.classList.remove('setTime')
                    }, 3000)
                }
            })
        },

        getData () {
            HTTP.get('v1/api/stock/')
            .then((res) => {
                this.stock = res.data.results
                this.getNoti ()
            })

            HTTP.get('v1/api/order/')
            .then((res) => {
                this.dataOrder = res.data.results
            })
        }
    },

    created () {
        if (this.$route.fullPath !== '/login') {
            this.getData()
        }
        setTimeout(() => {
            this.count = 1
        }, 5000)

    }
}
</script>

<style scoped lang='scss'>
    @import 'NotiOrder.scss';
</style>