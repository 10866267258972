@<template>
    <div class="produc-scan">
        <div 
            v-for="(value, index) in subOrderSelect"
            :key="index">
            <div 
                v-for="(item, key) in productImport"
                :key="key">
                <div
                    v-if="value.product.id == item.id"
                    class="product-item__container pt1 pb1 pl1 pr1 mb1 border-radius bg-white-1">
                    <div class="f jcb aic mb1">
                        <div class="large-info f aic">
                            <div class="mr05">
                                <img :src="item.image_product" alt="">
                            </div>
                            <div class="f fdc jcb">
                                <div class="mb025 h6 text-red-linear"> {{ item.title_product }} </div>
                                <div class="p4 gray"> Tồn kho  :{{item.quantity_in_stock}} </div>
                            </div>
                        </div>
                        <div 
                            @click="handleDeleteProduct(item.id_firebase.idScan)"
                            class="icon icon--bin"></div>
                    </div>
                    <div class="product-item__info mb1">
                        <div class="f jcb aic mb025">
                            <div class="p4 gray">Mã sản phẩm</div>
                            <div class="h6 blue"> #{{item.id}} </div>
                        </div>
                        <div class="f jcb aic mb025">
                            <div class="p4 gray">Mã lô hàng</div>
                            <div class="h6 blue"> #{{item.id}} </div>
                        </div>
                        <div class="f jcb aic mb025">
                            <div class="p4 gray">Nhà cung cấp</div>
                            <div class="p4"> The A Corp </div>
                        </div>  
                        <div class="f jcb aic mb025">
                            <div class="p4 gray">Vị trí lô</div>
                            <div class="p4"> Khu A- Kệ B- Tầng C- Hàng D </div>
                        </div>
                        <div class="f jcb aic mb025">
                            <div class="p4 gray">Ngày sản xuất</div>
                            <div class="p4"> dd/mm/yy </div>
                        </div>
                        <div class="f jcb aic mb025">
                            <div class="p4 gray">Hạn sử dụng</div>
                            <div class="p4"> dd/mm/yy </div>
                        </div>
                        <div class="f jcb aic mb025">
                            <div class="p4 gray">Đơn giá</div>
                            <div class="p4 text-red-linear"> {{item.price_product}} </div>
                        </div>
                    </div>
                    <div class="product-item__quantity f jcb aic">
                        <div class="p4" for="">Số lượng thực xuất</div>
                        <div class="product-item__quantity-change relative">
                            <input class="w100 align-c border-radius pl2 pr2" v-model.number="item.quantity" value=item.quantity type="number">
                            <!-- <div
                                @click="handlePlus"
                                class="icon-quantity icon-quantity-plus icon--plus"></div>
                            <div 
                                @click="handleMinus(item.id)"
                                class="icon-quantity icon-quantity-minus icon--minus"></div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div 
            v-for="(item, index) in productImport"
            :key="index + 'assd'">
            <div
                v-for="(value, key) in difference"
                :key="key">
                <div
                    v-if="value == item.id"
                    class="mb1">
                    <div class="product-item__container product-item__error pt1 pb1 pl1 pr1 border-red border-radius bg-white-1 mb05">
                        <div class="f jcb aic mb1">
                            <div class="large-info f aic">
                                <div class="mr05">
                                    <img :src="item.image_product" alt="">
                                </div>
                                <div class="f fdc jcb">
                                    <div class="mb025 h6 text-red-linear"> {{ item.title_product }} </div>
                                    <div class="p4 gray"> Tồn kho  :{{item.quantity_in_stock}} </div>
                                </div>
                            </div>
                            <div 
                                @click="handleDeleteProduct(item.id_firebase.idScan)"
                                class="icon icon--bin"></div>
                        </div>
                        <div class="product-item__info mb1">
                            <div class="f jcb aic mb025">
                                <div class="p4 gray">Mã sản phẩm</div>
                                <div class="h6 blue"> #{{item.id}} </div>
                            </div>
                            <div class="f jcb aic mb025">
                                <div class="p4 gray">Mã lô hàng</div>
                                <div class="h6 blue"> #{{item.id}} </div>
                            </div>
                            <div class="f jcb aic mb025">
                                <div class="p4 gray">Nhà cung cấp</div>
                                <div class="p4"> The A Corp </div>
                            </div>  
                            <div class="f jcb aic mb025">
                                <div class="p4 gray">Vị trí lô</div>
                                <div class="p4"> Khu A- Kệ B- Tầng C- Hàng D </div>
                            </div>
                            <div class="f jcb aic mb025">
                                <div class="p4 gray">Ngày sản xuất</div>
                                <div class="p4"> dd/mm/yy </div>
                            </div>
                            <div class="f jcb aic mb025">
                                <div class="p4 gray">Hạn sử dụng</div>
                                <div class="p4"> dd/mm/yy </div>
                            </div>
                            <div class="f jcb aic mb025">
                                <div class="p4 gray">Đơn giá</div>
                                <div class="p4 text-red-linear"> {{item.price_product}} </div>
                            </div>
                        </div>
                        <div class="product-item__quantity f jcb aic">
                            <div class="p4" for="">Số lượng thực xuất</div>
                            <div class="product-item__quantity-change relative">
                                <input class="w100 align-c border-radius pl2 pr2" v-model.number="item.quantity" value=item.quantity type="number">
                                <!-- <div
                                    @click="handlePlus"
                                    class="icon-quantity icon-quantity-plus icon--plus"></div>
                                <div 
                                    @click="handleMinus(item.id)"
                                    class="icon-quantity icon-quantity-minus icon--minus"></div> -->
                            </div>
                        </div>
                    </div>
                    <div class="f jce w100 p6 text-red-linear">Sản phẩm không thuộc đơn hàng</div>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
import { HTTP } from '@/http-default'

// firebase
import firebase from "@/firebaseInit";
import "firebase/firestore";
const db = firebase.firestore();

export default {
    props : {
        dataSubOrder : {
            type : Array,
            default () {
                return []
            }
        },
        listConsignment : {
            type : Array,
            default () {
                return []
            }
        },
        account : {
            type : Object,
            default () {
                return {}
            }
        },
        subOrderSelect : {
            type : Array,
            default() {
                return []
            }
        }
    },

    data () {
        return {
            listProduct : [],
            productImport : [],
            subOrder : [],
            categoryproduct : [],
            productScan : [],

            // type product
            productCorrect : [],
            productError : [],
            difference : []
        }
    },

    watch : {
        'productScan' : function () {
            this.productImport = []

            for (let k = 0; k < this.listProduct.length; k++) {
                for (let m = 0; m < this.productScan.length; m++) {
                    if (this.productScan[m].value == this.listProduct[k].key_product) {
                        this.listProduct[k].price_product = this.listProduct[k].price_product.toLocaleString('it-IT', {style : 'currency', currency : 'VND'});
                        this.listProduct[k].quantity = 1
                        this.listProduct[k].id_firebase = this.productScan[m]
                        this.$emit('updateQuantity', this.productImport)
                        this.productImport.push(this.listProduct[k])
                    }
                }
            }

            // ngược lại (chạy for productImport trước)
            // for (let i = 0; i < this.productImport.length; i++) {
            //     for (let n = 0; n < this.subOrderSelect.length; n++) {
            //         if (this.subOrderSelect[i].product.id != this.productImport[n].id) {
            //             this.$emit('updateError', true)
            //             break
            //         } else {
            //             this.$emit('updateError', false)
            //         }
            //     } 
            // }

            const productSubOrder = []
            const productImport = []

            for (let i = 0; i < this.subOrderSelect.length; i++) {
                productSubOrder.push(this.subOrderSelect[i].product.id)
            }
            
            for (let i = 0; i < this.productImport.length; i++) {
                productImport.push(this.productImport[i].id)
            }

            this.difference = productImport.filter(x => !productSubOrder.includes(x));
            if (this.difference.length != 0) {
                this.$emit('updateError', true)
            } else {
                this.$emit('updateError', false)
            }

            for (let i = 0; i < this.productImport.length; i++) {
                for (let k = 0; k < this.difference.length; k ++) {
                    if (this.productImport[i].id == this.difference[k]) {
                        this.productError.push(this.productImport[i])
                    } else {
                        this.productCorrect.push(this.productImport[i])
                    }
                }
            } 

        },

        'account' : function () {
            this.readProductScan ()
        }
    },

    methods : {
        async readProductScan() {
            if (this.account.key_doc_firebase) {
                var a = []
                let b = this.account.key_doc_firebase
                db.collection("product-item")
                .where('id', '==', b) 
                .onSnapshot(function(querySnapshot) {

                    querySnapshot.docChanges().forEach(function(change) {
                        const data = change.doc.data()
                        data.idScan = change.doc.id
                        a.push(data);
                    });
                });

                this.productScan = a
                this.$emit('updateProductScan', this.productScan)
            }
            
        },

        handleDeleteProduct (id) {
            db.collection("product-item").doc(id).delete()
            .then((res) => {
                this.readProductScan()
            }).catch((error) => {
                console.error("Error removing document: ", error);
            });
        },

        handlePlus (event) {
            var button = event.target.parentElement.parentElement
            var number = button.getElementsByTagName('input')[0]
            number.value = parseInt(number.value) + 1

            this.$emit('updateQuantity', this.productImport)
        },

        handleMinus (id) {
            for(let i = 0; i < this.productImport.length; i++) {
                if (this.productImport[i].id == id) {
                    this.productImport[i].quantity = parseInt(this.productImport[i].quantity) - 1
                }
            }

            this.$emit('updateQuantity', this.productImport)
        },


        handleConsignment (value) {
            this.$emit('updateIdProduct', value)
            this.$emit('updateQuantity', this.productImport)
            
            var consignment = document.getElementsByClassName('create-consignment__container')[0]
            consignment.classList.toggle('active')

            var createPackage = document.getElementsByClassName('create-package__container')[0]
            createPackage.classList.toggle('hide')
        },

        getData () {
            // HTTP.get('/v1/api/suborder/')
            // .then((res) => {
            //     this.subOrder = res.data.results
            //     for (let i = 0; i < this.subOrder.length; i++) {
            //         for (let n = 0; n < this.productScan.length; n++) {
            //             if (this.productScan[n].value == this.subOrder[i].id) {
            //                 // this.listProduct[i].price_product = this.listProduct[i].price_product.toLocaleString('it-IT', {style : 'currency', currency : 'VND'});
            //                 this.subOrderSelect.push(this.subOrder[i])
            //             }
            //         }
            //     }
            // })
            
            HTTP.get('/v1/api/product/')
            .then((res) => {
                this.listProduct = res.data
                // for (let k = 0; k < this.listProduct.length; k++) {
                //     for (let m = 0; m < this.subOrderSelect.length; m++) {
                //         if (this.productScan[m].value == this.listProduct[k].id) {
                //             this.listProduct[k].price_product = this.listProduct[k].price_product.toLocaleString('it-IT', {style : 'currency', currency : 'VND'});
                //             this.productImport.push(this.listProduct[k])
                //         }
                //     }
                // }
            })

            HTTP.get('v1/api/categoryproduct/')
            .then((res) => {
                this.categoryproduct = res.data.results
            })
        }
    },

    created () {
        this.getData()
        this.readProductScan()
    }
}
</script>

<style scoped lang='scss'>
    @import 'ProductScan.scss';
</style>