@<template>
    <div class="product__container">

        <!-- Header -->
        <div class="product__header f aic mb2">
            <span class="h3 pr1">Sản phẩm</span>
            <span class="h5 pl1 border-left text-red-linear">
                Sản phẩm
            </span>
        </div>

        <!-- Search -->
        <div class="container-nav text-input--search relative f jcb mb2">
            <div class="text-input__icon icon--search"></div>
            <input 
                class="text-input__el pl2 pt075 pb075 pr075" 
                type="text" 
                style="width: 417px" 
                placeholder='Nhập mã sản phẩm/tiêu đề sản phẩm bạn muốn tìm'>
        </div>

        <!-- Category -->
        <div class="product__category">

            <!-- no data -->
            <div 
                v-if="dataCategory.length == 0"
                class="category-notdata mb15 ">
                <div class="category-header h4 mb15">
                    Danh mục ngành hàng
                </div>
                <div class="category-body f fdc aic mb15 pt15 pb15 border-radius w100 bg-white-1">
                    <div class="category-body__title h4 mb15">
                        Có vẻ như bạn chưa có danh mục ngành hàng nào
                    </div>
                    <div class="category-btn f jcc aic pt075 pb075 pl1 pr1 border-radius bg-brown">
                        <svg width="16" height="12" viewBox="0 0 16 12">
                            <use xlink="http://www.w3.org/2000/svg" href="#folder" x="0" y="0"></use>
                        </svg>
                        <div class="h5 ml05 cursor white">
                            Tạo danh mục ngay
                        </div>
                    </div>
                </div>
            </div>

            <!-- have data -->
            <div 
                v-else
                class="category-data mb15">
                <div class="category-header f jcb mb15">
                    <div class="category-header__title h4">
                        Danh mục ngành hàng
                    </div>
                </div>
                <div class="category-body pt1 pb1 pl1 pr1 border-radius bg-white-1">
                    <div class="category-body__title f jcb aic mb1">
                        <div class="h5 text-red-linear">Tạo gần đây</div>
                        <router-link :to="{name: 'product-folder'}">
                            <div class="blue cursor h6">Xem tất cả các mục (99)</div>
                        </router-link>
                    </div>
                    <div class="category-list f fw w100">
                        <div 
                            class="category__item f aic w20 pt1 pb1 pl1 pr1 mr1 border border-radius bg-white-1 cursor"
                            v-for="(item, index) in dataCategory"
                            :key="index">
                            <div class="icon icon--category"></div>
                            <div class="ml1 p4"> {{ item.category_name}} </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- ListProduct -->
        <div class="product__list">

            <!-- no-data -->
            <div class="product-list">
                <div class="f jcb aic mb1">
                    <div class="list-header h4">
                        Danh sách sản phẩm
                    </div>
                </div>
                <div class="list-filter f aic">
                    <div 
                        v-for="(item, index) in filter" 
                        :key="index" >
                        <div
                            v-if="filterId === item.id"
                            class="button h6 pt05 pb05 pl075 pr075 mr05 border-radius cursor white bg-red-1">
                            {{item.title}}
                        </div>
                        <div 
                            v-else
                            @click="filterId = item.id"
                            class="button h6 pt05 pb05 pl075 pr075 mr05 border-radius cursor red bg-red">
                            {{item.title}}
                        </div>
                    </div>
                </div>
                <div class="f fdc aic w100">
                    <!-- <ItemList 
                    :data="data"
                    :titleSelect="selectTitle"
                    :function="handleStyle"
                    :handleInfo="handleInfoProductFilter"
                    :dataProps="dataProps"
                    ></ItemList> -->

                    <!-- title-product -->
                    <div
                        v-if="dataSystem.title_product" 
                        class="header w100 desktop aic pt1 pb1 pr1 pl1">
                        <div class="f aic jcc p2 mr2" style="width: 5%">
                            STT
                        </div>
                        <div 
                            v-if="dataSystem.title_product"
                            class="large-item"> {{dataSystem.title_product.text}} </div>
                        <div 
                            v-if="dataSystem.category"
                            class="small-item"> {{dataSystem.category.text}} </div>
                        <div 
                            v-if="dataSystem.price_product"
                            class="small-item"> {{dataSystem.price_product.text}} </div>
                        <div 
                            v-if="dataSystem.unit_count_type"
                            class="small-item"> {{dataSystem.unit_count_type.text}} </div>
                        <div 
                            v-if="dataSystem.quantity_in_stock"
                            class="small-item"> {{dataSystem.quantity_in_stock.text}} </div>
                        <div 
                            v-if="dataSystem.quantity_sold"
                            class="small-item"> {{dataSystem.quantity_sold.text}} </div>
                        <div 
                            v-if="dataSystem.status"
                            class="small-item"> {{dataSystem.status.text}} </div>
                    </div>

                    <!-- list-product-item -->
                    <div 
                        v-if="filterId !== 0"
                        class="list-item__contaner w100">
                        <div 
                            v-for="(item, index) in dataProduct"
                            :key="index">

                            <!-- desktop -->
                            <div 
                                v-if="item.status_product_inventory === filterId"
                                class="item__continer desktop aic pt075 pb075 pr1 pl1 mb1 border-radius bg-white-1">
                                <div class="f aic jcc p2 mr2" style="width: 5%">
                                    {{index + 1}}
                                </div>
                                <div class="large-item f aic">
                                    <img :src="item.image_product" style="width:48px;">
                                    <div class="ml05">
                                        <div class="h6 mb025 blue"> {{item.title_product}} </div>
                                        <div class="p3 blue"> {{item.key_product}} </div>
                                    </div>
                                </div>
                                <div 
                                    v-if="dataSystem.category"
                                    class="small-item p3 gray"> {{dataSystem.category.text}} </div>
                                <div class="small-item p3 gray"> {{item.price_product}} </div>
                                <div class="small-item p3 gray"> {{item.unit_count_type}} </div>
                                <div class="small-item p3 gray"> {{item.quantity_in_stock}} </div>
                                <div class="small-item p3 gray"> {{item.quantity_sold}} </div>
                                <div class="small-item p3 gray f jcc aic">
                                    <div 
                                        v-if="item.status_product_inventory == 1"
                                        class="h6 w80 pt05 pb05 border-radius green bg-green">
                                        Còn hàng
                                    </div>
                                    <div 
                                        v-if="item.status_product_inventory == 2"
                                        class="h6 w80 pt05 pb05 border-radius bg-red">
                                        <div class="text-red-linear">Hết hàng</div>
                                    </div>
                                </div>
                            </div>
                            
                            <!-- mobile -->
                            <div 
                                v-if="item.status_product_inventory === filterId"
                                class="item__continer mobile aic pt075 pb075 pr1 pl1 mb1 border-radius bg-white-1">
                                <div class="f aic mb1">
                                    <img :src="item.image_product" style="width:48px;">
                                    <div class="ml05">
                                        <div class="h6 mb025 blue"> {{item.title_product}} </div>
                                        <div class="p3 blue"> {{item.key_product}} </div>
                                    </div>
                                </div>
                                <div class="p3 gray f fw mb1">
                                    <div 
                                        v-if="item.status_product_inventory == 1"
                                        class="h6 pt05 pb05 pl1 pr1 border-radius green bg-green">
                                        Còn hàng
                                    </div>
                                    <div 
                                        v-if="item.status_product_inventory == 2"
                                        class="h6 pt05 pb05 pl1 pr1 border-radius bg-red">
                                        <div class="text-red-linear">Hết hàng</div>
                                    </div>
                                </div>
                                <div class="f jcb aic mb05">
                                    <div class="p3 gray">Danh mục sản phẩm</div>
                                    <div
                                        v-if="dataSystem.category" 
                                        class="p3">{{dataSystem.category.text}}</div>
                                </div>
                                <div class="f jcb aic mb05">
                                    <div class="p3 gray">Giá sản phẩm</div>
                                    <div class="p3">{{item.price_product}}</div>
                                </div>
                                <div class="f jcb aic mb05">
                                    <div class="p3 gray">Đơn vị tính</div>
                                    <div class="p3">{{item.unit_count_type}}</div>
                                </div>
                                <div class="f jcb aic mb05">
                                    <div class="p3 gray">Hàng có sẵn</div>
                                    <div class="p3">{{item.quantity_in_stock}}</div>
                                </div>
                                <div class="f jcb aic mb05">
                                    <div class="p3 gray">Số lượng đã bán</div>
                                    <div class="p3">{{item.quantity_sold}}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div 
                        v-else-if="filterId === 0"
                        class="list-item__contaner w100">
                        <div 
                            v-for="(item, index) in dataProduct"
                            :key="index">

                            <!-- desktop -->
                            <div 
                                class="item__continer desktop aic pt075 pb075 pr1 pl1 mb1 border-radius bg-white-1">
                                <div class="f aic jcc p2 mr2" style="width: 5%">
                                    {{index + 1}}
                                </div>
                                <div class="large-item f aic">
                                    <img :src="item.image_product" style="width:48px;">
                                    <div class="ml05">
                                        <div class="h6 mb025 blue"> {{item.title_product}} </div>
                                        <div class="p3 blue"> {{item.key_product}} </div>
                                    </div>
                                </div>
                                <div 
                                    v-if="dataSystem.category"
                                    class="small-item p3 gray"> {{dataSystem.category.text}} </div>
                                <div class="small-item p3 gray"> {{item.price_product}} </div>
                                <div class="small-item p3 gray"> {{item.unit_count_type}} </div>
                                <div class="small-item p3 gray"> {{item.quantity_in_stock}} </div>
                                <div class="small-item p3 gray"> {{item.quantity_sold}} </div>
                                <div class="small-item p3 gray f jcc aic">
                                    <div 
                                        v-if="item.status_product_inventory == 1"
                                        class="h6 w80 pt05 pb05 border-radius green bg-green">
                                        Còn hàng
                                    </div>
                                    <div 
                                        v-if="item.status_product_inventory == 2"
                                        class="h6 w80 pt05 pb05 border-radius bg-red">
                                        <div class="text-red-linear">Hết hàng</div>
                                    </div>
                                </div>
                            </div>
                            
                            <!-- mobile -->
                            <div 
                                class="item__continer mobile aic pt075 pb075 pr1 pl1 mb1 border-radius bg-white-1">
                                <div class="f aic mb1">
                                    <img :src="item.image_product" style="width:48px;">
                                    <div class="ml05">
                                        <div class="h6 mb025 blue"> {{item.title_product}} </div>
                                        <div class="p3 blue"> {{item.key_product}} </div>
                                    </div>
                                </div>
                                <div class="p3 gray f fw mb1">
                                    <div 
                                        v-if="item.status_product_inventory == 1"
                                        class="h6 pt05 pb05 pl1 pr1 border-radius green bg-green">
                                        Còn hàng
                                    </div>
                                    <div 
                                        v-if="item.status_product_inventory == 2"
                                        class="h6 w80 pt05 pb05 border-radius bg-red">
                                        <div class="text-red-linear">Hết hàng</div>
                                    </div>
                                </div>
                                <div class="f jcb aic mb05">
                                    <div class="p3 gray">Danh mục sản phẩm</div>
                                    <div
                                        v-if="dataSystem.category" 
                                        class="p3">{{dataSystem.category.text}}</div>
                                </div>
                                <div class="f jcb aic mb05">
                                    <div class="p3 gray">Giá sản phẩm</div>
                                    <div class="p3">{{item.price_product}}</div>
                                </div>
                                <div class="f jcb aic mb05">
                                    <div class="p3 gray">Đơn vị tính</div>
                                    <div class="p3">{{item.unit_count_type}}</div>
                                </div>
                                <div class="f jcb aic mb05">
                                    <div class="p3 gray">Hàng có sẵn</div>
                                    <div class="p3">{{item.quantity_in_stock}}</div>
                                </div>
                                <div class="f jcb aic mb05">
                                    <div class="p3 gray">Số lượng đã bán</div>
                                    <div class="p3">{{item.quantity_sold}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { HTTP } from '@/http-default'

export default {
    data () {
        return {
            dataSystem : [],
            dataCategory : [],
            dataProduct : [],
            filterId : 0,
            filter : [
                {
                    id : 0,
                    title : "Tất cả"
                },
                {
                    id :  1,
                    title : "Còn hàng"
                },
                {
                    id : 2,
                    title : "Hết hàng"
                }
            ]
        }
    },
    
    methods : {
        getData () {
            HTTP.get('v1/api/categoryproduct/')
            .then((res) => {
                this.dataCategory = res.data.results
            })

            HTTP.get('v1/api/product/')
            .then((res) => {
                this.dataProduct = res.data
                for (let i = 0; i < this.dataProduct.length; i++) {
                    this.dataProduct[i].price_product = this.dataProduct[i].price_product.toLocaleString('it-IT', {style : 'currency', currency : 'VND'});
                }
            })

            var systemSetting = localStorage.getItem("systemSetting")
            this.dataSystem = JSON.parse(systemSetting)
            for (let i = 0; i < this.dataSystem.length; i++) {
                if (this.dataSystem[i].name == 'product') {
                    this.dataSystem = this.dataSystem[i].props.listfield
                }
            }
        }
    },

    created () {
        this.getData ()
    }
}
</script>

<style lang="scss" scoped>
    @import 'Product.scss';
</style>>
