<template>
  <div class="nav-container w100 mt4 jcc">
    <div id="listAppNav" class="border-radius f pt05 pb05 pr05 pl05" style="width: 806px; background-color: rgba(255, 255, 255, 0.4)">
        <div v-for="(item, index) in App" :key="index">
          <div 
            v-if="
              item.titlemenu == 'quản lý nhân sự' 
              || item.titlemenu == 'quản lý sản phẩm'
              || item.titlemenu == 'quản lý đơn hàng'
              || item.titlemenu == 'quản lý kho'"
            class="app-nav__item mr025">
            <router-link :to="item.path">
              <div v-bind:class="item.icon" ></div>
            </router-link>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import { Vue } from 'vue-property-decorator';

export default Vue.extend({
  props: {
    App : Array
  }
})
</script>

<style lang="scss" scoped>
.app-nav__item {
  transform: scale(0.8);
}

.nav-container {
  position: fixed;
  bottom: 1.5em;
  left: calc(0%);
}
</style>