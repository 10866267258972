import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import HomeView from '@/views/HomeView/HomeView.vue'
// import DetailPerView from '../views/DetailView/DetailPerView.vue'
// import ListWork from '../views/ListWork.vue'
// import WorkDetail from '../views/WorkDetail.vue'
import LoginView from '@/views/LoginView/LoginView.vue'
import WareHouseView from '@/views/WareHouseView/WareHouseView.vue'
import WareHouseBodyView from '@/components/WareHouseBodyView/WareHouseBodyView.vue'
// import StockView from '@/components/StockView/StockView.vue'
import StockBodyView from '@/components/StockView/StockBodyView/StockBodyView.vue'
import OrderDetailView from '@/components/OrderDetailView/OrderDetailView.vue'

import ExportticketView from '@/components/ExportticketView/ExportticketView.vue'
import ImportTicketView from '@/components/ImportTicketView/ImportTicketView.vue'
import TicketDetail from '@/components/TicketDetail/TicketDetail.vue'
import QRcodeScanView from '@/components/QRcodeScanView/QRcodeScanView.vue'

// test


const authenticated = localStorage.getItem("user")

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'Home Page - Example App',
      metaTags: [
        {
          name: 'description',
          content: 'The home page of our example app.'
        },
        {
          property: 'og:description',
          content: 'The home page of our example app.'
        }
      ]
    }
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/:path',
    name: 'ware-house',
    redirect: '/quan-ly-product',
    component: WareHouseView,
    children: [
      {
        path: "/:sidebaritem",
        name: 'body-view',
        component: WareHouseBodyView,
        children: [
          {
            path: "/:sidebaritem/:menuitem",
            name: 'menu-stock-view',
            component: StockBodyView,
          },
          {
            path: "/:sidebaritem/:menuitem/:filter",
            name: 'filter-ticket',
            component: StockBodyView,
          },
        ]
      },
      {
        path: "/:sidebaritem/order/detail-order/:id",
        name: 'detail-view',
        component: OrderDetailView,
      },
      {
        path: "/:sidebaritem/order/detail-order/:id/exportticket",
        name: 'exportticket-view',
        component: ExportticketView,
      },
      {
        path: "/:sidebaritem/ticket/create-ticket/importticket",
        name: 'importticket-view',
        component: ImportTicketView,
      },
      {
        path: "/:sidebaritem/ticket/create-ticket/importticket",
        name: 'importticket-view',
        component: ImportTicketView,
      },
      {
        path: "/:sidebaritem/:menuitem/:filter/:typeTicket/:id",
        name: 'detail-ticket-view',
        component: TicketDetail,
      },
    ]
  },
  // {
  //   path: '/:path/:id',
  //   name: 'detail',
  //   component: DetailPerView
  // },
  // {
  //   path: '/personnel/detail',
  //   name: 'personnel-detail',
  //   component: DetailPerView
  // },
  {
    path: '/:sidebaritem/:id/exportticket/qr-code',
    name: 'qr-code',
    component: QRcodeScanView
  },
  {
    path: '/ware-house',
    name: 'ware-houses',
    component: WareHouseView
  },

]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name !== 'login' && !authenticated) next({ name: 'login' })
  else if (to.name === 'login' && authenticated) next ({ name: 'home'})
  else next()
})

export default router
