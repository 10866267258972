@<template>
    <div class="popup popup-loading jcc aic">
        <div class="loading__container f fdc jcb aic pt15 pb15 pl1 pr1 border-radius bg-white-1">
            <div class="icon--noti"></div>
            <div class="mb05 text-red-linear h3">Thông báo</div>
            <div class="p2 gray mb15">Đang chuyển tiếp trang</div>
            <div class="loader-1"></div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped lang='scss'>
    @import 'Loading.scss';
</style>