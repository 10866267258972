@<template>
    <div class="popup popup-confirm-delete jcc aic">
        <div class="confirm-delete__container f fdc jcc aic pt15 pb15 pl1 pr1 border-radius bg-white-1">
            <div class="icon--noti"></div>
            <div class="h3 mt1 mb05 text-red-linear">Thông báo</div>
            <div class="p2 mb15 gray">Xác nhận xóa lô hàng?</div>
            <div class="confirm-delete__action w100 f jcb aic">
                <div 
                    @click="handleBack"
                    class="w50 pt1 pb1 h5 align-c border-radius border">
                    Quay lại
                </div>
                <div 
                    @click="handleConfirmDelete"
                    class="w50 pt1 pb1 ml1 h5 align-c border-radius bg-red-1 white cursor">
                    Xác nhận
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props : {
        idConsignmentDelete : String,
        listConsignment : {
            type : Array,
            default() {
                return []
            }
        }
    },

    methods : {
        handleBack () {
            var popup = document.getElementsByClassName('popup-confirm-delete')[0]
            popup.classList.toggle('f')
        },

        handleConfirmDelete () {
            for (let i = 0; i < this.listConsignment.length; i++) {
                if (this.listConsignment[i].consignment_key == this.idConsignmentDelete) {
                    // eslint-disable-next-line vue/no-mutating-props
                    this.listConsignment.splice(i, 1)
                    var popup = document.getElementsByClassName('popup-confirm-delete')[0]
                    popup.classList.toggle('f')
                    break
                }
            }
        }
    }
}
</script>

<style scoped lang='scss'>
    @import 'DeleteConsignment.scss';
</style>