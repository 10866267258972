@<template>
    <div class="product-item">
        <div 
            v-for="(item, index) in subOrderSelect"
            :key="index + 4">
            <div class="product-item__container pt1 pb1 pl1 pr1 mb1 border-radius bg-gray-3">
                <div>
                    <div class="large-info f aic mb1">
                        <div class="mr05">
                            <img :src="item.product.image_product" alt="">
                        </div>
                        <div class="f fdc jcb">
                            <div class="mb025 h6 text-red-linear"> {{item.product.title_product}} </div>
                            <div class="p4 gray"> Hàng tồn: {{item.product.quantity_in_stock}} </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div class="large-info f aic w100">
                        <div class="product-item__info w100">
                            <div class="f jcb aic mb025">
                                <div class="p4 gray">Mã sản phẩm</div>
                                <div class="h6 blue"> #{{ item.product.id }} </div>
                            </div>
                            <div class="f jcb aic mb025">
                                <div class="p4 gray">Nhà cung cấp</div>
                                <div class="p3"> {{ item.product.organization }} </div>
                            </div>
                            <div 
                                v-for="(category, y) in categoryproduct"
                                :key="y + 2">
                                <div 
                                    v-if="category.id == item.product.category[0]"
                                    class="f jcb aic mb025">
                                    <div class="p4 gray">Danh mục sản phẩm</div>
                                    <div class="p4">
                                        {{ category.category_name }}
                                    </div>
                                </div>
                            </div>
                            <div class="f jcb aic mb025">
                                <div class="p4 gray">Đơn giá</div>
                                <div class="h6"> {{ item.product.price_product }} </div>
                            </div>
                            <div class="f jcb aic mb025">
                                <div class="p4 gray">Số lượng thực xuất</div>
                                <div class="h6"> {{ item.quantity_order }} </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { HTTP } from '../../../http-default'

export default {
    props : {
        dataSubOrder : {
            type : Number
        }
    },

    data () {
        return {
            subOrder : [],
            subOrderSelect : [],
            categoryproduct : []
        }
    },

    methods : {
        getData () {
            HTTP.get('/v1/api/suborder/')
            .then((res) => {
                this.subOrder = res.data.results
                for (let i = 0; i < this.subOrder.length; i++) {
                    if (this.subOrder[i].order != null) { 
                        if (this.dataSubOrder == this.subOrder[i].order.id) {
                            this.subOrder[i].product.price_product = this.subOrder[i].product.price_product.toLocaleString('it-IT', {style : 'currency', currency : 'VND'});
                            this.subOrderSelect.push(this.subOrder[i])
                        }
                    }
                }
            })
        
            HTTP.get('v1/api/categoryproduct/')
            .then((res) => {
                this.categoryproduct = res.data.results
            })
        } 
    },

    created () {
        this.getData()
    }
}
</script>

<style lang='scss' scoped>
    @import 'ProductItem.scss';
</style>