@<template>
    <div class="popup aic jcc popup-create-package">

        <!-- havent consignment -->
        <div
            v-if="statusConsignment"
            class="create-package__container border-radius bg-gray-1">
            <div class="create-package__body f">
                <div 
                    v-if="!isMobile()"
                    class="create-package__body-left pt1 pb1 pl1 pr1 bg-white-1">
                    <div class="body-left__header h5 text-red-linear mb1">
                        Thông tin sản phẩm
                    </div>
                    <div class="f jcb aic mb05">
                        <div class="h6">Thiết bị đã đồng bộ</div>
                        <div 
                            @click="handleTutorial"
                            class="p4 blue cursor">Hướng dẫn</div>
                    </div>
                    
                    <div class="list-device">
                        <!-- No-data-device -->
                        <div    
                            v-if="listDevice.length == 0"
                            class="body-left__body">
                                <div class="p4 mb1 gray">Chưa có dữ liệu</div>
                                <div class="f ais  pt1 pb1 pl1 pr1 border-radius border-yellow bg-yellow">
                                    <div class="icon icon--warning"></div>
                                    <div class="p4 ml1 orange">Bạn cần đồng bộ tài khoản của bạn với ít nhất 01 thiết bị di động (với camera sẵn sàng hoạt động) để tiến hành quét mã</div>
                                </div> 
                        </div>
                        <!-- Have-data-device -->
                        <div 
                            v-else
                            class="body-left__body">
                            <div 
                                v-for="(item, index) in listDevice"
                                :key="index">
                                <div v-if="item.connect != null && item.sessionId != sessionMyDevice">
                                    <div v-if="item.connect.response ">
                                        <div class="p6 mb05 green">Đang kết nối</div>
                                        <div class="device-connection">
                                            <div>
                                                <div 
                                                    class="device-item f jcb aic pt1 pb1 pl1 pr1 mb05 w100 border-green border-radius bg-gray-1">
                                                    <div class="p4">{{ item.name }}</div>
                                                    <div 
                                                        @click="handleDisconnection(item.sessionId)"
                                                        class="p6 red-1 cursor">Hủy bỏ
                                                    </div>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="p6 mb05 blue">Đang hoạt động</div>
                                <div class="disconnect-device">
                                    <div 
                                        v-for="(item, index) in listDevice"
                                        :key="index">
                                        <div 
                                            v-if="item.sessionId != sessionMyDevice && item.connect == null"
                                            class="device-item f jcb aic pt1 pb1 pl1 pr1 mb05 w100 border-radius bg-gray-1">
                                            <div class="p4">{{ item.name }}</div> 
                                            <div 
                                                @click="handleConnectionDevice(item.sessionId)"
                                                class="p6 blue cursor">Sử dụng
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="create-package__body-right pt1 pb1 pl1 pr1">
                    <div class="body-right__header f jcb aic mb1">
                        <div class="h5 gray">Sản phẩm quét được</div>
                        <div 
                            v-if="!isMobile()"
                            class="p4"> {{ this.productImport.length }} sản phẩm </div>
                        <div 
                            v-if="isMobile()"
                            @click="handleOpenCamera()"
                            class="cemera-scan f aic">
                            <div class="icon--camera"></div>
                            <div class="p6 gray ml05">Quét mã</div>
                        </div>
                    </div>
                    <div class="create-package__product mrn1">
                        <ProductScanView 
                            :account='account'
                            :dataSubOrder='dataSubOrder'
                            :listConsignment='listConsignment'
                            @updateIdProduct='updateIdProduct'
                            @updateProductScan='updateProductScan'></ProductScanView>
                    </div>
                </div>
            </div>
            <div class="create-package__action w100 f aic jce pt1 pb1 pl1 pr1 border-top bg-gray-1">
                <div 
                    class="h5 align-c border border-radius pt1 pb1 mr1 bg-white-1 cursor"
                    @click="handlePackage">Hủy bỏ</div>
                <div
                    v-if="productImport.length == 0" 
                    class="h5 align-c border-radius pt1 pb1 cursor bg-red white">
                        Tiếp tục
                </div>
                <div
                    v-else
                    @click="handleConfirmProduct"
                    class="h5 align-c border-radius pt1 pb1 cursor bg-red-1 white">
                        Tiếp tục
                </div>
            </div>
        </div>
        
        <!-- have consignment -->
        <div 
             v-if="!statusConsignment"
            class="confirm-product__container relative pt1 pb1 pl1 pr1 border-radius bg-gray-1">
            <div class="confirm-product__body f">
                <div class="confirm-product__body-left">
                    <div class="h5 mb1 text-red-linear">Thông tin sản phẩm</div>
                    <div class="list-product">
                        <div 
                            v-for="(item, index) in productImport"
                            :key="index">
                            <div 
                                v-if="item.id == idProduct"
                                @click="handleSelect(item.id)"
                                class="product-item pt1 pb1 pl1 pr1 mb05 border-radius cursor border-red bg-white-1">
                                <div >
                                    <div class="f aic">
                                        <div class="mr05">
                                            <img :src="item.image_product" alt="">
                                        </div>
                                        <div class="f fdc jcb">
                                            <div class="mb025 h6"> {{item.title_product}} </div>
                                            <div class="p4 gray"> Hàng có sẵn: {{item.quantity_in_stock}} {{item.unit_count_type}} </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div 
                                v-else
                                @click="handleSelect(item.id)"
                                class="product-item pt1 pb1 pl1 pr1 mb05 border-radius cursor bg-white-1">
                                <div>
                                    <div class="f aic">
                                        <div class="mr05">
                                            <img :src="item.image_product" alt="">
                                        </div>
                                        <div class="f fdc jcb">
                                            <div class="mb025 h6"> {{item.title_product}} </div>
                                            <div class="p4 gray"> Hàng có sẵn: {{item.quantity_in_stock}} {{item.unit_count_type}} </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="confirm-product__body-right ml1">
                    <div 
                        v-for="(item, index) in productImport"
                        :key="index">
                        <div 
                            v-if="item.id == idProduct"
                            class="product-item__container pt1 pb1 pl1 pr1 mb1 border-radius bg-white-1">
                            <div class="f jcb aic mb1">
                                <div class="large-info f aic">
                                    <div class="mr05">
                                        <img :src="item.image_product" alt="">
                                    </div>
                                    <div class="f fdc jcb">
                                        <div class="mb025 h6"> {{ item.title_product }} </div>
                                        <div class="p4 gray"> Hàng có sẵn :{{item.quantity_in_stock}} {{item.unit_count_type}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="product-item__info mb1">
                                <div class="f jcb aic mb025">
                                    <div class="p4 gray">Mã sản phẩm</div>
                                    <div class="h6 blue"> #{{item.id}} </div>
                                </div>
                                <div class="f jcb aic mb025">
                                    <div class="p4 gray">Nhà cung cấp</div>
                                    <div class="p4"> The A Corp </div>
                                </div>  
                            </div>
                            <div class="product-item__create-consignment mb1 pt1 pb1 border-bottom border-top">
                                <div class="h6 mb05 gray">Thiết lập thông tin nhập hàng</div>
                                <div
                                    v-if="listConsignment.length == 0" 
                                    class="p6 mb05 gray-5">Chưa có lô hàng nào được thiết lập</div>
                                <div class="product-item__list-consignment mt05 mb05">
                                    <div 
                                        v-for="(value, index) in listConsignment"
                                        :key="index">
                                        <div v-if="value.product == item.id">
                                            <div class="pt1 pb1 pl1 pr1 mb075 bg-gray-1 border-radius">
                                                <div class="f jcb aic">
                                                    <div>
                                                        <div class="h6"> {{ value.title_consignment }} </div>
                                                        <div class="p4 blue"> {{ value.consignment_key }} </div>
                                                    </div>
                                                    <div>
                                                        <div
                                                            @click="handleViewConsignment(value.consignment_key)" 
                                                            class="icon icon--eye"></div>
                                                    </div>
                                                </div>
                                                <div class="mt1 pt1 border-top">
                                                    <div class="f jcb aic mb05">
                                                        <div class="p3">Số lượng sản phẩm</div>
                                                        <div class="p4"> {{value.quantity_in_consignment}} đơn vị</div>
                                                    </div>
                                                    <div class="f jcb aic">
                                                        <div class="p3">Tổng giá trị</div>
                                                        <div class="p4 text-red-linear"> {{ formatMoney(value.total_price) }} </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="product-item__total">
                                <div class="f jcb aic mb025">
                                    <div class="p3 gray">Tổng số lượng nhập</div>
                                    <div class="p4">{{ item.total_quantity_consignment }} đơn vị</div>
                                </div>
                                <div class="f jcb aic">
                                    <div class="p3 gray">Tổng giá trị nhập</div>
                                    <div
                                        v-if="item.total_price_consignment"
                                        class="p4 text-red-linear"> {{ formatMoney(item.total_price_consignment) }} </div>
                                    <div 
                                        v-else
                                        class="p4 text-red-linear">Chưa có dữ liệu</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="confirm-product__footer w100 f aic jcb pt1 pb1 pl1 pr1 border-top bg-gray-1">
                <div class="confirm-product__total w80 p4">
                    Tổng giá trị nhập: <span class="h5 text-red-linear"> {{ formatMoney(totalPrice) }} </span>
                </div>
                <div class="confirm-product__action w100 f aic jce">
                    <div 
                        @click="handlePackage"
                        class="h5 align-c border border-radius pt1 pb1 cursor">
                            Quay lại
                    </div>
                </div>
            </div>
        </div>

        <QRcodeScanView 
            @handleClose='handleClose'
            v-if="cameraScan"/>

        <TutorialConnectView></TutorialConnectView>

        <CreateConsignmentView 
            @updateConsignment="updateConsignment"
            :idProduct="idProduct"
            :account='account'></CreateConsignmentView>

        <ConfirmProductView 
            v-if="statusConfirm"
            @closePopup='closePopup'
            @updateProductTicket='updateProductTicket'
            @updateStatusConsignment='updateStatusConsignment'
            :productImport='productImport'
            :listConsignment='listConsignment'></ConfirmProductView>
        <ViewConsignment 
            :listConsignment='listConsignment'
            :keySelect='keySelect'></ViewConsignment>
    </div>  
</template>

<script>
import ViewConsignment from '@/components/ImportTicketView/CreatePackageView/ConfirmProductView/ViewConsignment/ViewConsignment.vue'
import ProductScanView from '@/components/ImportTicketView/CreatePackageView/ProductScanView/ProductScanView.vue'
import ConfirmProductView from '@/components/ImportTicketView/CreatePackageView/ConfirmProductView/ConfirmProductView.vue'
import TutorialConnectView from '@/components/ImportTicketView/CreatePackageView/TutorialConnectView/TutorialConnectView.vue'
import CreateConsignmentView from '@/components/ImportTicketView/CreatePackageView/CreateConsignmentView/CreateConsignmentView.vue'
import QRcodeScanView from '@/components/QRcodeScanView/QRcodeScanView.vue'

import { HTTP } from '@/http-default'
import axios from 'axios'

export default {
    components : {
        ProductScanView,
        ConfirmProductView,
        TutorialConnectView,
        CreateConsignmentView,
        ViewConsignment,
        QRcodeScanView
    },

    props : {
        dataSubOrder : {
            type : Array,
            default () {
                return []
            }
        },
        account : {
            type : Object,
            default () {
                return {}
            }
        }
    },

    data() {
        return {
            // trang thai popup
            statusConfirm : false,
            cameraScan : false,
            // data
            statusConsignment : true,
            listProduct : [],
            productOrder : [],
            productScan : [],
            NewSubPackage : [],
            subOrder : [],
            subOrderSelect : [],
            listDevice : [],
            productImport : [],
            sessionMyDevice : '',
            
            // consignment
            keySelect : '',
            listConsignment : [],
            idProduct : 1,
            totalPrice : 0
        }
    },

    watch : {
        // 'listConsignment' : function () {
        //     this.$emit('updateProductTicket', this.productImport)
        // }
    },
  
    methods: {
        // formatMoney
        formatMoney(value) {
            value = value.toLocaleString('it-IT', {style : 'currency', currency : 'VND'})
            return value
        },

        handleOpenCamera () {
            this.cameraScan = true
            var container = document.getElementsByClassName('create-package__container')[0]
            container.classList.toggle('hide')
        },

        handleClose () {
            this.cameraScan = false
            var container = document.getElementsByClassName('create-package__container')[0]
            container.classList.toggle('hide')
        },

        isMobile () {
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        },

        // update product ticket
        updateProductTicket (value) {
            this.$emit('updateProductTicket', value)
        },

        handleSelect (id) {
            this.idProduct = id
        },

        // updateStatusConsignment
        updateStatusConsignment (value, price) {
            this.statusConsignment = value
            this.totalPrice = price

            this.$emit('updateTotalPrice', this.totalPrice)
        },

        // update product scan
        updateProductScan (value) { 
            this.productImport = value
            if (this.productImport.length != 0) {
                this.idProduct = this.productImport[0].id
            }
            
            // this.$emit('updateProductTicket', this.productImport)
        },

        // update id product
        updateIdProduct (value) {
            this.idProduct = value
        },

        // update consignment
        updateConsignment (value) {
            this.listConsignment.push(value)
        },

        // view consignment
        handleViewConsignment (value) {
            this.keySelect = value
            var popup = document.getElementsByClassName('popup-view-consignment')[0]
            popup.classList.add('f')
        },

        // open tutorial
        handleTutorial () {
            var tutorial = document.getElementsByClassName('tutorial-connect__container')[0]
            tutorial.classList.toggle('active')
            var popup = document.getElementsByClassName('create-package__container')[0]
            popup.classList.toggle('hide')
        },

        // connection device
        handleConnectionDevice (sessionId) {

            const token = localStorage.getItem('user')
            const axiosConfig = {
                headers : {
                    'Content-Type': 'application/json',
                    'Authorization': 'Token '+ token,
                },
                credentials: 'include'
                
            }
            const dataDevice = JSON.stringify({
                "sessionId_req" : this.$cookies.get('sessionId'),
                "sessionId_res" : sessionId
            });

            
            axios.post('https://api.nmeco.xyz/getdevice/', dataDevice, axiosConfig, {withCredentials: true})
            // .then(() => {
            //     this.getData()
            // })
        },
        // Disconnection
        handleDisconnection (sessionId) {
            var sessionid = []
            sessionid.push(sessionId)
            var data = JSON.stringify({
                "list_session" : sessionid
            })
            HTTP.put('disconnect/', data)
            // .then(() => {
            //     setInterval(this.getData(), 5000)
            // })
        },

        handlePackage () {
            var popup = document.getElementsByClassName('popup-create-package')[0]
            popup.classList.toggle('active-popup')
        },

        closePopup () {
            this.statusConfirm = false
        },

        handleConfirmProduct () {
            this.$emit('updateListConsignment', this.listConsignment)
            this.statusConfirm = true
            var createPackage = document.getElementsByClassName('create-package__container')[0]
            createPackage.classList.toggle('hide')
            // var confirmProduct = document.getElementsByClassName('confirm-product__container')[0]
            // confirmProduct.classList.toggle('active')

            // this.productSelect = []
            // this.productScan.forEach(i => {
            //     this.productSelect.push(i.value)
            // })
        },

        getData () {
                this.sessionMyDevice = this.$cookies.get('sessionId')
                HTTP.get('/v1/api/suborder/')
                .then((res) => {
                    this.subOrder = res.data.results
                    for (let i = 0; i < this.subOrder.length; i++) {
                        for (let n = 0; n < this.dataSubOrder.length; n++) {
                            if (this.dataSubOrder[n] == this.subOrder[i].id) {
                                // this.listProduct[i].price_product = this.listProduct[i].price_product.toLocaleString('it-IT', {style : 'currency', currency : 'VND'});
                                this.subOrderSelect.push(this.subOrder[i])
                            }
                        }
                    }
                })

                HTTP.get('/v1/api/product/')
                .then((res) => {
                    this.listProduct = res.data
                    for (let k = 0; k < this.listProduct.length; k++) {
                        for (let m = 0; m < this.subOrderSelect.length; m++) {
                            if (this.subOrderSelect[m].product == this.listProduct[k].id) {
                                this.listProduct[k].price_product = this.listProduct[k].price_product.toLocaleString('it-IT', {style : 'currency', currency : 'VND'});
                                this.productOrder.push(this.listProduct[k])
                            }
                        }
                    }
                })
        },

        getDataDevice () {
            setInterval(() => {
                HTTP.get('getdevice/')
                .then((res) => {
                    this.listDevice = res.data.device
                    this.listDevice.forEach(item => {
                        var nameDevice = item.name.split('(')
                        var b = nameDevice[1].split(')')
                        item.name = b[0]
                    })
                })
            }, 2000)
        }
    },

    created () {
        // this.getData();
        this.getData(); 
        this.getDataDevice ()
    } 
}
</script>

<style scoped lang="scss">
    @import 'CreatePackage.scss';
</style>