<template>
    <div class="f fdc w100">
        <div class="relative f fw jcb w100 mt2">
            <div class="desktop relative aic">
                    <img src="../../assets/imgLogoCompany.png" alt="">
                    <div 
                        class="organization-title h3 ml1 cursor white"
                        @click="handleSelect"> {{ organizationSelect }}</div>
                    <div 
                        class="popup-organization border-radius price-large bg-white-1"
                        >
                        <div 
                            class="organization-item p4 pt05 pb05 pl1 pr1 border-radius w100 cursor"
                            @click="handleOrganizationSelect"
                            v-for="(item, index) in organization"
                            :key="index"> 
                            {{item.title_organization}}  </div>
                    </div>
                <div class="absolute icon icon--down"></div>
            </div>
            <div @click="handleLogout" class="f aic cursor">
                <img class="w20" src="../../assets/imgLogoCompany.png" alt="">
                <div class="ml1 align-r">
                    <p class="white h6">
                        {{account.full_name}}
                    </p>
                    <p class="white p6">
                        Giám đốc công nghệ
                    </p>
                </div>
            </div>
            <PopupLogoutView></PopupLogoutView>
        </div>
        <div class="w100 mt15 ralative">
            <img src="../../assets/Enter email.png" class="absolute mt1 ml15" style="height: 18px">
            <input class="w100 pt1 pb1 pl4" placeholder="Tìm kiếm ..." type="text" name="" id="">
        </div>
    </div>
</template>

<script>
import PopupLogoutView from '@/components/PopupLogout/PopupLogoutView.vue'
import { HTTP } from '@/http-default'
export default {
    components: {
        PopupLogoutView
    },
    props: {
        organization : Array,
    },
    data () {
        return {
            organizationSelect : '',
            account : []
        }
    },
    methods: {
        handleLogout() {
            var logout = document.getElementsByClassName('logout')[0]
            logout.classList.toggle('active')
        },
        handleOrganizationSelect(event) {
            var button = event.target
            var organization = button.innerText
            var title = document.getElementsByClassName('organization-title')[0]
            title.innerHTML = `${organization}`
            this.handleSelect()
        },
        handleSelect () {
            var popup = document.getElementsByClassName('popup-organization')[0]
            popup.classList.toggle('active-popup')
        },

        getData () {
            HTTP.get('v1/api/account/')
            .then((res) => {
                this.account = res.data[0]
                this.organizationSelect = this.organization[0].title_organization
            })
        }
    },

    created () {
        this.getData()
    }
}

</script>

<style scoped lang="scss">
@import "HeaderHomeView.scss";
</style>


