@<template>
    <div class="confirm-product__container relative pt1 pb1 pl1 pr1 border-radius bg-gray-1">
        <div 
            v-if="dataProductConfirm.length !== 0"
            class="confirm-product__body f">
            <div 
                v-if="!isMobile()"
                class="confirm-product__body-left">
                <div class="h5 mb1 text-red-linear">Xác nhận thông tin sản phẩm</div>
                <div class="list-product">
                    <div 
                        v-for="(item, index) in dataProductConfirm"
                        :key="index">
                        <div 
                            v-if="item.id == idProduct"
                            @click="handleSelect(item.id)"
                            class="product-item pt1 pb1 pl1 pr1 mb05 border-radius cursor border-red bg-white-1">
                            <div>
                                <div class="f aic">
                                    <div class="mr05">
                                        <img :src="item.image_product" alt="">
                                    </div>
                                    <div class="f fdc jcb">
                                        <div class="product-item__title mb025 h6"> {{item.title_product}} </div>
                                        <div class="p4 gray"> Hàng có sẵn: {{item.quantity_in_stock}} </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div 
                            v-else
                            @click="handleSelect(item.id)"
                            class="product-item pt1 pb1 pl1 pr1 mb05 border-radius cursor bg-white-1">
                            <div>
                                <div class="f aic">
                                    <div class="mr05">
                                        <img :src="item.image_product" alt="">
                                    </div>
                                    <div class="f fdc jcb">
                                        <div class="product-item__title mb025 h6"> {{item.title_product}} </div>
                                        <div class="p4 gray"> Hàng có sẵn: {{item.quantity_in_stock}} </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div 
                v-if="!isMobile()"
                class="confirm-product__body-right ml1">
                <div class="h5 mb1 gray">Chi tiết thông tin sản phẩm</div>
                <div 
                    v-for="(item, index) in dataProductConfirm"
                    :key="index">
                    <div 
                        v-if="item.id == idProduct"
                        class="product-item__container pt1 pb1 pl1 pr1 mb1 border-radius bg-white-1">
                        <div>
                            <div class="f aic mb05">
                                <div class="mr05">
                                    <img :src="item.image_product" alt="">
                                </div>
                                <div class="f fdc jcb">
                                    <div class="mb025 h6"> {{item.title_product}} </div>
                                    <div class="p4 gray"> Hàng có sẵn: {{item.quantity_in_stock}} </div>
                                </div>
                            </div>
                        </div>
                        <div class="product-item__info mb1 border-bottom">
                            <div class="f jcb aic mb025">
                                <div class="p3 gray">Mã sản phẩm</div>
                                <div class="h6 blue"> #{{item.id}} </div>
                            </div>
                            <div class="f jcb aic mb025">
                                <div class="p3 gray">Mã lô hàng</div>
                                <div class="h6 blue"> #{{item.id}} </div>
                            </div>
                            <div class="f jcb aic mb025">
                                <div class="p3 gray">Vị trí lô</div>
                                <div class="p4"> Khu A- Kệ B- Tầng C- Hàng D </div>
                            </div>
                            <div class="f jcb aic mb025">
                                <div class="p3 gray">Nhà cung cấp</div>
                                <div class="p4"> The A Corp </div>
                            </div>
                            <div class="f jcb aic mb025">
                                <div class="p3 gray">Ngày sản xuất</div>
                                <div class="p4"> dd/mm/yy </div>
                            </div>
                            <div class="f jcb aic mb025">
                                <div class="p3 gray">Hạn sử dụng</div>
                                <div class="p4"> dd/mm/yy </div>
                            </div>
                            <div class="f jcb aic mb025">
                                <div class="p3 gray">Đơn giá</div>
                                <div class="p4 text-red-linear"> {{item.price_product}} </div>
                            </div>
                        </div>
                        <div
                            v-if="item.quantity" 
                            class="product-item__quantity f jcb aic">
                            <div class="p4">Số lượng thực xuất</div>
                            <div class="h6"> {{item.quantity}} </div>
                        </div>
                    </div>
                </div>
            </div>
            <div 
                v-if="isMobile()"
                class="confirm-product__body-right">
                <div class="h5 mb1 gray">Chi tiết thông tin sản phẩm</div>
                <div 
                    v-for="(item, index) in dataProductConfirm"
                    :key="index">
                    <div 
                        class="product-item__container pt1 pb1 pl1 pr1 mb1 border-radius bg-white-1">
                        <div>
                            <div class="f aic mb05">
                                <div class="mr05">
                                    <img :src="item.image_product" alt="">
                                </div>
                                <div class="f fdc jcb">
                                    <div class="mb025 h6"> {{item.title_product}} </div>
                                    <div class="p4 gray"> Hàng có sẵn: {{item.quantity_in_stock}} </div>
                                </div>
                            </div>
                        </div>
                        <div class="product-item__info mb1 border-bottom">
                            <div class="f jcb aic mb025">
                                <div class="p3 gray">Mã sản phẩm</div>
                                <div class="h6 blue"> #{{item.id}} </div>
                            </div>
                            <div class="f jcb aic mb025">
                                <div class="p3 gray">Mã lô hàng</div>
                                <div class="h6 blue"> #{{item.id}} </div>
                            </div>
                            <div class="f jcb aic mb025">
                                <div class="p3 gray">Vị trí lô</div>
                                <div class="p4"> Khu A- Kệ B- Tầng C- Hàng D </div>
                            </div>
                            <div class="f jcb aic mb025">
                                <div class="p3 gray">Nhà cung cấp</div>
                                <div class="p4"> The A Corp </div>
                            </div>
                            <div class="f jcb aic mb025">
                                <div class="p3 gray">Ngày sản xuất</div>
                                <div class="p4"> dd/mm/yy </div>
                            </div>
                            <div class="f jcb aic mb025">
                                <div class="p3 gray">Hạn sử dụng</div>
                                <div class="p4"> dd/mm/yy </div>
                            </div>
                            <div class="f jcb aic mb025">
                                <div class="p3 gray">Đơn giá</div>
                                <div class="p4 text-red-linear"> {{item.price_product}} </div>
                            </div>
                        </div>
                        <div
                            v-if="item.quantity" 
                            class="product-item__quantity f jcb aic">
                            <div class="p4">Số lượng thực xuất</div>
                            <div class="h6"> {{item.quantity}} </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="confirm-product__action w100 f aic jce pt1 pb1 pl1 pr1 border-top bg-gray-1">
            <div 
                @click="handleBack"
                class="h5 align-c border border-radius pt1 pb1 mr1 cursor">Quay lại</div>
            <div
                @click="postDataPackage"
                class="h5 align-c border-radius pt1 pb1 cursor bg-red-1 white">
                    Hoàn thành
            </div> 
        </div>
    </div>
</template>  

<script>
import { HTTP } from '@/http-default'
import axios from 'axios'

// import firebase from "@/firebaseInit";
// import "firebase/firestore";
// const db = firebase.firestore();
export default {
    props : {
        dataSubOrder : {
            type : Array,
            default() {
                return []
            }
        },
        productScan : {
            type : Array,
            default() {
                return []
            }
        },
        productImport : {
            type : Array,
            default() {
                return []
            }
        },
        dataOrder : {
            type : Object,
            default() {
                return {}
            }
        }
    },

    watch : {
        'productImport' : function () {
            if (this.productImport.length != 0) {
                this.idProduct = this.productImport[0].id
                this.dataProductConfirm = this.productImport
            }
        }
    },

    data () {
        return {
            dataProductConfirm: [],
            account : {},
            idProduct : 1,
            subOrder : [],
            subOrderSelect : [],
            listProduct : [],
            productOrder : [],
            subPackage : [],
            NewSubPackage : [],

            packageKeyParent : ''
        }
    },

    methods : {
        handleClose () {
            var confirmProduct = document.getElementsByClassName('popup-create-package')[0]
            confirmProduct.classList.toggle('active-poup')
        },

        isMobile () {
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        },


        handleBack () {
            this.dataProductConfirm = []
            this.$emit('updateDataTest')
            var createPackage = document.getElementsByClassName('create-package__container')[0]
            createPackage.classList.toggle('hide')
            var confirmProduct = document.getElementsByClassName('confirm-product__container')[0]
            confirmProduct.classList.toggle('active')
        },

        handleSelect (id) {
            this.idProduct = id
        },

        getData () {
            HTTP.get('/v1/api/suborder/')
            .then((res) => {
                this.subOrder = res.data.results
                for (let i = 0; i < this.subOrder.length; i++) {
                    for (let n = 0; n < this.dataSubOrder.length; n++) {
                        if (this.dataSubOrder[n] == this.subOrder[i].id) {
                            this.listProduct[i].price_product = this.listProduct[i].price_product.toLocaleString('it-IT', {style : 'currency', currency : 'VND'});
                            this.subOrderSelect.push(this.subOrder[i])
                        }
                    }
                }
            })

            HTTP.get('/v1/api/product/')
            .then((res) => {
                this.listProduct = res.data
                for (let k = 0; k < this.listProduct.length; k++) {
                    for (let m = 0; m < this.subOrderSelect.length; m++) {
                        if (this.subOrderSelect[m].product == this.listProduct[k].id) {
                            this.listProduct[k].price_product = this.listProduct[k].price_product.toLocaleString('it-IT', {style : 'currency', currency : 'VND'});
                            this.productOrder.push(this.listProduct[k])
                        }
                    }
                }
            })

            HTTP.get('v1/api/account/')
            .then((res) => {
                this.account = res.data[0]
            })
        },

        async postDataPackage () {
            const token = localStorage.getItem('user')
            const axiosConfig = {
                headers : {
                    'Content-Type': 'application/json',
                    'Authorization': 'Token '+ token,
                }
            }

            const dataSubTicket = JSON.stringify({
                "package_key": Math.floor(Math.random() * 9999999999),
                "created_at": "2022-05-18T07:53:12Z",
                "user_create": this.account['user'],
                "organization": 2,
                "weight_measure": 1,
                "dimensions_measure": 1
            });

            axios.post('https://api.nmeco.xyz/v1/api/package/', dataSubTicket, axiosConfig)
            .then((res) => {
                this.$emit('updatePackageParent', res.data.package_key)
                for (let n = 0; n < this.productImport.length; n++) {
                    var price = this.productImport[n].price_product.slice(0, -4).split('.').join('');
                    const dataSubTicket = JSON.stringify({
                        "package_key": Math.floor(Math.random() * 9999999999),
                        "created_at": "2022-05-18T07:53:12Z",
                        "price_product": price,
                        "total_quantity" : this.productImport[n].quantity,
                        "total_price": parseInt(price*parseInt(this.productImport[n].quantity)),
                        "weight_measure": 1,
                        "organization": 2, 
                        "dimensions_measure": 1,
                        "user_create": this.account['user'],
                        "product": this.productImport[n]['id'],
                        "package_parent" : res.data.id
                    });

                    axios.post('https://api.nmeco.xyz/v1/api/package/', dataSubTicket, axiosConfig)
                }
                
                var dataId = JSON.stringify({
                    "package": res.data.id,
                    "sub_order": this.dataSubOrder
                })

                axios.put('https://api.nmeco.xyz/v1/api/order/' + this.$route.params.id + '/', dataId, axiosConfig)
                .then(() => {
                    var popup = document.getElementsByClassName('popup-create-package')[0]
                    popup.classList.toggle('active-popup')

                    var createPackage = document.getElementsByClassName('create-package__container')[0]
                    createPackage.classList.toggle('hide')
                    var confirmProduct = document.getElementsByClassName('confirm-product__container')[0]
                    confirmProduct.classList.toggle('active')

                    this.$emit('updateData')
                })
            })
        }
    },

    created () {
        this.getData()
    }
}
</script>

<style scoped lang='scss'>
    @import 'ConfirmProduct.scss';
</style>