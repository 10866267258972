@<template>
    <div class="popup popup-QR jcc aic">

        <!-- Noti create QR -->
        <div 
            v-if="qrcode == null"
            class="popup-QR__container f fdc aic pt15 pb15 pl1 pr1 border-radius bg-white-1">
            <div class="icon--noti mb1"></div>
            <div class="h3 text-red-linear mb05">Thông báo</div>
            <div class="p2 gray align-c mb15">Sau khi tạo mã QR, thông tin kiện hàng sẽ không thể thay đổi. Bạn vẫn muốn tiếp tục?</div>
            <div class="f jcb aic w100">
                <div 
                    @click="handleQR"
                    class="pt1 pb1 h5 mr1 w50 align-c border border-radius cursor">Đóng</div>
                <div 
                    @click="handleConfirmQR"
                    class="pt1 pb1 h5 w50 align-c border-radius cursor bg-red-1 white">Xác nhận</div>
            </div>
        </div>

        <!-- QR -->
        <div v-else
            class="qr__container f fdc aic bg-white-1 border-radius pt15 pb15">
            <div class="icon--noti-success mb1"></div>
            <div class="h3 text-red-linear mb05">Thông báo</div>
            <div class="p2 gray align-c mb05">Tạo mã QR kiện hàng thành công</div>
            <div class="border border-radius align-c pt1 pb05 pl1 pr1 mb15">
                <div class="border-bottom pb05">
                    <img :src="qrcode.qrcode" alt="">
                </div>
                <div class="mt05 h6 blue">In mã QR</div>
            </div>
            <div 
                @click="handleClose"
                class="qr__btn h6 pt1 pb1 align-c border border-radius cursor">
                Đóng
            </div>
        </div>
    </div>
</template>

<script>
import { HTTP } from '@/http-default'
import axios from 'axios'
export default {
    props : {
        dataOrder : {
            type : Object,
            default() {
                return {}
            }
        }
    },

    data () {
        return {
            qrcode : null
        }
    },

    methods : {
        handleQR () {
            var popup = document.getElementsByClassName('popup-QR')[0]
            popup.classList.toggle('active-popup')
        },

        // create QR
        handleConfirmQR () {
            const token = localStorage.getItem('user')
            const axiosConfig = {
                headers : {
                    'Content-Type': 'application/json',
                    'Authorization': 'Token '+ token,
                }
            }

            const dataQR = JSON.stringify({
                "qrcode1" : true
            });

            axios.put('https://api.nmeco.xyz/v1/api/package/' +  this.dataOrder.package.id , dataQR, axiosConfig)
            .then ((res) => {
                this.qrcode = res.data
                this.$emit('UpdateQR', this.qrcode)
            })            
        },

        handleClose () {

            var popup = document.getElementsByClassName('popup-QR')[0]
            popup.classList.toggle('active-popup')
        },

        getData () {
            if (this.dataOrder.package != null) {
                HTTP.get('v1/api/package/' +  this.dataOrder.package.id)
                .then((res) => {
                    this.qrcode = res.data.qrcode
                })
            }
        }
    },
    created () {
        this.getData()
    }
}
</script>

<style scoped lang='scss'>
    @import 'PopupQR.scss';
</style>