<template>
  <div class="logout absolute hide border-radius bg-white-1">
      <div class="logout__header f aic pt05 pb05 pr1 pl1 bg-red-1">
          <img class="header__img mr1" src="../../assets/imgLogoCompany.png" alt="">
          <div class="header__info">
              <div class="mb025 h7 white">Số dư tài khoản</div>
              <div class="h6 white">0 VNĐ</div>
          </div>
      </div>
      <div class="logout__list pt1 pr1 pl1 border-bottom">
          <div 
            class="logout__list-item f aic pb1" 
            v-for="(item, index) in listdata" 
            :key="index"
            >
              <div class="icon" :class="item.icon"></div>
              <div class="h7"> {{item.title}} </div>
          </div>
      </div>
      <div 
        class="logout__btn--container pt075 pb075 pr1 pl1" 
        @mouseover="upHere = true"
        @mouseleave="upHere = false">
            <div 
                class="logout__btn f jcc aic border-radius pt05 pb05 pr1 pl1 cursor"
                @click="handleLogout">
                <div v-if="upHere" class="logout__btn-icon icon icon--logout-hover mr05"></div>
                <div v-else class="logout__btn-icon icon icon--logout mr05"></div>
                <div>Đăng xuất</div>
            </div>
      </div>
  </div>
</template>

<script>
import { HTTP } from '@/http-default'
export default {
    data() {
        return {
            upHere : false,
            listdata: [
                {
                    icon: 'icon--user',
                    title: 'Thông tin cá nhân'
                },
                {
                    icon: 'icon--password',
                    title: 'Đổi mật khẩu'
                },
                {
                    icon: 'icon--wallet',
                    title: 'Ví Eco'
                }
            ]
        }
    },

    methods: {
        handleLogout() {

            var data = JSON.stringify({
                "sessionId": this.$cookies.get('sessionId')
            })
            HTTP.post('logout/', data)

            window.localStorage.clear();
            this.$router.go({ path: "/login" })
        },
    },
}
</script>

<style lang="scss" scoped>
@import 'PopupLogout.scss';
</style>>
