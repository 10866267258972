@<template>
    <div class="confirm-product__container active relative pt1 pb1 pl1 pr1 border-radius bg-gray-1">
        <div class="confirm-product__body f">
            <div 
                v-if="!isMobile()"
                class="confirm-product__body-left">
                <div class="h5 mb1 text-red-linear">Xác nhận thông tin sản phẩm</div>
                <div class="list-product">
                    <div 
                        v-for="(item, index) in productImport"
                        :key="index">
                        <div 
                            v-if="item.id == idProduct"
                            @click="handleSelect(item.id)"
                            class="product-item pt1 pb1 pl1 pr1 mb05 border-radius cursor border-red bg-white-1">
                            <div >
                                <div class="f aic">
                                    <div class="mr05">
                                        <img :src="item.image_product" alt="">
                                    </div>
                                    <div class="f fdc jcb">
                                        <div class="product-item__title mb025 h6"> {{item.title_product}} </div>
                                        <div class="p4 gray"> Hàng có sẵn: {{item.quantity_in_stock}} {{item.unit_count_type}} </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div 
                            v-else
                            @click="handleSelect(item.id)"
                            class="product-item pt1 pb1 pl1 pr1 mb05 border-radius cursor bg-white-1">
                            <div>
                                <div class="f aic">
                                    <div class="mr05">
                                        <img :src="item.image_product" alt="">
                                    </div>
                                    <div class="f fdc jcb">
                                        <div class="product-item__title mb025 h6"> {{item.title_product}} </div>
                                        <div class="p4 gray"> Hàng có sẵn: {{item.quantity_in_stock}} {{item.unit_count_type}} </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div 
                v-if="!isMobile()"
                class="confirm-product__body-right ml1">
                <div 
                    v-for="(item, index) in productImport"
                    :key="index">
                    <div
                        v-if="item.id == idProduct"
                        class="product-item__container pt1 pb1 pl1 pr1 mb1 border-radius bg-white-1">
                        <div class="f jcb aic mb1">
                            <div class="large-info f aic">
                                <div class="mr05">
                                    <img :src="item.image_product" alt="">
                                </div>
                                <div class="f fdc jcb">
                                    <div class="mb025 h6"> {{ item.title_product }} </div>
                                    <div class="p4 gray"> Hàng có sẵn :{{item.quantity_in_stock}} </div>
                                </div>
                            </div>
                        </div>
                        <div class="product-item__info mb1">
                            <div class="f jcb aic mb025">
                                <div class="p4 gray">Mã sản phẩm</div>
                                <div class="h6 blue"> #{{item.id}} </div>
                            </div>
                            <div class="f jcb aic mb025">
                                <div class="p4 gray">Nhà cung cấp</div>
                                <div class="p4"> The A Corp </div>
                            </div>  
                        </div>
                        <div class="product-item__create-consignment mb1 pt1 pb1 border-bottom border-top">
                            <div class="h6 mb05 gray">Thiết lập thông tin nhập hàng</div>
                            <div
                                v-if="listConsignment.length == 0" 
                                class="p6 mb05 gray-5">Chưa có lô hàng nào được thiết lập</div>
                            <div class="product-item__list-consignment mt05 mb05">
                                <div 
                                    v-for="(value, index) in listConsignment"
                                    :key="index">
                                    <div v-if="value.product == item.id">
                                        <div class="pt1 pb1 pl1 pr1 mb075 bg-gray-1 border-radius">
                                            <div class="f jcb aic">
                                                <div>
                                                    <div class="h6"> {{ value.title_consignment }} </div>
                                                    <div class="p4 blue"> {{ value.consignment_key }} </div>
                                                </div>
                                                <div>
                                                    <div
                                                        @click="handleViewConsignment(value.consignment_key)" 
                                                        class="icon icon--eye"></div>
                                                </div>
                                            </div>
                                            <div class="mt1 pt1 border-top">
                                                <div class="f jcb aic mb05">
                                                    <div class="p3">Số lượng sản phẩm</div>
                                                    <div class="p4"> {{value.quantity_in_consignment}} đơn vị</div>
                                                </div>
                                                <div class="f jcb aic">
                                                    <div class="p3"> Giá trị nhập </div>
                                                    <div class="p4"> {{ formatMoney(value.total_price) }} </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="product-item__total">
                            <div class="f jcb aic mb025">
                                <div class="p3 gray">Tổng số lượng nhập</div>
                                <div class="p4">{{ item.total_quantity_consignment }} đơn vị</div>
                            </div>
                            <div class="f jcb aic">
                                <div class="p3 gray">Tổng giá trị nhập</div>
                                <div
                                    v-if="item.total_price_consignment"
                                    class="p4 text-red-linear"> {{ formatMoney(item.total_price_consignment) }} </div>
                                <div 
                                    v-else
                                    class="p4 text-red-linear">Chưa có dữ liệu</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div 
                v-if="isMobile()"
                class="confirm-product__body-right">
                <div 
                    v-for="(item, index) in productImport"
                    :key="index">
                    <div
                        class="product-item__container pt1 pb1 pl1 pr1 mb1 border-radius bg-white-1">
                        <div class="f jcb aic mb1">
                            <div class="large-info f aic">
                                <div class="mr05">
                                    <img :src="item.image_product" alt="">
                                </div>
                                <div class="f fdc jcb">
                                    <div class="mb025 h6"> {{ item.title_product }} </div>
                                    <div class="p4 gray"> Hàng có sẵn :{{item.quantity_in_stock}} </div>
                                </div>
                            </div>
                        </div>
                        <div class="product-item__info mb1">
                            <div class="f jcb aic mb025">
                                <div class="p4 gray">Mã sản phẩm</div>
                                <div class="h6 blue"> #{{item.id}} </div>
                            </div>
                            <div class="f jcb aic mb025">
                                <div class="p4 gray">Nhà cung cấp</div>
                                <div class="p4"> The A Corp </div>
                            </div>  
                        </div>
                        <div class="product-item__create-consignment mb1 pt1 pb1 border-bottom border-top">
                            <div class="h6 mb05 gray">Thiết lập thông tin nhập hàng</div>
                            <div
                                v-if="listConsignment.length == 0" 
                                class="p6 mb05 gray-5">Chưa có lô hàng nào được thiết lập</div>
                            <div class="product-item__list-consignment mt05 mb05">
                                <div 
                                    v-for="(value, index) in listConsignment"
                                    :key="index">
                                    <div v-if="value.product == item.id">
                                        <div class="pt1 pb1 pl1 pr1 mb075 bg-gray-1 border-radius">
                                            <div class="f jcb aic">
                                                <div>
                                                    <div class="h6"> {{ value.title_consignment }} </div>
                                                    <div class="p4 blue"> {{ value.consignment_key }} </div>
                                                </div>
                                                <div>
                                                    <div
                                                        @click="handleViewConsignment(value.consignment_key)" 
                                                        class="icon icon--eye"></div>
                                                </div>
                                            </div>
                                            <div class="mt1 pt1 border-top">
                                                <div class="f jcb aic mb05">
                                                    <div class="p3">Số lượng sản phẩm</div>
                                                    <div class="p4"> {{value.quantity_in_consignment}} đơn vị</div>
                                                </div>
                                                <div class="f jcb aic">
                                                    <div class="p3"> Giá trị nhập </div>
                                                    <div class="p4"> {{ formatMoney(value.total_price) }} </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="product-item__total">
                            <div class="f jcb aic mb025">
                                <div class="p3 gray">Tổng số lượng nhập</div>
                                <div class="p4">{{ item.total_quantity_consignment }} đơn vị</div>
                            </div>
                            <div class="f jcb aic">
                                <div class="p3 gray">Tổng giá trị nhập</div>
                                <div
                                    v-if="item.total_price_consignment"
                                    class="p4 text-red-linear"> {{ formatMoney(item.total_price_consignment) }} </div>
                                <div 
                                    v-else
                                    class="p4 text-red-linear">Chưa có dữ liệu</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="confirm-product__footer w100 f aic jcb pt1 pb1 pl1 pr1 border-top bg-gray-1">
            <div class="confirm-product__total p4">
                Tổng giá trị nhập: <span class="h5 text-red-linear"> {{ formatMoney(handleTotalMoney) }} </span>
            </div>
            <div class="confirm-product__action w100 f aic jce">
                <div 
                    @click="handleBack"
                    class="h5 align-c border border-radius pt1 pb1 mr1 cursor">
                        Quay lại
                </div>
                <div
                    @click="postDataPackage"
                    class="h5 align-c border-radius pt1 pb1 cursor bg-red-1 white">
                        Tiếp tục
                </div>
            </div>
        </div>

        <ViewConsignment 
            :listConsignment='listConsignment'
            :keySelect='keySelect'></ViewConsignment>
    </div>
</template>

<script>
import ViewConsignment from '@/components/ImportTicketView/CreatePackageView/ConfirmProductView/ViewConsignment/ViewConsignment.vue'
import { HTTP } from '@/http-default'
export default {
    components : {
        ViewConsignment
    },

    props : {
        dataSubOrder : {
            type : Array,
            default() {
                return []
            }
        },

        productImport : {
            type : Array,
            default() {
                return []
            }
        },

        listConsignment : {
            type : Array,
            default() {
                return []
            }
        }
    },

    watch : {
        'listConsignment' : function () {
            if (this.productImport.length !== 0) {
                this.idProduct = this.productImport[0].id
            }
            // this.handleTotal()
        },

        'productImport' : function () {
            this.consignmentProduct = [...this.productImport]
        },
    },

    computed : {
        handleTotalMoney () {
            var total = this.handleTotal()
            return total
        }
    },

    data () {
        return { 
            idProduct : 0,
            subOrder : [],
            subOrderSelect : [],
            listProduct : [],
            productOrder : [],
            dataOrder : [],
            subPackage : [],
            NewSubPackage : [],
            totalPriceAllConsignment : 0,
            consignmentProduct : [],
            keySelect : '',
        }
    },

    mounted () {
        this.handleTotal()
    },

    methods : {
        handleTotal () {
            var data = this.productImport
            var total = 0

            data.forEach(item => {
                this.idProduct = this.productImport[0].id
                total = total + parseInt(item.total_price_consignment)
            })
            this.totalPriceAllConsignment = total
            return total
        },

        isMobile () {
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        },

        formatMoney(value) {
            value = value.toLocaleString('it-IT', {style : 'currency', currency : 'VND'})
            return value
        },

        handleViewConsignment (value) {
            this.keySelect = value
            var popup = document.getElementsByClassName('popup-view-consignment')[0]
            popup.classList.add('f')
        },

        handleBack () {
            this.$emit('closePopup')
            var createPackage = document.getElementsByClassName('create-package__container')[0]
            createPackage.classList.toggle('hide')
            // var confirmProduct = document.getElementsByClassName('confirm-product__container')[0]
            // confirmProduct.classList.toggle('active')
        },

        handleSelect (id) {
            this.idProduct = id
        },

        getData () {

            HTTP.get('/v1/api/product/')
            .then((res) => {
                this.listProduct = res.data
            })
        },

        postDataPackage () {
            this.$emit('closePopup')
            this.$emit('updateStatusConsignment', false, this.totalPriceAllConsignment)
            this.$emit('updateProductTicket', this.productImport)
            var popup = document.getElementsByClassName('popup-create-package')[0]
            popup.classList.toggle('active-popup')

            // var confirmProduct = document.getElementsByClassName('confirm-product__container')[0]
            // confirmProduct.classList.toggle('active')
        }
    },

    created () {
        this.getData()
    }
}
</script>

<style scoped lang='scss'>
    @import 'ConfirmProduct.scss';
</style>