<template>
  <div id="PerView" class="per-view-container relative bg-gray-1">
    <HeaderView></HeaderView>
    <div class="f">
      <SideBarView
        v-if="this.$route.path != '/quan-ly-account'"
        :dataSideBar="dataSideBar"></SideBarView>
      <NavView :dataSideBar="dataSideBar"></NavView>
      <ListView v-if="this.$route.path == '/quan-ly-account'"></ListView>
      <router-view></router-view>
    </div>

    <back-home-btn></back-home-btn>
    
    <!-- Add Customer -->
  </div>
</template>

<script>
import { Vue } from 'vue-property-decorator';
import ListView from '@/views/ListView/ListView.vue'
import HeaderView from "@/components/HeaderView/HeaderView.vue";
import SideBarView from '@/components/SideBarView/SideBarView.vue';
import NavView from '@/components/NavView/NavView.vue'
import BackHomeBtn from "@/components/BackHomeBtn.vue";

import { HTTP } from '../../http-default'

import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
export default Vue.extend({
  components:{
    ListView,
    HeaderView,
    SideBarView, 
    BackHomeBtn,
    NavView,
  },
  data() {
    return {
      personnelActive: [],
      data: [],
      dataSideBar: [],
      dataMenu: [],
      dataKey: ['Sản phẩm', 'Mã sản phẩm', 'Đơn vị tính', 'Đơn giá', 'Tồn kho'],
      dataShow: [
        {
          name: 'Áo thun Hàn Quốc',
          id: 'ATHQ',
          unit: 'Chiếc',
          unitPrice : '120.000 VNĐ',
          inventory : '225 chiếc'
        },
      ],
      dateRange: {
          startDate: '2022-5-7',
          endDate: '2022-5-7',
        },
      wid: '',
      margin: '',
    }
  },
  watch: {
    
  },
  methods: {
    handleFilter() {
      var input = document.getElementById("myInput");
      var filter = input.value.toUpperCase();
      this.personnelActive = [];
      this.personnelWait = [];
      if(!filter) {
        for(let i = 0; i < 5; i++) {
        this.personnelActive.push(this.dataShow[i]);
      }
      }
      else {
        for (let i = 0; i < this.dataShow.length; i++) {
            var perName = this.dataShow[i].name;
            var txtValue = perName;
            if (txtValue.toUpperCase().indexOf(filter) > -1) {
                this.personnelWait.push(this.dataShow[i])
            }
        }
        for(let i = 0; i < this.personnelWait.length ; i++) {
          this.personnelActive.push(this.personnelWait[i]);
        }
      }
    },
    handleUpdate(event) {
      var button = event.target.parentElement.parentElement
      var poup = button.getElementsByClassName('poup-update')[0]
      var clearActive = document.getElementsByClassName('active-popup')
      if (clearActive.length != 0 && clearActive[0] != poup) {
        clearActive[0].classList.remove('active-popup')
      }
      poup.classList.toggle('active-popup')
    },
    getData() {
      const systemSetting = localStorage.getItem("systemSetting")
      this.data = JSON.parse(systemSetting)
      for (let i = 0; i < this.data.length; i++) {
        if (this.data[i].levelmenu == 1) {
          this.dataSideBar.push(this.data[i])
        }
        if (this.data[i].subparent == 'ticket' || this.data[i].subparent == 'stock') {
          this.dataMenu.push(this.data[i])
        }
      }
    },
  },
  created() {
    this.getData()
    setTimeout(() => {
      this.wid = (150)
      this.margin = (260/7)
    }, 5)
  }
})
</script>


<style scoped lang="scss">
@import 'WareHouse.scss';
</style>


