<template>
    <div class="mobile app bg-white-1">
        <div class="row f jcb aic">
            <div class="col-md-4 border">

                <div class="card relative">
                    <div 
                      v-if="$route.params.sidebaritem === 'connect-device'"
                      @click="handleDisconect"
                      class="card-header absolute f aic w100 pt075 pb075 pl1 pr1 bg-white-1">
                      <div class="card-header__icon icon--arrow-1"></div>
                        <div class="h5">
                            Quay lại
                        </div>
                    </div>
                    <div 
                      v-else
                      @click="handleClose"
                      class="card-header absolute f aic w100 pt075 pb075 pl1 pr1 bg-white-1">
                      <div class="card-header__icon icon--arrow-1"></div>
                        <div class="h5">
                            Quay lại
                        </div>
                    </div>
                    <qrcode-stream :camera="camera" @decode="onDecode" @init="onInit" />
                    <div v-show="showScanConfirmation" class="popup scan-confirmation w100 h100vh f jcc aic">
                      <div class="icon--noti-success"></div>
                    </div>
                    <div class="card-body absolute w100 pt1 pb1 align-c bg-white-1">
                        <div class="p6 orange">
                            Vui lòng chuyển hướng camera tới mã <br> vạch/mã QR của sản phẩm
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { HTTP } from '@/http-default'
import axios from 'axios'

import { QrcodeStream } from 'vue-qrcode-reader'
import firebase from "@/firebaseInit";
import "firebase/firestore";
const db = firebase.firestore();

export default {
  name: 'app',
  data() {
    return {
      // device
      dataDevice : [],
      myDevice : {},
      deviceReq : {},
      camera: 'auto',
      account: {},
      error: '',
      status: 'Webcam ready!',
      alert: 'alert-warning',
      statusScan: '', 
      showScanConfirmation: false
    }
  },
  components: { QrcodeStream },
  methods: {

    async onDecode (result) {

      const results = result.replaceAll("'", '"')
      const key = JSON.parse(results)
      db.collection('product-item').add({
        id : this.account['key_doc_firebase'],
        value: key.qrcode,
        productId: 2,
      });

      let audio = await new Audio('http://soundbible.com/mp3/Elevator Ding-SoundBible.com-685385892.mp3')      
      audio.play()

      this.pause()
      await this.timeout(500)
      this.unpause()
    },

    unpause () {
      this.camera = 'auto'
    },

    pause () {
      this.camera = 'off'
    },

    timeout (ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    },

    async onInit (promise) {
      try {
        await promise
      } catch (error) {
        
        if (error.name === 'NotAllowedError') {
          this.alert = 'alert-danger'
          this.status = "ERROR: you need to grant camera access permisson"
        } else if (error.name === 'NotFoundError') {
          this.alert = 'alert-danger'
          this.status = "ERROR: no camera on this device"
        } else if (error.name === 'NotSupportedError') {
          this.alert = 'alert-danger'
          this.status = "ERROR: secure context required (HTTPS, localhost)"
        } else if (error.name === 'NotReadableError') {
          this.alert = 'alert-danger'
          this.status = "ERROR: is the camera already in use?"
        } else if (error.name === 'OverconstrainedError') {
          this.alert = 'alert-danger'
          this.status = "ERROR: installed cameras are not suitable"
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.alert = 'alert-danger'
          this.status = "ERROR: Stream API is not supported in this browser"
        }
      } finally {
        this.showScanConfirmation = this.camera === "off"
      }
    },

    async getDevice () {
      HTTP.get('getdevice/')
      .then((res) => {
        this.dataDevice = res.data.device
        for (let i = 0; i < this.dataDevice.length; i++) {
          if (this.$cookies.get("sessionId") == this.dataDevice[i].sessionId) {
              this.myDevice = this.dataDevice[i]
          }
        }

        if (this.myDevice.connect != null) {
          for (let i = 0; i < this.dataDevice.length; i++) {
              if (this.myDevice.connect.request == this.dataDevice[i].sessionId) {
                  this.deviceReq = this.dataDevice[i]
              }
          }
        }
      })
    },

    handleDisconect () {
      const token = localStorage.getItem('user')
      const axiosConfig = {
        headers : {
          'Content-Type': 'application/json',
          'Authorization': 'Token '+ token,
        }
      }

      const dataDevice = JSON.stringify({
        "sessionId_req" : this.deviceReq.sessionId,
        "sessionId_res" : this.myDevice.sessionId,
      });

      axios.post('https://api.nmeco.xyz/disconnect/', dataDevice, axiosConfig)
      .then(() => {
        this.$router.go(-1)
      })
    },

    handleClose () {
      this.$emit('handleClose')
    },

    getData () {
      HTTP.get('v1/api/account/')
      .then ((res) => {
        
        this.account = res.data[0]
      })
    }
  },

  created () {
    this.getData ()
    this.getDevice ()
  }
}
</script>

<style>
.app {
  color: #404040;
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #2c3e50;
}
.table-title {
  font-size: 23px;
  color: #404040
}
.table-desc {
  font-size: 17px
}
.vue-font {
  color: green; font-weight: bold
}

.card {
  width: 100%;
  height: 100vh;
}
.card-header {
  top: 0;
  z-index: 1000;
}

.card-header__icon {
  transform: rotate(90deg);
}

.card-body {
  bottom: 0;
}
</style>