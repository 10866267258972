@<template>
    <div class="mobile connection__container h100vh w100 pt2 pb1 pl1 pr1 bg-gray-1">
        <div class="connection__title h5 text-red-linear mb1">
            Đề xuất kết nối
        </div>
        <div 
            v-if="notiConnection"
            class="connection__item pt1 pb1 pl1 pr1 border-radius bg-white-1">
            <div class="connection__item-time p6 mb05 gray">
                22:22 - 22/02/2022
            </div>
            <div class="p4 mb15">
                Thiết bị <span class="h6">{{deviceReq.name}}</span> đề xuất kết nối với camera của thiết bị này
            </div>
            <div class="connection__item-action">
                <div 
                    @click="handleAcceptConnection"
                    class="btn btn-accept pt025 pb025 mb05 border-radius align-c bg-red-1">
                    <div class="h6 white">
                        Chấp nhận và tiến hành quét
                    </div>
                </div>
                <div 
                    @click="handleDenyConnection"
                    class="btn btn-accept pt025 pb025 h6 border-radius border align-c">
                    Từ chối
                </div>
            </div>
        </div>

        <div 
            v-if="!notiConnection"
            class="connection__item f fdc aic pt1 pb1 pl1 pr1 border-radius bg-white-1">
            <div class="icon--noti mb1"></div>
            <div class="h6">Bạn chưa có đề xuất kết nối</div>
        </div>

        <div 
            v-if="statusNoti"
            class="popup popupDeny f jcc aic">
            <div class="deny-connection__container align-c pt1 pb1 pl1 pr1 border-radius bg-white-1">
                <div class="f jcc aic">
                    <div class="icon--noti mb1"></div>
                </div>
                <div class="h3 mb05 text-red-linear">Thông báo</div>
                <div class="p2 gray mb15">
                    Bạn đã từ chối kết nối với thiết bị này
                </div>
                <div class="f jcc aic">
                    <div 
                        @click="statusNoti = false"
                        class="w50 h5 pt1 pb1 align-c border border-radius">
                        Đóng
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {HTTP} from '@/http-default'
import axios from 'axios'
export default {
    data () {
        return {
            dataDevice : [],
            myDevice : {},
            deviceReq : {},
            notiConnection : false,
            statusNoti : false
        }
    },

    watch : {
        'myDevice' : function () {
            if (this.myDevice.connect != null) {
                this.notiConnection = true
            } else {
                this.notiConnection = false
            }
        }
    },

    methods : {
        async getDevice () {
            setInterval(() => {
                HTTP.get('getdevice/')
                .then((res) => {
                    this.dataDevice = res.data.device
                    for (let i = 0; i < this.dataDevice.length; i++) {
                        if (this.$cookies.get("sessionId") == this.dataDevice[i].sessionId) {
                            this.myDevice = this.dataDevice[i]
                        }

                        this.dataDevice[i].name.replace(/\(([^()]*)\)/, (str) => {
                            str = str.replace('(', '')
                            str = str.split(';')
                            this.dataDevice[i].name = str[0]
                        });
                    }

                    if (this.myDevice.connect != null) {
                        for (let i = 0; i < this.dataDevice.length; i++) {
                            if (this.myDevice.connect.request == this.dataDevice[i].sessionId) {
                                this.deviceReq = this.dataDevice[i]
                            }
                        }
                    }
                })
            }, 2000)
        },

        handleAcceptConnection () {
            
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const path = this

            const token = localStorage.getItem('user')
            const axiosConfig = {
                headers : {
                    'Content-Type': 'application/json',
                    'Authorization': 'Token '+ token,
                }
            }

            const dataDevice = JSON.stringify({
                "sessionId_req" : this.deviceReq.sessionId,
                "sessionId_res" : this.myDevice.sessionId,
                "accept" : true
            });
            axios.put('https://api.nmeco.xyz/getdevice/', dataDevice, axiosConfig)
            .then((res) => {
                const messeger = res.data
                if (messeger.message == "Accept") {
                    path.$router.push({ name: 'qr-code'})
                }
            })
        },

        handleDenyConnection () {
            this.statusNoti = true

            const token = localStorage.getItem('user')
            const axiosConfig = {
                headers : {
                    'Content-Type': 'application/json',
                    'Authorization': 'Token '+ token,
                }
            }

            const dataDevice = JSON.stringify({
                "sessionId_req" : this.deviceReq.sessionId,
                "sessionId_res" : this.myDevice.sessionId,
                "accept" : false
            });
            axios.put('https://api.nmeco.xyz/getdevice/', dataDevice, axiosConfig)
            .then(() => {
                this.getDevice()
            })
        }
    },

    created () {
        this.getDevice()
    }
}
</script>

<style scoped lang='scss'>
    @import 'Connection.scss';
</style>