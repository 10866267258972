import Vue from 'vue'
import Vuex from 'vuex'
import cookie from 'vue-cookies'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  },
})
