 @<template>
    <div class="exportticket__container w100">
        <div class="exportticket__header f aic mb2">
            <div class="desktop h3 pr1 border-right">
                Đơn hàng
            </div>
            <div class="desktop p3 pr025 pl1 gray">
                Đơn hàng
            </div> 
            <div class="desktop icon icon--next"></div>
            <div class="desktop p3 pr025 pl025 gray">
                Chi tiết đơn hàng
            </div>
            <div class="desktop icon icon--next"></div>
            <div class="desktop p3 pr025 pl025 gray">
                Chi tiết đơn hàng
            </div>
            <div class="desktop icon icon--next"></div>
            <div class="h5 pr025 pl025 text-red-linear">
                Tạo phiếu xuất hàng bán
            </div>
        </div>

        <div class="exportticket__body f fw jcb ais w100">

            <!-- body-left -->
            <div class="body-left">
                <div class="body-left__id-order mb1 pt1 pb1 pl1 pr1 border-radius bg-white-1">
                    <div class="mb1 h5 gray">Mã đơn hàng</div>
                    <div class="f jcb aic mb1 pt1 pb1 pl1 pr1 border-radius border">
                        <div class="code-number h5 blue">#{{ dataOrder.order_key }} </div>
                        <div 
                            class="icon icon--copy"
                            @click="handleCopyCode">
                        </div>
                    </div>
                </div>
            </div>

            <!-- body-center -->
            <div class="body-center pt1 pb1 pl1 pr1 mb1 border-radius bg-white-1">
                <div class="ticket-header h5 mb1">
                    Thiết lập thông tin phiếu
                </div>
                <div class="ticket-form">
                    <div v-if="data.props">
                        <div 
                            v-for="(item, key) in data.props.listfieldaddnew"
                            :key="key">
                                    <div
                                        v-if="key == 'title_export_ticket' || key == 'export_reason'" 
                                        class="f fdc mb1">
                                        <label class="upcase mb05 p4" for=""> {{item.text_lable}} <span class="text-red-linear">*</span></label>
                                        <input 
                                            v-if="key == 'title_export_ticket'"
                                            class="pt075 pb075 pl075 pr075"
                                            :type="item.type_input" 
                                            :placeholder="'Nhập ' + item.text_lable"
                                            v-model='title_export_ticket'>
                                        <input 
                                            v-else-if="key == 'export_reason'"
                                            class="pt075 pb075 pl075 pr075"
                                            :type="item.type_input" 
                                            :placeholder="'Nhập ' + item.text_lable"
                                            v-model='export_reason'>
                                        <!-- <textarea 
                                            v-else-if="key == 'note_ticket'"
                                            class="w100 p3 pt075 pb075 pl075 pr075 border-radius-s bg-white-1"
                                            :type="item.type_input" 
                                            :placeholder="'Nhập ' + item.text_lable"
                                            cols="30" 
                                            rows="10"
                                            v-model='note_ticket'></textarea> -->
                                    </div>
                        </div>
                        <div class="mb1">
                            <label class="upcase mb05 p4" for=""> Ghi chú phiếu xuất </label>
                            <textarea 
                                class="w100 p3 pt075 pb075 pl075 pr075 mt025 border-radius-s bg-white-1"
                                placeholder="Nhập ghi chú"
                                cols="30" 
                                rows="10"
                                v-model='note_ticket'></textarea>
                        </div>
                    </div>
                    
                    <!-- Thông tin người nhận hàng -->
                    <div>
                        <div 
                            class="form-item pt1 pb1 pr1 pl1 mb1 border-radius bg-gray-3">
                            <div 
                                @click="handleOpenDetail"
                                class="form-item__label f aic jcb">
                                <div 
                                    class="h6 gray">Thông tin người nhận hàng</div>
                                <div 
                                    class="icon--arrow arrow-rotage"></div>
                            </div>
                            <div class="form-item__detail border-top mt1 pt1">
                                <div
                                    v-if="dataOrder.customer" 
                                    class="f jcb aic mb05">
                                    <div class="p3">Tên người nhận hàng</div>
                                    <div class="h6"> {{ dataOrder.customer.full_name }} </div>
                                </div>
                                <div
                                    v-if="dataOrder.customer" 
                                    class="f jcb aic mb05">
                                    <div class="p3">Số điện thoại</div>
                                    <div class="h6"> {{ dataOrder.customer.phone }} </div>
                                </div>
                                <div 
                                    v-if="dataOrder.customer"
                                    class="f jcb aic mb05">
                                    <div class="p3">Địa chỉ nhận hàng</div>
                                    <div class="h6"> 
                                        {{ dataOrder.customer.address }}
                                    </div>
                                </div>
                                <div 
                                    v-if="dataOrder.customer"
                                    class="f jcb aic">
                                    <div class="p3">Ghi chú</div>
                                    <div 
                                        v-if="dataOrder.note"
                                        class="w50 h6 align-r"> 
                                        {{ dataOrder.note }}
                                    </div>
                                    <div v-else class="w50 h6 align-r">
                                        Chưa có ghi chú
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <!-- Thông tin thủ kho -->
                    <div>
                        <div class="form-item pt1 pb1 pr1 pl1 mb1 border-radius bg-gray-3">
                            <div 
                                @click="handleOpenDetail"
                                class="form-item__label f aic jcb">
                                <div    
                                    class="h6 gray">Thông tin thủ kho</div>
                                <div 
                                    class="icon--arrow arrow-rotage"></div>
                            </div>
                            <div class="form-item__detail border-top mt1 pt1">
                                <div
                                    v-if="account" 
                                    class="f jcb aic mb05">
                                    <div class="p3">Tên thủ kho</div>
                                    <div class="h6"> {{ account.full_name }} </div>
                                </div>
                                <div
                                    v-if="account"  
                                    class="f jcb aic mb05">
                                    <div class="p3">Số điện thoại</div>
                                    <div class="h6"> {{ account.phone }} </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div 
                            class="form-item pt1 pb1 pr1 pl1 border-radius bg-gray-3 gray">
                            <div 
                                @click="handleOpenDetail"
                                class="form-item__label f aic jcb">
                                <div 
                                    class="h6 gray">Thông tin kiện hàng</div>
                                <div 
                                    class="icon--arrow arrow-rotage"></div>
                            </div>
                            <div class="form-item__detail border-top mt1 pt1">
                                <div class="form-item__location f aic mb1">
                                    <div class="icon icon--location"></div>
                                    <div class="p4 gray">
                                        Vị trí lưu kiện hàng: <span class="black">Khu lưu kiện hàng bán</span>
                                    </div>
                                </div>

                                <div 
                                    v-if="dataOrder.package == null"
                                    class="pt1 pb1 pl1 pr1 mb05 border-radius bg-white-1 cursor"
                                    @click="handlePackage">
                                    <div class="f jcb aic mb05">
                                        <div class="h6 gray">Thông tin sản phẩm</div>
                                        <div class="p6 pt05 pb05 pl05 pr05 border-radius bg-yellow orange">Chưa thiết lập</div>
                                    </div>
                                    <div class="f jcb aic p6 mb025">
                                        <div>Tổng sản phẩm trong kiện</div>
                                        <div>Không có dữ liệu</div>
                                    </div>
                                    <div class="f jcb aic p6">
                                        <div>Tổng giá trị kiện hàng</div>
                                        <div>Không có dữ liệu</div>
                                    </div>
                                </div>
                                <div 
                                    v-else-if="dataOrder.package != null"
                                    class="pt1 pb1 pl1 pr1 mb05 border-radius bg-white-1 cursor"
                                    @click="handlePackage">
                                    <div class="f jcb aic mb05">
                                        <div class="h6 gray">Thông tin sản phẩm</div>
                                        <div class="p6 pt05 pb05 pl05 pr05 border-radius bg-green green">Đã thiết lập</div>
                                    </div>
                                    <div class="f jcb aic p6 mb025">
                                        <div>Tổng sản phẩm trong kiện</div>
                                        <div> {{TotalQuantityPackage()}} sản phẩm</div>
                                    </div>
                                    <div class="f jcb aic p6">
                                        <div>Tổng giá trị kiện hàng</div>
                                        <div> {{formatMoney(this.totalPrice)}} </div>
                                    </div>
                                </div>
                                <div 
                                    @click="handleSpecifications"
                                    v-if="dataOrder.package == null"
                                    class="pt1 pb1 pl1 pr1 mb05 border-radius bg-white-1 cursor">
                                    <div class="f jcb aic mb05">
                                        <div class="h6 gray">Thông số kiện hàng</div>
                                        <div class="p6 pt05 pb05 pl05 pr05 border-radius bg-yellow orange">Chưa thiết lập</div>
                                    </div>
                                    <div class="f jcb aic p6 mb025">
                                        <div>Khối lượng</div>
                                        <div>Không có dữ liệu</div>
                                    </div>
                                    <div class="f jcb aic p6">
                                        <div>Kích thước</div>
                                        <div>Không có dữ liệu</div>
                                    </div>
                                </div>
                                <div 
                                    @click="handleSpecifications"
                                    v-else-if="dataOrder.package != null && dataOrder.package.weight == 0"
                                    class="pt1 pb1 pl1 pr1 mb05 border-radius bg-white-1 cursor">
                                    <div class="f jcb aic mb05">
                                        <div class="h6 gray">Thông số kiện hàng</div>
                                        <div class="p6 pt05 pb05 pl05 pr05 border-radius bg-yellow orange">Chưa thiết lập</div>
                                    </div>
                                    <div class="f jcb aic p6 mb025">
                                        <div>Khối lượng</div>
                                        <div>Không có dữ liệu</div>
                                    </div>
                                    <div class="f jcb aic p6">
                                        <div>Kích thước</div>
                                        <div>Không có dữ liệu</div>
                                    </div>
                                </div>
                                <div 
                                    v-else-if="dataOrder.package != null && dataOrder.package.weight != 0"
                                    class="pt1 pb1 pl1 pr1 mb05 border-radius bg-white-1 cursor">
                                    <div class="f jcb aic mb05">
                                        <div class="h6 gray">Thông số kiện hàng</div>
                                        <div class="p6 pt05 pb05 pl05 pr05 border-radius bg-green green">Đã thiết lập</div>
                                    </div>
                                    <div class="f jcb aic p6 mb025">
                                        <div>Khối lượng</div>
                                        <div> {{ packageOrder.weight }} kg</div>
                                    </div>
                                    <div class="f jcb aic p6">
                                        <div>Kích thước</div>
                                        <div> {{ packageOrder.height }} x {{ packageOrder.length }} x {{ packageOrder.width }} cm</div>
                                    </div>
                                </div>

                                <div class="pt1 pb1 pl1 pr1 border-radius bg-white-1">
                                    <div class="h6 gray mb05">Mã QR</div>
                                    <div 
                                        v-if="dataOrder.package == null"
                                        class="h6 pt1 pb1 mb025 border-radius align-c bg-red white">
                                        Tạo mã QR
                                    </div>
                                    <div 
                                        v-else-if="dataOrder.package != null && dataOrder.package.qrcode == null && dataOrder.package.weight != 0"
                                        @click="handleQR"
                                        class="h6 pt1 pb1 mb025 border-radius align-c bg-red-1 white cursor">
                                        Tạo mã QR
                                    </div>
                                    <div
                                        v-if="dataOrder.package == null"
                                        class="f aic orange p6">
                                        <div class="icon icon--faq"></div>
                                        <div class="ml025">Bạn cần hoàn thiện thông tin trên để tạo mã QR cho kiện</div>
                                    </div>
                                    <div v-else-if="dataOrder.package != null && dataOrder.package.qrcode != null">
                                        <div class="f jcc aic border-2 border-radius pt1 pb1">
                                            <div class="pt1 pb05 pl1 pr1 border border-radius">
                                                <img :src="dataOrder.package.qrcode" class="mb05">
                                                <div 
                                                    @click="handlePhotoQR"
                                                    class="pt05 border-top align-c h6 blue cursor ">
                                                    In mã QR
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- body-right -->
            <div class="body-right border-radius">
                <div class="body-right__product pt1 pb1 pl1 pr1 border-radius bg-white-1">
                    <div class="body-right__header h5 mb05">
                        Thông tin sản phẩm khách hàng đã đặt
                    </div>
                    <div class="body-right__quantity-total mb1 p3 gray">
                        Đã đặt: <span class="p4 black">{{dataOrder.total_quantity}} Sản phẩm</span>
                    </div>
                    <div class="body-right__product-list">
                        <ProductItemView :dataSubOrder="dataOrder.id"></ProductItemView>
                    </div>
                </div>

                <div class="body-right__total pl1 pr1 mt1 border-radius bg-white-1">
                    <div class="f jcb aic pt1 pb1 border-bottom">
                        <div class="h5">Tổng tiền hàng xuất</div>
                        <div class="h5 text-red-linear"> {{formatMoney(this.totalPrice)}} </div>
                    </div>
                    <div class="w100 f jcb aic pt15 pb15">
                        <div
                            @click="handleCancel(false)" 
                            class="border border-radius h5 pt1 pb1 w50 align-c mr1 cursor">Hủy bỏ</div>
                        <div 
                            @click="handleCreateTicket(true)"
                            v-if="dataOrder.package != null 
                                && dataOrder.package.qrcode != null
                                && title_export_ticket.length != 0
                                && export_reason.length != 0
                                && dataOrder.package.weight != 0"
                            class="border-radius h5 pt1 pb1 w50 align-c cursor bg-red-1 white">Tạo phiếu</div>
                        <div 
                            v-else
                            class="border-radius h5 pt1 pb1 w50 align-c bg-red white">Tạo phiếu</div>
                    </div>
                </div>
            </div>
        </div> 

        <PackageSpecifications 
            :dataOrder='dataOrder'
            @getDataAll='getData'></PackageSpecifications>

        <CreatePackageView 
            @updateProductImport='updateProductImport'
            @updatePackageKey='updatePackageKey'
            :account='account'
            @getDataAll='getData'>
        </CreatePackageView>

        <PopupQRView 
            :dataOrder='dataOrder'
            @UpdateQR='UpdateQR'></PopupQRView>
        <PopupCreateTicketView
            :dataOrder='dataOrder'
            :packageKeyParent='packageKeyParent'
            :dataSubOrder="dataOrder.sub_order"
            :title_export_ticket='title_export_ticket'
            :note_ticket="note_ticket"
            :export_reason="export_reason"
            :ticket_package="dataOrder.package"
            :actionStatus='actionStatus'>
        </PopupCreateTicketView>

        <PopupPhotoQR :dataOrder='dataOrder'></PopupPhotoQR>
    </div>
</template>

<script>
import CreatePackageView from '@/components/ExportticketView/CreatePackageView/CreatePackageView'
import ProductItemView from '@/components/ExportticketView/ProductItemView/ProductItemView'
import PopupQRView from '@/components/ExportticketView/PopupNotiQRView/PopupQRView'
import PopupPhotoQR from '@/components/ExportticketView/PopupPhotoQRView/PopupPhotoQR.vue'
import PopupCreateTicketView from '@/components/ExportticketView/PopupCreateTicket/PopupCreateTicketView'
import PackageSpecifications from '@/components/ExportticketView/PackageSpecifications/PackageSpecifications.vue'
import { HTTP } from '../../http-default'

export default {
    components : {
        CreatePackageView,
        ProductItemView,
        PopupQRView,
        PopupCreateTicketView,
        PopupPhotoQR,
        PackageSpecifications
    },
    data () {
        return {
            dataPackage : [],
            dataOrder : {},
            data : [],
            packageOrder : [],
            title_export_ticket : '',
            note_ticket : '',
            export_reason : '',
            actionStatus : null,
            account : {},
            packageKeyParent : '',
            productImport : [],
            totalPrice : 0
        }
    },

    methods: {
        // formatMoney
        formatMoney(value) {
            value = value.toLocaleString('it-IT', {style : 'currency', currency : 'VND'})
            return value
        },

        // total quantity in package
        TotalQuantityPackage () {
            var total = 0
            this.productImport.forEach(item => {
                total = total + item.total_quantity
            })

            return total
        },

        // update package key parent
        updatePackageKey (value) {
            this.packageKeyParent = value
        },

        // update quantity product package
        updateProductImport (value) {
            this.productImport = value
            this.productImport.forEach(item => {
                this.totalPrice = this.totalPrice + item.total_price
            })
        },

        handleSpecifications () {
            var popup = document.getElementsByClassName('popup-specifications')[0]
            popup.classList.toggle('f')
        },

        handleCopyCode () {
            var copyText = document.getElementsByClassName('code-number')[0].innerText
            var TempText = document.createElement("input");
            TempText.value = copyText;
            document.body.appendChild(TempText);
            TempText.select();
            document.execCommand("copy");
            document.body.removeChild(TempText);
            alert("Copied the text: " + TempText.value);
        },

        handleOpenDetail (event) {
            var button = event.target.parentElement.parentElement;
            var popup = button.getElementsByClassName('form-item__detail')[0]
            var arrown = button.getElementsByClassName('icon--arrow')[0]
            popup.classList.toggle('active')
            arrown.classList.toggle('arrow-rotage')
        },

        // create tickage
        handlePackage () {
            var popup = document.getElementsByClassName('popup-create-package')[0]
            popup.classList.toggle('active-popup')
        },

        // update QR package
        UpdateQR (value) {
            this.getData() 
            this.dataPackage = value
        },

        // create QR package
        handleQR () {
            var popup = document.getElementsByClassName('popup-QR')[0]
            popup.classList.toggle('active-popup')
        },

        handlePhotoQR () {
            var popup = document.getElementsByClassName('popup-photo-QR')[0]
            popup.classList.toggle('active-popup')
        },

        // cancel create
        handleCancel(value) {
            this.actionStatus = value
            var popup = document.getElementsByClassName('popup-create-ticket')[0]
            popup.classList.toggle('active-popup')
        },

        // create export ticket
        handleCreateTicket (value) {
            this.actionStatus = value
            var popup = document.getElementsByClassName('popup-create-ticket')[0]
            popup.classList.toggle('active-popup')
        },

        // get data API
        getData () {
            var systemSetting = localStorage.getItem("systemSetting")
            this.data = JSON.parse(systemSetting)
            for (let i = 0; i < this.data.length; i++) {
                if (this.data[i].name == "exportticket") {
                    this.data = this.data[i]
                    break
                }
            }

            HTTP.get('v1/api/order/' + this.$route.params.id + '/')
            .then((res) => {
                this.dataOrder = res.data
                // const setting = this.dataOrder.customer.toLowerCase()
                // const results = setting.replaceAll("'", '"')
                // this.dataOrder.customer = JSON.parse(results)
                if (this.dataOrder.package != null) {
                    HTTP.get('v1/api/package/' +  this.dataOrder.package.id)
                    .then((res) => {
                        this.packageOrder = res.data
                    })
                }
            })

            HTTP.get('v1/api/account/')
            .then ((res) => {
                this.account = res.data[0]
            })
        }
    },

    created () {
        this.getData(); 
    }
}
</script>

<style scoped lang='scss'>
    @import 'Exportticket.scss';
</style>