@<template>
    <div class="create-consignment__container border-radius pt1 pb1 pl1 pr1 bg-white-1">
        <div class="create-consignment__header h5 mb1 text-red-linear">
            Tạo lô hàng
        </div>
        <div class="create-consignment__form">
            <div class="f fdc mb1">
                <label class="p4 mb05" for="">Tên lô hàng <span class="text-red-linear">*</span></label>
                <input 
                    class="pt075 pb075 pl075 pr075 w100"
                    type="text"
                    placeholder="Nhập tên lô hàng"
                    v-model="dataInput.title_consignment">
            </div>
            <div class="f fdc mb1">
                <label class="p4 mb05" for="">Mã lô của bạn <span class="text-red-linear">*</span></label>
                <input 
                    class="pt075 pb075 pl075 pr075 w100"
                    type="text"
                    placeholder="Nhập mã lô hàng"
                    v-model="dataInput.consignment_key">
            </div>
            <div class="f jcb aic">
                <div class="f fdc mb1 w50">
                    <label class="p4 mb05" for="">Số lượng sản phẩm <span class="text-red-linear">*</span></label>
                    <input 
                        class="pt075 pb075 pl075 pr075 w100"
                        type="number"
                        placeholder="Nhập số lượng sản phẩm"
                        v-model="dataInput.quantity_in_consignment">
                </div>
                <div class="relative f fdc mb1 w50 ml05">
                    <div class="absolute p4 gray" style="right: 0.75em; bottom: 0.75em">VNĐ</div>
                    <label class="p4 mb05" for="">Đơn giá nhập <span class="text-red-linear">*</span></label>
                    <input 
                        class="pt075 pb075 pl075 pr075 w100"
                        type="number"
                        placeholder="Nhập đơn giá nhập"
                        v-model="dataInput.price_product"
                        value="" data-type="currency">
                </div>
            </div>
            <div class="relative f fdc mb1">
                <div class="absolute p4 gray" style="right: 0.75em; bottom: 0.75em">%</div>
                <label class="p4 mb05" for="">Ngưỡng cảnh báo số lượng <span class="text-red-linear">*</span></label>
                <input 
                    class="pt075 pb075 pl075 pr075 w100"
                    type="text"
                    placeholder="Nhập ngưỡng cảnh báo"
                    v-model="dataInput.warning_quantity_percent">
            </div>

            <!--input date -->
            <div class="select-date f aic mb05">
                <input type="checkbox" v-model="dateInput" :checked='dateInput'>
                <div class="p3 ml05">Sản phẩm này có hạn sử dụng</div>
            </div>

            <div
                v-if="dateInput"
                class="input-date">
                <div class="f jcb aic">
                    <div class="f fdc mb1 w50">
                        <label class="p4 mb05 gray" for="">Ngày sản xuất <span class="text-red-linear">*</span></label>
                        <input 
                            v-model="dataInput.manufacturing_date"
                            class="w100 p4" 
                            type="date">
                    </div>
                    <div class="f fdc mb1 w50 ml05">
                        <label class="p4 mb05 gray" for="">Ngày hết hạn <span class="text-red-linear">*</span></label>
                        <input 
                            v-model="dataInput.expiry_date"
                            class="w100 p4" 
                            type="date">
                    </div>
                </div>
                <div class="p4 mb05">Cảnh báo tôi khi hạn sử dụng của sản phẩm còn *</div>
                <div class="f jcb aic">
                    <div class="f fdc mb1 w50">
                        <input 
                            v-model="dataInput.warning_date"
                            class="pt075 pb075 pl075 pr075 w100"
                            type="text"
                            placeholder="Nhập ngưỡng cảnh báo">
                    </div>
                    <div class="relative f fdc mb1 w50 ml05">
                        <div 
                            @click="handleListType"
                            class="f jcb aic pt05 pb05 pl075 pr075 border border-radius-s w100">
                            <div class="p3"> {{this.typeDateInput}}</div>
                            <div class="border-left">
                                <div class="icon icon--arrow"></div>
                            </div>
                        </div>
                        <div class="type-date__contaienr absolute hide fdc w100 border border-radius-s bg-white-1 pb05">
                            <div 
                                v-for="(item, index) in listTypeDate"
                                :key="index">
                                <div 
                                    v-if="item != typeDateInput"
                                    class="type-date__item pt05 pb05 pl15 pr15 p3 cursor"
                                    @click="handleSelectType(item)">
                                    {{ item }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="relative f fdc mb1">
                <label class="p4 mb05" for="">Vị trí lô hàng <span class="text-red-linear">*</span></label>
                <div class="icon icon--dot pl1">

                    <div class="pt05 pb05 pl05 pr05 border-radius-s border">
                        <InputTag v-model="dataInput.position" placeholder='Enter to make tag'></InputTag>
                    </div>
                </div>
            </div>
            <div class="f jcb aic w100">
                <div class="p4 w50">
                    Tổng giá trị lô hàng: 
                    <span v-if="totalPrice == null" class="h6 text-red-linear">Chưa có dữ liệu</span>
                    <span v-else class="h6 text-red-linear"> {{ totalPrice }} </span>
                </div>
                <div class="f aic w50 pl2">
                    <div 
                        @click="handleCloseConsignment"
                        class="w50 align-c pt05 pb05 h5 border border-radius cursor">Hủy bỏ</div>
                    <div 
                        @click="handleCreateConsignment(dataInput)"
                        class="w50 align-c ml1 pt05 pb05 h5 border-radius bg-red-1 white cursor">Lưu lại</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import InputTag from '../../../../../node_modules/vue-input-tag/src/components'

export default {
    props : {
        idProduct : Number,
        account : {
            type : Object,
            default () {
                return {}
            }
        }
    },

    components : {
        InputTag,
    },

    data () {
        return {
            typeDateInput : 'Ngày',
            listTypeDate : ['Ngày', 'Tuần', 'Tháng', 'Năm'],
            dateInput : false,
            A : [],
            dataInput : {
                title_consignment: '',
                consignment_key: '',
                position: '',
                total_quantity: null,
                total_price: null,
                note: '',
                user_create: 2,
                sub_consignment: null,
                price_product: null,
                warning_quantity_percent: 0,
                quantity_in_consignment : null,
                manufacturing_date: '',
                expiry_date: '',
                time_measure: '',
                is_expiry_product: '',
                warning_date: ''
            },

            totalPrice : '',
            tags : [],
        }
    },

    watch : {
        'dataInput.quantity_in_consignment' : function () {
            this.dataInput.total_price = parseInt(this.dataInput.quantity_in_consignment)*parseInt(this.dataInput.price_product)
            this.totalPrice = parseInt(this.dataInput.quantity_in_consignment)*parseInt(this.dataInput.price_product)
            this.totalPrice = this.totalPrice.toLocaleString('it-IT', {style : 'currency', currency : 'VND'});
        },

        'dataInput.price_product': function () {
            this.dataInput.total_price = parseInt(this.dataInput.quantity_in_consignment)*parseInt(this.dataInput.price_product)
            this.totalPrice = parseInt(this.dataInput.quantity_in_consignment)*parseInt(this.dataInput.price_product)
            this.totalPrice = this.totalPrice.toLocaleString('it-IT', {style : 'currency', currency : 'VND'});
        }
    },

    methods : {
        // add tag input
        addTag (event) {
            event.preventDefault()
            var val = event.target.value.trim()
            if (val.length < 0) {
                this.tags.push(val)
                event.target.value = ''
            }
        },
        
        // formatMoney
        formatMoney(value) {
            value = value.toLocaleString('it-IT', {style : 'currency', currency : 'VND'})
            return value
        },

        // handle select type date
        handleListType () {
            var container = document.getElementsByClassName('type-date__contaienr')[0]
            container.classList.toggle('hide')
        },
        handleSelectType (value) {
            this.typeDateInput = value
            if (value == 'Ngày') {
                this.dataInput.time_measure = 1
            }
            if (value == 'Tuần') {
                this.dataInput.time_measure = 2
            }
            if (value == 'Tháng') {
                this.dataInput.time_measure = 3
            }
            if (value == 'Năm') {
                this.dataInput.time_measure = 4
            }
            this.handleListType()
        },

        handleCloseConsignment () {
            var consignment = document.getElementsByClassName('create-consignment__container')[0]
            consignment.classList.toggle('active')

            var createPackage = document.getElementsByClassName('create-package__container')[0]
            createPackage.classList.toggle('hide')
        },

        handleCreateConsignment (value) {
            value.sub_consignment = this.idProduct

            var dataTest = {}
            dataTest.title_consignment = value.title_consignment
            dataTest.consignment_key = value.consignment_key
            dataTest.user_create = value.user_create
            dataTest.product = this.idProduct
            dataTest.user_create = this.account.user
            dataTest.quantity_in_consignment = this.dataInput.quantity_in_consignment
            dataTest.price_product = this.dataInput.price_product
            dataTest.total_price = this.dataInput.total_price
            dataTest.position = this.dataInput.position.toString()
            dataTest.warning_quantity_percent = this.dataInput.warning_quantity_percent
            dataTest.is_expiry_product = this.dateInput
            dataTest.manufacturing_date = this.dataInput.manufacturing_date
            dataTest.expiry_date = this.dataInput.expiry_date
            dataTest.time_measure = this.dataInput.time_measure
            dataTest.warning_date = this.dataInput.warning_date
            
            // eslint-disable-next-line no-const-assign
            this.$emit('updateConsignment', dataTest)

            var consignment = document.getElementsByClassName('create-consignment__container')[0]
            consignment.classList.toggle('active')

            var createPackage = document.getElementsByClassName('create-package__container')[0]
            createPackage.classList.toggle('hide')

            this.dataInput = {
                title_consignment: '',
                consignment_key: '',
                position: '',
                total_quantity: null,
                total_price: null,
                note: '',
                user_create: 2,
                sub_consignment: null,
                price_product: 0,
                warning_quantity_percent: 0,
                quantity_in_consignment : 0,
                manufacturing_date: '',
                expiry_date: '',
                time_measure: '',
                is_expiry_product: '',
                warning_date: ''
            }
        }   
    }
}
</script>

<style scoped lang='scss'>
    @import 'CreateConsignment.scss';
</style>