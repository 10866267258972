@<template>
    <div class="product-item">
        <div v-if="this.$route.params.typeTicket == 'exportticket'">
            <div 
                v-for="(item, index) in productImport"
                :key="index">
                <div class="product-item__container pt1 pb1 pl1 pr1 mb1 border-radius bg-gray-3">
                    <div>
                        <div class="large-info f aic mb1">
                            <div class="mr05">
                                <img class="img-product" :src="item.image_product" alt="">
                            </div>
                            <div class="f fdc jcb">
                                <div class="mb025 h6 text-red-linear"> {{item.title_product}} </div>
                                <div class="p4 gray"> Hàng tồn: {{item.quantity_in_stock}} </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div class="large-info f aic w100">
                            <div class="product-item__info w100">
                                <div class="f jcb aic mb025">
                                    <div class="p4 gray">Mã sản phẩm</div>
                                    <div class="h6 blue"> #{{ item.id }} </div>
                                </div>
                                <div class="f jcb aic mb025">
                                    <div class="p4 gray">Nhà cung cấp</div>
                                    <div class="p3"> {{ item.organization }} </div>
                                </div>
                                <div 
                                    v-for="(category, key) in categoryproduct"
                                    :key="key">
                                    <div 
                                        v-if="category.id == item.category[0]"
                                        class="f jcb aic mb025">
                                        <div class="p4 gray">Danh mục sản phẩm</div>
                                        <div class="p4">
                                            {{ category.category_name }}
                                        </div>
                                    </div>
                                </div>
                                <div class="f jcb aic mb025">
                                    <div class="p4 gray">Đơn giá</div>
                                    <div
                                        v-if="$route.params.typeTicket == 'importticket'" 
                                        class="h6"> {{ formatMoney(item.price_import) }} </div>
                                    <div
                                        v-if="$route.params.typeTicket == 'exportticket'" 
                                        class="h6"> {{ formatMoney(item.price_product) }} </div>
                                </div>
                                <div class="f jcb aic mb025">
                                    <div class="p4 gray">Số lượng xuất</div>
                                    <div class="h6"> {{ item.total_quantity }} </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="this.$route.params.typeTicket == 'importticket'">
            <div 
                v-for="(item, index) in listConsignment"
                :key="index">
                <div class="product-item__container pt1 pb1 pl1 pr1 mb1 border-radius bg-gray-3">
                    <div>
                        <div class="large-info f aic mb1">
                            <div class="mr05">
                                <img :src="item.product.image_product" alt="">
                            </div>
                            <div class="f fdc jcb">
                                <div class="mb025 h6 text-red-linear"> {{item.product.title_product}} </div>
                                <div class="p4 gray"> Hàng tồn: {{item.product.quantity_in_stock}} </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div class="large-info f aic w100">
                            <div class="product-item__info w100">
                                <div class="f jcb aic mb025">
                                    <div class="p4 gray">Mã sản phẩm</div>
                                    <div class="h6 blue"> #{{ item.product.id }} </div>
                                </div>
                                <div class="f jcb aic mb025">
                                    <div class="p4 gray">Nhà cung cấp</div>
                                    <div class="p3"> {{ item.product.organization }} </div>
                                </div>
                                <div 
                                    v-for="(category, key) in categoryproduct"
                                    :key="key">
                                    <div 
                                        v-if="category.id == item.product.category[0]"
                                        class="f jcb aic mb025">
                                        <div class="p4 gray">Danh mục sản phẩm</div>
                                        <div class="p4">
                                            {{ category.category_name }}
                                        </div>
                                    </div>
                                </div>
                                <div class="f jcb aic mb025">
                                    <div class="p4 gray">Đơn giá</div>
                                    <div
                                        v-if="$route.params.typeTicket == 'importticket'" 
                                        class="h6"> {{ formatMoney(item.price_product) }} </div>
                                    <div
                                        v-if="$route.params.typeTicket == 'exportticket'" 
                                        class="h6"> {{ formatMoney(item.price_product) }} </div>
                                </div>
                                <div class="f jcb aic mb025">
                                    <div class="p4 gray">Số lượng nhập</div>
                                    <div class="h6"> {{ item.quantity_in_consignment }} </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { HTTP } from '@/http-default'
export default {
    props : {
        listProduct : {
            type: Array,
            default() {
                return []
            }
        },
        listConsignment : {
            type: Array,
            default() {
                return []
            }
        },
        listPackage : {
            type: Array,
            default() {
                return []
            }
        }
        
    },

    data () {
        return {
            productImport : [],
            categoryproduct : [],
        }
    },

    methods : {
        // formatMoney
        formatMoney(value) {
            value = value.toLocaleString('it-IT', {style : 'currency', currency : 'VND'})
            return value
        },

        getData () {
            setInterval(() => {
                if (this.$route.params.typeTicket == 'importticket') {
                    this.productImport = []
                    this.listConsignment.forEach(item => {
                        this.listProduct.forEach(value => {
                            if (item.product == value.id) {
                                item.product = value
                                this.productImport.push(item)
                            }
                        })
                    })
                }

                else if (this.$route.params.typeTicket == 'exportticket') {
                    this.productImport = []
                    this.listPackage.forEach(item => {
                        this.listProduct.forEach(value => {
                            if (item.product == value.id) {
                                value.total_quantity = item.total_quantity
                                value.total_price = item.total_price
                                this.productImport.push(value)
                            }
                        })
                    })
                }

                HTTP.get('v1/api/categoryproduct/')
                .then((res) => {
                    this.categoryproduct = res.data.results
                })
            }, 3000)
        }
    },

    created () {
        if (this.$route.params.typeTicket) {
            this.getData()
        } else {
            clearInterval()
        }
    }
}
</script>

<style scoped lang="scss">
    @import 'ProductTicketDetail.scss';
</style>