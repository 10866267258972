@<template>
    <div class="log-order__container border-left mt05">
        <div
            v-for="(item, index) in dataLogOrder"
            :key="index" 
            class="item-logorder w100">
            <div 
                class="icon icon--dot w100"
                v-if="index == 0">
                <div class="f jcb ais w100">
                    <div 
                        style="width: 50%"
                        class="p3 ml1">   
                        {{converTime(item.created_at)}}
                    </div>
                    <div class="title-logorder w50 align-l p4 text-red-linear">
                        {{item.title_logorder}}
                    </div>
                </div>
            </div>
            <div 
                class="icon icon--dot w100"
                v-else>
                <div class="f jcb ais w100">
                    <div 
                        style="width: 50%"
                        class="p3 ml1">   
                        {{converTime(item.created_at)}}
                    </div>
                    <div class="title-logorder w50 align-l p3 gray">
                        {{item.title_logorder}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import firebase from "@/firebaseInit";
import "firebase/firestore";
import { HTTP } from '@/http-default';

export default {
    props : {
        data : {
            type : Array,
            default() {
                return []
            }
        }
    },

    data () {
        return {
            dataLogOrder: [],
            dataOrder : [],
            stock: []
        }
    },

    watch : {
        data : function () {
            this.dataLogOrder = this.data
        }
    },
    
    methods : {
        converTime (value) {
            var time = value.split('T')
            time[1] = time[1].split('.')
            return time[1][0] + ' ' + time[0]
        },  
    },
}
</script>

<style scoped lang='scss'>
    @import 'LogOrder.scss';
</style>