@<template>
    <div class="popup popup__edit-consignment jcc aic">
        <div 
            v-if="dataConsignmentEdit.length != 0"
            class="edit-consignment__container border-radius pt1 pb1 pl1 pr1 bg-white-1">
            <div class="edit-consignment__header h5 mb1 text-red-linear">
                Chỉnh sửa lô hàng
            </div>
            <div>
                <div>
                    <div class="edit-consignment__form">
                        <div class="f fdc mb1">
                            <label class="p4 mb05" for="">Tên lô hàng <span class="text-red-linear">*</span></label>
                            <input 
                                class="pt075 pb075 pl075 pr075 w100"
                                type="text"
                                placeholder="Nhập tên lô hàng"
                                v-model="dataConsignmentEdit[0].title_consignment"
                                value="">
                        </div>
                        <div class="f fdc mb1">
                            <label class="p4 mb05" for="">Mã lô của bạn <span class="text-red-linear">*</span></label>
                            <input 
                                class="pt075 pb075 pl075 pr075 w100"
                                type="text"
                                placeholder="Nhập mã lô hàng"
                                v-model="dataConsignmentEdit[0].consignment_key">
                        </div>
                        <div class="f jcb aic">
                            <div class="f fdc mb1 w50">
                                <label class="p4 mb05" for="">Số lượng sản phẩm <span class="text-red-linear">*</span></label>
                                <input 
                                    class="pt075 pb075 pl075 pr075 w100"
                                    type="number"
                                    placeholder="Nhập số lượng sản phẩm"
                                    v-model="dataConsignmentEdit[0].quantity_in_consignment">
                            </div>
                            <div class="f fdc mb1 w50 ml05">
                                <label class="p4 mb05" for="">Đơn giá nhập <span class="text-red-linear">*</span></label>
                                <input 
                                    class="pt075 pb075 pl075 pr075 w100"
                                    type="number"
                                    placeholder="Nhập đơn giá nhập"
                                    v-model="dataConsignmentEdit[0].price_product">
                            </div>
                        </div>
                        <div class="relative f fdc mb1">
                            <div class="absolute p4 gray" style="right: 0.75em; bottom: 0.75em">%</div>
                            <label class="p4 mb05" for="">Ngưỡng cảnh báo số lượng <span class="text-red-linear">*</span></label>
                            <input 
                                class="pt075 pb075 pl075 pr075 w100"
                                type="number"
                                placeholder="Nhập ngưỡng cảnh báo"
                                v-model="dataConsignmentEdit[0].warning_quantity_percent">
                        </div>

                        <!--input date -->
                        <div class="select-date f aic mb05">
                            <input type="checkbox" 
                                v-model="dateInput" 
                                :checked='dateInput'>
                            <div class="p3 ml05">Sản phẩm này có hạn sử dụng</div>
                        </div>

                        <div
                            v-if="dateInput"
                            class="input-date">
                            <div class="f jcb aic">
                                <div class="f fdc mb1 w50">
                                    <label class="p4 mb05 gray" for="">Ngày sản xuất <span class="text-red-linear">*</span></label>
                                    <input 
                                        v-model="dataConsignmentEdit[0].manufacturing_date"
                                        class="w100 p4" 
                                        type="date">
                                </div>
                                <div class="f fdc mb1 w50 ml05">
                                    <label class="p4 mb05 gray" for="">Ngày hết hạn <span class="text-red-linear">*</span></label>
                                    <input 
                                        v-model="dataConsignmentEdit[0].expiry_date"
                                        class="w100 p4" 
                                        type="date">
                                </div>
                            </div>
                            <div class="p4 mb05">Cảnh báo tôi khi hạn sử dụng của sản phẩm còn *</div>
                            <div class="f jcb aic">
                                <div class="f fdc mb1 w50">
                                    <input 
                                        v-model="dataConsignmentEdit[0].warning_date"
                                        class="pt075 pb075 pl075 pr075 w100"
                                        type="text"
                                        placeholder="Nhập ngưỡng cảnh báo">
                                </div>
                                <div class="relative f fdc mb1 w50 ml05">
                                    <div 
                                        @click="handleListType"
                                        class="f jcb aic pt05 pb05 pl075 pr075 border border-radius-s w100">
                                        <div class="p3"> {{this.typeDateInput}}</div>
                                        <div class="border-left">
                                            <div class="icon icon--arrow"></div>
                                        </div>
                                    </div>
                                    <div class="type-date__contaienr absolute hide fdc w100 border border-radius-s bg-white-1 z10 pb05">
                                        <div 
                                            v-for="(item, index) in listTypeDate"
                                            :key="index">
                                            <div 
                                                v-if="item != typeDateInput"
                                                class="type-date__item pt05 pb05 pl15 pr15 p3 cursor"
                                                @click="handleSelectType(item)">
                                                {{ item }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="relative f fdc mb1">
                            <div class="absolute p4 gray" style="right: 0.75em; bottom: 0.75em">%</div>
                            <label class="p4 mb05" for="">Vị trí lô hàng <span class="text-red-linear">*</span></label>
                            <div class="icon icon--dot pl1">
                                <input 
                                    class="pt075 pb075 pl075 pr075 w100 p4"
                                    type="text"
                                    placeholder="Nhập vị trí"
                                    >
                            </div>
                        </div>
                        <div class="f jcb aic w100">
                            <div class="p4 w50">
                                Tổng giá trị lô hàng: 
                                <span class="h6 text-red-linear"> {{formatMoney(dataConsignmentEdit[0].total_price)}} </span>
                            </div>
                            <div class="f aic w50 pl2">
                                <div 
                                    @click="handleCloseConsignment"
                                    class="w50 align-c pt05 pb05 h5 border border-radius cursor">Hủy bỏ</div>
                                <div 
                                    @click="handleCreateConsignment()"
                                    class="w50 align-c ml1 pt05 pb05 h5 border-radius bg-red-1 white cursor">Lưu lại</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props : {
        idConsignmentEdit : String,
        listConsignment : {
            type : Array,
            default() {
                return []
            }
        } 
    },

    watch : {
        'idConsignmentEdit' : function () {
            this.dataConsignmentEdit = []
            this.listConsignment.forEach(item => {
                if (item.consignment_key == this.idConsignmentEdit) {
                    var select = item
                    this.dataConsignmentEdit.push(select)
                } 
            })
        },

        watchQuantity : function () {
            this.handleTotalMoney()
        },

        watchPrice : function () {
            this.handleTotalMoney()
        }
        // 'dataConsignmentEdit[0].quantity_in_consignment' : function () {
        //     this.dataConsignmentEdit[0].total_price = parseInt(this.dataConsignmentEdit[0].quantity_in_consignment)*parseInt(this.dataConsignmentEdit[0].price_product)
        //     // this.totalPrice = parseInt(this.dataConsignmentEdit[0].quantity_in_consignment)*parseInt(this.dataConsignmentEdit[0].price_product)
        //     // this.totalPrice = this.totalPrice.toLocaleString('it-IT', {style : 'currency', currency : 'VND'});
        // },

        // 'dataConsignmentEdit[0].price_product': function () {
        //     // this.totalPrice = parseInt(this.dataConsignmentEdit[0].quantity_in_consignment)*parseInt(this.dataConsignmentEdit[0].price_product)
        //     // this.totalPrice = this.totalPrice.toLocaleString('it-IT', {style : 'currency', currency : 'VND'});
        // }
    },

    data () {
        return {
            typeDateInput : 'Ngày',
            listTypeDate : ['Ngày', 'Tuần', 'Tháng', 'Năm'],
            dateInput : false,
            dataConsignmentEdit : []
        }
    },

    computed : {
        // theo doi so luong
        watchQuantity : function () {
            if (this.dataConsignmentEdit[0]) {
                console.log(this.dataConsignmentEdit[0].quantity_in_consignment);
                return this.dataConsignmentEdit[0].quantity_in_consignment
            } else {
                return 0
            }
        },

        // theo doi gia nhap
        watchPrice : function () {
            if (this.dataConsignmentEdit[0]) {
                console.log(this.dataConsignmentEdit[0].price_product);
                return this.dataConsignmentEdit[0].price_product
            } else {
                return 0
            }
        },
    },

    methods : {
        handleTotalMoney () {
            this.dataConsignmentEdit[0].total_price = parseInt(this.dataConsignmentEdit[0].quantity_in_consignment)*parseInt(this.dataConsignmentEdit[0].price_product)
        },

        formatMoney(value) {
            value = value.toLocaleString('it-IT', {style : 'currency', currency : 'VND'})
            return value
        },

        handleListType () {
            var container = document.getElementsByClassName('type-date__contaienr')[0]
            container.classList.toggle('hide')
        },
        handleSelectType (value) {
            this.typeDateInput = value
            this.handleListType()
        },
        
        handleCloseConsignment () {
            var consignment = document.getElementsByClassName('popup__edit-consignment')[0]
            consignment.classList.toggle('f') 
        },

        handleCreateConsignment () {
            this.$emit('updateEditConsignment')
            this.handleCloseConsignment()
        }
    },
    
    // mounted () {
    //     if (this.dataConsignmentEdit[0]) {
    //         this.$watch(() => {
    //             this.dataConsignmentEdit[0].quantity_in_consignment, this.handleTotalMoney
    //         })
    //     }
    // }
}
</script>

<style scoped lang='scss'>
    @import 'EditConsignment.scss';
</style>