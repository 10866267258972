@<template>
    <div class="detail-ticket__container w100">
        <div class="detail-ticket__header f aic mb2">
            <div class="desktop h3 pr1 border-right">
                Kho & hàng tồn
            </div>
            <div
                v-if="$route.params.menuitem == 'quan-ly-importticket'" 
                class="desktop p3 pr025 pl1 gray">
                Phiếu nhập hàng
            </div>
            <div
                v-if="$route.params.menuitem == 'quan-ly-exportticket'" 
                class="desktop p3 pr025 pl1 gray">
                Phiếu xuất hàng
            </div>
            <div class="desktop icon icon--next"></div>
            <div class="desktop upcase p3 pr025 pl025 gray">
                {{ this.$route.params.filter }}
            </div>
            <div class="desktop icon icon--next"></div>
            <div
                v-if="$route.params.typeTicket == 'importticket'" 
                class="h5 pr025 pl025 text-red-linear">
                Chi tiết phiếu nhập hàng
            </div>
            <div
                v-if="$route.params.typeTicket == 'exportticket'" 
                class="h5 pr025 pl025 text-red-linear">
                Chi tiết phiếu xuất hàng
            </div>
        </div>
        
        <!-- importticket -->
        <div
            v-if="$route.params.typeTicket == 'importticket'" 
            class="detail-ticket__body f fw jcb ais w100">

            <!-- body-left -->
            <div class="body-left">
                <div class="body-left__ticket-status mb1 pt1 pb1 pl1 pr1 border-radius bg-white-1">
                    <div class="f jcb aic mb1">
                        <div class="h5 gray">Trạng thái phiếu</div>
                        <div 
                            class="pt05 pb05 pl1 pr1 h6 border-radius green bg-green"
                            v-if="ticket.ticket_status == 1">
                            Đã tạo
                        </div>
                        <div 
                            class="pt05 pb05 pl1 pr1 h6 border-radius gray bg-gray-1"
                            v-if="ticket.ticket_status == 2">
                            Đã tạo
                        </div>
                    </div>
                    <div class="f jcc aic mb1 pt1 pb1 pl1 pr1 border-radius bg-gray-1">
                        <div class="upcase h5">  {{ this.$route.params.filter }} </div>
                    </div>
                    <div class="body-left__ticket-time pb1 border-bottom">
                        <div class="f jcb aic mb05">
                            <div class="w50 p3 gray">Thời gian khởi tạo</div>
                            <div class="p3"> {{ this.ticket.time_created_ticket[1] }} - {{ this.ticket.time_created_ticket[0] }} </div>
                        </div> 
                        <div class="f jcb aic">
                            <div class="p6 gray">Người khởi tạo</div>
                            <div class="p6 blue"> {{ this.account.full_name }}</div>
                        </div>
                    </div>
                    <div class="body-left__ticket-time pt1">
                        <div class="f jcb aic mb05">
                            <div class="w50 p3 gray">Lần cập nhật cuối</div>
                            <div class="p3"> {{ this.ticket.time_created_ticket[1] }} - {{ this.ticket.time_created_ticket[0] }} </div>
                        </div> 
                        <div class="f jcb aic">
                            <div class="p6 gray">Người cập nhật</div>
                            <div class="p6 blue"> {{ this.account.full_name }}</div>
                        </div>
                    </div>
                </div>

                <div class="body-left__id-order mb1 pt1 pb1 pl1 pr1 border-radius bg-white-1">
                    <div class="mb1 h5 gray">Mã phiếu</div>
                    <div class="f jcb aic mb1 pt1 pb1 pl1 pr1 border-radius border">
                        <div class="code-number h5 blue">  #{{ this.ticket.key_ticket }} </div>
                        <div 
                            class="icon icon--copy"
                            @click="handleCopyCode"></div>
                    </div>
                </div>
            </div>

            <!-- body-center -->
            <div class="body-center pt1 pb1 pl1 pr1 mb1 border-radius bg-white-1">
                <div class="ticket-header h5 mb1">
                    Thiết lập thông tin phiếu
                </div>
                <div class="ticket-form">
                    <div 
                        v-for="(item, key) in data.props.listfieldaddnew"
                        :key="key">
                                <div
                                    v-if="key == 'title_import_ticket' || key == 'original_documents' || key == 'note_ticket'" 
                                    class="f fdc mb1">
                                    <label class="upcase mb05 p4" for=""> {{item.text_lable}} <span v-if="key == 'title_import_ticket'" class="text-red-linear">*</span></label>
                                    <input 
                                        v-if="key == 'title_import_ticket'"
                                        class="pt075 pb075 pl075 pr075"
                                        :type="item.type_input" 
                                        :placeholder="'Nhập ' + item.text_lable"
                                        v-model='ticket.title_import_ticket'>
                                    <input 
                                        v-else-if="key == 'original_documents'"
                                        class="pt075 pb075 pl075 pr075"
                                        :type="item.type_input" 
                                        :placeholder="'Nhập ' + item.text_lable"
                                        v-model='ticket.original_documents'>
                                    <input 
                                        v-else-if="key == 'note_ticket'"
                                        class="pt075 pb075 pl075 pr075"
                                        cols="30" rows="10"
                                        :type="item.type_input" 
                                        :placeholder="'Nhập ' + item.text_lable"
                                        v-model='ticket.note_ticket'>
                                </div>
                    </div>

                    <div>
                        <div class="form-item pt1 pb1 pr1 pl1 mb1 border-radius bg-gray-3">
                            <div 
                                @click="handleOpenDetail"
                                class="form-item__label f aic jcb">
                                <div 
                                    class="h6 gray">Thông tin người giao hàng</div>
                                <div 
                                    class="icon--arrow arrow-rotage"
                                    @click="handleOpenDetail"></div>
                            </div>
                            <div class="form-item__detail border-top mt1 pt1">
                                <div class="f jcb aic w100 mb1">
                                    <div class="w50 mr1">
                                        <div class="mb05 p4">Tên người giao hàng</div>
                                        <input 
                                            type="text"
                                            class="w100 p3 pt075 pb075 pl075 pr075 bg-white-1"
                                            placeholder="Nhập tên người giao hàng"
                                            v-model="ticket.shipper.name">
                                    </div>
                                    <div class="w50">
                                        <div class="mb05 p4">Số điện thoại</div>
                                        <input 
                                            type="text"
                                            class="w100 p3 pt075 pb075 pl075 pr075 bg-white-1"
                                            placeholder="Nhập số điện thoại"
                                            v-model="ticket.shipper.phone">
                                    </div>
                                </div>
                                <div class="w100 mb1">
                                    <div class="mb05 p4">Ghi chú</div>
                                    <input 
                                        v-model="ticket.shipper.note"
                                        type="text"
                                        class="w100 p3 pt075 pb075 pl075 pr075 bg-white-1"
                                        placeholder="Nhập ghi chú">
                                </div>
                                <div class="w100">
                                    <div class="p3 mb05">Ảnh người giao hàng</div>
                                    <div class="w100 f mt05 aic pt1 pb1 pl1 pr1 border-radius bg-white-1" style="border: 1px dashed #D9DDE8;">
                                        <pre class="h5 pt05 pb05 pr1 pl1 mr1 border-radius bg-blue-1 white cursor" style="border: 1px solid #D9DDE8;">Tải ảnh lên</pre>
                                        <div>
                                            <div class="h6">Bạn có thể thêm nhiều ảnh cùng lúc (tối đa: 10 ảnh)</div>
                                            <div class="p4 gray">Dung lượng ảnh tối đa: 2MB</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div class="form-item pt1 pb1 pr1 pl1 mb1 border-radius bg-gray-3">
                            <div 
                                @click="handleOpenDetail"
                                class="form-item__label f aic jcb">
                                <div 
                                    class="h6 gray">Thông tin thủ kho</div>
                                <div 
                                    class="icon--arrow arrow-rotage"></div>
                            </div>
                            <div class="form-item__detail w100 border-top mt1 pt1">
                                <div class="w100">
                                    <div 
                                        v-if="account" 
                                        class="f jcb aic mb05">
                                        <div class="p4">Tên thủ kho</div>
                                        <div class="h6"> {{account.full_name}} </div>
                                    </div>
                                    <div 
                                        v-if="account" 
                                        class="f jcb aic mb05">
                                        <div class="p4">Số điện thoại</div>
                                        <div class="h6"> {{account.phone}} </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div class="form-item pt1 pb1 pr1 pl1 border-radius bg-gray-3 gray">
                            <div 
                                @click="handleOpenDetail"
                                class="form-item__label f aic jcb">
                                <div 
                                    class="h6 gray">Thông tin sản phẩm nhập kho</div>
                                <div 
                                    class="icon--arrow arrow-rotage"></div>
                            </div>
                            <div class="form-item__detail border-top mt1 pt1">
                                <div 
                                    v-if="ticket.consignment"
                                    class="pt1 pb1 pl1 pr1 mb05 border-radius bg-white-1 cursor"
                                    @click="handlePackage">
                                    <div class="f jcb aic mb05">
                                        <div class="h6 gray">Thông tin sản phẩm</div>
                                        <div class="p6 pt05 pb05 pl05 pr05 border-radius bg-green green">Đã thiết lập</div>
                                    </div>
                                    <div class="f jcb aic p6 mb025">
                                        <div>Tổng sản phẩm trong kiện</div>
                                        <div> {{totalQuantity()}} sản phẩm</div>
                                    </div>
                                    <div class="f jcb aic p6">
                                        <div>Tổng giá trị kiện hàng</div>
                                        <div> {{this.totalPrice}}</div>
                                    </div>
                                </div>
                                <div 
                                    v-else
                                    class="pt1 pb1 pl1 pr1 mb05 border-radius bg-white-1 cursor"
                                    @click="handlePackage">
                                    <div class="f jcb aic">
                                        <div class="h6 gray">Thiết lập thông tin sản phẩm</div>
                                        <div class="p6 pt05 pb05 pl05 pr05 border-radius bg-yellow orange">Chưa thiết lập</div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- body-right -->
            <div class="body-right border-radius">
                <div class="body-right__product pt1 pb1 pl1 pr1 border-radius bg-white-1">
                    <div class="body-right__header h5 mb05">
                        Thông tin sản phẩm nhập kho 
                    </div>
                    <div class="p4 mb1">
                        <span class="p3 gray">Đã nhập:</span> {{totalQuantity()}} sản phẩm
                    </div>
                    <div class="body-right__product-list">
                        <ProductTicketDetail 
                            :listConsignment='listConsignment'
                            :listPackage='listPackage'
                            :listProduct='listProduct'></ProductTicketDetail>
                    </div>
                </div>

                <div class="body-right__footer mt1 pt1 pb1 pl1 pr1 border-radius bg-white-1">
                    <div class="body-right__total f jcb aic">
                        <div class="h5">Tổng tiền hàng nhập</div>
                        <div class="h5 text-red-linear"> 
                            {{this.totalPrice}}
                        </div>
                    </div>
                    <div class="body-right__action border-radius">
                        <div
                            v-if="ticket.ticket_status == 2"
                            class="w100 f jcb aic mt1 pt15 border-top">
                            <div 
                                @click="handleSaveTicket('save')"
                                class="border border-radius h5 pt1 pb1 w50 align-c mr05 cursor">Lưu Nháp</div>
                            <div
                                @click="handleCancelTicket('delete')" 
                                class="border border-radius h5 pt1 pb1 w50 align-c mr05 bg-white-1 cursor">Hủy bỏ</div>
                            <div 
                                @click="handleCreateTicket('create')"
                                v-if="listConsignment.length != 0 
                                    && title_import_ticket.length != 0
                                    && original_documents.length != 0"
                                class="border-radius h5 pt1 pb1 w50 align-c cursor bg-red-1 white">Tạo phiếu</div>
                            <div 
                                v-else
                                class="border-radius h5 pt1 pb1 w50 align-c bg-red white">Tạo phiếu</div>
                        </div>

                        <div
                            v-if="ticket.ticket_status == 1"
                            class="w100 f jcb aic mt1 pt15 border-top">
                            <div
                                @click="handleCancelTicket" 
                                class="border border-radius h5 pt1 pb1 w50 align-c mr05 bg-white-1 cursor">Hủy bỏ</div>
                            <div 
                                class="border-radius h5 pt1 pb1 w50 align-c bg-red-1 white cursor">Cập nhật</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- exportticket -->
        <div
            v-if="$route.params.typeTicket == 'exportticket'" 
            class="exportticket__body f fw jcb ais w100">

            <!-- body-left -->
            <div class="body-left">
                <div class="body-left__ticket-status mb1 pt1 pb1 pl1 pr1 border-radius bg-white-1">
                        <div 
                            v-for="(item, index) in listStatus"
                            :key="index">
                            <div
                                v-if="ticket.ticket_status == item.id" 
                                class="f jcb aic w100 mb1">
                                <div class="h5 gray">Trạng thái phiếu</div>
                                <div 
                                     v-if="dataOrder.status_order.title_status_order == 'Thành công'"
                                    class="pt05 pb05 pl1 pr1 h6 border-radius green bg-green">
                                        {{ dataOrder.status_order.title_status_order }}
                                </div>
                                <div 
                                    class="pt05 pb05 pl1 pr1 h6 border-radius orange bg-yellow"
                                    v-if="dataOrder.status_order.title_status_order == 'Đang xử lý'">
                                        {{ dataOrder.status_order.title_status_order }}
                                </div>
                                <div 
                                    class="pt05 pb05 pl1 pr1 h6 border-radius red bg-red"
                                    v-if="dataOrder.status_order.title_status_order == 'Đã huỷ'">
                                        {{ dataOrder.status_order.title_status_order }}
                                </div>
                            </div>
                        </div>
                    <div class="f jcc aic mb1 pt1 pb1 pl1 pr1 border-radius bg-gray-1">
                        <div class="upcase h5">  {{ this.$route.params.filter }} </div>
                    </div>
                    <div class="body-left__ticket-time pb1 border-bottom">
                        <div class="f jcb aic mb05">
                            <div class="w50 p3 gray">Thời gian khởi tạo</div>
                            <div
                                v-if="this.ticket.time_created_ticket" 
                                class="p3"> {{ this.ticket.time_created_ticket[1] }} {{ this.ticket.time_created_ticket[0] }} </div>
                        </div> 
                        <div class="f jcb aic">
                            <div class="p6 gray">Người khởi tạo</div>
                            <div class="p6 blue"> {{ this.account.full_name }}</div>
                        </div>
                    </div>
                    <div class="body-left__ticket-time pt1">
                        <div class="f jcb aic mb05">
                            <div class="w50 p3 gray">Lần cập nhật cuối</div>
                            <div
                                v-if="this.ticket.time_created_ticket" 
                                class="p3"> {{ this.ticket.time_updated_ticket[1] }} {{ this.ticket.time_updated_ticket[0] }} </div>
                        </div> 
                        <div class="f jcb aic">
                            <div class="p6 gray">Người cập nhật</div>
                            <div class="p6 blue"> {{ this.account.full_name }}</div>
                        </div>
                    </div>
                </div>

                <div class="body-left__id-order mb1 pt1 pb1 pl1 pr1 border-radius bg-white-1">
                    <div class="mb1 h5 gray">Mã phiếu</div>
                    <div class="f jcb aic mb1 pt1 pb1 pl1 pr1 border-radius border">
                        <div class="code-number h5 blue">  #{{ this.ticket.key_ticket }} </div>
                        <div 
                            class="icon icon--copy"
                            @click="handleCopyCode"></div>
                    </div>
                </div>

                <div class="body-left__id-order mb1 pt1 pb1 pl1 pr1 border-radius bg-white-1">
                    <div class="mb1 h5 gray">Mã đơn hàng</div>
                    <div class="f jcb aic mb1 pt1 pb1 pl1 pr1 border-radius border">
                        <div class="code-number h5 blue">  #{{ this.dataOrder.order_key }} </div>
                        <div 
                            class="icon icon--copy"
                            @click="handleCopyCode"></div>
                    </div>
                </div>
            </div>

            <!-- body-center -->
            <div class="body-center pt1 pb1 pl1 pr1 mb1 border-radius bg-white-1">
                <div class="ticket-header h5 mb1">
                    Thiết lập thông tin phiếu
                </div>
                <div class="ticket-form">
                    <div v-if="data.props">
                        <div 
                            v-for="(item, key) in data.props.listfieldaddnew"
                            :key="key">
                                    <div
                                        v-if="key == 'title_export_ticket' || key == 'export_reason' || key == 'note_ticket'" 
                                        class="f fdc mb1">
                                        <label class="upcase mb05 p4" for=""> {{item.text_lable}} <span class="text-red-linear">*</span></label>
                                        <input 
                                            v-if="key == 'title_export_ticket'"
                                            class="pt075 pb075 pl075 pr075"
                                            :type="item.type_input" 
                                            :placeholder="'Nhập ' + item.text_lable"
                                            v-model='ticket.title_export_ticket'>
                                        <input 
                                            v-else-if="key == 'export_reason'"
                                            class="pt075 pb075 pl075 pr075"
                                            :type="item.type_input" 
                                            :placeholder="'Nhập ' + item.text_lable"
                                            v-model='ticket.export_reason'>
                                        <input 
                                            v-else-if="key == 'note_ticket'"
                                            class="pt075 pb075 pl075 pr075"
                                            :type="item.type_input" 
                                            :placeholder="'Nhập ' + item.text_lable"
                                            v-model='ticket.note_ticket'>
                                    </div>
                        </div>
                    </div>
                    <div>
                        <div class="form-item pt1 pb1 pr1 pl1 mb1 border-radius bg-gray-3">
                            <div 
                                @click="handleOpenDetail"
                                class="form-item__label f aic jcb">
                                <div 
                                    class="h6 gray">Thông tin người nhận hàng</div>
                                <div 
                                    class="icon--arrow arrow-rotage"></div>
                            </div>
                            <div class="form-item__detail border-top mt1 pt1">
                                <div
                                    v-if="dataOrder.customer" 
                                    class="f jcb aic mb05">
                                    <div class="p3">Tên người nhận hàng</div>
                                    <div class="h6"> {{ dataOrder.customer.full_name }} </div>
                                </div>
                                <div
                                    v-if="dataOrder.customer" 
                                    class="f jcb aic mb05">
                                    <div class="p3">Số điện thoại</div>
                                    <div class="h6"> {{ dataOrder.customer.phone }} </div>
                                </div>
                                <div class="f jcb aic mb05">
                                    <div class="p3">Địa chỉ nhận hàng</div>
                                    <div class="h6"> 
                                        {{dataOrder.customer.address}}
                                    </div>
                                </div>
                                <div class="f jcb aic">
                                    <div class="p3">Ghi chú</div>
                                    <div 
                                        v-if="dataOrder.note !== null"
                                        class="h6"> 
                                        {{dataOrder.note}}
                                    </div>
                                    <div v-else class="h6 gray">
                                        Chưa có dữ liệu
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div class="form-item pt1 pb1 pr1 pl1 mb1 border-radius bg-gray-3">
                            <div 
                                @click="handleOpenDetail"
                                class="form-item__label f aic jcb">
                                <div 
                                    class="h6 gray">Thông tin thủ kho</div>
                                <div 
                                    class="icon--arrow arrow-rotage"></div>
                            </div>
                            <div class="form-item__detail border-top mt1 pt1">
                                <div
                                    v-if="account" 
                                    class="f jcb aic mb05">
                                    <div class="p3">Tên thủ kho</div>
                                    <div class="h6"> {{ account.full_name }} </div>
                                </div>
                                <div
                                    v-if="account"  
                                    class="f jcb aic mb05">
                                    <div class="p3">Số điện thoại</div>
                                    <div class="h6"> {{ account.phone }} </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div class="form-item pt1 pb1 pr1 pl1 border-radius bg-gray-3 gray">
                            <div 
                                @click="handleOpenDetail"
                                class="form-item__label f aic jcb">
                                <div 
                                    class="h6 gray">Thông tin kiện hàng</div>
                                <div 
                                    class="icon--arrow arrow-rotage"></div>
                            </div>
                            <div class="form-item__detail border-top mt1 pt1">
                                <div class="form-item__location f aic mb1">
                                    <div class="icon icon--location"></div>
                                    <div class="p4 gray">
                                        Vị trí lưu kiện hàng: <span class="black">Khu lưu kiện hàng bán</span>
                                    </div>
                                </div>

                                <div 
                                    v-if="dataOrder.package == null"
                                    class="pt1 pb1 pl1 pr1 mb05 border-radius bg-white-1 cursor"
                                    @click="handlePackage">
                                    <div class="f jcb aic mb05">
                                        <div class="h6 gray">Thông tin sản phẩm</div>
                                        <div class="p6 pt05 pb05 pl05 pr05 border-radius bg-yellow orange">Chưa thiết lập</div>
                                    </div>
                                    <div class="f jcb aic p6 mb025">
                                        <div>Tổng sản phẩm trong kiện</div>
                                        <div>Không có dữ liệu</div>
                                    </div>
                                    <div class="f jcb aic p6">
                                        <div>Tổng giá trị kiện hàng</div>
                                        <div>Không có dữ liệu</div>
                                    </div>
                                </div>
                                <div 
                                    v-else-if="dataOrder.package != null"
                                    class="pt1 pb1 pl1 pr1 mb05 border-radius bg-white-1 cursor"
                                    @click="handlePackage">
                                    <div class="f jcb aic mb05">
                                        <div class="h6 gray">Thông tin sản phẩm</div>
                                        <div class="p6 pt05 pb05 pl05 pr05 border-radius bg-green green">Đã thiết lập</div>
                                    </div>
                                    <div class="f jcb aic p6 mb025">
                                        <div>Tổng sản phẩm trong kiện</div>
                                        <div> {{totalQuantity()}} sản phẩm</div>
                                    </div>
                                    <div class="f jcb aic p6">
                                        <div>Tổng giá trị kiện hàng</div>
                                        <div> {{this.totalPrice}} </div>
                                    </div>
                                </div>
                                <div 
                                    v-if="dataOrder.package == null"
                                    class="pt1 pb1 pl1 pr1 mb05 border-radius bg-white-1 cursor">
                                    <div class="f jcb aic mb05">
                                        <div class="h6 gray">Thông số kiện hàng</div>
                                        <div class="p6 pt05 pb05 pl05 pr05 border-radius bg-yellow orange">Chưa thiết lập</div>
                                    </div>
                                    <div class="f jcb aic p6 mb025">
                                        <div>Khối lượng</div>
                                        <div>Không có dữ liệu</div>
                                    </div>
                                    <div class="f jcb aic p6">
                                        <div>Kích thước</div>
                                        <div>Không có dữ liệu</div>
                                    </div>
                                </div>
                                <div 
                                    v-else-if="dataOrder.package != null"
                                    class="pt1 pb1 pl1 pr1 mb05 border-radius bg-white-1 cursor">
                                    <div class="f jcb aic mb05">
                                        <div class="h6 gray">Thông số kiện hàng</div>
                                        <div class="p6 pt05 pb05 pl05 pr05 border-radius bg-green green">Đã thiết lập</div>
                                    </div>
                                    <div class="f jcb aic p6 mb025">
                                        <div>Khối lượng</div>
                                        <div> {{ dataOrder.package.weight }} kg</div>
                                    </div>
                                    <div class="f jcb aic p6">
                                        <div>Kích thước</div>
                                        <div> {{ dataOrder.package.length }} x {{ dataOrder.package.height }} x {{ dataOrder.package.width }} cm </div>
                                    </div>
                                </div>

                                <div class="pt1 pb1 pl1 pr1 border-radius bg-white-1">
                                    <div class="h6 gray mb05">Mã QR</div>
                                    <div 
                                        v-if="dataOrder.package == null"
                                        class="h6 pt1 pb1 mb025 border-radius align-c bg-red white">
                                        Tạo mã QR
                                    </div>
                                    <div 
                                        v-else-if="dataOrder.package != null && dataOrder.package.qrcode == null"
                                        @click="handleQR"
                                        class="h6 pt1 pb1 mb025 border-radius align-c bg-red-1 white cursor">
                                        Tạo mã QR
                                    </div>
                                    <div
                                        v-if="dataOrder.package == null"
                                        class="f aic orange p6">
                                        <div class="icon icon--faq"></div>
                                        <div class="ml025">Bạn cần hoàn thiện thông tin trên để tạo mã QR cho kiện</div>
                                    </div>
                                    <div v-else-if="dataOrder.package != null && dataOrder.package.qrcode != null">
                                        <div class="f jcc aic border-2 border-radius pt1 pb1">
                                            <div class="pt1 pb05 pl1 pr1 border border-radius">
                                                <img :src="dataOrder.package.qrcode" class="mb05">
                                                <div 
                                                    
                                                    class="pt05 border-top align-c h6 blue cursor ">
                                                    In mã QR
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- body-right -->
            <div class="body-right border-radius">
                <div class="body-right__product pt1 pb1 pl1 pr1 border-radius bg-white-1">
                    <div class="body-right__header h5 mb05">
                        Thông tin sản phẩm khách hàng đã đặt
                    </div>
                    <div class="body-right__quantity-total mb1 p3 gray">
                        Đã đặt: <span class="p4 black"> {{totalQuantity()}} Sản phẩm</span>
                    </div>
                    <div class="body-right__product-list">
                        <ProductTicketDetail 
                            :listConsignment='listConsignment'
                            :listPackage='listPackage'
                            :listProduct='listProduct'></ProductTicketDetail>
                    </div>
                </div>

                <div class="body-right__total pl1 pr1 mt1 border-radius bg-white-1">
                    <div class="f jcb aic pt1 pb1 border-bottom">
                        <div class="h5">Tổng tiền hàng xuất</div>
                        <div class="h5 text-red-linear"> {{this.totalPrice}} </div>
                    </div>
                    <div class="w100 f jcb aic pt15 pb15">
                        <div
                            @click="handleCancel(false)" 
                            class="border border-radius h5 pt1 pb1 w50 align-c mr1 cursor">Hủy bỏ</div>
                        <div 
                            @click="handleCreateTicket(true)"
                            class="border-radius h5 pt1 pb1 w50 align-c cursor bg-red-1 white">Cập nhật</div>
                    </div>
                </div>
            </div>
        </div> 

        <ProductPackage 
            :listConsignment='listConsignment'
            :listPackage='listPackage'
            :listProduct='listProduct'
            :totalPrice='totalPrice'>
        </ProductPackage>

        <PopupQRView></PopupQRView>
        <PopupCreateTicketView
            :statusAction="statusAction"
            :dataSubOrder="dataOrder.sub_order"
            :title_import_ticket='title_import_ticket'
            :note_ticket="note_ticket"
            :original_documents="original_documents"
            :listConsignment="listConsignment">
        </PopupCreateTicketView>
    </div>
</template>

<script>
import ProductPackage from '@/components/TicketDetail/ProductPackageView/ProductPackage.vue'
import ProductTicketDetail from '@/components/TicketDetail/ProductTicketDetail/PropductTicketDetail'
import PopupQRView from '@/components/ImportTicketView/PopupNotiQRView/PopupQRView'
import PopupCreateTicketView from '@/components/ImportTicketView/PopupCreateTicket/PopupCreateTicketView'
import { HTTP } from '../../http-default'
// import axios from 'axios'

// import firebase from "@/firebaseInit";
// import "firebase/firestore";
// const db = firebase.firestore();
export default {
    components : {
        ProductPackage,
        ProductTicketDetail,
        PopupQRView,
        PopupCreateTicketView,
    },
    data () {
        return {
            ticket : [],

            dataPackage : [],
            dataOrder : [],
            data : [
                {
                    props : [
                        {
                            listfieldaddnew : []
                        }
                    ]
                }
            ],

            // action with ticket
            statusAction : '',

            title_import_ticket : '',
            note_ticket : '',
            original_documents : '',

            listProduct : [],

            listStatus : [],

            // Account
            account : {},

            // consignment
            listConsignment : [],
            consignment_parent : [],
            totalPrice : 0,

            // package
            listPackage : [],
            // product Scan
            listProductScan : [],
            productImport : [],
        }
    },

    methods: {
        // handle update consignment
        updateListConsignment (value, product) {
            this.productImport = []
            this.listConsignment = value
            this.listProductScan = product

            for (let k = 0; k < this.listProduct.length; k++) {
                for (let m = 0; m < this.listProductScan.length; m++) {
                    if (this.listProductScan[m].value == this.listProduct[k].id) {
                        this.listProduct[k].price_product = this.listProduct[k].price_product.toLocaleString('it-IT', {style : 'currency', currency : 'VND'});
                        this.productImport.push(this.listProduct[k])
                    }
                }
            }
        },

        // update product scan
        updateProductTicket (value) {
            this.productImport = value
        },

        totalQuantity () {
            var totalQuantity = 0
            if (this.$route.params.filter === 'phiếu nhập hàng thông thường') {
                this.listConsignment.forEach(item => {
                    totalQuantity = totalQuantity + item.quantity_in_consignment
                })
            } else {
                this.listPackage.forEach(item => {
                    totalQuantity = totalQuantity + item.total_quantity
                })
            }
            return totalQuantity
        },
        
        handleCopyCode () {
            var copyText = document.getElementsByClassName('code-number')[0].innerText
            var TempText = document.createElement("input");
            TempText.value = copyText;
            document.body.appendChild(TempText);
            TempText.select();
            document.execCommand("copy");
            document.body.removeChild(TempText);
            alert("Copied the text: " + TempText.value);
        },

        handleOpenDetail (event) {
            var button = event.target.parentElement.parentElement;
            var popup = button.getElementsByClassName('form-item__detail')[0]
            var arrown = button.getElementsByClassName('icon--arrow')[0]
            popup.classList.toggle('active')
            arrown.classList.toggle('arrow-rotage')
        },

        // create package
        handlePackage () {
            HTTP.get('v1/api/account/')
            .then ((res) => {
                this.account = res.data[0]
            })

            var popup = document.getElementsByClassName('popup-create-package')[0]
            popup.classList.toggle('active-popup')
        },

        // create QR package
        handleQR () {

            var popup = document.getElementsByClassName('popup-QR')[0]
            popup.classList.toggle('active-popup')
        },

        // create ticket
        handleCreateTicket (value) {
            this.statusAction = value
            var popup = document.getElementsByClassName('popup-create-ticket')[0]
            popup.classList.toggle('active-popup')
        },

        // save ticket
        handleSaveTicket (value) {
            this.statusAction = value
            var popup = document.getElementsByClassName('popup-create-ticket')[0]
            popup.classList.toggle('active-popup')
        },

        // delete ticket
        handleCancelTicket () {
            this.$router.go(-1)
        },

        // get data API
        getData () {
            var systemSetting = localStorage.getItem("systemSetting")
            this.data = JSON.parse(systemSetting)
            for (let i = 0; i < this.data.length; i++) {
                if (this.data[i].name == this.$route.params.typeTicket) {
                    this.data = this.data[i]
                    break
                }
            }

            HTTP.get('/v1/api/ticketstatus/')
            .then((res) => {
                this.listStatus = res.data.results
            })

            HTTP.get('/v1/api/product/')
            .then((res) => {
                this.listProduct = res.data
            })

            HTTP.get('v1/api/account/')
            .then ((res) => {
                this.account = res.data[0]
            })

            // import ticket
            if (this.$route.params.typeTicket == 'importticket') {
                HTTP.get('v1/api/consignment/')
                .then((res) => {
                    var listDataConsigment = res.data.results
                    HTTP.get('v1/api/' + this.$route.params.typeTicket + '/' + this.$route.params.id + '/')
                    .then((res) => {
                        this.ticket = res.data
                        // shipper
                        const setting = this.ticket.shipper.toLowerCase()
                        const results = setting.replaceAll("'", '"')
                        this.ticket.shipper = JSON.parse(results)

                        //time
                        var time = this.ticket['time_created_ticket'].split('T')
                        time[1] = time[1].split('.')[0]
                        this.ticket['time_created_ticket'] = time

                        listDataConsigment.forEach(element => {
                            if (element.consignment_parent == this.ticket.consignment) {
                                this.listConsignment.push(element)
                                this.totalPrice = this.totalPrice + element.total_price
                            }
                            if (element.id == this.ticket.consignment) {
                                this.consignment_parent = element
                            }
                        });

                        this.totalPrice = this.totalPrice.toLocaleString('it-IT', {style : 'currency', currency : 'VND'});
                    })
                })
            }

            if (this.$route.params.typeTicket == 'exportticket') {
                HTTP.get('v1/api/' + this.$route.params.typeTicket + '/' + this.$route.params.id + '/')
                .then((res) => {
                    this.ticket = res.data
                    // shipper
                    const setting = this.ticket.shipper.toLowerCase()
                    const results = setting.replaceAll("'", '"')
                    this.ticket.shipper = JSON.parse(results)

                    //time
                    var time = this.ticket['time_created_ticket'].split('T')
                    time[1] = time[1].split('.')[0]
                    time[1] = time[1].split('+')[0]
                    this.ticket['time_created_ticket'] = time

                    var timeUpdate = this.ticket['time_updated_ticket'].split('T')
                    timeUpdate[1] = timeUpdate[1].split('.')[0]
                    timeUpdate[1] = timeUpdate[1].split('+')[0]
                    this.ticket['time_updated_ticket'] = timeUpdate
                    
                    // customer
                    const data = this.ticket.customer.toLowerCase()
                    const data1 = data.replaceAll("'", '"')
                    this.ticket.customer = JSON.parse(data1)

                    // get dataOrder
                    HTTP.get('v1/api/order/')
                    .then((res) => {
                        var listdataOrder = res.data.results
                        
                        listdataOrder.forEach(item => {
                            if (item.id == this.ticket.order) {
                                this.dataOrder = item
                            }
                        })

                        HTTP.get('v1/api/package/')
                        .then((res) => {
                            var listDataPackage = res.data 
                            listDataPackage.forEach(item => {
                                if (item.package_parent == this.dataOrder.package.id) {
                                    this.listPackage.push(item)
                                    this.totalPrice = this.totalPrice + item.total_price
                                }
                            })

                            this.totalPrice = this.totalPrice.toLocaleString('it-IT', {style : 'currency', currency : 'VND'});
                        })
                    })
                })
            }

        }
    },

    created () {
        this.getData(); 
    }
}
</script>

<style scoped lang='scss'>
    @import 'TicketDetail.scss';
</style>