<template>
  <div class="header-view">
    <div class="desktop per-view-header relative w100 jcb aic bg-white-1" style="box-shadow: 0px 0px 10px rgba(68, 79, 120, 0.15)">
      <div class="f aic">
        <div @click="handleBack">
            <div class="button--back f aic ml2 cursor">
              <div class="icon--back"></div>
              <p class="h5 ml1 mr2 gray">Quay lại</p>
            </div>
        </div>
        <div class="f jcb aic pt05 pb05 pl1 pr1  bg-gray-1">
          <img src="../../assets/imgPerson.png" alt="">
          <div class="ml05 mr2">
            <p class="h6 blue-1">Công ty CP Công Nghệ IDT</p>
            <p class="h7 gray mt05">Giám đốc Công Nghệ</p>
          </div>
          <div class="icon icon--arrow"></div>
        </div>
      </div>
      <div class="f jcb aic">
        <div
          @click="handleListNoti" 
          class="relative mr2 f jcc aic align-c" style="border: 1px solid #D9DDE8; border-radius: 40px; width: 39px; height: 39px">
          <div class="icon icon--bell"></div>
        </div>
        <div class="f jcb aic" @click="handleLogout">
          <img src="../../assets/imgPerson.png" alt="">
          <div class="mr2 ml05">
            <p class="h6 blue-1">{{account.full_name}}</p>
            <p class="h7 gray mt025">Giám đốc Công Nghệ</p>
          </div>
        </div>
      </div>   
      <PopupLogoutView></PopupLogoutView>
      <ListNoti
        v-if="listNoti"></ListNoti>
    </div>

      <div 
        v-if="isMobile()"
        class="mobile orer-detail__header w100 f jcb aic pt075 pb075 pl1 pr1 bg-white-1">
        <div
          @click="handleBack"
          class="f aic">
          <div class="orer-detail__header-arrow icon--arrow-1"></div>
          <div class="h5">Quay lại</div>
        </div>
        <div 
          class="f aic">
          <router-link :to="{ name: 'home', params : '/' }">
            <img src="../../assets/home.png" alt="">
          </router-link>
          <!-- <div class="p6 gray ml05">Trang chủ</div> -->
        </div>
      </div>
  </div>
</template>

<script>
import PopupLogoutView from '@/components/PopupLogout/PopupLogoutView.vue'
import ListNoti from '@/components/ListNotiView/ListNoti.vue'
import { HTTP } from '@/http-default'

export default {
  components: {
    PopupLogoutView,
    ListNoti
  },
  data() {
    return {
      back: '',
      account : [],
      listNoti: false
    }
  },
  methods: {
    isMobile () {
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            return true
        } else {
            return false
        }
    },

    handleBack() {
      this.$router.go(-1)
    },
    handleLogout() {
      var logout = document.getElementsByClassName('logout')[0]
      logout.classList.toggle('logout-active')
    },

    handleListNoti () {
      if (this.listNoti === false) {
        this.listNoti = true
      } else {
        this.listNoti = false
      }
      // var listNoti = document.getElementsByClassName('noti-list__container')[0]
      // listNoti.classList.toggle('active')
    },

    getData () {
      HTTP.get('v1/api/account/')
      .then((res) => {
        this.account = res.data[0]
      })
    }
  },

  created () {
    this.getData()
  }
}
</script>

<style scoped lang="scss">
  @import 'HeaderView.scss';
</style>