@<template>
    <div class="menu__container mb1">
        <div class="content__sidebar pt1 pb1 pr1 pl1 mr2 border-radius bg-white-1">
            <div class="content__sidebar-header h5">Danh mục</div>
            <router-link :to="{ name: 'menu-stock-view', params: { menuitem: 'quan-ly-stock'} }">
                <div 
                    v-if="this.$route.params.menuitem == 'quan-ly-stock'"
                    class="content__sidebar-item f aic pt1 pb1">
                    <div class="icon icon--stock-real-1"></div>
                    <div class="h6 upcase ml05 text-red-linear"> Tồn kho thực tế </div>
                </div>
                <div 
                    v-else
                    class="content__sidebar-item f aic pt1 pb1">
                    <div class="icon icon--stock-real"></div>
                    <div class="p4 upcase ml05"> Tồn kho thực tế </div>
                </div>
            </router-link>
            <div v-for="(item, index) in dataMenu"
                :key="index">
                <div v-if="item.props.titlecontent == 'phiếu nhập hàng'">
                    <router-link 
                        :to="{ name: 'menu-stock-view', params: { menuitem: 'quan-ly-importticket'} }"
                        v-if="$route.params.menuitem == 'quan-ly-importticket'">
                        <div 
                            class="content__sidebar-item f aic border-top pt1 pb05">
                            <div class="icon" :class="item.icon + '-1'"></div>
                            <div class="h6 upcase ml05 text-red-linear"> {{item.props.titlecontent}} </div>
                        </div>
                    </router-link>
                    <router-link 
                        :to="{ name: 'menu-stock-view', params: { menuitem: 'quan-ly-importticket'} }"
                        v-else>
                        <div 
                            class="content__sidebar-item f aic border-top pt1 pb05">
                            <div class="icon" :class="item.icon"></div>
                            <div class="p4 upcase ml05"> {{item.props.titlecontent}} </div>
                        </div>
                    </router-link>
                    <div 
                        v-for="(value, index) in importtickettype"
                        :key="index"
                        @click="handleFilter(item.name, value.id)">
                        <router-link 
                            v-if="keyFilter.length != 0 && $route.params.filter == value.title_ticket_type"
                            class="content__sidebar-item f aic pl2 pb05 cursor"
                            :to="{ name: 'filter-ticket', params: { menuitem: 'quan-ly-importticket', filter: value.title_ticket_type } }">
                            <div class="icon icon--arrow-right"></div>
                            <div class="h6 upcase ml05 upcase text-red-linear"> {{ value.title_ticket_type }} </div>
                        </router-link>
                        <router-link 
                            v-else
                            class="content__sidebar-item f aic pl2 pb05 cursor"
                            :to="{ name: 'filter-ticket', params: { menuitem: 'quan-ly-importticket', filter: value.title_ticket_type } }">
                            <div class="icon icon--arrow-right"></div>
                            <div class="p4 upcase ml05 upcase"> {{ value.title_ticket_type }} </div>
                        </router-link>
                    </div>
                </div>
                <div v-if="item.props.titlecontent == 'phiếu xuất hàng'">
                    <router-link 
                        :to="{ name: 'menu-stock-view', params: { menuitem: 'quan-ly-exportticket'} }"
                        v-if="$route.params.menuitem == 'quan-ly-exportticket'">
                        <div 
                            class="content__sidebar-item f aic border-top pt1 pb05">
                            <div class="icon" :class="item.icon + '-1'"></div>
                            <div class="h6 upcase ml05 text-red-linear"> {{item.props.titlecontent}} </div>
                        </div>
                    </router-link>
                    <router-link 
                        :to="{ name: 'menu-stock-view', params: { menuitem: 'quan-ly-exportticket'} }"
                        v-else>
                        <div 
                            class="content__sidebar-item f aic border-top pt1 pb05">
                            <div class="icon" :class="item.icon"></div>
                            <div class="p4 upcase ml05"> {{item.props.titlecontent}} </div>
                        </div>
                    </router-link>
                    <div 
                        v-for="(value, index) in exporttickettype"
                        :key="index"
                        @click="handleFilter(item.name, value.id)">
                        <router-link 
                            v-if="keyFilter.length != 0 && $route.params.filter == value.title_ticket_type"
                            class="content__sidebar-item f aic pl2 pb05 cursor"
                            :to="{ name: 'filter-ticket', params: { menuitem: 'quan-ly-exportticket', filter: value.title_ticket_type } }">
                            <div class="icon icon--arrow-right"></div>
                            <div class="h6 upcase ml05 upcase text-red-linear"> {{ value.title_ticket_type }} </div>
                        </router-link>
                        <router-link 
                            v-else
                            class="content__sidebar-item f aic pl2 pb05 cursor"
                            :to="{ name: 'filter-ticket', params: { menuitem: 'quan-ly-exportticket', filter: value.title_ticket_type } }">
                            <div class="icon icon--arrow-right"></div>
                            <div class="p4 upcase ml05 upcase"> {{ value.title_ticket_type }} </div>
                        </router-link>
                    </div>
                </div>
            </div>
            <router-link :to="{ name: 'menu-stock-view', params: { menuitem: 'quan-ly-sessionreport'} }">
                <div    
                    v-if="$route.params.menuitem == 'quan-ly-sessionreport'"
                    class="content__sidebar-item f aic border-top pt1 pb1">
                    <div class="icon icon--report-1"></div>
                    <div class="h6 upcase ml05 text-red-linear"> Báo cáo nhập / xuất hàng </div>
                </div>
                <div    
                    v-else
                    class="content__sidebar-item f aic border-top pt1 pb1">
                    <div class="icon icon--report"></div>
                    <div class="p4 upcase ml05"> Báo cáo nhập / xuất hàng </div>
                </div>
            </router-link>
        </div>
    </div>
</template>

<script>
import { HTTP } from '@/http-default'

export default {
    props : {
        dataMenu : {
            type : Array,
            default() {
                return []
            }
        }
    },

    data () {
        return {
            importtickettype : [],
            exporttickettype : [],
            keyFilter : []
        }
    },

    methods : {
        getData () {
            HTTP.get('v1/api/importtickettype/')
            .then((res) => {
                this.importtickettype = res.data.results
            })

            HTTP.get('v1/api/exporttickettype/')
            .then((res) => {
                this.exporttickettype = res.data.results
            })
        },

        handleFilter (id1, id2) {
            this.keyFilter = []
            this.keyFilter.push(id1)
            this.keyFilter.push(id2)

            this.$emit('updateFilter', this.keyFilter)
        }
    },

    created () {
        this.getData()
    }
}
</script>

<style scoped lang="scss">
    @import 'Menu.scss';
</style>