@<template>
        <div class="register f fdc jcb pb1 pl1 pr1 border-radius bg-white-1">
            <div>
                <div class="logo logo-bzi-small"></div>
                <div class="title f aic mb15 w100">
                    <div class="h3">Đăng ký</div>
                </div>
                <form v-on:submit.prevent="register" class="mb1">
                    <div class="f fdc mb1">
                        <label class="h7 mb05" for="">Số điện thoại (Tên đăng nhập)</label>
                        <input 
                            v-model="username" 
                            type="text" 
                            placeholder="Nhập số điện thoại vào đây">
                    </div>
                    <div class="f fdc mb1">
                        <label class="h7 mb05" for="">Email</label>
                        <input 
                            v-model="email" 
                            type="text" 
                            placeholder="Nhập địa chỉ email của bạn ">
                    </div>
                    <div class="relative f fdc mb1">
                        <div @click="handleViewPassWord" class="icon__input absolute cursor">
                            <div class="absolute icon--blind"></div>
                        </div>
                        <div @click="handleViewPassWord" class="icon__input absolute cursor hide">
                            <div class="absolute icon--eye"></div>
                        </div>
                        <label class="h7 mb05" for="">Mật khẩu</label>
                        <input 
                            v-model="password"
                            type="password" 
                            placeholder="Nhập mật khẩu vào đây">
                    </div>
                    <div class="relative f fdc mb2">
                        <div @click="handleViewPassWord" class="icon__input absolute cursor">
                            <div class="absolute icon--blind"></div>
                        </div>
                        <div @click="handleViewPassWord" class="icon__input absolute cursor hide">
                            <div class="absolute icon--eye"></div>
                        </div>
                        <label class="h7 mb05" for="">Xác nhận lại mật khẩu</label>
                        <input 
                            v-model="passwordConfirm"
                            type="password" 
                            placeholder="Nhập lại mật khẩu">
                    </div>
                    <button class="w100 h5 mt2 pt075 pb075 align-c border-radius bg-red-1 white">Đăng ký</button>
                </form>
                <div class="mt1 align-c p3 pl05 pr05 gray">
                    Bằng việc chọn "Đăng ký", bạn đồng ý với các <a class="blue h6" href="">Điều khoản và Điều kiện </a>của chúng tôi
                </div>
            </div>
            <div class="w100 align-c mt15">
                <span class="gray p3">Đã có tài khoản? </span>
                <span @click="handleLogin" class="cursor blue h6">Đăng nhập ngay</span>
            </div>

            <ConfirmCodeView 
                :email='email'
                :idRegister='idRegister'></ConfirmCodeView>
        </div>
</template>

<script>
import ConfirmCodeView from '@/views/LoginView/RegisterView/ConfirmCodeView/ConfirmCodeView.vue'
import axios from 'axios'
export default {
    components : {
        ConfirmCodeView
    },

    data() {
        return {
            idRegister : 0,
            username : "",
            email: "",
            password : "",
            passwordConfirm : "",
            type: 'password'
        }
    },
    methods : {
        handleLogin() {
            var login = document.querySelector('.login');
            login.classList.remove('visible');
            var register = document.querySelector('.register');
            register.classList.remove('active');
        },
        
        handleViewPassWord(event) {
            var button = event.target.parentElement.parentElement
            var iconEye = button.getElementsByClassName('icon__input')[1]
            iconEye.classList.toggle('active')
            var iconBlind = button.getElementsByClassName('icon__input')[0]
            iconBlind.classList.toggle('visible')
            var view = button.getElementsByClassName('visible');
            var input = button.getElementsByTagName('input')[0]
            if(view.length > 0) {
                input.type = 'text'
            } else {
                input.type = 'password'
            }
        },

        register() {
            var dataUser = JSON.stringify({
                "username": this.username, 
                "password": this.password,
                "email": this.email,
                "first_name": "LongHoang"
            });

            const axiosConfig = {
                headers : {'Content-Type': 'application/json'} 
            }

            if (this.password == this.passwordConfirm) {
                axios.post('https://api.nmeco.xyz/register/', dataUser, axiosConfig)
                .then((res) => {
                    this.idRegister = res.data.id
                    this.username = ""
                    this.password = ""
                    this.passwordConfirm = ""
                    var popup = document.getElementsByClassName('popup__confirm-code')[0]
                    popup.classList.toggle('f')
                })
            } else {
                alert('Mật khẩu không đúng')
            }
        }
    }
}
</script>

<style scoped lang='scss'>
    @import 'Register.scss';
</style>