
<template>
  <div id="PerView" class="per-view-container">
    <div class="per-view-content pt15 pl2 pr2">
      <div class="content__title f aic">
        <p class="h3 mr1 black">Danh sách {{this.dataKey[0]['titlecontent']}}</p>
        <p class="h5 pl1 text-red-linear" style="border-left: 1px solid #C7C9D9">Danh sách {{this.dataKey[0].titlecontent}}</p>
      </div>
      <div class="content__tab-bar f jcb aic mt15 ">
        <div class="f relative aic">
          <img src="../../assets/Enter email.png" class="absolute ml1" alt="">
          <input id="myInput" @keyup="handleFilter" style="height: 45px; width: 450px; padding-left: 3em" type="text" placeholder="Tìm kiếm nhân sự" name="">
          <div class="f jcc aic ml05 border-radius" style="border: 1px solid #C7C9D9; width: 45px; height: 45px ">
            <img src="../../assets/typeSearch.png" alt="">
          </div>
        </div>
        <div 
          class="f aic border-radius bg-red-1 pr15 pl15 cursor" 
            style="height: 45px"
            @click="handleAdd">
           <img src="../../assets/action.png" alt="">
           <p class="h6 ml05 white">Hành động</p>
        </div>
      </div>
      <div class="content-list pt15 pb15">
        <div class="content-list__header f aic pr15 pl15 mb15">
          <div class="mr2">
            <input class="checkbox" type="checkbox">
          </div>
          <div class="mr2 h6 blue-1 align-c" style="width: 30px">
            STT
          </div>
          <div class="f jcb aic w100 h6 blue-1">
            <div v-for="(item, key) in dataKey[0]['listfield']" :key="key" class="h6">
              <div class="align-c qcont blue-1"
                v-bind:style="{ width: wid + 'px', marginLeft: margin + 'px'}"
                v-if="key != 'updated_at' 
                          && key != 'created_at'
                          && key != 'date_end' 
                          && key != 'date_start'  
                          && key != 'id'
                          && key != 'key_customer'
                          && key != 'fullname'
                          && key != 'permission'
                          && key != 'address_2'">
                {{item.text}}
              </div>
            </div>
          </div>
        </div>
        <div class="content-list__body f fw aic">
          <div class="w100" v-for="(item, index) in personnelActive" :key="index">
            <router-link :to="{ name: 'detail', params: { id: item.id} }" class="w100">
              <div class="item-person f aic w100 border-radius mb1 pr15 pl15 pt1 pb1 bg-white-1">
                <div class="mr2">
                  <input class="checkbox" type="checkbox"> 
                </div>
                <div class="mr2 h6 align-c" style="width: 30px">
                  {{index + 1}}
                </div>
                <div class="f jcb aic w100">
                  <div class="jcb" v-for="(value, key) in item" :key="key">
                    <div
                      v-bind:style="{ width: wid + '%'}"
                      v-if="key == 'title_customer'">
                      <div style="width: 200px" class="f pl2">
                        <img class="mr05" src="../../assets/imgLogoCompany.png" alt="" style="height: 48px">
                        <div>
                          <div class="h6 per-name mb05" style="color: #2B79F7">{{item.fullname}}</div>
                          <div class="h7">{{item.key_customer}}</div>
                        </div>
                      </div>
                    </div>
                    <div class="align-c"
                      v-bind:style="{ width: wid + 'px', marginLeft: margin + 'px'}"
                      v-else-if="key != 'updated_at' 
                              && key != 'created_at'
                              && key != 'date_end' 
                              && key != 'date_start'  
                              && key != 'id'
                              && key != 'key_customer'
                              && key != 'fullname'
                              && key != 'permission'
                              && key != 'address_2'"
                              >
                        {{value}}
                    </div>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="per-view-more w100 fixed f jce align-r pl2 pr2 pb2" style="bottom: 150px">
      <div 
        @click="handleMorePer"
        class="align-c active ml05 f jcc aic h7 bg-white-1" 
        style="width: 29px; height: 29px; border-radius: 40px; border: 1px solid #CCD2E2; color: #444F78">1</div>
      <div
        @click="handleMorePer" 
        class="align-c ml05 f jcc aic h7 bg-white-1" 
        style="width: 29px; height: 29px; border-radius: 40px; border: 1px solid #CCD2E2; color: #444F78">2</div>
      <div 
        @click="handleMorePer"
        class="align-c ml05 f jcc aic h7 bg-white-1" 
        style="width: 29px; height: 29px; border-radius: 40px; border: 1px solid #CCD2E2; color: #444F78" >3</div>
      <div 
        @click="handleMorePer"
        class="align-c ml05 f jcc aic h7 bg-white-1" 
        style="width: 29px; height: 29px; border-radius: 40px; border: 1px solid #CCD2E2; color: #444F78">4</div>
    </div>
    <div class="per-view-button-back fixed f aic jcc mt4" style="left: 45%; bottom: 50px">
      <router-link to="/">
        <button class="button border-radius h6 pt1 pb1 pr1 pl1 bg-white-1 blue-1" style="box-shadow: 0px 0px 16px rgba(88, 92, 162, 0.24);">
          <img src="../../assets/home.png" alt="">
          Màn hình chính
        </button>
      </router-link>
    </div>
    <back-home-btn></back-home-btn>
    
    <!-- Add Customer -->
    <div class="add-customer absolute hide  h100vh w100">
      <div class="add__container f fdc jcb bg-gray-1 h100vh">
        <div class="f fdc jcb h100">
          <div class="add__header f jcb aic price-large pt05 pb05 pr1 pl1 white bg-red-1">Thêm hồ sơ nhân sự
            <div class="icon icon--close" @click="handleAdd"></div>
          </div>
          <form class="f fdc jcb " style="height: 94%" action="">
            <div class="add__body pt1 pb05 pr1 pl1">
              <div 
                class="mb1"
                v-for="(item, key) in dataKey[0]['listfieldaddnew']" :key="key">
                <div 
                  class="f fdc jcs"
                  v-if="item.show 
                        && key != 'date_start' 
                        && key != 'permission' 
                        && key != 'status_account'
                        && key != 'number_son'" >
                    <label 
                      class="mb025 qcont">{{ item.text_lable }}</label>
                    <div v-if="item.type_input == 'select' && key == 'organization'">
                      <select 
                        class="pt075 pb075 pr075 pl075"
                        v-model="organization"
                        id="">
                        <option v-for="(value, index) in organizationList" :key="index" :value='value.id'> {{ value.title_organization }} </option>
                      </select>
                    </div>
                    <div v-else-if="item.type_input == 'select' && key == 'user'">
                      <select 
                        class="pt075 pb075 pr075 pl075"
                        v-model="user"
                        id="">
                        <option v-for="(value, index) in userList" :key="index" :value='value.id'> {{ value.username }} </option>
                      </select>
                    </div>
                    <div v-else>
                      <input 
                        v-if="key == 'full_name'"
                        class="pt075 pb075 pr075 pl075"
                        :type="item.type_input"
                        v-model="full_name"
                        placeholder="Nhập vào đây">
                      <input 
                        v-if="key == 'key_account'"
                        class="pt075 pb075 pr075 pl075"
                        :type="item.type_input"
                        v-model="key_account"
                        placeholder="Nhập vào đây">
                    </div>
                </div>
              </div>
            </div>
            <div class="add__btn f jce aic pt05 pb05 pr1 pl1 bg-blue-2">
              <div 
                @click="handleAdd"
                class="btn-close pt05 pb05 pr1 pl1 border-radius h7 bg-white-1 cursor">Huỷ bỏ</div>
              <div 
                @click="handleAddNewAccount"
                class="btn-save ml05 pt05 pb05 pr1 pl1 border-radius h7 bg-red-1 white cursor">Lưu lại</div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Vue } from 'vue-property-decorator';
import BackHomeBtn from "@/components/BackHomeBtn.vue";
import axios from 'axios'
import { HTTP } from '../../http-default'

export default Vue.extend({
  components:{
    BackHomeBtn,
  },
  data() {
    return {
      personnelActive: [],
      data: [],
      dataKey: [],
      dataShow: [],
      organizationList: [],
      userList: [],

      full_name : '',
      key_account : '',
      organization : '',
      user : '',
      wid: '',
      margin: '',
    }
  },
  watch: {
    
  },
  methods: {
    handleFilter() {
      var input = document.getElementById("myInput");
      var filter = input.value.toUpperCase();
      this.personnelActive = [];
      this.personnelWait = [];
      if(!filter) {
        for(let i = 0; i < 5; i++) {
        this.personnelActive.push(this.dataShow[i]);
      }
      }
      else {
        for (let i = 0; i < this.dataShow.length; i++) {
            var perName = this.dataShow[i].name;
            var txtValue = perName;
            if (txtValue.toUpperCase().indexOf(filter) > -1) {
                this.personnelWait.push(this.dataShow[i])
            }
        }
        for(let i = 0; i < this.personnelWait.length ; i++) {
          this.personnelActive.push(this.personnelWait[i]);
        }
      }
    },
    handleMorePer(event) {
      var tickactive = document.getElementsByClassName('active');
      if(tickactive.length == 1) {
        tickactive[0].classList.remove('active')
      }
      var button = event.target;
      var per = button;
      var active = event.target;
      active.classList.add('active');
      var number = per.innerText;
      this.personnelActive = [];
      for(let i = (number * 5 -5); i < (number * 5); i++) {
        var birthday = this.dataShow[i]['birthday'].split('T')
        this.dataShow[i]['birthday'] = birthday[0]
        this.personnelActive.push(this.dataShow[i]);
      }
    },
    handleAdd(event) {
      event.preventDefault()
      var add = document.getElementsByClassName('add-customer')[0]
      add.classList.toggle('add')
    },
    getKey() {
      var systemSetting = localStorage.getItem("systemSetting")
      this.data = JSON.parse(systemSetting)
      for (let i = 0; i < this.data.length; i++) {
        if(this.data[i]['path'] == this.$route.fullPath) {
          this.dataKey.push(this.data[i]['props'])
          HTTP.get(this.data[i].props.urlapi)
          .then((res) => {
              this.dataShow = res.data.results
              for(let i = 0; i < this.dataShow.length; i++) {
                // var birthday = this.dataShow[i]['birthday'].split('T')
                // this.dataShow[i]['birthday'] = birthday[0]
                this.personnelActive.push(this.dataShow[i]);
              }
          })
        }
      }

      HTTP.get('v1/api/organization/')
      .then((res) => {
        this.organizationList = res.data.results
      })

      HTTP.get('v1/api/user/')
      .then((res) => {
        this.userList = res.data.results
      })
    },

    handleAddNewAccount () {
      var dataUser = JSON.stringify({
        "full_name": this.full_name,
        "key_account": this.key_account,
        "organization": [ this.organization ],
        "user" : this.user,
        "date_start": "2022-05-17T00:00",
      });

      const token = localStorage.getItem('user')
      const axiosConfig = {

        headers : {
            'Content-Type': 'application/json',
            'Authorization': 'Token '+ token,
          },
      }
        axios.post('https://api.nmeco.xyz/v1/api/account/', dataUser, axiosConfig)
        .then((response) => {
          alert(response)
        })
        .catch((error) => {
          alert(error)
        })
    }
  },
  created() {
    this.getKey()
    setTimeout(() => {
      this.wid = (150)
      this.margin = (260/7)
    }, 5)
  }
})
</script>


<style scoped lang="ListView.scss">
@import 'ListView.scss';
</style>


