





























import { Vue } from 'vue-property-decorator';

export default Vue.extend({

  props: {
    dataSystem: Array,
  },

  methods: {
    isMobile () {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
  },

  created () {
    this.isMobile ()
  }
}) 
